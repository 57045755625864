import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import  InputComponent  from '../../commonComponents/InputComponent/input.js';
import { excel, plus, searchIcon,back_icon_png,no_data_image,
} from "../../images";
import {Form,notification} from 'antd';
import moment from 'moment';

import { 
    ModalComponent} from "../../commonComponents";

import {getStudentCourseContentDetails,
getCoursesContentSectionDetails,getCoursesContentAssignmentDetails,
getCoursesContentreferenceDetails,getCoursesContentContentDetails,getCoursesContentQuestionDetails
} from '../../redux/coursesContent/action.js'


import * as XLSX from 'xlsx';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import SectionViewModal from '../../components/coursesContent/viewModal/sectionViewModal.js';
import ContentViewViewModal from '../../components/coursesContent/viewModal/contentViewModal.js';
import AssignmentViewModal from '../../components/coursesContent/viewModal/assignmentViewModal.js';
import QuestionAndAnswerViewModal from '../../components/coursesContent/viewModal/questionAnswerViewModal.js';
import ReferenceViewModal from '../../components/coursesContent/viewModal/referenceViewModal.js';






const CoursesManagementStudent = () => {
    let dispatch = useDispatch();
    const { coursesName, selectedCourseId, selectedBatchId } = useParams();

    const [api, contextHolder] = notification.useNotification();

    const [data, setData] = useState([]);
    const [filteredDataList, setFilteredData] = useState([]); // Filtered data  

    const [viewSectionVisible, setViewSectionVisible] = useState(false);
    const [viewContentVisible, setViewContentVisible] = useState(false);
    const [viewAssignmentVisible, setViewAssignmentVisible] = useState(false);
    const [viewQuestionAndAnswerVisible, setViewQuestionAndAnswerVisible] = useState(false);
    const [viewReferenceVisible, setViewSReferenceVisible] = useState(false);

    const [popupVisible, setPopUpVisible] = useState(false);
    const [updateViewVisible, setUpdateViewVisible] = useState(null);
    const [mainContentId, setMainContentId] = useState(null);
    const [visibleNotification, setVisibleNotification] = useState(false);
    const [selectedContentId, setSelectedContentId] = useState(null);


    const [coursesContentData, setCoursesContentDataList] = useState([]);


      let [allCoursesContent,setAllCoursesContent] = useState([]);

      let getCoursesContentList = useSelector((state)=> state?.CoursesContentReducer?.getStudentCourseContent);
  
      let getCourseContentSection = useSelector((state)=> state?.CoursesContentReducer?.getCourseContentSection);
      let getCoursesContantDetails = useSelector((state)=> state?.CoursesContentReducer?.getCoursesContantDetails);
      let getCoursesContentAssignment = useSelector((state)=> state?.CoursesContentReducer?.getCoursesContentAssignment);
      let getCourseReference = useSelector((state)=> state?.CoursesContentReducer?.getCourseReference);
      let getCoursesQuestion = useSelector((state)=> state?.CoursesContentReducer?.getCoursesQuestion);

      useEffect(()=>{
        if (getCoursesQuestion) {
          setCoursesContentDataList(getCoursesQuestion)
          if(popupVisible === true){

            


            if(updateViewVisible === 1){
              setViewQuestionAndAnswerVisible(true)
            }else if(updateViewVisible === 2){
            }


          }

        }
    },[getCoursesQuestion])

      useEffect(()=>{
        if (getCourseReference) {
          setCoursesContentDataList(getCourseReference)
          if(popupVisible === true){

            


            if(updateViewVisible === 1){
              setViewSReferenceVisible(true)
            }else if(updateViewVisible === 2){
            }

          }

        }
    },[getCourseReference])

      useEffect(()=>{
        if (getCoursesContentAssignment) {
          setCoursesContentDataList(getCoursesContentAssignment)
          if(popupVisible === true){

            if(updateViewVisible === 1){
              setViewAssignmentVisible(true)
            }else if(updateViewVisible === 2){
            }

          }

        }
    },[getCoursesContentAssignment])

      useEffect(()=>{
        if (getCourseContentSection) {

          setCoursesContentDataList(getCourseContentSection)
          if(popupVisible === true){
            if(updateViewVisible === 1){
              setViewSectionVisible(true)
            }else if(updateViewVisible === 2){
            }

          }

        }
    },[getCourseContentSection])

    useEffect(()=>{
      if (getCoursesContantDetails) {
        setCoursesContentDataList(getCoursesContantDetails)
        if(popupVisible === true){

          if(updateViewVisible === 1){
            setViewContentVisible(true)
          }else if(updateViewVisible === 2){
          }

        }

      }
  },[getCoursesContantDetails])

      useEffect(()=>{
        if (getCoursesContentList) {
    
            setAllCoursesContent(getCoursesContentList)
            setData(getCoursesContentList)
            setFilteredData(getCoursesContentList)

        }
    },[getCoursesContentList])


    useEffect(()=>{

      let payload ={
        "operation_type":"course_content_for_student",
        "student_id":window.localStorage.getItem("student_id"),
        "tenant_id": window.localStorage.getItem("tenant_Id"),
        "batch_id":selectedBatchId,
        "course_id":selectedCourseId
    }
  dispatch(getStudentCourseContentDetails(payload))

  },[])




    const searchCoursePrice = (e) =>{
        // setSearchEntry(e?.target?.value)
        const searchValue = e.target.value.toLowerCase();


        if (searchValue.trim() === "") {
          setData(filteredDataList);
          return;
        }

        const filtered = data.filter(item => 
          item.title.toLowerCase().includes(searchValue) || 
          item.category.toLowerCase().includes(searchValue) || 
          item.content_id.toLowerCase().includes(searchValue) || 
          item.xp.toLowerCase().includes(searchValue) || 
          item.status.toLowerCase().includes(searchValue) || 
          item.badge_name.toLowerCase().includes(searchValue) || 
          item.description.toLowerCase().includes(searchValue)
        );
        // setFilteredData(filtered);
        setData(filtered)

      }

      let handleExportExcel = (type,id) =>{
        const transformedItems = allCoursesContent?.map((item) => {
          return { 
             CONTENT_ID: item.content_id,
             CATEGORY: item.category,
             TITLE:  item.title,
            DESCRIPTION:  item.description,
            BADGE_NAME: item.badge_name,
           XP:  item.xp,
           STATUS:  item.status == 'Inactive' ? 'In Active' : 'Active',


          
          };
        });

        
        exportToExcel(transformedItems, 'course_content');
      
        
      }

      const exportToExcel = (data, fileName) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        XLSX.writeFile(wb, `${fileName}.xlsx`);
      };
      

let handleDeleteCancel= ()=>{
    setViewSectionVisible(false)
    setViewAssignmentVisible(false)
    setViewContentVisible(false)
    setViewSReferenceVisible(false)
    setViewQuestionAndAnswerVisible(false)
} 

    const handleView = (id,category) => {


      setPopUpVisible(true)
      setUpdateViewVisible(1)

      if(category == 'Section'){

        let payload ={
          "operation_type":"retrieve",
    "category":"Section",
    "content_id":id
  
      }
    dispatch(getCoursesContentSectionDetails(payload))

        }else if(category == 'Content'){

          let payload ={
            "operation_type":"retrieve",
            "category":"Content",
            "content_id":id
    
        }
      dispatch(getCoursesContentContentDetails(payload))

      }else if(category == 'Reference'){

        let payload ={
          "operation_type":"retrieve",
            "category":"Reference",
            "content_id":id
  
      }
    dispatch(getCoursesContentreferenceDetails(payload))

      }else if(category == 'Assignment'){
       
        let payload ={
          "operation_type":"retrieve",
          "category":"Assignment",
          "content_id":id
  
      }
    dispatch(getCoursesContentAssignmentDetails(payload))

      }else if(category == 'Q&A'){

        let payload ={
          "operation_type":"retrieve",
          "category":"Q&A",
          "content_id":id
  
      }
    dispatch(getCoursesContentQuestionDetails(payload))

      }



    };

    const handleUpload= (id,category) => {

        setSelectedContentId(id)

         if(category == 'Assignment'){
            navigate(`/studUploadAssignment/${coursesName}/${selectedCourseId}/${selectedBatchId}/${id}`);

        
        }else if(category == 'Q&A'){
  
            navigate(`/studUploadQ&A/${coursesName}/${selectedCourseId}/${selectedBatchId}/${id}`);

        }
  
  
  
      };
  
    const actionBodyTemplate = (rowData) => {
      return (
        <div className="action-buttons">
        
          <Button 
            icon="pi pi-eye" 
            className="p-button-rounded p-button-text" 
            onClick={() => handleView(rowData.content_id,rowData.category)} 
            title="View"
          />

          {rowData.category === 'Assignment' || rowData.category === 'Q&A' ?

          <Button 
  icon="pi pi-upload" 
  className="p-button-rounded p-button-text" 
  onClick={() => handleUpload(rowData.content_id, rowData.category)} 
  title="Upload"
/> : <div></div>}
          
        </div>
      );
    };

    const navigate = useNavigate();


    const backMethod = () => {
      navigate("/studCourseContent")
    };

    const nullOrEmpty = (rowData, field) => {
      return rowData[field] && rowData[field] !== null && rowData[field] !== " " ? rowData[field] : 'N/A';
    };

 
    return (
        <div>
  {contextHolder}

  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
  <h3 style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
    <img 
      src={back_icon_png} 
      alt="Back" 
      style={{ marginRight: '8px', cursor: 'pointer', height: '25px', width: '25px' }} 
      onClick={() => backMethod()} 
    />
    {coursesName} Content
  </h3>

  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ width: '200px', position: 'relative' }}>
      <InputComponent 
        onChange={(e) => searchCoursePrice(e)} 
        placeholder="Search" 
        style={{ paddingRight: '25px', width: '100%' }} 
      />
      <img 
        src={searchIcon} 
        alt="Search Icon" 
        style={{ height: '15px', width: '15px', position: 'absolute', top: '15px', right: '8px' }} 
      />
    </div>

    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
    

      <img 
        src={excel} 
        alt="Excel" 
        style={{ height: '25px', width: '25px', cursor: 'pointer', margin: '0 5px' }} 
        onClick={() => handleExportExcel("Add Category", null)} 
      />
    </div>
  </div>
</div>



{data && data.length > 0 ? (

 <DataTable
        value={data}
       
        paginator
        rows={5}
      >



        <Column field="content_id" header="ID"  />
        <Column field="category" header="Category" />
        <Column field="title" header="Title" />

        <Column field="description" header="Description" />
        <Column field="badge_name" header="Badges"  body={(rowData) => nullOrEmpty(rowData, 'badge_name')} />

        <Column field="xp" header="XP"  body={(rowData) => nullOrEmpty(rowData, 'xp')} />

        <Column field="is_certificate" header="Certificate"  body={(rowData) => nullOrEmpty(rowData, 'is_certificate')} />
        
        {/* <Column field="status" header="Status" body={statusBodyTemplate}     style={{ width: '150px', textAlign: 'center' }} // Fixed width and centering text
 /> */}
      
        <Column header="Actions" body={actionBodyTemplate}  // Fixed width and centering text
        />
      </DataTable>): (
  <div style={{ textAlign: 'center', padding: '20px' }}>
    <img src={no_data_image} alt="No Data Available" style={{ width: '200px', height: 'auto' }} />
    <p>Course Content has not yet created</p>
  </div>
)}
     






{viewSectionVisible ? 
  <ModalComponent title={"View Section"}
  content={<SectionViewModal 
    coursesContentData={coursesContentData}

   handleCancel={handleDeleteCancel}
   />}
open={viewSectionVisible} 
handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>
:""}

{viewContentVisible ? 
  <ModalComponent title={"View Content"}
  content={<ContentViewViewModal 
    coursesContentData={coursesContentData}
   handleCancel={handleDeleteCancel}
   />}
open={viewContentVisible} 
handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>
:""}

{viewAssignmentVisible ? 
  <ModalComponent title={"View Assignment"}
  content={<AssignmentViewModal 
    coursesContentData={coursesContentData}

   handleCancel={handleDeleteCancel}
   />}
open={viewAssignmentVisible} 
handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>
:""}

{viewReferenceVisible ? 
  <ModalComponent title={"View Reference"}

  content={<ReferenceViewModal 
    coursesContentData={coursesContentData}

   handleCancel={handleDeleteCancel}
   />}
open={viewReferenceVisible} 
handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>
:""}

{viewQuestionAndAnswerVisible ? 
  <ModalComponent title={"View Q&A"}

  content={<QuestionAndAnswerViewModal 
    coursesContentData={coursesContentData}

   handleCancel={handleDeleteCancel}
   />}
open={viewQuestionAndAnswerVisible} 
handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>
:""}

        </div>
    );
}

export default CoursesManagementStudent;
