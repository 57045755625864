import React, { useEffect,useState } from 'react'

import { InputComponent,InputSelect } from '../../../commonComponents';

import { Button, Form, message, Alert, DatePicker } from 'antd';
import './addInstructor.css';
import { type } from '@testing-library/user-event/dist/type';
import { Spin } from 'antd';
import moment from 'moment';




let AddInstructor=(props)=> {




  let list = []
  for(let x=18;x<=50;x++){
   let newObj= {};
      newObj["value"] = x;
      newObj["label"] = x;
    list.push(newObj)
 }
let obj=[]
 props.categoryDropdown && Object.values(props.categoryDropdown).map((i,Index)=>{
  let newObj= {};
  newObj["value"] = i.course_category_id;
  newObj["label"] = i.course_category;
obj.push(newObj)

})

let obj2=[]
 props.categoryBasedCourses && Object.values(props.categoryBasedCourses).map((i,Index)=>{
  let newObj= {};
  newObj["value"] = i.course_id;
  newObj["label"] = i.course_name;
obj2.push(newObj)

})
console.log(props.instructorStatus,"status")


const [dateError, setDateError] = useState('');

let handleDateChange = (dt,e,type)=>{

 const age = moment().diff(e, 'years');

 console.log(e,age,"DOB")

 if (age < 18) {
   setDateError('You must be at least 18 years old.');
   // form.setFieldsValue({ startDate: null });
 } else {
   setDateError('');
  //  setStartDate(e)
  //  setDate(dt); 

   console.log(e,"DOB")
  //  form.setFieldsValue({ startDate: e })  
 }
 



 
}

const disabledDate = (current) => {

  const today = moment();
  // Disable dates that are in the future
  return current && current > moment().endOf('day');
};

  return (
    <div className='addInstructor'>
      
          {props?.loader && props?.loader ? <Spin/>: ""}
        <Form
        autoComplete="off"
        layout="vertical"
        form={props.form}
        onFinish={props.onFinish}
        >
        <div className='names'>
            <div>
                <Form.Item
                label="First Name"
                name={"instructorFirstName"}
                rules={[
                  { required: true, message: 'Please enter first name' }
                  ]}>
                <div className='InstructorInput'>
                  <InputComponent placeholder={"First Name"}
                   className="InstructorInput" value={props.instructorFirstName}
                 name={"instructorFirstName"}
                  onChange={(e)=>props.handleInputChange(e,{name: "instructorFirstName"})}/></div>
            </Form.Item>
            </div>
            <div>
              <Form.Item
              label="Last Name"
              name="instructorLastName"
              rules={[
                { required: true, message: 'Please enter last name' }
                ]}>
                <div className='InstructorInput'>
                  <InputComponent
                  name="instructorLastName"
                   placeholder={"Last Name"}
                    className={"InstructorInput"}
                     value={props.instructorLastName} 
                      onChange={(e)=>props.handleInputChange(e,{name:"instructorLastName"})}/>
                  </div>
                  </Form.Item>
            </div>
        </div>

        <div className='selectDropdownsInstructor'>
            <div >
              <Form.Item
              label="Gender"
              name="gender"
              rules={[
                { required: true, message: 'Please select gender' }
                ]}>
            <div ><InputSelect 
                defaultValue={props.gender ? props.gender : "Select"}
                value={props.gender}
                 name={"gender"}
                onChange={(e)=>props.handleDropdowns(e,{name: "gender"})}
                options={[
                    {
                        value: "Male",
                        label: 'Male',
                      },
                      {
                        value: "Female",
                        label: 'Female',
                      },
                  
                  ]}/>
              </div>
              </Form.Item>
            </div>

            <div>

            <div >
<Form.Item
           label="DOB"
            name="dob"
            rules={[{ required: true, message: 'Please select dob.' }]}>
            <div className='batchDate'>
          <DatePicker name='dob'
          disabledDate={disabledDate}
          // onChange={handleDateChange}
          placeholder="Select Date"
          value={props.studentDOb ? moment(props.studentDOb) : null}

        onChange={(e,dateString)=>props.handleDropdownChange(dateString,{name:"dob"})}

         
            />

          </div>
          </Form.Item>
          {/* {dateError && <Alert message={dateError} type="error" showIcon />} */}

    </div>

            {/* <Form.Item
    label="DOB"
    name="dob"
    rules={[{ required: true, message: 'Please select DOB.' }]}
>
    <div className="batchDate">
        <DatePicker
            name="dob"
            disabledDate={disabledDate}

            onChange={(date, dateString) => props.handleDropdownChange(dateString, { name: "dob" })}
            value={props.studentDOb ? moment(props.studentDOb) : null}
            placeholder="Select Date"
        />
    </div>
</Form.Item> */}


{/* <Form.Item
                         label="DOB"
                         name={"dob"}
                          rules={[{ required: true, message: 'Please select DOB.' }]}>
                          <div className='batchDate'>
                       

<DatePicker
  name='dob'
  mode='date'
  onChange={(date, dateString) => props.handleDropdownChange(dateString, { name: "dob" })}
  // defaultValue={props.studentDOb}
  // value={props.studentDOb}

  value={props.studentDOb ? moment(props.studentDOb) : null}
  placeholder={"Select Date"}

/>
                        </div>
                        </Form.Item> */}
</div>



          
         
        </div>

        <div className='instructorCourseSelection' >
              <Form.Item
              label={"Select Course Category"}
              name="instructorTopic"
              rules={[
                { required: true, message: 'Please select course category' }
                ]}>
             <div>
                    <InputSelect  name="instructorTopic"
                  defaultValue={props.instructorTopic? props.instructorTopic :"Select"}
                 value={props.instructorTopic} 
                 onChange={(e)=>props.handleDropdowns(e,{name: "instructorTopic"})}
                 options={obj}
                  
                  />
                  {/* <Select name="instructorTopic"
                  onChange={(e)=>props.handleDropdowns(e,{name:"instructorTopic"})}>
                  {props.categoryDropdown && props.categoryDropdown.map((item,index)=>{
                    return <Select.Option key={item.course_category_id} value={item.course_category_id}>
                      {item.course_category}</Select.Option>
                    })}
                  </Select> */}
                  </div>
                  </Form.Item>
            </div>

        <div className='instructorCourseSelection'>
          <Form.Item
           label={"Select Course"}
           name="instructorCourseId"
           rules={[
             { required: true, message: 'Please select topic' }
             ]}>
              <InputSelect  name="instructorCourseId"
                  defaultValue={props.instructorCourseId? props.instructorCourseId :"Select"}
                 value={props.instructorCourseId} 
                 onChange={(e)=>props.handleDropdowns(e,{name: "instructorCourseId"})}
                 options={obj2}
                  
                  />

          </Form.Item>
        </div>







        {props?.resumeData && (
          <div>
            <p>File Chosen:</p>
            {props?.resumeData.type.startsWith('image/') ? (
              <img src={props.resumeUpload} alt="chosen file" style={{ maxWidth: '100%' }} />
            ) : (
              <div>
                <p>File type: {props?.resumeData.type}</p>
                <a href={props.resumeUpload}
                 download={props?.resumeData.name}>Download {props?.resumeData.name}</a>
              </div>
            )}
          </div>
        )}
        <div className='uploadResume'>
                  <Form.Item
                  label="Resume"
                  name="resumeUpload"
                  rules={[
                    //  { required: true, message: 'Please Upload resume' }
                    ]}>
    
    <span className='fileExistsInInstructor'>{props?.modalType !="Add Instructor" ? "File uploaded": ""}</span>

      <InputComponent  type={"file"} name={'resumeUpload'}  
        onChange={(e)=>props.onFileChange(e,{name: "resumeUpload"})}
        />
    </Form.Item>
 
        </div>

        <div className='contact'>
            <div>
              <Form.Item
              label={"Phone Number"}
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: 'Phone number is required only numbers allowed',
                  pattern: new RegExp(/^[0-9]+$/),
                 
                },
                {
                 max: 10,
                 min:10,
                 message: "10 digit mobile number is required",
               },

              ]}>
                <div className='InstructorInput'>
      <InputComponent
       placeholder={"Phone Number"} className={"InstructorInput"} 
       value={props.phoneNumber} 
       name="phoneNumber" onChange={(e)=>props.handleInputChange(e,{name:"phoneNumber"})}/>
                </div>
                </Form.Item>
            </div>

            <div>
              <Form.Item
              label="Email"
              name={"email"}
              rules={[
                { required: true, message: 'Please enter email',type:"email" }
                ]}>
                <div className='InstructorInput'>
     <InputComponent placeholder={"Email"} className={"InstructorInput"} value={props.email}
      name={"email"} onChange={(e)=>props.handleInputChange(e,{name:"email"})}/>
                  </div>   
                  </Form.Item>                                     

            </div>
        </div>

        <div className='address'>
            <div>
              <Form.Item
              label="Address"
              name="address"
              rules={[
                { required: true, message: 'Please enter address' }
                ]}>
            <InputComponent placeholder={"Enter Address"} 
             value={props.address} name={"address"}
              onChange={(e)=>props.handleInputChange(e,{name:"address"})}/>
            </Form.Item>

            </div>
        </div>

        {props.modalType == "Edit Instructor" ?
            <div>
              <Form.Item
              label="Status"
              name={"instructorStatus"}
              rules={[
                // { required: true, message: 'Please select status' }
                ]}>
                    <div className="genderSelet"><InputSelect
                     selected={props.instructorStatus}
                     name = "instructorStatus"
                     onChange={(e)=>props.handleDropdowns(e,{name: "instructorStatus"})}
                     defaultValue={props.instructorStatus == 'true' ? "Active" : "Inactive"}
                     options={[
                      {
                        value: 'true',
                        label: 'Active',
                      },
                      {
                        value: 'inactive',
                        label: 'Inactive',
                      },
                      
                     
                     ]}/>
                     </div>
                     </Form.Item>
            </div>
            : ""}

            <div>
            <Form.Item>
                <div className='addTenantFooterBtns'>
                <div>
                <Button type={"secondary"}  className="cancel" onClick={props?.closeCreateModal}>Cancel</Button>
                </div>
                <div className='alignRight'>
                <Button type={"primary"}  className="submit" htmlType="Submit">Submit</Button>
                </div>
                </div>
                </Form.Item>
            </div>
            </Form>
    </div>
  )
}

export default AddInstructor