import { InputComponent, InputSelect } from "../../../commonComponents";
import { Button, DatePicker, Form, Space ,Select} from 'antd';
import { Spin } from 'antd';
import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { studentRegistration, studentRegistrtionSuccess ,retrieveInterestedCourses} from "../../../redux/studentRegister/action";
import moment from "moment";



import './addStudent.css'

let AddStudent = (props) =>{
  

  let dispatch = useDispatch()

  console.log(props.studentDOb,'avni')
  let list = []
   for(let x=18;x<=50;x++){
    let newObj= {};
       newObj["value"] = x;
       newObj["label"] = x;
     list.push(newObj)
  }

  const disabledDate = (current) => {

    console.log('Checking Date:', current);
  
    return current && current > moment().endOf('day');
  
  
  }
  let interestedCoursesDetails = useSelector((state)=>state.studentRegisterReducer.retrieveInterestedCourses);
  useEffect(()=>{
   
    let registrationPayload = {
      "operation_type":"course_retrieve",
      "tenant_id":1
  }

  console.log(registrationPayload,'registrationPayload')
   dispatch(retrieveInterestedCourses(registrationPayload)) 
  },[])
  useEffect(()=>{
    if (interestedCoursesDetails) {
      //  openNotification(registration)
      setCategoryDropdown(interestedCoursesDetails)
      // navigate("/verifyEmail",{state:{email:registrationEmail}})
      console.log(interestedCoursesDetails,"stages")
    }
  },[interestedCoursesDetails])
  let [categoryDropdown,setCategoryDropdown] = useState();


  let [startDate,setStartDate] = useState("");
  let [interestedId,setInterestedId] = useState();


  let handleDateChange = (e,type)=>{
    setStartDate(e)
}

let handleCategoryChange = (e,type) =>{
 

  setInterestedId(e)

}
     return (
        <div className="AddStudent">
          {props?.loader && props?.loader ? <Spin/>: ""}
          <Form
          autoComplete="off"
          layout="vertical"
          form={props.form}
          onFinish={props.onFinish}>
            <div className="studentNames">
                <div className="studentInput">
                  <Form.Item
                  label="First Name"
                  name="firstName"
                  rules={[
                    { required: true, 
                      message: 'Please enter first name'
                     }
                    ]}>
                    <div className="InstructorInput">
                      <InputComponent placeholder={"First Name"}
                       name={"firstName"} 
                       value={props.firstName}
                        onChange={(e)=>props.handleInputChange(e,{name:"firstName"})}/>
                    </div>
                    </Form.Item>
                </div>
                <div className="studentInput">
                  <Form.Item
                  label="Last Name"
                  name={"lastName"}
                  rules={[
                    { required: true, message: 'Please enter last name' }
                    ]}>
                    <div className="InstructorInput">
                      <InputComponent placeholder={"Last Name"}
                       name={"lastName"} value={props.lastName}
                       onChange={(e)=>props.handleInputChange(e,{name:"lastName"})}/></div>
                </Form.Item>
                    </div>
            </div>

            <div>
                      <Form.Item
                       label="Course Name"
                       name={"course_name"}
                        rules={[
                          { required: true, message: 'Please select course name' }
                          ]}>
                      <div className='SelectWidth'>
                      <Select name={"studentCourseName"} 
                      // value={props.studentCourseName } 

                        defaultValue={props.studentCourseName  ? props.studentCourseName :"Select Course Name"}
                        onChange={(e)=>props.handleDropdownChange(e,{name:"course_name"})}
                        placeholder="Select Course Name"
                        >
                          {categoryDropdown && categoryDropdown.data?.map((item,index)=>{
                          return  <Select.Option value={item.course_id}>{item.course_name}</Select.Option>
                          })}
                        </Select>

                      </div>
                      </Form.Item>
                    </div>

            <div className="">
              <div className="ff">
                    <div className="studentGender">
                        <div className="studentSelectGender">
                          <Form.Item
                          label="Gender"
                          name={"gender"}
                          rules={[
                            { required: true, message: 'Please select gender' }
                            ]}>
                          <InputSelect
                         name="gender"
                        //  selected={props.gender} 
                         onChange={(e)=>props.handleDropdownChange(e,{name:"gender"})}
                         value = {props.gender}
                         defaultValue = {props.gender ? props.gender : "Select"}
                         options={[
                          {
                              value: "Male",
                              label: 'Male',
                            },
                            {
                              value: "Female",
                              label: 'Female',
                            },
                        
                        ]}/>
                            </Form.Item>
                         </div>



     


                         <div className="intructorSelectAge">



                   
                         {/* <Form.Item
                         label="DOB"
                         name={"dob"}
                          rules={[{ required: true, message: 'Please select DOB.' }]}>
                          <div className='batchDate'>
                       

<DatePicker
  name='startDate'
  mode='date'
  onChange={(date, dateString) => props.handleDropdownChange(dateString, { name: "dob" })}
  defaultValue={props.studentDOb}
  placeholder={"Select Date"}
  // value={props.studentDOb}
/>
                        </div>
                        </Form.Item> */}

                        <Form.Item
    label="DOB"
    name={"dob"}
    rules={[{ required: true, message: 'Please select DOB.' }]}
>
    <div className='batchDate'>
        <DatePicker
            name='startDate'
            disabledDate={disabledDate}

            onChange={(date, dateString) => props.handleDropdownChange(dateString, { name: "dob" })}
            defaultValue={props.studentDOb ? moment(props.studentDOb) : null} // Use moment for defaultValue
            placeholder={"Select Date"}
        />
    </div>
</Form.Item>

                    </div>


 

                    </div>
               
                        </div>
                    

            </div>
                
            <div className="studentContact">
                <div className="studentInput">
                  <Form.Item
                  label="Phone Number"
                  name="phNumber"
                  rules={[
                    {
                      required: true,
                      message: 'Phone number is required only numbers allowed',
                      pattern: new RegExp(/^[0-9]+$/),
                     
                    },
                    {
                     max: 10,
                     min:10,
                     message: "10 digit mobile number is required",
                   },

                  ]}>
                    <div><InputComponent placeholder={"Phone Number"}
                    name="phNumber" value={props.phNumber}
                     onChange={(e)=>props.handlePhoneNumberChange(e,{name:"phNumber"})}/></div>
                  </Form.Item>              
                </div>

                <div className="studentInput">
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: 'Please enter email', type:"email" }
                    ]}>    
                    <div><InputComponent placeholder={"Enter Email"} value={props.email} name={"email"}
                      onChange={(e)=>props.handleEmailChange(e,{name:"email"})}/></div>
                </Form.Item>
                </div>

            </div>

            <div className="studentAddress">
              <Form.Item
              label="Address"
              name={"address"}
              rules={[
                { required: true, message: 'Please enter address' }
                ]}>
                <div><InputComponent placeholder={"Enter Address"}
                 name={"address"} value={props.address} 
                 onChange={(e)=>props.handleAddressChange(e,{name:"address"})}/></div>
              </Form.Item>            
            </div>

            {props.modalType !== "Add Student" ?
            <div>
              <Form.Item
               label="Status"
               name={"studentStatus"}
               rules={[
                //  { required: true, message: 'Please select status' }
                 ]}>
                    <div className="genderSelet">
                      <InputSelect name="studentStatus"
                     selected={props.studentStatus}
                     onChange={(e)=>props.handleDropdownChange(e,{name:"studentStatus"})}
                     defaultValue={props.studentStatus == 'true' ? "Active" : "Inactive"}
                     options={[
                       {
                         value: 'true',
                         label: 'Active',
                       },
                       {
                         value: 'inactive',
                         label: 'Inactive',
                       },
                      
                     
                     ]}/>

                     </div>
                     </Form.Item>
            </div>
            : ""}
            <div>
            <Form.Item>
                <div className='addTenantFooterBtns'>
                <div>
                <Button type={"secondary"}  className="cancel" onClick={props?.closeModal}>Cancel</Button>
                </div>
                <div className='alignRight'>
                <Button type={"primary"}  className="submit" htmlType="Submit">Submit</Button>
                </div>
                </div>
                </Form.Item>
            </div>
            </Form>
        </div>
     )
}

export default AddStudent