import React, { useEffect } from "react";

import { PopOverComponent,InputComponent,TableComponent,ModalComponent, CheckboxComponent, ButtonComponent } from "../../commonComponents";

import { useState } from "react";
import './allStudents.css';
import { NavLink, Outlet } from "react-router-dom";

import CourseTypes from "../courseTypes/courseTypes";
import dayjs from 'dayjs';

import { course,edit,excel,plus,upload,deleteIcon,searchIcon,TableEyeIcon } from "../../images";
import { Button, Form, Table, notification } from "antd";
import AddStudent from "./AddStudent/addStudent";
import store from '../../redux/store'
import moment from "moment";
import { useSelector,useDispatch } from "react-redux";
import { createStudents, deleteStudent, editStudent, emailExistsStudent, emailExistss, loadStudents, validateEmail } from "../../redux/student/action";
import StudentDetail from "./StudentDetail/studentDetail";
import { type } from "@testing-library/user-event/dist/type";
import { bulkAdminStudentDelete, createAdminStudent, deleteAdminStudent, retrieveAllAdminStudents, studentBulkActions, updateStudentAdmin,studentRequestApproved,studentRequestRejected } from "../../redux/adminStudent/action";

import * as XLSX from 'xlsx';
import ExportToExcel from '../../commonComponents/XlsxFile/ExportToExcel'

let AllStudents = () =>{
  

  let dispatch = useDispatch();

  const [api, contextHolder] = notification.useNotification();

  let [students,setStudents] = useState();

  let [studentId, setStudentId] = useState();

  const [popoverVisible, setPopoverVisible] = useState(false);


  let [status,setStatus] = useState()
  let [studentDetails,setStudentDetails] = useState(false);

  let [studentRecord,setStudentRecord] = useState([]);

  const [selectedRowsKeys, setSelectedRowKeys] = useState([]);

  const [selectedDropdown,setSelectedDropdown] = useState("Select")

  const [initialpage,setInitialPage] = useState("")
  // let data1 = useSelector((state) => state.student.students?.data);


  // let imp = useSelector((state)=> state.student?.emailIN)
  // console.log('imp',imp)
  
  
  // useEffect(()=>{
  //   setStudents(data1)
  // },[data1])

 
  // useEffect(()=>{
  //     dispatch(loadStudents())
  // },[])
 

  


// useEffect(()=>{
//   setStudents(allAdminStudents?.data)
// },[actionType])

let [studentIdType,setStudentIdType] = useState();


  let onChangeBulkActions = (type) =>{

    console.log(type,"anil")
    console.log(selectedRowsKeys,"anil")

    if(type == true){

      setStudentIdType('true')

    }else if(type == false){

      setStudentIdType('inactive')

    }


   if(selectedRowsKeys.length != 0){

    if (type !=="Delete") {


      

      let payload ={
        "operation_type":"multiple_update",
        "op_field_details":{
            "student_id":selectedRowsKeys[0],
            "is_active":type == true ? 'true' : 'inactive'
        }
    }
    dispatch(studentBulkActions(payload))
    setSelectedDropdown("Select");
    }else{
      let deletePayload = {
        "operation_type": "delete",
        "op_field_details":{
            "student_id": selectedRowsKeys[0],
            "tenant_id": window.localStorage.getItem("tenant_Id")
        }
    }
    dispatch(bulkAdminStudentDelete(deletePayload))
    }
   }else{
    openNotification('Select student id')

   }

    
  }
  
  //state
  let studentCrudStatus = useSelector((state)=>state.AdminStudent?.studentCrudStatus);

  let allAdminStudents = useSelector((state)=>state.AdminStudent?.adminStudents);

  let studentRequestApprovedMsg = useSelector((state)=>state.AdminStudent?.approvedStatus);
  let studentRequestRejectedMsg = useSelector((state)=>state.AdminStudent?.rejectedStatus);

 
  const [activeTypes,setActiveTypes] = useState([]);

  const [inactiveTypes,setInactiveTypes] = useState([]);


  useEffect(()=>{
    if(studentRequestApprovedMsg){

      console.log(studentRequestApprovedMsg,'studentRequestApprovedMsg')
      openNotification(studentRequestApprovedMsg.msg)

    }else{

    }
  },[studentRequestApprovedMsg])

  useEffect(()=>{

    if(studentRequestRejectedMsg){
      console.log(studentRequestRejectedMsg,'studentRequestRejectedMsg')

      openNotification(studentRequestRejectedMsg.msg)

    }else{

    }
  },[studentRequestRejectedMsg])
  

  const openNotification = (status) => {
   
      if (status) {
        api.info({
            description:
            `${status}`,
  
          });
    }
  
    if (status == false) {
      api.info({
        // message: `Category ${details.course_category_name}`,
        description:
          'Email already exists!',
      });
    }
  
  };
 
  useEffect(()=>{
    dispatch(retrieveAllAdminStudents())
  },[])

  useEffect(()=>{
      if (allAdminStudents) {
        setStudents(allAdminStudents?.data)
      }else{
        setStudents([])

      }
  },[allAdminStudents])

  let bulkActions = [
    {label: "ACTIVE",value:true},
    {label: "INACTIVE",value:false},
    {label: "DELETE",value: "Delete"}
  ]


  const courseTypeValues = [
    {label:"ALL",value:allAdminStudents?.data?.length},
    {label:"ACTIVE",value:allAdminStudents?.data?.filter((item)=> item.is_active == 'true').length},
    {label:"INACTIVE",value:allAdminStudents?.data?.filter((item)=> item.is_active == 'false').length},
    {label:"PENDING",value:allAdminStudents?.data?.filter((item)=> item.is_active == 'pending').length},

      // {label:"TRASH",value:0},
  ]

  const [actionType,setActionType] = useState("");

  const handleCourseTypes = (type) =>{
    setActionType(type.target?.getAttribute("label"))
    if (type.target?.getAttribute("label") == "ACTIVE") {
       let array=[]
      allAdminStudents?.data && allAdminStudents?.data.filter((item,index)=>{
         if (item.is_active == 'true') {
          array.push(item)
         }
      })
      setStudents(array)
      }

    if (type.target?.getAttribute("label") == "INACTIVE") {
      let array = []
      allAdminStudents?.data && allAdminStudents?.data.filter((item,index)=>{
        if (item.is_active == 'false') {
          array.push(item)
        }
     })
     setStudents(array)
    }

    if (type.target?.getAttribute("label") == "PENDING") {
      let array = []
      allAdminStudents?.data && allAdminStudents?.data.filter((item,index)=>{
        if (item.is_active == 'pending') {
          array.push(item)
        }
     })
     setStudents(array)
    }

    if (type.target?.getAttribute("label") == "ALL") {
      setStudents(allAdminStudents?.data)
    }
  }

  const [form] = Form.useForm();
  let [isModalVisible,setIsModalVisible] = useState(false);

  let [isDeleteModalVisible,setIsDeleteModalVisile] = useState(false);

  let[modalType,setModalType] = useState(" ");
  let [firstName,setFirstName] = useState("");
  let [lastName,setLastName] = useState("");
  let [gender,setGender] = useState("");

  let [studentDOb,setStudentDob] = useState("");
  let [studentCourseName,setStudentCourseName] = useState("");


  let [phNumber,setPhNumber] = useState("");
  
  let [email,setEmail] = useState("");

  let [address,setAddress] = useState("");

  let [age,setAge] = useState("");

  let [guardianName,setGuardianName] = useState("");

  let [studentStatus,setStudentStatus] = useState("");

  let [topic,setTopic] = useState("");


  let[searchEntry,setSearchEntry] = useState("");

  let [loader,setLoader] = useState(false)

//state ends

  let handleModal = (type,id) =>{
    setIsModalVisible(true)
    setIsDeleteModalVisile(false)
    setModalType(type)
  }

  let handleCancel= ()=>{
    setIsModalVisible(false)
    setFirstName("");
    setLastName("");
    setGender("");
    setAge("");
    setPhNumber("");
    setTopic("");
    setEmail("");
    setAddress("");
    setGuardianName("");
    dispatch(loadStudents())
  }  

  let handleEmailChange = (e,type) =>{
      if (type.name == "email") {
        setEmail(e.target.value)
      }
  }

  let handlePhoneNumberChange = (e,type) =>{
    if (type.name == "phNumber") {
      setPhNumber(e.target.value)
    }
  }



  let handleAddressChange = (e,type) =>{
    if (type.name == "address") {
      setAddress(e.target.value)
    }
  }
  let handleInputChange=(e,type)=>{
      if (type.name == "firstName") {
        setFirstName(e.target.value)
        form.setFieldsValue({[type.name]:e?.target.value})
      }

      if (type.name == "lastName") {
        setLastName(e.target.value);
        form.setFieldsValue({[type.name]:e?.target.value})

      }

      if (type.name == "guardianName") {
        setGuardianName(e.target.value);
        form.setFieldsValue({[type.name]:e?.target.value})
      }
    }

    let handleDropdownChange = (e,type)=>{
      if (type.name == "age") {
        setAge(e)
        form.setFieldsValue({[type.name]:e})

      }

      if (type.name == "gender") {
        setGender(e);
        form.setFieldsValue({[type.name]:e})

      }
      if (type.name == "studentStatus") {
        setStudentStatus(e);
        form.setFieldsValue({[type.name]:e})

      }

      if (type.name == "course_name") {
        setStudentCourseName(e);
        form.setFieldsValue({[type.name]:e})

      }

      if (type.name == "dob") {

        console.log(e)
        setStudentDob(e);
        form.setFieldsValue({[type.name]:e})

      }
    }


  const onFinish = () => {
    if (modalType !="Edit Student") {
      console.log(studentDOb,"createPayload");
     

        let payload= {
          "operation_type": "student_enquiry",
          "op_field_details": {
              "first_name": firstName,
              "last_name": lastName,
              "gender": gender,
              "address": address,
              "tenant_id": window.localStorage.getItem("tenant_Id"),
              "email": email?.toLowerCase(),
              "phone_no": phNumber,
              // "guardian_name": guardianName,
              "course_id":studentCourseName,
              "birth_date":studentDOb
              // "is_active":true
              
          }
        }
        dispatch(createAdminStudent(payload))
        console.log(payload,"createPayload");
        // setLoader(true)

        setIsModalVisible(false)
        
       
      }

      
        else  {
          
         let editPayload= {
          "operation_type":"update",
          "op_field_details": {
              "student_id":studentId,
              "first_name": firstName,
              "last_name": lastName,
              "gender": gender,
              "address": address,
              "tenant_id": 1,//window.localStorage.getItem("tenant_Id")
              "email": email?.toLowerCase(),
              "phone_no": phNumber,
              "is_active": (studentStatus),
              "course_id":studentCourseName,
              "birth_date":studentDOb,


          }
        }

        console.log(editPayload,'editPayload')
           
          dispatch(updateStudentAdmin(editPayload))
          setIsModalVisible(false)
          setFirstName("");
          setLastName("");
          setGender("");
          setAge("");
          setPhNumber("");
          setTopic("");
          setEmail("");
          setAddress("");
          setGuardianName("");
          setStatus("");
        }
  };

  useEffect(()=>{
    if (studentCrudStatus) {
      openNotification(studentCrudStatus?.msg,studentCrudStatus)

      if(studentCrudStatus.res_status  === true){
      setFirstName('')
      setLastName('')
      setAddress('')
      setEmail('')
      setPhNumber('')
      setStudentDob(null)
      setGender('')
      setStudentCourseName('')
      form.setFieldsValue({["firstName"]:''})
      form.setFieldsValue({["lastName"]:''})
      form.setFieldsValue({["course_name"]:''})
      form.setFieldsValue({["gender"]:''})
      form.setFieldsValue({["startDate"]:null})
      form.setFieldsValue({["phNumber"]:''})
      form.setFieldsValue({["email"]:''})
      form.setFieldsValue({["address"]:''})
      form.setFieldsValue({["firstName"]:''})
      form.setFieldsValue({["firstName"]:''})
      form.setFieldsValue({["firstName"]:''})
      form.setFieldsValue({["firstName"]:''})
      }

    

    }
  },[studentCrudStatus])

  // const openNotification = (msg,studentCrudStatus) => {
  //   // if (msg !="" && studentCrudStatus?.res_status == false) {
  //   //   api.info({
  //   //     type: 'error',
  //   //     description:
  //   //       `${msg}`,
  //   //   });
  //   // }
    
  //   if (msg && studentCrudStatus) {
  //       if (studentCrudStatus?.res_status) {
  //         setIsModalVisible(false);
  //         setLoader(false)
  //         setFirstName("");
  //         setLastName("");
  //         setGender("select");
  //         setAge("select");
  //         setPhNumber("");
  //         setEmail("");
  //         setAddress("");
  //         setGuardianName("");
  //         form.setFieldsValue({["age"]:"select"})
  //         form.setFieldsValue({["gender"]:""})
  //       }
        
  //       api.info({
  //           description:
  //           `${msg}`,
  
  //         });
  //   }
  // };

  
  let closeModal = () =>{
    setIsModalVisible(false)
    setFirstName("");
    setLastName("");
    setGender("");
    setAge("");
    setPhNumber("");
    setTopic("");
    setEmail("");
    setAddress("");
    setGuardianName("");
    setStatus("")
    dispatch(loadStudents())
  }
 
 

  //EditModal
  let handleEditModal = (type,id) =>{
    setIsModalVisible(true)
    setIsDeleteModalVisile(false)
    setModalType(type)
      setStudentId(id)  
      students.filter((item,index)=>{
        if(item.student_id == id){
          console.log(item.gender,item.age,'edit')
          setFirstName(item.first_name);
          setLastName(item.last_name)
          setGender(item.gender);
          setAge(item.age);
          setGuardianName(item.guardian_name);
          setPhNumber(item.phone_no);
          setEmail(item.email);
          setAddress(item.address);
          setStudentRecord(item)
          setStudentStatus(item.is_active);
          setStudentDob(dayjs(item.birth_date,"DD-MM-YYYY"));//moment(item.birth_date, "YYYY-MM-DD")
          setStudentCourseName(item.course_id);
        
          console.log(item.birth_date,'date')
          console.log(moment(item.birth_date, "YYYY-MM-DD"),'date')

          form.setFieldsValue({["firstName"]:item.first_name})
          form.setFieldsValue({["lastName"]:item.last_name})
          form.setFieldsValue({["age"]:item.age})
          form.setFieldsValue({["gender"]:item.gender})
          form.setFieldsValue({["address"]:item.address})
          form.setFieldsValue({["phNumber"]:item.phone_no})
          form.setFieldsValue({["studentStatus"]:item.is_active})
          form.setFieldsValue({["email"]:item.email})
          form.setFieldsValue({["guardianName"]:item.guardian_name})
          form.setFieldsValue({["dob"]:item.birth_date})
          form.setFieldsValue({["course_name"]:item.course_name})

          }
      })

  }

      //DeleteModal
      let handleDeleteModal = (id) =>{
        setStudentId(id)
        setIsDeleteModalVisile(true)
        setIsModalVisible(false)
        setModalType("Delete Student")
      }
    
      let  closeDeleteModal = () =>{
        setIsDeleteModalVisile(false);
      }
    
      let handleDeleteCancel= ()=>{
        setIsDeleteModalVisile(false)
      } 
    
      const handleDeleteOk = (student_id) => {
          dispatch(deleteAdminStudent(studentId))
          setIsDeleteModalVisile(false);
      };



      //singleStudent

    let handleViewStudent = (Id) =>{
      setStudentId(Id);
      students.filter((item,index)=>{
        if(item.student_id == Id){
          setStudentRecord(item)
        }
      })
      setStudentDetails(true)
      }

    let handleStudentCancel = () =>{
      setStudentDetails(false)
      setFirstName("");
      setLastName("")
      setGender("");
      setAge("");
      setGuardianName("");
      setPhNumber("");
      setEmail("");
      setAddress("");
      setStudentRecord("")
      setStatus("")
    }

    useEffect(()=>{
      if (searchEntry) {
        setStudents(allAdminStudents?.data)
      }
  },[searchEntry])

  const searchStudentByEmail = (e) =>{
    let data = e.target.value;
    // setSearchEntry(allAdminStudents?.data)
setSearchEntry(data)
// if (data == "") {
//   setStudents(allAdminStudents?.data)
// }

// if (!data) return; // Handle empty search term
// const filteredArray = students.filter(record => 
//   record.email.toLowerCase().includes(data?.toLowerCase())||
//   record.first_name.toLowerCase()?.includes(data?.toLowerCase())||
//   record.last_name.toLowerCase()?.includes(data?.toLowerCase())||
//   record.phone_no.includes(data)||
//   record.guardian_name != null && record.guardian_name.toLowerCase()?.includes(data?.toLowerCase())

// );
// setStudents(filteredArray); 
  }
    const columns = [
        {
          title: 'ID',
          dataIndex: 'student_id',
          sorter: true,
          sorter: (a, b) => a.student_id - b.student_id,
          filteredValue: [searchEntry],
          onFilter: (value,record)=>{
            return String(record.first_name).toLowerCase().includes(value.toLowerCase())||
                   String(record.last_name).toLowerCase().includes(value.toLowerCase())||
                   String(record.phone_no).toLowerCase().includes(value.toLowerCase())||
                   String(record.email).toLowerCase().includes(value.toLowerCase())||
                   String(record.guardian_name).toLowerCase().includes(value.toLowerCase())||
                   String(record.course_name).toLowerCase().includes(value.toLowerCase())||
                   String(record.is_active).toLowerCase().includes(value.toLowerCase())||
                   String(record.age).includes(value)||
                   String(record.student_id).includes(value)
                 }

        },
        {
            title: 'Student Name',
            dataIndex: 'first_name',
            sorter: true,
            // onFilter: (value, record) => record.category.indexOf(value) === 0,
            sorter: (a, b) => a.first_name.localeCompare(b.first_name),
            render: (text, record) => (
              <div className="actionIcons">
                  <span>{record.first_name+" "+record.last_name}</span>
              </div>
             ),
          },
          
        {
          title: 'Phone',
          dataIndex: 'phone_no',
          // sorter: true,
          sorter: (a, b) => a.phone_no .localeCompare(b.phone_no),
        },
        {
          title: 'Email',
          dataIndex: 'email',
          // sorter: true,
          sorter: (a, b) => a.email .localeCompare(b.email),
         

        },

        {
          title: 'Course Name',
          dataIndex: 'course_name',
          // sorter: true,
          // sorter: (a, b) => a.course_name -(b.course_name),

          sorter: (a, b) => {
            // Handle cases where course_name is null
            if (a.course_name === null && b.course_name === null) return 0;
            if (a.course_name === null) return -1;
            if (b.course_name === null) return 1;
            
            // Normal comparison if course_name is not null
            return a.course_name.localeCompare(b.course_name);
          },
         
        },

        {
          title: 'Registered Date and Time',
          dataIndex: 'created_date',
          // sorter: true,
          render: (text, record) => (
            <div className="actionIcons">
                <span>{(moment(record.created_date, "DD-MM-YYYY HH:mm:ss"))
                ? (moment(record.created_date, "DD-MM-YYYY HH:mm:ss")).format("DD-MM-YYYY hh:mm:ss A") : "" }</span>
            </div>
           ),
           sorter: (a, b) => a.created_date.localeCompare(b.created_date),

        },
        // {
        //   title: 'Guardian Name',
        //   dataIndex: 'guardian_name',
        //    sorter: true,
        //   sorter: (a, b) => a.guardian_name .localeCompare(b.guardian_name),

        // },
        {
            title: 'Age',
            dataIndex: 'calculated_age',
            sorter: true,
            // render: (text, record) => (
            //   <div className="actionIcons">
            //       <span>{(moment(record.birth_date, "DD-MM-YYYY"))
            //       ? (moment(record.birth_date, "DD-MM-YYYY")).format("DD-MM-YYYY") : "" }</span>
            //   </div>
            //  ),
            //  sorter: (a, b) => a.calculated_age.localeCompare(b.calculated_age),
            // sorter: (a, b) => a.birth_date - b.birth_date,  
          },
          {
            title: 'Status',
            dataIndex: 'is_active',
            sorter: true,


            
            // sorter: (a, b,c) => c.is_active - b.is_active - a.is_active,

            sorter: (a, b) => {

              console.log("a:", a);
              console.log("b:", b);
              // Convert status strings to numerical values for comparison
              const statusOrder = {
                'Active': 1,
                'In Active': 2,
                'Pending': 3
              };
          
              // Retrieve numerical values for comparison
              const statusA = statusOrder[a.is_active];
              const statusB = statusOrder[b.is_active];
          
              // Compare status values
              return   statusB - statusA;
            },

            

            render: (text, record1) => (
              <div className="actionIcons">
                {record1.is_active == 'pending' ? 
                    <div className={"statusInactivePending"}><span class="dotInactivepending"></span> Pending</div>
                          
                : record1.is_active == 'false' ? 
                <div className={"statusInactive"}><span class="dotInactive"></span> Inactive</div>

                : record1.is_active == 'inactive' ? 
                <div className={"statusInactive"}><span class="dotInactive"></span> Inactive</div>
                      
            :<div className={"statusActive"}><span class="dotActive" ></span> Active</div>}
              </div>
             ),
          },
        {
          title:'Action',
          dataIndex: 'is_active',
          render: (text, record) => (
            <div className="actionIcons">


            {record.is_active == 'pending' ? 
            <> 
             {/* <img src={TableEyeIcon} onClick={()=> handleViewStudent(record.student_id)}/> */}

            
                <img  src={TableEyeIcon} onClick={()=> handleViewStudent(record.student_id)} />   


            <div className="actionIconsReschedule">
            <PopOverComponent popUp={popUpDataOriginal(record.student_id,record.first_name,record.last_name,record.email,record.tenant_id)} element = {img} trigger="click"
             visible={popoverVisible}
             onVisibleChange={setPopoverVisible} />
            </div>                          
              </>  :  record.is_active == 'false' ? 
            <> 
                <img  src={TableEyeIcon} onClick={()=> handleViewStudent(record.student_id)} />   


              </> 
               :  record.is_active == 'inactive' ? 
               <> 
                <img  src={edit} onClick={()=>handleEditModal("Edit Student",record.student_id)} />
                <img  src={deleteIcon} onClick={()=>handleDeleteModal(record.student_id)} />  
                <img  src={TableEyeIcon} onClick={()=> handleViewStudent(record.student_id)} />   


   
                 </> 
              
              :  <> 
              
                <img src={edit} onClick={()=>handleEditModal("Edit Student",record.student_id)}/>
                <img src={deleteIcon} onClick={()=>handleDeleteModal(record.student_id)}/>  
                {/* <img src={TableEyeIcon} onClick={()=> handleViewStudent(record.student_id)}/> */}
                <img  src={TableEyeIcon} onClick={()=> handleViewStudent(record.student_id)} />   

                {/* <div className="actionIconsReschedule">
                  <PopOverComponent popUp={popUpDataOriginal(record.student_id,record.first_name,record.last_name,record.email,record.tenant_id)} element = {img} trigger="click"/>
              </div> */}
              
               </> }
              

                
            </div>
           ),
        }
      ];

      const handleApprove = (student_id,first_name,last_name,email,tenant_id) =>{


  
  
       let payload1={
        "operation_type": "student_approval_action",
  "action": "approved",
  "op_field_details": [
    {
      "student_id": student_id,
      "first_name": first_name,
      "last_name": last_name,
      "email":email,
      "tenant_id": window.localStorage.getItem("tenant_Id")
    }
  ]
     }
     dispatch(studentRequestApproved(payload1))
     setPopoverVisible(false);
      }
    
      const hanldeReject = (student_id,first_name,last_name,email,tenant_id) =>{
  
      
  
      let payload={
        "operation_type": "student_approval_action",
        "action": "rejected",
        "student_id":[student_id],
        "tenant_id": window.localStorage.getItem("tenant_Id")
     }
     dispatch(studentRequestRejected(payload))
     setPopoverVisible(false);

      }

      const popUpDataOriginal = (student_id,first_name,last_name,email,tenant_id) =>{
        return(
          <div className='popUpContainer'>
                        <NavLink  title='Approve' className={'menutitle'}>

          <p   style={{color:"black"}} onClick={()=>handleApprove(student_id,first_name,last_name,email,tenant_id)} >Approve</p>

          </NavLink>
          <NavLink  title='Reject' className={'menutitle'}>

          <p  style={{color:"black"}}  onClick={()=>hanldeReject(student_id,first_name,last_name,email,tenant_id)} className='approveClick'>Reject</p>
     
          </NavLink>
      </div>
        )
    }

      const rowSelection = {
        // selectedRowKeys: students && students.filter(item => item.is_active).map((item=>item.student_id)),
        onSelectAll: (selected, selectedRows, changeRows) => {
          if (selectedRowsKeys.length !== 0) {
            setSelectedRowKeys([selected]);
            
          }
        },
        onChange: (selectedRowKeys, selectedRows) => {
          setSelectedRowKeys([selectedRowKeys]);
         
        }
      };

      let img = [
        <div className='statusClick'>
                    <span className='dotPopUp'></span>
                    <span className='dotPopUp'></span>
                    <span className='dotPopUp'></span>
      </div>
      ]

      let deleteModalFooter = [
        <div className="editCourse">
          {/* <div>
              <CheckboxComponent/>
          </div> */}
          <div>
            <Button type={"secondary"} default value={'Cancel'} className={'cancel ant-btn-default'} onClick={closeDeleteModal}>
              Cancel</Button>
  
            </div>
          <div className="alignRight">
            
            <div>
            <Button type={"primary"}  danger value={'Delete'} className={'submit'} onClick={handleDeleteOk}>Delete</Button>
              
            </div>
         
          </div>
        </div>
    ]


    const exportToExcel = (data, fileName) => {
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
      XLSX.writeFile(wb, `${fileName}.xlsx`);
    };
    
    
    const transformedItems = students?.map((item) => {
     
    
      return { 
         STUDENT_ID: item.student_id,
         STUDENT_NAME:  item.first_name,
        PHONE_NO:  item.phone_no,
        EMAIL: item.email,
        COURSE_NAME:  item.course_name,
       CREATED_DATE:  item.created_date,
      
       GUARDIAN_NAME:  item.guardian_name,
       AGE: item.calculated_age,
       STATUS:  item.is_active == 'pending' ? 'pending' :
       item.is_active == 'inactive' ? 'In Active' :
       item.is_active == 'false' ? 'In Active' : 'Active'

       ,
      };
    });
    
    let handleCategoryModal1 = (type,id) =>{
       
      exportToExcel(transformedItems, 'student');
    
      
    }

    return (
        <>
        <div className="allStudentsHeader">
        {contextHolder}

            <div><h3 className="allStudents">All Students</h3></div>
            {/* <div><InputSelect defaultValue={"Select"}/></div> */}

            <div className="searchCourse">
                <div className="searchC"><InputComponent
                 className='searchField' placeholder={'Search'}
                 onChange={(e)=>searchStudentByEmail(e)}/></div>
                <img src={searchIcon} className="searchIconStudents"/>
            </div>
        </div>
            <div className="allCoursesSelections">
                <CourseTypes handleModal={()=>handleModal("Add Student")}
                 handleModal1={()=>handleCategoryModal1("")}
                bulkActions={bulkActions} onChangeBulkActions={onChangeBulkActions}
                selectedDropdown={selectedDropdown} courseTypeValues={courseTypeValues}
                handleCourseTypes={handleCourseTypes} actionType = {actionType}/>
            </div>

            <div className="studentsTable">
              <TableComponent columns={columns} selectedRowKeys= {selectedRowsKeys}
               data={students} sorter={true} current={initialpage}
                rowKey={"student_id"}  rowSelection={rowSelection}  sortOrder={["descend", "ascend"]}
                defaultSortOrder ={'descend'}
                pageOptions={["10","20","30"]}/>
            </div>
            {/* loading={!students ? true : false } */}
          { isModalVisible ?
        
        
        
        <ModalComponent title={modalType} 
         content={<AddStudent 
          handleInputChange={handleInputChange} handleDropdownChange={handleDropdownChange}
           modalType={modalType} handleEmailChange= {handleEmailChange} handlePhoneNumberChange = {handlePhoneNumberChange}
          form={form} onFinish = {onFinish} handleAddressChange = {handleAddressChange}
          firstName={firstName} lastName={lastName} phNumber={phNumber} studentStatus={studentStatus}
         email={email} gender={gender} age={age} address={address} guardianName={guardianName}
         studentDOb={studentDOb} studentCourseName={studentCourseName}
         closeModal={closeModal} loader={loader}
          />}
         open={isModalVisible}  closeModal={closeModal}
        showModal={handleModal} handleCancel={handleCancel}/>
    : ""} 

                  {isDeleteModalVisible ?
              <ModalComponent title={"Delete Student"} 
                  footer={deleteModalFooter} content={"Are you sure you want to delete this student?"}
                   open={isDeleteModalVisible} handleOk={handleDeleteOk} closeModal={closeDeleteModal}
                  showModal={handleDeleteModal} handleCancel={handleDeleteCancel}/>: " "}
    
    {studentDetails ? 
        <ModalComponent title={"Student Details"} content = {<StudentDetail studentRecord = {studentRecord}/>}
        open={handleViewStudent} handleCancel={handleStudentCancel}/>
    : ""}
        </>

    )
}


export default AllStudents;