import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { excel, plus, TableEyeIcon, searchIcon,edit,deleteIcon ,docs,pdf,back_icon_png,no_data_image} from "../../../images";
import { Button, Form, Select ,Input,notification,DatePicker,Checkbox,Collapse, List, Typography,Row, Col } from 'antd';

import AddCoursesContentSection from '../courseContentScreen/addCoursesContentSection';
import AddCoursesContentContent from '../courseContentScreen/addCoursesContentContent';
import AddCoursesContentReference from '../courseContentScreen/addCoursesContentReference';
import AddCoursesContentAssignMent from '../courseContentScreen/addCoursesContentAssignment';
import AddCoursesContentQuestionAndAnswer from '../courseContentScreen/addCoursesContentQuesitionAndAnswer';


let AddContentManagement = () =>{

  

    let dispatch = useDispatch();
    let navigate = useNavigate();

    const { coursesName, selectedCourseId, selectedBatchId } = useParams();
    const [fieldsVisible, setFieldsVisible] = useState('1');
  

    const contentList = [
        { value: '1', label: 'Section', description: 'Description for Tag 1' },
        { value: '2', label: 'Content', description: 'Description for Tag 2' },
        { value: '3', label: 'Reference', description: 'Description for Tag 3' },
        { value: '4', label: 'Assignment', description: 'Description for Tag 2' },
        { value: '5', label: 'Q&A', description: 'Description for Tag 3' },
      ];

    const backMethod = () => {
        navigate(`/courseContentDetails/${coursesName}/${selectedCourseId}/${selectedBatchId}`);

                };


                let handleAllMainContentChange = (value, type) => {
                    if (value) {
                      console.log('Selected title:', value);
          
                      setFieldsVisible(value);
                    } else {
                      console.log('Selection cleared');
                      setFieldsVisible(null);
                    }
                  };

    return (
     <div>

<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
  <h3 style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
    <img 
      src={back_icon_png} 
      alt="Back" 
      style={{ marginRight: '8px', cursor: 'pointer', height: '25px', width: '25px' }} 
      onClick={() => backMethod()} 
    />
    Add Content
  </h3>


</div>



<div style={{ display: 'flex', flexDirection: 'row' }}>

<div style={{ flex: 1 }}>
<div className='cancelClassContainer' style={{ flex: 1 }}>
        <Form
         

          autoComplete="off"
          layout="vertical"
          initialValues={{
            selected_id: contentList[0].value, // Default to first item's value
          }}
        >

<div style={{ display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap', marginTop: '10px' }}>
    <Form.Item
    label='Category'
      name="selected_id"
      style={{ flex: 0.49, minWidth: '250px', marginBottom: 0 }}
      rules={[
        {
          required: true,
          message: 'Select is required',
        },
      ]}
    >
      <Select
        size="small"
        className="custom-select-height"
        onChange={handleAllMainContentChange}
        allowClear
        placeholder="Section"
        optionLabelProp="label"
      >
        {contentList.map((item) => (
          <Select.Option key={item.value} value={item.value} label={item.label}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{item.label}</span>
              <div style={{ display: 'flex', gap: '5px', marginLeft: '5px' }}>
                {/* Additional content can go here if needed */}
              </div>
            </div>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  </div>

        </Form>
        </div>
</div>


 
</div>


<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 




{fieldsVisible === '1' ? (
    <AddCoursesContentSection 
        coursesName={coursesName} 
        selectedCourseId={selectedCourseId} 
        selectedBatchId={selectedBatchId} 
    />
) : fieldsVisible === '2' ? (
    <AddCoursesContentContent 
        coursesName={coursesName} 
        selectedCourseId={selectedCourseId} 
        selectedBatchId={selectedBatchId} 
    />
) : 
 fieldsVisible === '3' ? (
  <AddCoursesContentReference 
      coursesName={coursesName} 
      selectedCourseId={selectedCourseId} 
      selectedBatchId={selectedBatchId} 
  />
) : fieldsVisible === '4' ? (
  <AddCoursesContentAssignMent 
      coursesName={coursesName} 
      selectedCourseId={selectedCourseId} 
      selectedBatchId={selectedBatchId} 
  />
) :
fieldsVisible === '5' ? (
  <AddCoursesContentQuestionAndAnswer 
      coursesName={coursesName} 
      selectedCourseId={selectedCourseId} 
      selectedBatchId={selectedBatchId} 
  />
) :
(
    <div></div>
)}



      </div>

    )
}

export default AddContentManagement