import React from 'react';
import InputComponent from '../../../commonComponents/InputComponent/input';
import { Button, Form } from 'antd';

const AddCertificateModal = (props) => {

  const [form] = Form.useForm();


    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          if (file) {
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
              resolve(fileReader.result);
            };
          }
         
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };


      let  onFileChangecertificateTtemplate = async(event,type)=>{
        const file = event.target.files[0];
      const base64 = await convertToBase64(file);
      console.log(base64,"ppp")
    
      props.handleClassCertificate(base64, { name: "certificate_template" })

       }

       let  onFileChangecsignatureTemplate = async(event,type)=>{
        const file = event.target.files[0];
      const base64 = await convertToBase64(file);
      console.log(base64,"ppp")
    
      props.handleClassCertificate(base64, { name: "signature_template" })

       }

  return (
    <div className='instructorCancelClassContainer'>
      <div className='cancelClassContainer'>
        <Form
          initialValues={props.addCertificateDetailsPayload}
          form={props.formCertificate}
          onFinish={props?.onFinishCertificate}
          autoComplete="off"
          layout="vertical"
        >
          <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Form.Item
              label="Certificate Name"
              name="certificate_name"
              rules={[
                {
                  required: true,
                  message: 'Please enter certificate name',
                },
              ]}
              style={{ flex: '1' }} // Make the Form.Item take up available space
            >
              <InputComponent
                placeholder={"Certificate Name"}
                name={"certificate_name"}
                value={props.certificate_name}
                onChange={(e) => props.handleClassCertificate(e, { name: "certificate_name" })}
                style={{ width: '100%' }} // Ensure the input takes full width of the Form.Item
              />
            </Form.Item>
          </div>

          <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,}}>
<Form.Item
 style={{ flex: 1 }}
label="Upload Certificate Template"
                     name="certificate_template"
                     rules={[
                       {
                         required: true,
                         message: 'Image  is required',
               
                       },
                     ]}>
                   <div className="instructorResumeUpload">
                  <InputComponent type="file" name={"certificate_template"}  style={{"display": "none"}}
                  onChange={(e)=>onFileChangecertificateTtemplate(e,{name: "certificate_template"})}
                  className="instructorResumeUpload"/>
                  </div>
                  </Form.Item>
</div>

<div
      style={{
        border: "1px solid #ddd",
        borderRadius: "10px",
        marginTop: "10px",
        padding: "10px",
      }}
    >
<div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Form.Item
              label="Signature"
              name="signature"
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please enter signature',
              //   },
              // ]}
              style={{ flex: '1' }} // Make the Form.Item take up available space
            >
              <InputComponent
                placeholder={"Signature"}
                name={"signature"}
                value={props.signature}
                onChange={(e) => props.handleClassCertificate(e, { name: "signature" })}
                style={{ width: '100%' }} // Ensure the input takes full width of the Form.Item
              />
            </Form.Item>
          </div>


    

          <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
  {/* Left Line */}
  <div
    style={{
      backgroundColor: '#D3D3D3',
      height: '1px', // Adjust thickness
      flex: 1, // Equal space for the line
    }}
  />
  
  {/* Center Text */}
  <p style={{ margin: '0 10px', fontSize: '14px', color: '#808080' }}>OR</p>
  
  {/* Right Line */}
  <div
    style={{
      backgroundColor: '#D3D3D3',
      height: '1px', // Adjust thickness
      flex: 1, // Equal space for the line
    }}
  />
</div>


          <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,}}>
<Form.Item
 style={{ flex: 1 }}
label="Upload Signature"
                     name="signature_template"
                    //  rules={[
                    //    {
                    //      required: true,
                    //      message: 'Image  is required',
               
                    //    },
                    //  ]}
                     
                     >


                   <div className="instructorResumeUpload">
                  <InputComponent type="file" name={"signature_template"}  style={{"display": "none"}}
                  onChange={(e)=>onFileChangecsignatureTemplate(e,{name: "signature_template"})}
                  className="instructorResumeUpload"/>
                  </div>
                  </Form.Item>
</div>
    </div>






          {/* Button Container */}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
            <Form.Item>
              <Button htmlType='submit' type='primary' style={{ marginRight: '8px' }}>
                Submit
              </Button>
              <Button htmlType='button' onClick={props.handleCancel}>
                Cancel
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddCertificateModal;
