


import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePicker, Form, notification } from 'antd';
import {ModalComponent,TableComponent} from "../../../commonComponents/index.js";
import CertificateViewModal from '../viewModal/certificateView.js';

import {updateAssignmentSatusDetails} from '../../../redux/coursesContent/action';


const StudUploadAssignmentViewModal = ({handleCancel,assignmentUploadData,assignmentStatus,selectedContentId}) => {

  let dispatch = useDispatch();

  const [visibleNotification, setVisibleNotification] = useState(false);
  const [api, contextHolder] = notification.useNotification();


  let updateAssignmentStatus = useSelector((state)=> state?.CoursesContentReducer?.updateAssignmentStatus);

  useEffect(()=>{
      if (updateAssignmentStatus) {
        if(visibleNotification == true){
        
          if(updateAssignmentStatus?.res_status === true){
    
            openNotification(updateAssignmentStatus?.msg)

            handleCancel()
    
      //     let payload ={
      //       "operation_type":"retrieve_all_content",
      //           "course_id":selectedCourseId,
      //           "tenant_id":window.localStorage.getItem("tenant_Id"),
      //           "instructor_id":window.localStorage.getItem("instructor_id"),
      //           "batch_id":selectedBatchId
    
      //   }
      // dispatch(getCoursesContentDetails(payload))
        
        
          }else{
            openNotification(updateAssignmentStatus?.msg)

          }
        
        }else{
        
        }
      }
  },[updateAssignmentStatus])

  const openNotification = (msg) => {
  
    api.info({
      description:
      `${msg}`,
  
    });
  
    setVisibleNotification(false)
  
  };
  

  const handleApproved = () => {


      let payload ={
          "operation_type": "student_assignment_status_update",
          "op_field_details": {
              "content_id": selectedContentId,
              "student_id":[assignmentStatus],
              "status":"approved"
            }
      }

      console.log(payload)

  dispatch(updateAssignmentSatusDetails(payload))
  setVisibleNotification(true)


            };

            const handleRejected = () => {
      let payload ={
          "operation_type": "student_assignment_status_update",
          "op_field_details": {
              "content_id": selectedContentId,
              "student_id":[assignmentStatus],
              "status":"rejected"
            }
      }
  dispatch(updateAssignmentSatusDetails(payload))
  setVisibleNotification(true)

            };

  return (
    <div
    style={{
      border: "1px solid #ddd",
      borderRadius: "8px",
      padding: "10px",
      margin: "10px 0",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#f9f9f9",
    }}
  >
    {contextHolder}
  
   <div style={{ height: '350px', width: '100%' }}>
      <iframe
        src={assignmentUploadData}
        title="PDF Viewer"
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    </div>


    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
    <Button htmlType='submit' 
                onClick={handleApproved}

              type='primary' style={{ marginRight: '8px', width:"110px" }} >
                Approved
              </Button>
            
              <Button
  htmlType="submit"
  onClick={ handleRejected}
  style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px"
  }}
>
  Rejected
</Button>
 </div>
  
   



   
  </div>
  
  );
}



export default StudUploadAssignmentViewModal;
