import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import  InputComponent  from '../../../commonComponents/InputComponent/input.js';
import { excel, plus, TableEyeIcon, searchIcon,edit,deleteIcon ,docs,pdf,back_icon_png,no_data_image,
  certificate_1,certificate_2,certificate_3
} from "../../../images";
import { Table, Form,notification,DatePicker ,Select} from 'antd';
import moment from 'moment';

import { TableComponent,ButtonComponent,
    ModalComponent} from "../../../commonComponents";

import {getCoursesContentDetails,addCoursesContentSectionDetails,addCoursesContentAssignmentDetails,
updateDragAndDropDetails,deleteCoursesContentDetails,addCoursesContentDetails,
getCoursesContentSectionDetails,getCoursesContentAssignmentDetails,
getCoursesContentreferenceDetails,getCoursesContentContentDetails,contentStatusUpdateDetails,getCoursesContentQuestionDetails
} from '../../../redux/coursesContent/action.js'


    import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';


import AddCourseContentModal from '../courseModel/addCoursesContent.js';    

import { jsPDF } from 'jspdf';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import SectionViewModal from '../viewModal/sectionViewModal.js';
import ContentViewViewModal from '../viewModal/contentViewModal.js';
import AssignmentViewModal from '../viewModal/assignmentViewModal.js';
import QuestionAndAnswerViewModal from '../viewModal/questionAnswerViewModal.js';
import ReferenceViewModal from '../viewModal/referenceViewModal.js';

import UpdateSectionModal from '../updateModal/sectionUpdateModal.js'
import UpdateAssignmentModal from '../updateModal/assignmentUpdateModal.js';
import UpdateCoursesContentContent from '../updateModal/contentUpdateModal.js';
import UpdateCoursesContentReference from '../updateModal/referenceUpdateModal.js';
import UpdateCoursesContentQuestionAndAnswer from '../updateModal/questionAnswerUpdateModal.js'

import './tabController.css'




const CoursesManagement = () => {
    let dispatch = useDispatch();
    const { coursesName, selectedCourseId, selectedBatchId } = useParams();

    const [api, contextHolder] = notification.useNotification();

    const [data, setData] = useState([]);
    const [filteredDataList, setFilteredData] = useState([]); // Filtered data



    const [searchEntry,setSearchEntry] = useState("");
    const [addCoursesContentVisible,setAddCoursesContentVisible] = useState(false);
    const [form2] = Form.useForm();
    const [formSection] = Form.useForm();
    const [formContent] = Form.useForm();
    const [formReference] = Form.useForm();
    const [formAssignment] = Form.useForm();
    const [formQuestionAndAns] = Form.useForm();
    const [deleteContentVisible,setDeleteContentVisible] = useState(false);

    const [viewSectionVisible, setViewSectionVisible] = useState(false);
    const [viewContentVisible, setViewContentVisible] = useState(false);
    const [viewAssignmentVisible, setViewAssignmentVisible] = useState(false);
    const [viewQuestionAndAnswerVisible, setViewQuestionAndAnswerVisible] = useState(false);
    const [viewReferenceVisible, setViewSReferenceVisible] = useState(false);

    const [popupVisible, setPopUpVisible] = useState(false);
    const [updateViewVisible, setUpdateViewVisible] = useState(null);
    const [mainContentId, setMainContentId] = useState(null);



    const [updateSectionVisible, setUpdateSectionVisible] = useState(false);
    const [updateContentVisible, setUpdateContentVisible] = useState(false);
    const [updateAssignmentVisible, setUpdateAssignmentVisible] = useState(false);
    const [updateQuestionAndAnswerVisible, setUpdateQuestionAndAnswerVisible] = useState(false);
    const [updateReferenceVisible, setUpdateReferenceVisible] = useState(false);



    const [visibleNotification, setVisibleNotification] = useState(false);
    const [visibleCoursesContentDelete, setVisibleCoursesContentDelete] = useState(false);
    const [visibleTitleDescriptionDelete, setVisibleTitleDescriptionDelete] = useState(false);
    const [visibleTitleDescriptionUpdate, setVisibleTitleDescriptionUpdate] = useState(false);

    const [visibleAssignmentView, setVisibleAssignmentView] = useState(false);

    const [coursesContentData, setCoursesContentDataList] = useState([]);




    const [selectedContentId,setSelectedCoursesContentId] = useState("");
    const [updateCoursesContentVisible,setUpdateCoursesContentVisible] = useState(false);

    const [viewCoursesContentVisible,setViewCoursesContentVisible] = useState(false);

    const [apiCallMethod,setApiCallMethod] = useState('');
    const [selectedPosition,setSelectedPosition] = useState(null);
    const [assignmentDate, setAssignmentDate] = useState(null);
    const [onlyAssignmentDetails, setOnlyAssignmentDetails] = useState([]);

 
    const [addMultipleRecord,setAddMultipleRecord] = useState(1);


    const [pdfDocData,setPdfDocData] = useState(false);

    let[referencePayloadDetails,setReferenceDetails] = useState({
      "reference_details":[]
      })

      let[assignmentsPayloadDetails,setAssignmentsDetails] = useState({
        "title":"",
        "description":"",
        "due_date":"", 
        "xp":"",
        "category":"Assignments"

        })

    let[coursesContentPayloadDetails,setCoursesContentPayloadDetails] = useState({
      "title":"",
      "description":"",
      "category_name":"",
      "xp":"",
      "category":"Section"

      })

      let[coursesContentListPayloadDetails,setCoursesContentListPayloadDetails] = useState({
        "category":"Content",
        "title": "",
        "description":"",
        "content_description":"",
        "xp":"",
  
        })



      let[topicMappingDetails,setTopicMappingDetails] = useState({
        "id":'',
        "topic_id":'',
        "title":"",
        "description":"",
        "tenant_id": ""
        })

      let [allCoursesContent,setAllCoursesContent] = useState([]);

      let getCoursesContentList = useSelector((state)=> state?.CoursesContentReducer?.getCoursesContent);
      let addCourseContentSection = useSelector((state)=> state?.CoursesContentReducer?.addCoursesContentSection);
      let addCourseContentAssignment = useSelector((state)=> state?.CoursesContentReducer?.addCoursesContentAssignment);
      let updateDragAndDrop = useSelector((state)=> state?.CoursesContentReducer?.updateDragAndDrop);
      let deleteCoursesContent = useSelector((state)=> state?.CoursesContentReducer?.deleteCoursesContent);
      let addCoursesContentDetailsList = useSelector((state)=> state?.CoursesContentReducer?.addCoursesContentDetails);

      let getCourseContentSection = useSelector((state)=> state?.CoursesContentReducer?.getCourseContentSection);
      let getCoursesContantDetails = useSelector((state)=> state?.CoursesContentReducer?.getCoursesContantDetails);
      let getCoursesContentAssignment = useSelector((state)=> state?.CoursesContentReducer?.getCoursesContentAssignment);
      let getCourseReference = useSelector((state)=> state?.CoursesContentReducer?.getCourseReference);
      let contentStatusUpdate = useSelector((state)=> state?.CoursesContentReducer?.contentStatusUpdate);
      let getCoursesQuestion = useSelector((state)=> state?.CoursesContentReducer?.getCoursesQuestion);

      useEffect(()=>{
        if (getCoursesQuestion) {
          setCoursesContentDataList(getCoursesQuestion)
          if(popupVisible === true){

            


            if(updateViewVisible === 1){
              setViewQuestionAndAnswerVisible(true)
            }else if(updateViewVisible === 2){
              setUpdateQuestionAndAnswerVisible(true)
            }


          }

        }
    },[getCoursesQuestion])


      useEffect(()=>{    
        if (contentStatusUpdate) {
    if(visibleNotification == true){
      openNotification(contentStatusUpdate?.msg)
    
      if(contentStatusUpdate?.res_status === true){

      if(addMultipleRecord === 1){

        formContent.resetFields();

        formContent.setFieldsValue({['title']:''})
        formContent.setFieldsValue({['description']:''})
        formContent.setFieldsValue({['content_description']:''})
        formContent.setFieldsValue({['xp']:''})

        setCoursesContentListPayloadDetails({
          "title":"",
          "description":"",
          "content_description":"",
          "xp":"",
          "category":"Content"
      
        })

        setAddCoursesContentVisible(false)
      }else{

    
        
        formContent.setFieldsValue({['title']:''})
        formContent.setFieldsValue({['description']:''})
        formContent.setFieldsValue({['content_description']:''})
        formContent.setFieldsValue({['xp']:''})
      
        setCoursesContentListPayloadDetails({
          "title":"",
          "description":"",
          "content_description":"",
          "xp":"",
          "category":"Content"
      
        })
      }


      let payload ={
        "operation_type":"retrieve_all_content",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCoursesContentDetails(payload))
    
    
      }
    
    }else{
    
    }
    
        }
    },[contentStatusUpdate])
    

      useEffect(()=>{
        if (getCourseReference) {
          setCoursesContentDataList(getCourseReference)
          if(popupVisible === true){

            


            if(updateViewVisible === 1){
              setViewSReferenceVisible(true)
            }else if(updateViewVisible === 2){
              setUpdateReferenceVisible(true)
            }

          }

        }
    },[getCourseReference])

      useEffect(()=>{
        if (getCoursesContentAssignment) {
          setCoursesContentDataList(getCoursesContentAssignment)
          if(popupVisible === true){

            if(updateViewVisible === 1){
              setViewAssignmentVisible(true)
            }else if(updateViewVisible === 2){
              setUpdateAssignmentVisible(true)
            }

          }

        }
    },[getCoursesContentAssignment])

      useEffect(()=>{
        if (getCourseContentSection) {

          setCoursesContentDataList(getCourseContentSection)
          if(popupVisible === true){
            if(updateViewVisible === 1){
              setViewSectionVisible(true)
            }else if(updateViewVisible === 2){
              setUpdateSectionVisible(true)
            }

          }

        }
    },[getCourseContentSection])

    useEffect(()=>{
      if (getCoursesContantDetails) {
        setCoursesContentDataList(getCoursesContantDetails)
        if(popupVisible === true){

          if(updateViewVisible === 1){
            setViewContentVisible(true)
          }else if(updateViewVisible === 2){
            setUpdateContentVisible(true)
          }

        }

      }
  },[getCoursesContantDetails])

      useEffect(()=>{    
        if (addCoursesContentDetailsList) {
    if(visibleNotification == true){
      openNotification(addCoursesContentDetailsList?.msg)
    
      if(addCoursesContentDetailsList?.res_status === true){

      if(addMultipleRecord === 1){

        formContent.resetFields();

        formContent.setFieldsValue({['title']:''})
        formContent.setFieldsValue({['description']:''})
        formContent.setFieldsValue({['content_description']:''})
        formContent.setFieldsValue({['xp']:''})

        setCoursesContentListPayloadDetails({
          "title":"",
          "description":"",
          "content_description":"",
          "xp":"",
          "category":"Content"
      
        })

        setAddCoursesContentVisible(false)
      }else{

    
        
        formContent.setFieldsValue({['title']:''})
        formContent.setFieldsValue({['description']:''})
        formContent.setFieldsValue({['content_description']:''})
        formContent.setFieldsValue({['xp']:''})
      
        setCoursesContentListPayloadDetails({
          "title":"",
          "description":"",
          "content_description":"",
          "xp":"",
          "category":"Content"
      
        })
      }


      let payload ={
        "operation_type":"retrieve_all_content",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCoursesContentDetails(payload))
    
    
      }
    
    }else{
    
    }
    
        }
    },[addCoursesContentDetailsList])
      
      useEffect(()=>{    
        if (deleteCoursesContent) {
    if(visibleNotification == true){
      openNotification(deleteCoursesContent?.msg)
    
      if(deleteCoursesContent?.res_status === true){


        formAssignment.resetFields();

        formAssignment.setFieldsValue({['title']:''})
        formAssignment.setFieldsValue({['description']:''})
        formAssignment.setFieldsValue({['due_date']:null})
        formAssignment.setFieldsValue({['xp']:''})

        setAssignmentsDetails({
          "title":"",
        "description":"",
        "due_date":null, 
        "xp":"",
        "category":"Assignments"
      
        })

        setAddCoursesContentVisible(false)
  

    
        
        formAssignment.setFieldsValue({['title']:''})
        formAssignment.setFieldsValue({['description']:''})
        formAssignment.setFieldsValue({['due_date']:null})
        formAssignment.setFieldsValue({['xp']:''})
      
        setAssignmentsDetails({
          "title":"",
        "description":"",
        "due_date":null, 
        "xp":"",
        "category":"Assignments"
      
        })
      


      let payload ={
        "operation_type":"retrieve_all_content",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCoursesContentDetails(payload))
    
    
      }
    
    }else{
    
    }
    
        }
    },[deleteCoursesContent])

      useEffect(()=>{    
        if (updateDragAndDrop) {
    if(visibleNotification == true){
      openNotification(updateDragAndDrop?.msg)
    
      if(updateDragAndDrop?.res_status === true){


        formAssignment.resetFields();

        formAssignment.setFieldsValue({['title']:''})
        formAssignment.setFieldsValue({['description']:''})
        formAssignment.setFieldsValue({['due_date']:null})
        formAssignment.setFieldsValue({['xp']:''})

        setAssignmentsDetails({
          "title":"",
        "description":"",
        "due_date":null, 
        "xp":"",
        "category":"Assignments"
      
        })

        setAddCoursesContentVisible(false)
  

    
        
        formAssignment.setFieldsValue({['title']:''})
        formAssignment.setFieldsValue({['description']:''})
        formAssignment.setFieldsValue({['due_date']:null})
        formAssignment.setFieldsValue({['xp']:''})
      
        setAssignmentsDetails({
          "title":"",
        "description":"",
        "due_date":null, 
        "xp":"",
        "category":"Assignments"
      
        })
      


      let payload ={
        "operation_type":"retrieve_all_content",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCoursesContentDetails(payload))
    
    
      }
    
    }else{
    
    }
    
        }
    },[updateDragAndDrop])

      useEffect(()=>{    
        if (addCourseContentAssignment) {
    if(visibleNotification == true){
      openNotification(addCourseContentAssignment?.msg)
    
      if(addCourseContentAssignment?.res_status === true){

      if(addMultipleRecord === 1){

        formAssignment.resetFields();

        formAssignment.setFieldsValue({['title']:''})
        formAssignment.setFieldsValue({['description']:''})
        formAssignment.setFieldsValue({['due_date']:null})
        formAssignment.setFieldsValue({['xp']:''})

        setAssignmentsDetails({
          "title":"",
        "description":"",
        "due_date":null, 
        "xp":"",
        "category":"Assignments"
      
        })

        setAddCoursesContentVisible(false)
      }else{

    
        
        formAssignment.setFieldsValue({['title']:''})
        formAssignment.setFieldsValue({['description']:''})
        formAssignment.setFieldsValue({['due_date']:null})
        formAssignment.setFieldsValue({['xp']:''})
      
        setAssignmentsDetails({
          "title":"",
        "description":"",
        "due_date":null, 
        "xp":"",
        "category":"Assignments"
      
        })
      }


      let payload ={
        "operation_type":"retrieve_all_content",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCoursesContentDetails(payload))
    
    
      }
    
    }else{
    
    }
    
        }
    },[addCourseContentAssignment])


      useEffect(()=>{    
        if (addCourseContentSection) {
    if(visibleNotification == true){
      openNotification(addCourseContentSection?.msg)
    
      if(addCourseContentSection?.res_status === true){

      if(addMultipleRecord === 1){

        formSection.resetFields();

        formSection.setFieldsValue({['title']:''})
        formSection.setFieldsValue({['description']:''})
        formSection.setFieldsValue({['category_name']:''})
        formSection.setFieldsValue({['xp']:''})

        setCoursesContentPayloadDetails({
          "title":"",
          "description":"",
          "category_name":"",
          "xp":"",
          "category":"Section"
      
        })

        setAddCoursesContentVisible(false)
      }else{

    
        
        formSection.setFieldsValue({['title']:''})
        formSection.setFieldsValue({['description']:''})
        formSection.setFieldsValue({['category_name']:''})
        formSection.setFieldsValue({['xp']:''})
      
        setCoursesContentPayloadDetails({
          "title":"",
          "description":"",
          "category_name":"",
          "xp":"",
          "category":"Section"
      
        })
      }


      let payload ={
        "operation_type":"retrieve_all_content",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCoursesContentDetails(payload))
    
    
      }
    
    }else{
    
    }
    
        }
    },[addCourseContentSection])







    
      useEffect(()=>{
        if (getCoursesContentList) {
    
            setAllCoursesContent(getCoursesContentList)
            setData(getCoursesContentList)
            setFilteredData(getCoursesContentList)

            // const transformedData = groupByTag(getCoursesContentList);
            // console.log(JSON.stringify(transformedData, null, 2));
            setPdfDocData(getCoursesContentList)
        }
    },[getCoursesContentList])


    const groupByTag = (data) => {
      const groupedData = data.reduce((acc, item) => {
        const { tag_name, ...details } = item;
        if (!acc[tag_name]) {
          acc[tag_name] = {
            tag_name: tag_name,
            details: []
          };
        }
        acc[tag_name].details.push(details);
        return acc;
      }, {});
    
      return Object.values(groupedData);
    };
    
  

    useEffect(()=>{

      let payload ={
        "operation_type":"retrieve_all_content",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCoursesContentDetails(payload))

  },[])

  const openNotification = (msg) => {
  
    api.info({
      description:
      `${msg}`,
  
    });
  
    setVisibleNotification(false)
  
  };


    const searchCoursePrice = (e) =>{
        // setSearchEntry(e?.target?.value)
        const searchValue = e.target.value.toLowerCase();


        if (searchValue.trim() === "") {
          setData(filteredDataList);
          return;
        }

        const filtered = data.filter(item => 
          item.title.toLowerCase().includes(searchValue) || 
          item.category.toLowerCase().includes(searchValue) || 
          item.content_id.toLowerCase().includes(searchValue) || 
          item.xp.toLowerCase().includes(searchValue) || 
          item.status.toLowerCase().includes(searchValue) || 
          item.badge_name.toLowerCase().includes(searchValue) || 
          item.description.toLowerCase().includes(searchValue)
        );
        // setFilteredData(filtered);
        setData(filtered)

      }

      let handleExportExcel = (type,id) =>{
        const transformedItems = allCoursesContent?.map((item) => {
          return { 
             CONTENT_ID: item.content_id,
             CATEGORY: item.category,
             TITLE:  item.title,
            DESCRIPTION:  item.description,
            BADGE_NAME: item.badge_name,
           XP:  item.xp,
           STATUS:  item.status == 'Inactive' ? 'In Active' : 'Active',


          
          };
        });

        
        exportToExcel(transformedItems, 'course_content');
      
        
      }

      const exportToExcel = (data, fileName) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        XLSX.writeFile(wb, `${fileName}.xlsx`);
      };
      

      let handleDocs = (type,id) =>{
        let textContent = '';
        
        pdfDocData.forEach((group, groupIndex) => {
          // Add numbered group tag (e.g., "Tag 1: Java")
          textContent += `Topic ${groupIndex + 1}: ${group.topics}\n\n`;
      
          group.topics_details.forEach((item, itemIndex) => {
            // Add bold headers for the fields
            textContent += `• Title:\n   ${item.title}\n`; // Indented value
            textContent += `• Description:\n   ${item.description}\n`;
            // textContent += `• Level:\n   ${item.level_name}\n\n`;
          });
      
          textContent += '-------------------\n'; // Separator between groups
        });
      
        const blob = new Blob([textContent], { type: 'text/plain' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'course-data.txt';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      let handleEPdf = (type,id) =>{

        const doc = new jsPDF();
doc.setFontSize(12);

let yOffset = 10; // Starting y-coordinate for the text

pdfDocData.forEach((group, groupIndex) => {
  // Print the group tag
  doc.setFont('helvetica', 'bold');
  doc.text(`Topic ${groupIndex + 1}: ${group.topics}`, 10, yOffset);
  yOffset += 10; // Add space below the tag

  group.topics_details.forEach((item, itemIndex) => {
    doc.setFont('helvetica', 'normal');
    
    // Print Title
    doc.text(` • Title: ${item.title}`, 10, yOffset);
    yOffset += 10;

    // Print Content (split long content into multiple lines)
    const contentLines = doc.splitTextToSize(` • Description: ${item.description}`, 180); // Adjust width as needed
    doc.text(contentLines, 10, yOffset);
    yOffset += contentLines.length * 7; // Adjust line height as needed

    // Print Level
    // doc.text(` • Level: ${item.level_name}`, 10, yOffset);
    // yOffset += 15; // Add extra space before the next detail

    // Check if yOffset exceeds page height and create a new page if needed
    if (yOffset > 270) { // Adjust 270 as needed for page margin
      doc.addPage();
      yOffset = 10; // Reset yOffset for the new page
    }
  });

  yOffset += 10; // Space between different groups
});

doc.save('course-doc.pdf');


}

    let handleAddCoursesComponent= (type,id) =>{
        // setAddCoursesContentVisible(true)
        // setUpdateCoursesContentVisible(false)
    
        navigate(`/courseContentDetailsAdd/${coursesName}/${selectedCourseId}/${selectedBatchId}`);


        
}

let onFinish1 = (values) =>{

  if(apiCallMethod == 'Section'){
 
    console.log(coursesContentPayloadDetails)



  
  
  
  
    // dispatch(createCourseContentDetails(payload))

  }else if(apiCallMethod == 'Reference'){
    console.log('API CALL Reference')

    console.log(referencePayloadDetails)

  }else if(apiCallMethod == 'Assignment'){
    console.log('API CALL Assignment')

    console.log(assignmentsPayloadDetails)


 
    

  
  
  
  
    // dispatch(addAssignmentDetails(payload))

  }



  setVisibleNotification(true)


}



let onFinishReferenceAdd= (values) =>{



  console.log(referencePayloadDetails)




  // dispatch(updateCourseContentDetails(payload))

  setVisibleNotification(true)


}


let onFinishSectionAdd= (values) =>{

  let payload = {
    "operation_type":"insert",
    "op_field_details":{
        "category":"Section",
        "title": coursesContentPayloadDetails.title,
        "description":coursesContentPayloadDetails.description,
        "xp":coursesContentPayloadDetails.xp === '' ? null :coursesContentPayloadDetails.xp,
        "batch_id":selectedBatchId,
        "instructor_id":window.localStorage.getItem("instructor_id"),
        "course_id":selectedCourseId,
        "tenant_id":window.localStorage.getItem("tenant_Id")
    }
}
  
  
    dispatch(addCoursesContentSectionDetails(payload))

    setVisibleNotification(true)


}
let onFinishContextAdd= (values) =>{

  let payload = {
    "operation_type":"insert",
    "op_field_details":{
      "category":"Content",
      "title": coursesContentListPayloadDetails.title,
      "description":coursesContentListPayloadDetails.description,
      "content_description":coursesContentListPayloadDetails.content_description,
        "xp":coursesContentListPayloadDetails.xp === '' ? null :coursesContentListPayloadDetails.xp,
        "batch_id":selectedBatchId,
        "instructor_id":window.localStorage.getItem("instructor_id"),
        "course_id":selectedCourseId,
        "tenant_id":window.localStorage.getItem("tenant_Id")
    }
}
  
  
    dispatch(addCoursesContentDetails(payload))

    setVisibleNotification(true)

}
let onFinishAssignmentAdd= (values) =>{
  console.log(assignmentsPayloadDetails.due_date)

  let payload = {
    "operation_type":"insert",
    "op_field_details":{
      "category":"Assignment",
      "title": assignmentsPayloadDetails.title,
      "description":assignmentsPayloadDetails.description,
      "assignment_description":assignmentsPayloadDetails.description,
      "due_date":moment(assignmentsPayloadDetails.due_date).format("YYYY-MM-DD"),
        "xp":assignmentsPayloadDetails.xp === '' ? null :assignmentsPayloadDetails.xp,
        "batch_id":selectedBatchId,
        "instructor_id":window.localStorage.getItem("instructor_id"),
        "course_id":selectedCourseId,
        "tenant_id":window.localStorage.getItem("tenant_Id")
    }
}
  
  
    dispatch(addCoursesContentAssignmentDetails(payload))

    setVisibleNotification(true)


}
let onFinishQuestionnAdd= (values) =>{}



const handleClassChangeSingleAdd = (e, type) => {

  console.log('SINGLE CLICK')

  setAddMultipleRecord(1)


  if (!type || !type.name) {
    console.error("Type or type.name is undefined:", type);
    return; // Exit early to avoid the error
  }

  let value;

  if (e && e.target) {
    value = e.target.value; // For event objects
  } else {
    value = e; // For direct values
  }

  // Set the state based on the type
  if (["title", "description", "category_name", "xp"].includes(type.name)) {
    setCoursesContentPayloadDetails(prevDetails => ({
      ...prevDetails,
      [type.name]: value
    }));
  }
};


const handleClassChange = (e, type) => {


  


  console.log('MULTIPLE CLICK')
  setAddMultipleRecord(2)

  if (!type || !type.name) {
    console.error("Type or type.name is undefined:", type);
    return; // Exit early to avoid the error
  }

  let value;

  if (e && e.target) {
    value = e.target.value; // For event objects
  } else {
    value = e; // For direct values
  }



  // Set the state based on the type
  if (["title", "description", "category_name", "xp"].includes(type.name)) {
    setCoursesContentPayloadDetails(prevDetails => ({
      ...prevDetails,
      [type.name]: value
    }));
  }
};

let  closeDeleteModal = () =>{
  setDeleteContentVisible(false)


}

let deleteCourseContent= ()=>{

  setDeleteContentVisible(false)

  let payload = {
    "operation_type":"delete",
    "op_field_details":{
        "content_id":[selectedContentId]
    }
}
  
  
    dispatch(deleteCoursesContentDetails(payload))

    setVisibleNotification(true)


  

} 

let deleteTitleDescriptionModalFooter = [
  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' ,marginBottom:"10px"}}>
  <Button   style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
  }} default value={'Cancel'}  onClick={closeDeleteModal}>
        Cancel</Button>

        <div style={{marginLeft:"10px"}}></div>

        <Button   style={{
    border: '1px solid red',  // Red border
    color: 'white',              // Red text color
    backgroundColor: 'red',  // No background color
  }}  danger value={'Delete'}  onClick={deleteCourseContent}>Delete</Button>
   
  
  </div>
]


let handleDeleteCancel= ()=>{

    setAddCoursesContentVisible(false)
    setUpdateCoursesContentVisible(false)
    setViewCoursesContentVisible(false)
    setVisibleTitleDescriptionDelete(false)
    setVisibleTitleDescriptionUpdate(false)
    setVisibleTitleDescriptionUpdate(false)
    setVisibleCoursesContentDelete(false)
    setVisibleAssignmentView(false)
    setDeleteContentVisible(false)
    setUpdateAssignmentVisible(false)

    setViewSectionVisible(false)
    setViewAssignmentVisible(false)
    setViewContentVisible(false)
    setViewSReferenceVisible(false)
    setViewQuestionAndAnswerVisible(false)

    setUpdateAssignmentVisible(false)
    setUpdateContentVisible(false)
    setUpdateReferenceVisible(false)
    setUpdateSectionVisible(false)
    setUpdateQuestionAndAnswerVisible(false)



    form2.resetFields();
    
        
    form2.setFieldsValue({['course_id']:''})
    form2.setFieldsValue({['title']:''})
    form2.setFieldsValue({['content']:''})

    form2.setFieldsValue({['tag_id']:''})
    form2.setFieldsValue({['level_id']:''})
    form2.setFieldsValue({['tenant_id']:''})
    form2.setFieldsValue({['topic_id']:''})

  
    setCoursesContentPayloadDetails({
      "course_id":"",
      "title":"",
      "content":"",
      "tag_id":"",
      "level_id":"",
      "tenant_id":"","topic_id":""
  
    })
    

  } 

  
  let handleTitleDeleteClick = (values) =>{
    deleteContentVisible(false)

  }

 

  



  // console.log(selectedCourseId)





 
    const handleClassSingleReferenceChange = (e, type) => {

     

      if (!type || !type.name) {
        console.error("Type or type.name is undefined:", type);
        return; // Exit early to avoid the error
      }
    
      let value;
    
      if (e && e.target) {
        value = e.target.value; // For event objects
      } else {
        value = e; // For direct values
      }


    
      // Set the state based on the type
      if (["reference_details"].includes(type.name)) {
        setReferenceDetails(prevDetails => ({
          ...prevDetails,
          [type.name]: value
        }));
      }
    };

    const handleClassMultipleReference = (e, type) => {


    
      if (!type || !type.name) {
        console.error("Type or type.name is undefined:", type);
        return; // Exit early to avoid the error
      }
    
      let value;
    
      if (e && e.target) {
        value = e.target.value; // For event objects
      } else {
        value = e; // For direct values
      }


    
      // Set the state based on the type
      if (["reference_details"].includes(type.name)) {
        setReferenceDetails(prevDetails => ({
          ...prevDetails,
          [type.name]: value
        }));
      }
    };

    const handleClassSingleAssignmentChange = (e, type) => {


      setAddMultipleRecord(1)
      console.log('SINGLE')
    
      if (!type || !type.name) {
        console.error("Type or type.name is undefined:", type);
        return;
      }
    
      let value = e && e.target ? e.target.value : e; // Handle both events and direct values
      console.log(type.name )

      console.log(e)


      if (type.name === "due_date") {
        setAssignmentDate(e);


        // formAssignment.setFieldsValue({ [type.name]: e });
    
        // setAssignmentsDetails((prevDetails) => ({
        //   ...prevDetails,
        //   [type.name]: e,
        // }));
      }
    

    
      // Update other fields
      if (["title", "description","xp","due_date"].includes(type.name)) {
        setAssignmentsDetails((prevDetails) => ({
          ...prevDetails,
          [type.name]: value,
        }));
      }
    };
    
    const handleClassMultipleAssignment = (e, type) => {
      console.log('MULTIPLE')

      setAddMultipleRecord(2)

   
    
      if (!type || !type.name) {
        console.error("Type or type.name is undefined:", type);
        return;
      }
    
      let value = e && e.target ? e.target.value : e;

      console.log(type.name )


      if (type.name === "due_date") {
        setAssignmentDate(e);
        // form2.setFieldsValue({ [type.name]: e });
    
        // setAssignmentsDetails((prevDetails) => ({
        //   ...prevDetails,
        //   [type.name]: e,
        // }));
      }

  
    

    
      if (["title", "description","xp","due_date"].includes(type.name)) {
        setAssignmentsDetails((prevDetails) => ({
          ...prevDetails,
          [type.name]: value,
        }));
      }
    };
 

    const handleClassSingleCoursesContentChange = (e, type) => {
 

      setAddMultipleRecord(1)
      console.log('SINGLE')
    
      if (!type || !type.name) {
        console.error("Type or type.name is undefined:", type);
        return;
      }
    
      let value = e && e.target ? e.target.value : e; // Handle both events and direct values
      console.log(type.name )

      console.log(e)

  
      // Update other fields
      if (["title", "description","xp","content_description"].includes(type.name)) {
        setCoursesContentListPayloadDetails((prevDetails) => ({
          ...prevDetails,
          [type.name]: value,
        }));
      }
    };
    
    const handleClassMultipleCoursesContent = (e, type) => {
      console.log('MULTIPLE')

      setAddMultipleRecord(2)

   
    
      if (!type || !type.name) {
        console.error("Type or type.name is undefined:", type);
        return;
      }
    
      let value = e && e.target ? e.target.value : e;

      console.log(type.name )


    
      if (["title", "description","xp","content_description"].includes(type.name)) {
        setCoursesContentListPayloadDetails((prevDetails) => ({
          ...prevDetails,
          [type.name]: value,
        }));
      }
    };

    const onRowReorder = (e) => {
      // Get the reordered rows and update row_order
      const reorderedData = e.value.map((item, index) => ({
        ...item,
        row_order: index + 1, // Update row_order based on new index
      }));
  
      setData(reorderedData);



      let payload = {
        "operation_type":"drag_and_drop",
        "op_field_details":reorderedData
    }
      
      
        dispatch(updateDragAndDropDetails(payload))
    
        setVisibleNotification(true)



      console.log('Reordered Data:', reorderedData);
    };
  
    const handleDelete = (id) => {
      setDeleteContentVisible(true)
      setSelectedCoursesContentId(id)
    };

    const handleUploadViewDocument= (id,category) => {

      // setSelectedContentId(id)

       if(category == 'Assignment'){
          navigate(`/assignmentUploadView/${coursesName}/${selectedCourseId}/${selectedBatchId}/${id}`);

      
      }else if(category == 'Q&A'){

          navigate(`/questionAndAnswerUploadView/${coursesName}/${selectedCourseId}/${selectedBatchId}/${id}`);

      }



    };
  
    const handleUpdate = (id,category) => {

      setMainContentId(id)
      setPopUpVisible(true)
      setUpdateViewVisible(2)


      if(category == 'Section'){

        let payload ={
          "operation_type":"retrieve",
    "category":"Section",
    "content_id":id
  
      }
    dispatch(getCoursesContentSectionDetails(payload))

        }else if(category == 'Content'){

          let payload ={
            "operation_type":"retrieve",
            "category":"Content",
            "content_id":id
    
        }
      dispatch(getCoursesContentContentDetails(payload))

      }else if(category == 'Reference'){

        let payload ={
          "operation_type":"retrieve",
            "category":"Reference",
            "content_id":id
  
      }
    dispatch(getCoursesContentreferenceDetails(payload))

      }else if(category == 'Assignment'){
       
        let payload ={
          "operation_type":"retrieve",
          "category":"Assignment",
          "content_id":id
  
      }
    dispatch(getCoursesContentAssignmentDetails(payload))

      }else if(category == 'Q&A'){
        let payload ={
          "operation_type":"retrieve",
          "category":"Q&A",
          "content_id":id
  
      }
    dispatch(getCoursesContentQuestionDetails(payload))

      }
  
    };
  
    const handleView = (id,category) => {


      setPopUpVisible(true)
      setUpdateViewVisible(1)

      if(category == 'Section'){

        let payload ={
          "operation_type":"retrieve",
    "category":"Section",
    "content_id":id
  
      }
    dispatch(getCoursesContentSectionDetails(payload))

        }else if(category == 'Content'){

          let payload ={
            "operation_type":"retrieve",
            "category":"Content",
            "content_id":id
    
        }
      dispatch(getCoursesContentContentDetails(payload))

      }else if(category == 'Reference'){

        let payload ={
          "operation_type":"retrieve",
            "category":"Reference",
            "content_id":id
  
      }
    dispatch(getCoursesContentreferenceDetails(payload))

      }else if(category == 'Assignment'){
       
        let payload ={
          "operation_type":"retrieve",
          "category":"Assignment",
          "content_id":id
  
      }
    dispatch(getCoursesContentAssignmentDetails(payload))

      }else if(category == 'Q&A'){

        let payload ={
          "operation_type":"retrieve",
          "category":"Q&A",
          "content_id":id
  
      }
    dispatch(getCoursesContentQuestionDetails(payload))

      }



    };
  
    const actionBodyTemplate = (rowData) => {
      return (
        <div className="action-buttons">
          <Button 
            icon="pi pi-pencil" 
            className="p-button-rounded p-button-text" 
            onClick={() => handleUpdate(rowData.content_id,rowData.category)} 
            title="Update"
          />
          <Button 
            icon="pi pi-eye" 
            className="p-button-rounded p-button-text" 
            onClick={() => handleView(rowData.content_id,rowData.category)} 
            title="View"
          />
          <Button 
            icon="pi pi-trash" 
            className="p-button-rounded p-button-text" 
            onClick={() => handleDelete(rowData.content_id)} 
            title="Delete"
          />

{rowData.category === 'Assignment' || rowData.category === 'Q&A' ?

<Button 
icon="pi pi-upload" 
className="p-button-rounded p-button-text" 
onClick={() => handleUploadViewDocument(rowData.content_id, rowData.category)} 
title="Upload"
/> : <div></div>}
        </div>
      );
    };

    const navigate = useNavigate();


    const backMethod = () => {

      if(window.localStorage.getItem("role_Type") == 'admin'){
        navigate("/adminCourseContent")

      }else if(window.localStorage.getItem("role_Type") == 'instructor'){
        navigate("/courseContent")

      }


    };

    const handleStatusChange = (id,contentStatus) => {



    let payload ={
      "operation_type":"status_update",
      "op_field_details":{
          "content_id": [id],
          "status": contentStatus === 'Inactive' ? "Active" : 'Inactive'
      }
      
  }

  dispatch(contentStatusUpdateDetails(payload))

  setVisibleNotification(true)

    };


    const statusBodyTemplate = (rowData) => {
      return (
        <div className="action-buttons">
         <div className="actionIcons">

         <label className="switch">
          <input
            type="checkbox"
            checked={rowData.status === "Active"}
            onChange={() => handleStatusChange(rowData.content_id,rowData.status)}
          />
          <span className="slider"></span>
        </label>

                {/* {rowData.status == "Inactive" ? 
                      <div className={"statusInactive"}><span class="dotInactive"></span>In Active</div>
                            
                  : <div className={"statusActive"}><span class="dotActive"></span>Active</div>}   */}
                  
                          </div>
        </div>
      );
    };

    const nullOrEmpty = (rowData, field) => {
      return rowData[field] && rowData[field] !== null && rowData[field] !== " " ? rowData[field] : 'N/A';
    };

    return (
        <div>
  {contextHolder}

  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
  <h3 style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
    <img 
      src={back_icon_png} 
      alt="Back" 
      style={{ marginRight: '8px', cursor: 'pointer', height: '25px', width: '25px' }} 
      onClick={() => backMethod()} 
    />
    {coursesName} Content
  </h3>

  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ width: '200px', position: 'relative' }}>
      <InputComponent 
        onChange={(e) => searchCoursePrice(e)} 
        placeholder="Search" 
        style={{ paddingRight: '25px', width: '100%' }} 
      />
      <img 
        src={searchIcon} 
        alt="Search Icon" 
        style={{ height: '15px', width: '15px', position: 'absolute', top: '15px', right: '8px' }} 
      />
    </div>

    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
      <img 
        src={plus} 
        alt="Add" 
        style={{ height: '25px', width: '25px', cursor: 'pointer', margin: '0 5px' }} 
        onClick={() => handleAddCoursesComponent("Add Category", null)} 
      />

      <img 
        src={excel} 
        alt="Excel" 
        style={{ height: '25px', width: '25px', cursor: 'pointer', margin: '0 5px' }} 
        onClick={() => handleExportExcel("Add Category", null)} 
      />
    </div>
  </div>
</div>



{data && data.length > 0 ? (

 <DataTable
        value={data}
        reorderableRows
        onRowReorder={onRowReorder}
        paginator
        rows={5}
      >



        <Column field="content_id" header="ID"  />
        <Column field="category" header="Category" />
        <Column field="title" header="Title" />

        <Column field="description" header="Description" />
        <Column field="badge_name" header="Badges" body={(rowData) => nullOrEmpty(rowData, 'badge_name')} />

        <Column field="xp" header="XP" body={(rowData) => nullOrEmpty(rowData, 'xp')} />

        <Column field="is_certificate" header="Certificate" body={(rowData) => nullOrEmpty(rowData, 'is_certificate')} />
        
        <Column field="status" header="Status" body={statusBodyTemplate}     style={{ width: '150px', textAlign: 'center' }} // Fixed width and centering text
 />
      
        <Column header="Actions" body={actionBodyTemplate}     style={{ width: '250px', textAlign: 'center' }} // Fixed width and centering text
        />
      </DataTable>): (
  <div style={{ textAlign: 'center', padding: '20px' }}>
    <img src={no_data_image} alt="No Data Available" style={{ width: '250px', height: 'auto' }} />
    <p>Course Content has not yet created</p>
  </div>
)}
     




        {addCoursesContentVisible ? 
  <ModalComponent title={"Add Content"}
  width={1000}  height={1000}
  content={<AddCourseContentModal 
   form2={form2}
   formSection={formSection}
   formContent={formContent}
   formReference={formReference}
   formAssignment={formAssignment}
   formQuestionAndAns={formQuestionAndAns}

   selectedCourseId={selectedCourseId}
  onFinish={onFinish1} 
  onFinish2={onFinishReferenceAdd} 

  onFinishSectionAdd={onFinishSectionAdd} 
  onFinishContextAdd={onFinishContextAdd} 
  onFinishAssignmentAdd={onFinishAssignmentAdd} 
  onFinishQuestionnAdd={onFinishQuestionnAdd} 



   handleClassChange={handleClassChange}
   handleClassChangeSingleAdd={handleClassChangeSingleAdd}

   handleClassSingleReferenceChange={handleClassSingleReferenceChange}
   handleClassMultipleReference={handleClassMultipleReference}

   handleClassSingleAssignmentChange={handleClassSingleAssignmentChange}
   handleClassMultipleAssignment={handleClassMultipleAssignment}

   handleClassSingleCoursesContentChange={handleClassSingleCoursesContentChange}
   handleClassMultipleCoursesContent={handleClassMultipleCoursesContent}

   assignmentDate={assignmentDate}

   handleCancel={handleDeleteCancel}

   coursesContentListPayloadDetails={coursesContentListPayloadDetails}
  referencePayloadDetails={referencePayloadDetails}
  assignmentsPayloadDetails={assignmentsPayloadDetails}

   cancelClassDetails = {coursesContentPayloadDetails}

   selectedBatchId={selectedBatchId}
   
   
   
   />}
open={addCoursesContentVisible} 
handleCancel={handleDeleteCancel}

style={{
  maxHeight: '100px',
  minHeight: '100px',
  width: '80%', // You can adjust this value based on your preference
  maxWidth: '900px', // Optional: Set a maximum width if needed
}}

// style={{ minHeight: `500px` }}
 ></ModalComponent>

:""}


{viewSectionVisible ? 
  <ModalComponent title={"View Section"}
  content={<SectionViewModal 
    coursesContentData={coursesContentData}

   handleCancel={handleDeleteCancel}
   />}
open={viewSectionVisible} 
handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>
:""}

{viewContentVisible ? 
  <ModalComponent title={"View Content"}
  content={<ContentViewViewModal 
    coursesContentData={coursesContentData}
   handleCancel={handleDeleteCancel}
   />}
open={viewContentVisible} 
handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>
:""}

{viewAssignmentVisible ? 
  <ModalComponent title={"View Assignment"}
  content={<AssignmentViewModal 
    coursesContentData={coursesContentData}

   handleCancel={handleDeleteCancel}
   />}
open={viewAssignmentVisible} 
handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>
:""}

{viewReferenceVisible ? 
  <ModalComponent title={"View Reference"}

  content={<ReferenceViewModal 
    coursesContentData={coursesContentData}

   handleCancel={handleDeleteCancel}
   />}
open={viewReferenceVisible} 
handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>
:""}

{viewQuestionAndAnswerVisible ? 
  <ModalComponent title={"View Q&A"}

  content={<QuestionAndAnswerViewModal 
    coursesContentData={coursesContentData}

   handleCancel={handleDeleteCancel}
   />}
open={viewQuestionAndAnswerVisible} 
handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>
:""}

{updateSectionVisible ? 
  <ModalComponent title={"Update Section"}
  width={1000}  height={1000}
  content={<UpdateSectionModal 

   handleCancel={handleDeleteCancel}
   coursesContentData={coursesContentData}
   coursesName={coursesName} 
   selectedCourseId={selectedCourseId} 
   selectedBatchId ={selectedBatchId}
   mainContentId={mainContentId}
   />}
open={updateSectionVisible} 
handleCancel={handleDeleteCancel}

style={{
  maxHeight: '100px',
  minHeight: '100px',
  width: '80%', // You can adjust this value based on your preference
  maxWidth: '900px', // Optional: Set a maximum width if needed
}}
 ></ModalComponent>
:""}


{updateAssignmentVisible ? 
  <ModalComponent title={"Update Assignment"}
  width={1000}  height={1000}
  content={<UpdateAssignmentModal 

   handleCancel={handleDeleteCancel}
   coursesContentData={coursesContentData}
   coursesName={coursesName} 
   selectedCourseId={selectedCourseId} 
   selectedBatchId ={selectedBatchId}
   mainContentId={mainContentId}
   />}
open={updateAssignmentVisible} 
handleCancel={handleDeleteCancel}

style={{
  maxHeight: '100px',
  minHeight: '100px',
  width: '80%', // You can adjust this value based on your preference
  maxWidth: '900px', // Optional: Set a maximum width if needed
}}
 ></ModalComponent>
:""}

{updateContentVisible ? 
  <ModalComponent title={"Update Content"}
  width={1000}  height={1000}
  content={<UpdateCoursesContentContent 

   handleCancel={handleDeleteCancel}
   coursesContentData={coursesContentData}
   coursesName={coursesName} 
   selectedCourseId={selectedCourseId} 
   selectedBatchId ={selectedBatchId}
   mainContentId={mainContentId}
   />}
open={updateContentVisible} 
handleCancel={handleDeleteCancel}

style={{
  maxHeight: '100px',
  minHeight: '100px',
  width: '80%', // You can adjust this value based on your preference
  maxWidth: '900px', // Optional: Set a maximum width if needed
}}
 ></ModalComponent>
:""}

{updateReferenceVisible ? 
  <ModalComponent title={"Update Reference"}
  width={1000}  height={1000}
  content={<UpdateCoursesContentReference 

   handleCancel={handleDeleteCancel}
   coursesContentData={coursesContentData}
   coursesName={coursesName} 
   selectedCourseId={selectedCourseId} 
   selectedBatchId ={selectedBatchId}
   mainContentId={mainContentId}
   />}
open={updateReferenceVisible} 
handleCancel={handleDeleteCancel}

style={{
  maxHeight: '100px',
  minHeight: '100px',
  width: '80%', // You can adjust this value based on your preference
  maxWidth: '900px', // Optional: Set a maximum width if needed
}}
 ></ModalComponent>
:""}

{updateQuestionAndAnswerVisible ? 
  <ModalComponent title={"Update Q&A"}
  width={1000}  height={1000}
  content={<UpdateCoursesContentQuestionAndAnswer 

   handleCancel={handleDeleteCancel}
   coursesContentData={coursesContentData}
   coursesName={coursesName} 
   selectedCourseId={selectedCourseId} 
   selectedBatchId ={selectedBatchId}
   mainContentId={mainContentId}
   />}
open={updateQuestionAndAnswerVisible} 
handleCancel={handleDeleteCancel}

style={{
  maxHeight: '100px',
  minHeight: '100px',
  width: '80%', // You can adjust this value based on your preference
  maxWidth: '900px', // Optional: Set a maximum width if needed
}}
 ></ModalComponent>
:""}


{deleteContentVisible ?
              <ModalComponent title={"Delete Content"} 
                  footer={deleteTitleDescriptionModalFooter} content={"Are you sure you want to delete this content?"}
                   open={deleteContentVisible} handleOk={deleteCourseContent} closeModal={closeDeleteModal}
                  showModal={handleTitleDeleteClick} handleCancel={handleDeleteCancel}/>: " "}







        </div>
    );
}

export default CoursesManagement;
