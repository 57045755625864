import { GET_ACTIVE_COURSES_DETAILS,GET_ACTIVE_COURSES_DETAILS_BASED_ON_INSTRUTOR_ID ,GET_COURSE_CONTENT,
    ADD_COURSE_CONTENT_SECTION,ADD_COURSE_CONTENT_ASSIGNMENT,ADD_COURSE_REFERENCE,COURSES_CONTENT_DRAG_AND_DROP,
    DELETE_COURSE_CONTENT,ADD_COURSE_CONTENT_DETAILD,ADD_COURSE_CONTENT_Q_AND_A,
    GET_COURSE_CONTENT_SECTION,GET_COURSE_CONTENT_DETAILD,GET_COURSE_CONTENT_ASSIGNMENT,GET_COURSE_REFERENCE,CONTENT_STATUS_UPDATE,
    GET_COURSE_QUESTION,UPDATE_COURSE_CONTENT_SECTION,UPDATE_COURSE_CONTENT_CONTENT,UPDATE_COURSE_CONTENT_ASSIGNMENT,
    UPDATE_COURSE_CONTENT_REFERENCE,UPDATE_COURSE_CONTENT_QUESTION,GET_STUDENT_COURSE_CONTENT,GET_ALL_COURSE_FOR_ADMIN,
    UPLOAD_STUDENT_ASSIGNMENT,GET_STUDENT_ASSIGNMENT,UPLOAD_STUDENT_QUIZ,GET_STUDENT_QUIZ,
    GET_UPLOAD_QUESTION_AND_ANSWER,GET_UPLOAD_ASSIGNMENT,UPDATE_ASSIGNMENT_STATUS


} from "./actionType";

import axios from "axios";
import apiConfig from '../../apiConfig/api';



export const getActiveCouresDetails = (data)=>{
    return{
        type: GET_ACTIVE_COURSES_DETAILS,
        payload: data?.data
    }
}

export const getActiveCouresDetailsList = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/courses",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getActiveCouresDetails(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getActiveCouresDetails([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const getCourseContent = (data)=>{
    return{
        type: GET_COURSE_CONTENT,
        payload:   data
    }
}

export const getCoursesContentDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getCourseContent(res.data?.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getCourseContent([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const getActiveCouresDetailsInstructorId = (data)=>{
    return{
        type: GET_ACTIVE_COURSES_DETAILS_BASED_ON_INSTRUTOR_ID,
        payload: data?.data
    }
}

export const getActiveCouresDetailsListData = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/batch",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getActiveCouresDetailsInstructorId(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getActiveCouresDetailsInstructorId([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const addCoursesContentSection = (data)=>{

    return{
        type: ADD_COURSE_CONTENT_SECTION,
        payload: data
    }
}

export const addCoursesContentSectionDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(addCoursesContentSection(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(addCoursesContentSection(res.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const addCoursesContentAssignment = (data)=>{

    return{
        type: ADD_COURSE_CONTENT_ASSIGNMENT,
        payload: data
    }
}

export const addCoursesContentAssignmentDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(addCoursesContentAssignment(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(addCoursesContentAssignment(res.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const addCoursesContentReference = (data)=>{

    return{
        type: ADD_COURSE_REFERENCE,
        payload: data
    }
}

export const addCoursesContentReferenceDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(addCoursesContentReference(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(addCoursesContentReference(res.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const updateDragAndDrop = (data)=>{

    return{
        type: COURSES_CONTENT_DRAG_AND_DROP,
        payload: data
    }
}

export const updateDragAndDropDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(updateDragAndDrop(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(updateDragAndDrop(res.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const deleteCoursesContent = (data)=>{

    return{
        type: DELETE_COURSE_CONTENT,
        payload: data
    }
}

export const deleteCoursesContentDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(deleteCoursesContent(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(deleteCoursesContent(res.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const addCoursesContent = (data)=>{

    return{
        type: ADD_COURSE_CONTENT_DETAILD,
        payload: data
    }
}

export const addCoursesContentDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(addCoursesContent(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(addCoursesContent(res.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const addCoursesContentQuestionAndAnswer = (data)=>{

    return{
        type: ADD_COURSE_CONTENT_Q_AND_A,
        payload: data
    }
}

export const addCoursesContentQuestionAndAnswerDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(addCoursesContentQuestionAndAnswer(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(addCoursesContentQuestionAndAnswer(res.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const getCoursesContentSection = (data)=>{
    return{
        type: GET_COURSE_CONTENT_SECTION,
        payload: data?.data
    }
}

export const getCoursesContentSectionDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getCoursesContentSection(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getCoursesContentSection([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}



export const getCoursesContentAssignment = (data)=>{
    return{
        type: GET_COURSE_CONTENT_ASSIGNMENT,
        payload: data?.data
    }
}

export const getCoursesContentAssignmentDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getCoursesContentAssignment(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getCoursesContentAssignment([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const getCoursesContentreference = (data)=>{
    return{
        type: GET_COURSE_REFERENCE,
        payload: data?.data
    }
}

export const getCoursesContentreferenceDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getCoursesContentreference(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getCoursesContentreference([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const getCoursesContentContent = (data)=>{
    return{
        type: GET_COURSE_CONTENT_DETAILD,
        payload: data?.data
    }
}

export const getCoursesContentContentDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getCoursesContentContent(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getCoursesContentContent([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const contentStatusUpdate = (data)=>{

    return{
        type: CONTENT_STATUS_UPDATE,
        payload: data
    }
}

export const contentStatusUpdateDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(contentStatusUpdate(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(contentStatusUpdate(res.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const getCoursesContentQuestion= (data)=>{
    return{
        type: GET_COURSE_QUESTION,
        payload: data?.data
    }
}

export const getCoursesContentQuestionDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getCoursesContentQuestion(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getCoursesContentQuestion([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const updateCoursesContentSection = (data)=>{

    return{
        type: UPDATE_COURSE_CONTENT_SECTION,
        payload: data
    }
}

export const updateCoursesContentSectionDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(updateCoursesContentSection(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(updateCoursesContentSection(res.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const updateCoursesContentContent = (data)=>{

    return{
        type: UPDATE_COURSE_CONTENT_CONTENT,
        payload: data
    }
}

export const updateCoursesContentContentDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(updateCoursesContentContent(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(updateCoursesContentContent(res.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const updateCoursesContentAssignment = (data)=>{

    return{
        type: UPDATE_COURSE_CONTENT_ASSIGNMENT,
        payload: data
    }
}

export const updateCoursesContentAssignmentDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(updateCoursesContentAssignment(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(updateCoursesContentAssignment(res.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const updateCoursesContentReference = (data)=>{

    return{
        type: UPDATE_COURSE_CONTENT_REFERENCE,
        payload: data
    }
}

export const updateCoursesContentReferenceDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(updateCoursesContentReference(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(updateCoursesContentReference(res.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const updateCoursesContentQuestion = (data)=>{

    return{
        type: UPDATE_COURSE_CONTENT_QUESTION,
        payload: data
    }
}

export const updateCoursesContentQuestionDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(updateCoursesContentQuestion(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(updateCoursesContentQuestion(res.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const getStudentCourseContent= (data)=>{
    return{
        type: GET_STUDENT_COURSE_CONTENT,
        payload: data?.data
    }
}

export const getStudentCourseContentDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/course_content",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getStudentCourseContent(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getStudentCourseContent([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const getAllActiveCoursesForAdmin = (data)=>{
    return{
        type: GET_ALL_COURSE_FOR_ADMIN,
        payload: data?.data
    }
}

export const getAllActiveCoursesForAdminDetailsList = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/content_assignment",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getAllActiveCoursesForAdmin(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getAllActiveCoursesForAdmin([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const uploadStudentAssignment = (data)=>{

    return{
        type: UPLOAD_STUDENT_ASSIGNMENT,
        payload: data
    }
}

export const uploadStudentAssignmentDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/content_assignment",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(uploadStudentAssignment(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(uploadStudentAssignment(res.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const getStudentAssignment = (data)=>{
    return{
        type: GET_STUDENT_ASSIGNMENT,
        payload: data?.data
    }
}

export const getStudentAssifnemntDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/content_assignment",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getStudentAssignment(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getStudentAssignment([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const uploadStudentQuiz= (data)=>{

    return{
        type: UPLOAD_STUDENT_QUIZ,
        payload: data
    }
}

export const uploadStudentQuizDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/quiz",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(uploadStudentQuiz(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(uploadStudentQuiz(res.data))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const getStudentQuiz = (data)=>{
    return{
        type: GET_STUDENT_QUIZ,
        payload: data?.data
    }
}

export const getStudentQuizDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/quiz",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getStudentQuiz(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getStudentQuiz([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const getUploadQuestionAndAnswer = (data)=>{
    return{
        type: GET_UPLOAD_QUESTION_AND_ANSWER,
        payload: data?.data
    }
}

export const getUploadQuestionAndAnswerDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/quiz",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getUploadQuestionAndAnswer(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getUploadQuestionAndAnswer([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const getUploadAssignemnt= (data)=>{
    return{
        type: GET_UPLOAD_ASSIGNMENT,
        payload: data?.data
    }
}

export const getUploadAssignemntDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/content_assignment",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getUploadAssignemnt(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getUploadAssignemnt([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const updateAssignmentSatus= (data)=>{
    return{
        type: UPDATE_ASSIGNMENT_STATUS,
        payload: data
    }
}

export const updateAssignmentSatusDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/content_assignment",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(updateAssignmentSatus(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(updateAssignmentSatus([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


