



import { Button, DatePicker, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import {ModalComponent,TableComponent} from "../../../commonComponents/index.js";
import CertificateViewModal from '../viewModal/certificateView.js';


const ReferenceViewModal = (props) => {

  const [viewCertificateName,setViewCertificateName] = useState(props.coursesContentData[0]?.certificate_name);
  const [viewCertificateTemplate, VsetiewCertificateTemplate] = useState(props.coursesContentData[0]?.certificate_image);
  const [viewCertificateType,setViewCertificateType] = useState(props.coursesContentData[0]?.signature_type);
  const [viewCertificateSignature,setViewCertificateSignature] = useState(props.coursesContentData[0]?.signature);
  const [viewCertificate,setViewCertificate] = useState(false);

  const viewItemCertificate = () => {
 setViewCertificate(true)
};

let handleDeleteCancel= ()=>{
setViewCertificate(false)
} 

let  closeDeleteModal = () =>{
  setViewCertificate(false)
}


  return (
    <div
    style={{
      border: "1px solid #ddd",
      borderRadius: "8px",
      padding: "10px",
      margin: "10px 0",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#f9f9f9",
    }}
  >

<div style={{ minHeight: '200px', maxHeight: '300px', overflowY: 'auto' }}>
<div style={{ marginBottom: "10px" }}>
      <h4 style={{ margin: "0", color: "#555" }}>Title:</h4>
      <p style={{ margin: "5px 0", color: "#333",  fontSize: "14px" }}>
        {props.coursesContentData[0]?.title || "No title available"}
      </p>
    </div>

    <div>
      <h4 style={{ margin: "0", color: "#555" }}>Description:</h4>
      <p style={{ margin: "5px 0", color: "#333", fontSize: "14px" }}>
        {props.coursesContentData[0]?.description || "No description available"}
      </p>
    </div>

    <div>
      <h4 style={{ margin: "0", color: "#555" }}>XP:</h4>
      <p style={{ margin: "5px 0", color: "#333", fontSize: "14px" }}>
        {props.coursesContentData[0]?.xp || "No xp available"}
      </p>
    </div>

    <div>
      <h4 style={{ margin: "0", color: "#555" }}>Badges Name:</h4>
      <p style={{ margin: "5px 0", color: "#333", fontSize: "14px" }}>
        {props.coursesContentData[0]?.badge_name || "No badges name available"}
      </p>
    </div>

    <div>
  <h4 style={{ margin: "0", color: "#555" }}>Badges Image:</h4>
  {props.coursesContentData[0]?.image ? (
    <img
      src={props.coursesContentData[0].image}
      alt="Badge"
      style={{
        height: "50px",
        width: "50px",
        marginTop: "10px",
        objectFit: "cover", // Ensures the image fits properly
        border: "1px solid #ddd", // Optional: Adds a border for styling
        borderRadius: "8px", // Optional: Rounds the corners for a modern look
      }}
      onError={(e) => {
        e.target.onerror = null; // Prevents infinite loop
        e.target.src = "https://via.placeholder.com/50"; // Fallback placeholder
      }}
    />
  ) : (
    <p style={{ margin: "10px 0", color: "#333" }}>No image available</p>
  )}
</div>


{props.coursesContentData[0].reference_type === 'File' ?
(

  <div>
  <h4 style={{ margin: "0", color: "#555" }}>Reference Document:</h4>
  {props.coursesContentData[0]?.reference_document ? (
    props.coursesContentData[0].reference_document.startsWith("data:image/") ? (
      // Render image if it's a valid base64 image string
      <img
        src={props.coursesContentData[0].reference_document}
        alt="Reference Document"
        style={{
          height: "200px",
          marginTop: "10px",
          objectFit: "cover", // Ensures the image fits properly
          border: "1px solid #ddd", // Optional: Adds a border for styling
          borderRadius: "8px", // Optional: Rounds the corners for a modern look
        }}
      />
    ) : (
      // Handle non-image data (e.g., documents)

    //   <a
    //   href={props.coursesContentData[0].reference_document}
    //   target="_blank"
    //   rel="noopener noreferrer"
    //   style={{
    //     color: "#007bff",
    //     textDecoration: "none",
    //     fontSize: "14px",
    //     marginRight: "10px",
    //   }}
    // >
    //   View Document
    // </a>
      <a
        href={props.coursesContentData[0].reference_document}
        download="reference_document"
        style={{
          color: "#007bff",
          textDecoration: "none",
          fontSize: "14px",
        }}
      >
        Download Reference Document
      </a>
    )
  ) : (
    <p style={{ margin: "10px 0", color: "#333" }}>No document available</p>
  )}
</div>


// <div>
//   <h4 style={{ margin: "0", color: "#555" }}>Reference Document:</h4>
//   {props.coursesContentData[0]?.reference_document ? (
//     <img
//       src={props.coursesContentData[0].reference_document}
//       alt="Badge"
//       style={{
//         height: "200px",
//         marginTop: "10px",
//         objectFit: "cover", // Ensures the image fits properly
//         border: "1px solid #ddd", // Optional: Adds a border for styling
//         borderRadius: "8px", // Optional: Rounds the corners for a modern look
//       }}
     
//     />
//   ) : (
//     <p style={{ margin: "10px 0", color: "#333" }}>No image available</p>
//   )}
// </div>

) :(<div>
  <div>
  <h4 style={{ margin: "0", color: "#555" }}>Reference Link:</h4>

  {props.coursesContentData[0]?.reference_document ? (
    <a
      href={props.coursesContentData[0].reference_document}
      target="_blank" // Opens the link in a new tab
      rel="noopener noreferrer" // Ensures security for external links
      style={{
        color: "#007bff", // Optional: Sets the link color
        textDecoration: "none", // Optional: Removes underline
        fontSize: "14px",
      }}
    >
      {props.coursesContentData[0].reference_document}
    </a>
  ) : (
    <p style={{ margin: "5px 0", color: "#333", fontSize: "14px" }}>
      No link available
    </p>
  )}
</div>

  </div>)}


  <div>
      <h4 style={{ margin: "0", color: "#555" }}>Certificate:</h4>

      {props.coursesContentData[0]?.certificate_id === null || props.coursesContentData[0]?.certificate_id === ''
      ? <p style={{ margin: "5px 0", color: "#333", fontSize: "14px" }}>
      {"No certificate available"}
    </p> : <div> 
        

    <button
          style={stylesCertificate.viewButton}
          onClick={(e) => {
            e.stopPropagation(); // Prevent item click event when view button is clicked
            viewItemCertificate();
          }}
        >
          View
        </button>

       </div>}

     
    </div>




    {viewCertificate ? 
  <ModalComponent title={"View Certificate"}
  width={1000}  height={1000}

  content={<CertificateViewModal 
    viewCertificateName={viewCertificateName}
    viewCertificateTemplate={viewCertificateTemplate}
    viewCertificateType={viewCertificateType}
    viewCertificateSignature={viewCertificateSignature}
   handleCancel={handleDeleteCancel}
   />}
open={viewCertificate} 
handleCancel={handleDeleteCancel}
closeModal={closeDeleteModal}

style={{
  maxHeight: '100px',
  minHeight: '100px',
  width: '80%', // You can adjust this value based on your preference
  maxWidth: '900px', // Optional: Set a maximum width if needed
}}
 ></ModalComponent>
:""}

</div>

   
  



  
  </div>
  );
}

const stylesCertificate = {
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
    padding: "15px",

  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "0.3s" ,// Smooth transition for style change
    backgroundColor: "#f0f0f0", // Default background color

  },
  image: {
    marginTop: "10px",
    width: "150px",
    height: "80px",
    objectFit: "cover",
    borderRadius: "5px"
  },
  name: {
    marginTop: "10px",
    fontSize: "10px",
    textAlign: "center",
    color: "#333"
  },
  deleteButton: {
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    backgroundColor: "#ff4d4f",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer"
  },
  viewButton: {
    backgroundColor: "#4caf50",
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginLeft:"5px",
    marginRight:'5px'
  },
};

export default ReferenceViewModal;
