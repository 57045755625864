
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { course, edit, excel, plus, upload, TableEyeIcon, searchIcon, add_icon, back, deleteIcon
  ,gold_image,silver_image,bronze_image
 } from "../../../images/index.js";import { Button, Form, Select ,Input,notification,DatePicker,Checkbox,Collapse, List, Typography,Row, Col } from 'antd';
import InputComponent from '../../../commonComponents/InputComponent/input.js';
import {addCoursesContentQuestionAndAnswerDetails,getCoursesContentDetails,updateCoursesContentQuestionDetails
} from '../../../redux/coursesContent/action'
import { DeleteOutlined } from '@ant-design/icons';

import moment from 'moment';

import {ModalComponent,TableComponent} from "../../../commonComponents/index.js";
import AddBadgesModal from '../courseModel/addBadgesModal.js';

import {createBadgesDetails,deleteBadgesDetails,getBadgesDetails,
  createCertificateDetails,deleteCertificateDetails,getCertificateDetails
} from '../../../redux/badges/action.js';

import AddCertificateModal from '../courseModel/addCertificateModal.js';
import CertificateViewModal from '../viewModal/certificateView.js';


let UpdateCoursesContentQuestionAndAnswer = ({handleCancel, coursesContentData,coursesName, selectedCourseId, selectedBatchId ,mainContentId}) =>{

  

    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [api, contextHolder] = notification.useNotification();

    const [formQuestionAndAns] = Form.useForm();
    const [formBadges] = Form.useForm();
    const [formCertificate] = Form.useForm();
    const [formPreferences] = Form.useForm();

    
  useEffect(()=>{
    formQuestionAndAns.setFieldsValue({['title']:coursesContentData.content_data[0].title})
    formQuestionAndAns.setFieldsValue({['description']:coursesContentData.content_data[0].description})
    formQuestionAndAns.setFieldsValue({['xp']:coursesContentData.content_data[0].xp || ''})

    formQuestionAndAns.setFieldsValue({['min_pass_percentage']:coursesContentData.content_data[0].min_pass_percentage})
    formQuestionAndAns.setFieldsValue({['due_date']:coursesContentData.content_data[0].due_date ? moment(coursesContentData.content_data[0].due_date) : null})
    formQuestionAndAns.setFieldsValue({['duration']:coursesContentData.content_data[0].duration || ''})
  
},[coursesContentData])

    

    const [viewCertificateName,setViewCertificateName] = useState("");
    const [viewCertificateTemplate, VsetiewCertificateTemplate] = useState(false);
    const [viewCertificateType,setViewCertificateType] = useState(false);
    const [viewCertificateSignature,setViewCertificateSignature] = useState(false);
    const [addCertificateVisible, setAddCertificateVisible] = useState(false);
    
    const [selectedCertificate, setSelectedCertificate] = useState(coursesContentData.content_data[0].certificate_image 
      === null ? null : coursesContentData.content_data[0].certificate_id );


      const [selectedId, setSelectedId] = useState(coursesContentData.content_data[0].badge_name 
        === null ? null : coursesContentData.content_data[0].badge_id
      );

    const [certificateList, setCertificateList] = useState([]);
    const [viewCertificate,setViewCertificate] = useState(false);
    const [deleteCertificateVisible,setDeleteCertificateVisible] = useState(false);

    let[addCertificateDetailsPayload,setAddCertificateDetailsPayload] = useState({
      "certificate_name": "",
      "certificate_template": "",
      "signature": "",
      "signature_template": "",
      "batch_id":selectedBatchId,
      "instructor_id":window.localStorage.getItem("instructor_id"),
      "course_id":selectedCourseId,
      "tenant_id": window.localStorage.getItem("tenant_Id"),
   
      })

    const { Panel } = Collapse;
    const { Text } = Typography;


    const [visibleNotification, setVisibleNotification] = useState(false);
    const [addMultipleRecord,setAddMultipleRecord] = useState(1);
    const [preferenceLinkList, setPreferenceLinkList] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [assignmentDate, setAssignmentDate] = useState(null);

    const [preferencesListErrow, setPreferencesListError] = useState(false);
    const [questionListErrow, setQuestionListError] = useState(false);


    
    const [badgesList, setBadgesList] = useState([]);
    const [addBadgesVisible, setAddBadgesVisible] = useState(false);
    const [deleteContentVisible,setDeleteContentVisible] = useState(false);
    const [selectedContentId,setSelectedCoursesContentId] = useState("");

    const [questionDetailsList,setQuestionDetailsList] = useState(coursesContentData.questions || []);


    let[addBadgesDetailsPayload,setAddBadgesDetailsPayload] = useState({
      "name": "",
      "document": "",
      "batch_id":selectedBatchId,
      "instructor_id":window.localStorage.getItem("instructor_id"),
      "course_id":selectedCourseId,
      "tenant_id": window.localStorage.getItem("tenant_Id")
      })

 

      let[allQuestionAndAnser,setAllQuestionAndAnser] = useState({
        "category": "Q&A",
        "title":coursesContentData.content_data[0].title,
        "description":coursesContentData.content_data[0].description,
        "duration":coursesContentData.content_data[0].duration,
        "min_pass_percentage":coursesContentData.content_data[0].min_pass_percentage,
        "due_date":coursesContentData.content_data[0].due_date ? moment(coursesContentData.content_data[0].due_date) : null,
        "batch_id":selectedBatchId,
        "instructor_id":window.localStorage.getItem("instructor_id"),
        "course_id":selectedCourseId,
        "tenant_id": window.localStorage.getItem("tenant_Id")
        })


        
      let crateBadges = useSelector((state)=> state?.badgesReducer?.crateBadges);
      let getBadges = useSelector((state)=> state?.badgesReducer?.getBadges);
      let deleteBadges = useSelector((state)=> state?.badgesReducer?.deleteBadges);

      let addCourseContentQuestionAndAnswer = useSelector((state)=> state?.CoursesContentReducer?.addCourseContentQuestionAndAnswer);
      let updateCourseContentQuestion = useSelector((state)=> state?.CoursesContentReducer?.updateCourseContentQuestion);

      useEffect(()=>{    
        if (updateCourseContentQuestion) {
    if(visibleNotification == true){
      openNotification(updateCourseContentQuestion?.msg)
    
      if(updateCourseContentQuestion?.res_status === true){


        formQuestionAndAns.resetFields();

        formQuestionAndAns.setFieldsValue({['title']:''})
        formQuestionAndAns.setFieldsValue({['description']:''})
        formQuestionAndAns.setFieldsValue({['duration']:''})
        formQuestionAndAns.setFieldsValue({['min_pass_percentage']:''})
        formQuestionAndAns.setFieldsValue({['due_date']:''})



          setAllQuestionAndAnser({
            "category": "Q&A",
            "title":"",
            "description":"",
            "duration":"",
            "min_pass_percentage":"",
            "due_date":"",
            "batch_id":selectedBatchId,
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "course_id":selectedCourseId,
            "tenant_id": window.localStorage.getItem("tenant_Id")
      
        })





 

      let payload ={
        "operation_type":"retrieve_all_content",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCoursesContentDetails(payload))
  setAddMultipleRecord(null)

    
      }
    
    }else{
    
    }
    
        }
    },[updateCourseContentQuestion])

      let crateCertificate = useSelector((state)=> state?.badgesReducer?.crateCertificate);
      let getCertificate = useSelector((state)=> state?.badgesReducer?.getCertificate);
      let deleteCertificate = useSelector((state)=> state?.badgesReducer?.deleteCertificate);

      useEffect(()=>{    
        if (deleteCertificate) {
    if(visibleNotification == true){
      openNotificationBadgeAndCertificate(deleteCertificate?.msg)
    
      if(deleteCertificate?.res_status === true){
  
        setAddCertificateVisible(false)
        formCertificate.resetFields();
  
        formCertificate.setFieldsValue({['certificate_name']:''})
        formCertificate.setFieldsValue({['certificate_template']:''})
  
        formCertificate.setFieldsValue({['signature']:''})
        formCertificate.setFieldsValue({['signature_template']:''})
  
  
        setAddCertificateDetailsPayload({
          "certificate_name": "",
          "certificate_template": "",
          "signature": "",
          "signature_template": "",
        })
  
        let payload ={
          "operation_type":"retrieve",
              "course_id":selectedCourseId,
              "tenant_id":window.localStorage.getItem("tenant_Id"),
              "instructor_id":window.localStorage.getItem("instructor_id"),
              "batch_id":selectedBatchId
  
      }
    dispatch(getCertificateDetails(payload))
    setSelectedCertificate(null)
  
    
      }
    
    }else{
    
    }
    
        }
    },[deleteCertificate])
  
      useEffect(()=>{    
        if (getCertificate) {
  
          setCertificateList(getCertificate)
    
        }
    },[getCertificate])
    
  
      useEffect(()=>{    
        if (crateCertificate) {
    if(visibleNotification == true){
      openNotificationBadgeAndCertificate(crateCertificate?.msg)
    
      if(crateCertificate?.res_status === true){
  
        setAddCertificateVisible(false)
        formCertificate.resetFields();
  
        formCertificate.setFieldsValue({['certificate_name']:''})
        formCertificate.setFieldsValue({['certificate_template']:''})
  
        formCertificate.setFieldsValue({['signature']:''})
        formCertificate.setFieldsValue({['signature_template']:''})
  
  
        setAddCertificateDetailsPayload({
          "certificate_name": "",
          "certificate_template": "",
          "signature": "",
          "signature_template": "",
        })
  
        let payload ={
          "operation_type":"retrieve",
              "course_id":selectedCourseId,
              "tenant_id":window.localStorage.getItem("tenant_Id"),
              "instructor_id":window.localStorage.getItem("instructor_id"),
              "batch_id":selectedBatchId
  
      }
    dispatch(getCertificateDetails(payload))
    setSelectedCertificate(null)
  
    
      }
    
    }else{
    
    }
    
        }
    },[crateCertificate])
  
      useEffect(()=>{
  
        let payload ={
          "operation_type":"retrieve",
              "course_id":selectedCourseId,
              "tenant_id":window.localStorage.getItem("tenant_Id"),
              "instructor_id":window.localStorage.getItem("instructor_id"),
              "batch_id":selectedBatchId
  
      }
    dispatch(getCertificateDetails(payload))
  
    },[])
      
      useEffect(()=>{    
        if (addCourseContentQuestionAndAnswer) {
    if(visibleNotification == true){
      openNotification(addCourseContentQuestionAndAnswer?.msg)
    
      if(addCourseContentQuestionAndAnswer?.res_status === true){

      if(addMultipleRecord === 1){

        formQuestionAndAns.resetFields();

        formQuestionAndAns.setFieldsValue({['title']:''})
        formQuestionAndAns.setFieldsValue({['description']:''})
        formQuestionAndAns.setFieldsValue({['duration']:''})
        formQuestionAndAns.setFieldsValue({['min_pass_percentage']:''})
        formQuestionAndAns.setFieldsValue({['due_date']:''})



          setAllQuestionAndAnser({
            "category": "Q&A",
            "title":"",
            "description":"",
            "duration":"",
            "min_pass_percentage":"",
            "due_date":"",
            "batch_id":selectedBatchId,
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "course_id":selectedCourseId,
            "tenant_id": window.localStorage.getItem("tenant_Id")
      
        })

        navigate(`/courseContentDetails/${coursesName}/${selectedCourseId}/${selectedBatchId}`);


      }else{

    
        
        formQuestionAndAns.setFieldsValue({['title']:''})
        formQuestionAndAns.setFieldsValue({['description']:''})
        formQuestionAndAns.setFieldsValue({['duration']:''})
        formQuestionAndAns.setFieldsValue({['min_pass_percentage']:''})
        formQuestionAndAns.setFieldsValue({['due_date']:''})

        setAllQuestionAndAnser({
          "category": "Q&A",
          "title":"",
          "description":"",
          "duration":"",
          "min_pass_percentage":"",
          "due_date":"",
          "batch_id":selectedBatchId,
          "instructor_id":window.localStorage.getItem("instructor_id"),
          "course_id":selectedCourseId,
          "tenant_id": window.localStorage.getItem("tenant_Id")
        })
      }

 

      let payload ={
        "operation_type":"retrieve_all_content",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCoursesContentDetails(payload))
  setAddMultipleRecord(null)

    
      }
    
    }else{
    
    }
    
        }
    },[addCourseContentQuestionAndAnswer])
  
      useEffect(()=>{    
        if (deleteBadges) {
    if(visibleNotification == true){
      openNotificationBadgeAndCertificate(deleteBadges?.msg)
    
      if(deleteBadges?.res_status === true){
  
        setAddBadgesVisible(false)
        formBadges.resetFields();
  
        formBadges.setFieldsValue({['name']:''})
        formBadges.setFieldsValue({['document']:''})
  
  
        setAddBadgesDetailsPayload({
          "name":"",
          "document":""
        })
  
  
  
   
    
        
        formBadges.setFieldsValue({['name']:''})
        formBadges.setFieldsValue({['document']:''})
  
      
        setAddBadgesDetailsPayload({
          "name":"",
          "document":"",
        })
    
  
  
      let payload ={
        "operation_type":"retrieve_badges_detail",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId
  
    }
  dispatch(getBadgesDetails(payload))
  setAddMultipleRecord(null)
  
    
      }
    
    }else{
    
    }
    
        }
    },[deleteBadges])
  
      useEffect(()=>{    
        if (getBadges) {
  
          console.log(getBadges)
          setBadgesList(getBadges)
    
        }
    },[getBadges])

    const handleClearBadgesModal = () => {
     
      setSelectedId(null)
     };

     const handleClearCertificateModal = () => {
      setSelectedCertificate(null)
    };
  
  
      useEffect(()=>{
  
        let payload ={
          "operation_type":"retrieve_badges_detail",
              "course_id":selectedCourseId,
              "tenant_id":window.localStorage.getItem("tenant_Id"),
              "instructor_id":window.localStorage.getItem("instructor_id"),
              "batch_id":selectedBatchId
  
      }
    dispatch(getBadgesDetails(payload))
  
    },[])
  
      useEffect(()=>{    
        if (crateBadges) {
    if(visibleNotification == true){
      openNotificationBadgeAndCertificate(crateBadges?.msg)
    
      if(crateBadges?.res_status === true){
  
        setAddBadgesVisible(false)
        formBadges.resetFields();
  
        formBadges.setFieldsValue({['name']:''})
        formBadges.setFieldsValue({['document']:''})
  
  
        setAddBadgesDetailsPayload({
          "name":"",
          "document":""
        })
  
  
  
   
    
        
        formBadges.setFieldsValue({['name']:''})
        formBadges.setFieldsValue({['document']:''})
  
      
        setAddBadgesDetailsPayload({
          "name":"",
          "document":"",
        })
    
  
  
      let payload ={
        "operation_type":"retrieve_badges_detail",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId
  
    }
  dispatch(getBadgesDetails(payload))
  setAddMultipleRecord(null)
  
    
      }
    
    }else{
    
    }
    
        }
    },[crateBadges])

    const openNotificationBadgeAndCertificate = (msg) => {
  
      api.info({
        description:
        `${msg}`,
    
      });
    
      setVisibleNotification(false)

   
    
    };

  
    const handleClassChangeSingleQuestion = (e, type) => {
 

        setAddMultipleRecord(1)
        console.log('SINGLE')
      
        if (!type || !type.name) {
          console.error("Type or type.name is undefined:", type);
          return;
        }
      
        let value = e && e.target ? e.target.value : e; // Handle both events and direct values
        console.log(type.name )
  
        console.log(e)
  
        if (type.name === "due_date") {
          setAssignmentDate(e);
        }
    
        // Update other fields
        if (["title", "description","duration","min_pass_percentage","due_date"].includes(type.name)) {
          setAllQuestionAndAnser((prevDetails) => ({
            ...prevDetails,
            [type.name]: value,
          }));
        }
  
     
      };
   
  
  
      const handleClassChangeMultipleQuestion = (e, type) => {
        console.log('MULTIPLE')
  
        setAddMultipleRecord(2)
  
     
      
        if (!type || !type.name) {
          console.error("Type or type.name is undefined:", type);
          return;
        }
      
        let value = e && e.target ? e.target.value : e;
  
        console.log(type.name )
     
      
        if (["title", "description","duration","min_pass_percentage","due_date"
        ].includes(type.name)) {
          setAllQuestionAndAnser((prevDetails) => ({
            ...prevDetails,
            [type.name]: value,
          }));
        }

      };

      let onFinishQuestionnAdd= (values) =>{

        if(preferences.length === 0 ){
          setPreferencesListError(true)
        }else  if(questionDetailsList.length === 0 ){
          setQuestionListError(true)

        }else{


          const dueDate = new Date(allQuestionAndAnser.due_date); // Original date
          dueDate.setDate(dueDate.getDate() + 1); // Increment the day by 1
          const formattedDate = dueDate.toISOString().split('T')[0];
          console.log("Formatted Date:", formattedDate);

          const sortedData = preferences.sort(
            (a, b) => parseFloat(b.pass_percentage) - parseFloat(a.pass_percentage)
          );
          
          // Assigning preferencesAttempt values to no_of_attempts
          const updatedDataPreferences = sortedData.map((item, index) => ({
            ...item,
            no_of_attempts: preferencesAttempt[index] || ""
          }));
          
          console.log(updatedDataPreferences);

          let payload = {
            "operation_type":"update",
            "op_field_details":{
              "category":"Q&A",
              "title": allQuestionAndAnser.title,
              "description":allQuestionAndAnser.description,
              "duration":allQuestionAndAnser.duration,
              "min_pass_percentage":allQuestionAndAnser.min_pass_percentage,
                "batch_id":selectedBatchId,
                "instructor_id":window.localStorage.getItem("instructor_id"),
                "course_id":selectedCourseId,
                "tenant_id":window.localStorage.getItem("tenant_Id"),
                "xp":null,
                "due_date":formattedDate,
                "preferences": updatedDataPreferences,
                "questions":questionDetailsList,
                "badge_id":selectedId ,
                "certificate_id":selectedCertificate,
                "content_id":mainContentId    
            }
        }
  
     
          dispatch(updateCoursesContentQuestionDetails(payload))
  
          setVisibleNotification(true)
        }

   

      }
      let handleDeleteCancel= ()=>{
        setDeleteContentVisible(false)
        setAddBadgesVisible(false)
        setViewCertificate(false)
        setAddCertificateVisible(false)

    
      }   

    

      const handleQuestionDelete = (index) => {
        // Delete the link at the specified index
        const updatedList = questionDetailsList.filter((item, i) => i !== index);
        setQuestionDetailsList(updatedList);
      };

      const handleClassChange = (e, type) => {

      
      };

      const handleCreateQuestionModal = () => {
       
      };    


      
      let  closeDeleteModal = () =>{
        setDeleteContentVisible(false)
      
        setDeleteContentVisible(false)
        setAddBadgesVisible(false)
      }

      const openNotification = (msg) => {
  
        api.info({
          description:
          `${msg}`,
      
        });
      
        setVisibleNotification(false)

        setTimeout(() => {
          handleCancel()
        }, 1000);
      
      };
      
      let deleteCourseContent= ()=>{
      
        setDeleteContentVisible(false)
      
        let payload = {
          "operation_type":"delete",
          "op_field_details":{
              "badge_id":[selectedContentId]
          }
      }
        
        
          dispatch(deleteBadgesDetails(payload))
      
          setVisibleNotification(true)
      
      
        
      
      } 

      let deleteTitleDescriptionModalFooter = [
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' ,marginBottom:"10px"}}>
        <Button   style={{
          border: '1px solid red',  // Red border
          color: 'red',              // Red text color
          backgroundColor: 'transparent',  // No background color
        }} default value={'Cancel'}  onClick={closeDeleteModal}>
              Cancel</Button>
      
              <div style={{marginLeft:"10px"}}></div>
      
              <Button   style={{
          border: '1px solid red',  // Red border
          color: 'white',              // Red text color
          backgroundColor: 'red',  // No background color
        }}  danger value={'Delete'}  onClick={deleteCourseContent}>Delete</Button>
         
        
        </div>
      ]

      const deleteItem = (id) => {
        setDeleteContentVisible(true)
        setSelectedCoursesContentId(id)

      };

      const handleClassBadges= (e, type)=>{
        if (!type || !type.name) {
          console.error("Type or type.name is undefined:", type);
          return;
        }
      
        let value = e && e.target ? e.target.value : e; // Handle both events and direct values
        console.log(type.name )
  
        console.log(e)
  
    
        // Update other fields
        if (["name", "document"].includes(type.name)) {
          setAddBadgesDetailsPayload((prevDetails) => ({
            ...prevDetails,
            [type.name]: value,
          }));
        };
                  
              
                };


      let onFinishBadges= ()=>{

        let payload =  {
          "operation_type":"insert",
          "op_field_details":{
             "badge_name":addBadgesDetailsPayload.name,
             "batch_id":selectedBatchId,
             "image":addBadgesDetailsPayload.document,
             "instructor_id":window.localStorage.getItem("instructor_id"),
             "course_id":selectedCourseId,
             "tenant_id":window.localStorage.getItem("tenant_Id")
          }
       }

  
          dispatch(createBadgesDetails(payload))
      
          setVisibleNotification(true)
      } 

      const handleAddBadgesModal = () => {
       
        setAddBadgesVisible(true)
      };

      const handleItemClick = (id) => {
        setSelectedId(id); // Set the clicked item's id as selected
      };

      let handleTitleDeleteClick = (values) =>{
        deleteContentVisible(false)
    
      }


      //-----------------//

      const [questionData, setQuestionData] = useState({
        question_text: "",
        options: [
          { option_id: 1, option_text: "", is_correct: false },
          { option_id: 2, option_text: "", is_correct: false },
          { option_id: 3, option_text: "", is_correct: false },
          { option_id: 4, option_text: "", is_correct: false },
        ],
      });
    
      const [errors, setErrors] = useState({
        question_text: "",
        options: {},
        checkbox: "",
      });
    
      const handleQuestionChange = (e) => {
        setQuestionData({ ...questionData, question_text: e.target.value });
        setErrors({ ...errors, question_text: "" }); // Clear question error
      };
    
      const handleOptionChange = (e, id) => {
        const updatedOptions = questionData.options.map((option) =>
          option.option_id === id
            ? { ...option, option_text: e.target.value }
            : option
        );
        setQuestionData({ ...questionData, options: updatedOptions });
        setErrors({ ...errors, options: { ...errors.options, [id]: "" } }); // Clear option error
      };
    
      const handleCheckboxChange1 = (e, id) => {
        const updatedOptions = questionData.options.map((option) =>
          option.option_id === id
            ? { ...option, is_correct: e.target.checked }
            : option
        );
        setQuestionData({ ...questionData, options: updatedOptions });
        setErrors({ ...errors, checkbox: "" }); // Clear checkbox error
      };
    
      const validateForm = () => {
        let valid = true;
        const newErrors = { question_text: "", options: {}, checkbox: "" };
    
        // Validate question text
        if (!questionData.question_text.trim()) {
          newErrors.question_text = "Question is required";
          valid = false;
        }
    
        // Validate options text
        questionData.options.forEach((option) => {
          if (!option.option_text.trim()) {
            newErrors.options[option.option_id] = `Option ${option.option_id} is required`;
            valid = false;
          }
        });
    
        // Validate checkboxes
        const isAnyCheckboxSelected = questionData.options.some(
          (option) => option.is_correct
        );
        if (!isAnyCheckboxSelected) {
          newErrors.checkbox = "At least one correct option must be selected";
          valid = false;
        }
    
        setErrors(newErrors);
        return valid;
      };
    
      const handleSubmit = () => {
        if (validateForm()) {

          setQuestionDetailsList((prevList) => [...prevList, questionData]);

         setQuestionData({
            question_text: "",
            options: [
              { option_id: 1, option_text: "", is_correct: false },
              { option_id: 2, option_text: "", is_correct: false },
              { option_id: 3, option_text: "", is_correct: false },
              { option_id: 4, option_text: "", is_correct: false },
            ],
          });

          console.log("Form submitted:", questionData);
        }
      };



      //---------------------




      const [preferences, setPreferences] = useState(coursesContentData.preferences || []);

      const [preferencesAttempt, setPreferencesAttempt] = useState(["first","second","third","fourth","fifth",
        "sixth","seventh","eighth"]);


      const [formData, setFormData] = useState({
        no_of_attempts: "",
        pass_percentage: "",
        xp: "",
      });
    
      const handleInputChange = (e,maxLength) => {
        const { name, value } = e.target;

        // Ensure the value length does not exceed maxLength
        if (value.length <= maxLength) {
          setFormData({
            ...formData,
            [name]: value,
          });
        }
      };
    
      const handleAddPreference = () => {
        if (!formData.pass_percentage || !formData.xp) {
          alert("Please fill in all fields!");
          return;
        }
    
        setPreferences((prev) => [...prev, formData]);
        setFormData({ no_of_attempts: "", pass_percentage: "", xp: "" }); // Reset form
      };

      const handleDeletePreference = (index) => {
        setPreferences((prev) => prev.filter((_, i) => i !== index));
      };


      const disabledDate = (current) => {
        
        return current && current < moment().endOf('day');
      
      
      }

      const deleteItemCertificate = (id) => {

        setDeleteCertificateVisible(true)
      
        setSelectedCertificate(id)
      };

      const handleClassCertificate= (e, type)=>{
                 
        if (!type || !type.name) {
          console.error("Type or type.name is undefined:", type);
          return;
        }
      
        let value = e && e.target ? e.target.value : e; // Handle both events and direct values
        console.log(type.name )
  
        console.log(e)
      
        // Update other fields
        if (["certificate_name", "certificate_template","signature","signature_template"].includes(type.name)) {
          setAddCertificateDetailsPayload((prevDetails) => ({
            ...prevDetails,
            [type.name]: value,
          }));
        };
        
              
                };

                let onFinishCertificate= ()=>{

                  console.log(addCertificateDetailsPayload)
      
                  // Completion of Python Course
      
                 let payload =  {
                  "operation_type": "insert",
                  "op_field_details": {
                     "certificate_name": addCertificateDetailsPayload.certificate_name,
                     "image":addCertificateDetailsPayload.certificate_template,
                     "signature": addCertificateDetailsPayload.signature?  addCertificateDetailsPayload.signature : addCertificateDetailsPayload.signature_template,
                     "signature_type":addCertificateDetailsPayload.signature? 'TEXT':"IMAGE",
                     "batch_id":selectedBatchId,
                     "instructor_id":window.localStorage.getItem("instructor_id"),
                     "course_id":selectedCourseId,
                     "tenant_id":window.localStorage.getItem("tenant_Id")
                  }
               }
          
      
               console.log(payload)
               
                    dispatch(createCertificateDetails(payload))
                
                    setVisibleNotification(true)
}

const handleAddCertificateModal = () => {
  setAddCertificateVisible(true)
};

const viewItemCertificate = (name,template,type,signature) => {



  setViewCertificateName(name)
  VsetiewCertificateTemplate(template)
  setViewCertificateType(type)
  setViewCertificateSignature(signature)

  setViewCertificate(true)


};

let deleteCertificateModal= ()=>{
      
  setDeleteCertificateVisible(false)

  let payload = {
    "operation_type":"delete",
    "op_field_details":{
        "certificate_id":[selectedCertificate]
    }
}
  
  
    dispatch(deleteCertificateDetails(payload))

    setVisibleNotification(true)


  

} 

let deleteCertificateModalFooter = [
  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' ,marginBottom:"10px"}}>
  <Button   style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
  }} default value={'Cancel'}  onClick={closeDeleteModal}>
        Cancel</Button>

        <div style={{marginLeft:"10px"}}></div>

        <Button   style={{
    border: '1px solid red',  // Red border
    color: 'white',              // Red text color
    backgroundColor: 'red',  // No background color
  }}  danger value={'Delete'}  onClick={deleteCertificateModal}>Delete</Button>
   
  
  </div>
]

const handleCertificateItemClick = (id) => {
  setSelectedCertificate(id); 
};


    return (
     <div>
 {contextHolder}
<div style={{ display: 'flex', flexDirection: 'row' }}>

<div style={{ flex: 1 }}>
<div style={{ minHeight: '200px', maxHeight: '300px', overflowY: 'auto' }}>
<Form
          form={formQuestionAndAns}
          onFinish={onFinishQuestionnAdd}

          autoComplete="off"
          layout="vertical"
    
        >
 
 <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please enter title',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"Title"}
                name={"title"}
                value={allQuestionAndAnser.title}
                onChange={(e) => handleClassChangeSingleQuestion(e, { name: "title" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>


     

          <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
  <Form.Item
    label="Description"
    name="description"
    rules={[
      {
        required: true,
        message: 'Please enter description',
      },
    ]}
    style={{ flex: 1 }}
  >
    <Input.TextArea
      placeholder="Description"
      name="description"
      value={allQuestionAndAnser.description} // Adjust this to the appropriate state variable if necessary
      onChange={(e) => handleClassChangeSingleQuestion(e, { name: "description" })}
      style={{ width: '100%' }}
      autoSize={{ minRows: 3, maxRows: 6 }} // Allows the textarea to expand as needed
    />
  </Form.Item>
  </div>



  <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
            <Form.Item
              label="Duration (minutes)"
              name="duration"
              rules={[
                {
                  required: true,
                  message: 'Please enter duration',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"Duration"}
                name={"duration"}
                value={allQuestionAndAnser.duration}
                onChange={(e) => handleClassChangeSingleQuestion(e, { name: "duration" })}
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item
              label="Min Pass Percentage (%)"
              name="min_pass_percentage"
              rules={[
                {
                  required: true,
                  message: 'Please enter min pass pencentage',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"Min Pass Percentage (%)"}
                name={"min_pass_percentage"}
                value={allQuestionAndAnser.min_pass_percentage}
                onChange={(e) => handleClassChangeSingleQuestion(e, { name: "min_pass_percentage" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>

          <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
  <Form.Item
    label="Due Date"
    name="due_date"
    rules={[{ required: true, message: 'Please select due date.' }]}
    style={{ flex: 1 }}
  >
    <DatePicker
      disabledDate={disabledDate}
      onChange={(date) => handleClassChangeSingleQuestion(date, { name: "due_date" })}
      value={allQuestionAndAnser.due_date ? moment(allQuestionAndAnser.due_date) : null} // Ensure it's a moment object or null
      style={{ flex: 1, height: "40px" }}
    />
  </Form.Item>
</div>


              {/* <div style={{border:"1px solid #ddd" ,borderRadius:"10px" ,marginTop:'10px'}}>

              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "95%" ,marginLeft:"10px",marginRight:"10px"}}>
  <p>Question & Answer Session</p>
  <img src={plus} alt="plus-icon" style={{ height: "25px", width: "20px" }}  onClick={() => handleCreateQuestionModal()}/>
</div>

<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 

                <div style={{margin:"10px"}}>





                <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
            <Form.Item
              label="Question"
              name="question"
              rules={[
                {
                  required: true,
                  message: 'Please enter question',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"Question"}
                name={"question"}
                value={allOnlyQuestionAndAnser.question}
                onChange={(e) => handleClassChangeSingleQuestion(e, { name: "question" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>




<div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,marginTop:'10px'}}>
  <Form.Item
    label={
      <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '5px' }}>Option - 1</span>
              

                <div style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingLeft: '10px',
          paddingRight: '10px',
          marginLeft:"5px",marginRight:"5px",
          border: '1px solid #DCF2DF',
          borderRadius: '6px',
          backgroundColor: '#DCF2DF',
        }}>
          <Checkbox    
            // checked={selectedOption === 'first_option_correct'}
 onChange={(e) => handleCheckboxChange(e, 'first_option_correct')}
           style={{
            color: '#28861F',
            border:"#28861F" // Change text color based on status

            // color: isChecked ? '#28861F' : '#ccc', // Change text color based on status
          }} />
          <span style={{ marginLeft: '5px', fontSize: '14px', color: '#28861F', fontWeight: 'bold'  }}>Correct</span>
        </div>

      </div>
    }
   
    name="first_option"
    rules={[
      {
        required: true,
        message: 'Please enter first option',
      },
      () => ({
        validator(_, value) {
          if (validateCheckboxSelection()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please select at least one correct option'));
        },
      }),
    ]}
    style={{ flex: 1 }}
    colon={false} // Removes the colon after the label

  >
    <InputComponent
      placeholder="First Option"
      name="first_option"
      value={allOnlyQuestionAndAnser.first_option}
      onChange={(e) => handleClassChangeSingleQuestion(e, { name: 'first_option' })}
      style={{ width: '100%' }}
    />
  </Form.Item>

  <Form.Item
    label={
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '5px' }}>Option - 2</span>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingLeft: '10px',
          paddingRight: '10px',
          marginLeft:"5px",marginRight:"5px",
          border: '1px solid #DCF2DF',
          borderRadius: '6px',
          backgroundColor: '#DCF2DF',
        }}>
          <Checkbox  
          //  checked={selectedOption === 'second_option_correct'} 
          onChange={(e) => handleCheckboxChange(e, 'second_option_correct')}
           style={{
            color: '#28861F',
            border:"#28861F" // Change text color based on status

            // color: isChecked ? '#28861F' : '#ccc', // Change text color based on status
          }} />
          <span style={{ marginLeft: '5px', fontSize: '14px', color: '#28861F', fontWeight: 'bold'  }}>Correct</span>
        </div>      </div>
    }
    name="details[0].second_option"
    rules={[
      {
        required: true,
        message: 'Please enter second option',
      },() => ({
        validator(_, value) {
          if (validateCheckboxSelection()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please select at least one correct option'));
        },
      }),
    ]}
    style={{ flex: 1 }}
    colon={false} 
  >
    <InputComponent
      placeholder="Second Option"
      name="second_option"
      value={allOnlyQuestionAndAnser.second_option}
      onChange={(e) => handleClassChangeSingleQuestion(e, { name: 'second_option' })}
      style={{ width: '100%' }}
    />
  </Form.Item>
</div>


<div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,marginTop:'10px'}}>
  <Form.Item
    label={
      <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '5px' }}>Option - 3</span>
              

                <div style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingLeft: '10px',
          paddingRight: '10px',
          marginLeft:"5px",marginRight:"5px",

          border: '1px solid #DCF2DF',
          borderRadius: '6px',
          backgroundColor: '#DCF2DF',
        }}>
          <Checkbox
          //  checked={selectedOption === 'third_option_correct'} 
          onChange={(e) => handleCheckboxChange(e, 'third_option_correct')}
           style={{
            color: '#28861F',
            border:"#28861F" // Change text color based on status

            // color: isChecked ? '#28861F' : '#ccc', // Change text color based on status
          }} />
          <span style={{ marginLeft: '5px', fontSize: '14px', color: '#28861F', fontWeight: 'bold'  }}>Correct</span>
        </div>

      </div>
    }
    name="third_option"   

    rules={[
      {
        required: true,
        message: 'Please enter third option',
      },() => ({
        validator(_, value) {
          if (validateCheckboxSelection()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please select at least one correct option'));
        },
      }),
    ]}
    
    style={{ flex: 1 }}
    colon={false} // Removes the colon after the label

  >
    <InputComponent
      placeholder="Third Option"
      name="third_option"
      value={allOnlyQuestionAndAnser.third_option}
      onChange={(e) => handleClassChangeSingleQuestion(e, { name: 'third_option' })}
      style={{ width: '100%' }}
    />
  </Form.Item>

  <Form.Item
    label={
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '5px' }}>Option - 4</span>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingLeft: '10px',
          paddingRight: '10px',
          marginLeft:"5px",marginRight:"5px",
          border: '1px solid #DCF2DF',
          borderRadius: '6px',
          backgroundColor: '#DCF2DF',
        }}>
          <Checkbox 
          // checked={selectedOption === 'fourth_option_correct'} 
          onChange={(e) => handleCheckboxChange(e, 'fourth_option_correct')}
           style={{
            color: '#28861F',
            border:"#28861F" // Change text color based on status

            // color: isChecked ? '#28861F' : '#ccc', // Change text color based on status
          }} />
          <span style={{ marginLeft: '5px', fontSize: '14px', color: '#28861F', fontWeight: 'bold'  }}>Correct</span>
        </div>      </div>
    }
    name="fourth_option"
    rules={[
      {
        required: true,
        message: 'Please enter fourth option',
      },() => ({
        validator(_, value) {
          if (validateCheckboxSelection()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please select at least one correct option'));
        },
      }),
    ]}
    style={{ flex: 1 }}
    colon={false} 
  >
    <InputComponent
      placeholder="Fourth Option"
      name="fourth_option"
      value={allOnlyQuestionAndAnser.fourth_option}
      onChange={(e) => handleClassChangeSingleQuestion(e, { name: 'fourth_option' })}
      style={{ width: '100%' }}
    />
  </Form.Item>
</div>


                </div>
             
              </div> */}


<div
      style={{
        border: "1px solid #ddd",
        borderRadius: "10px",
        marginTop: "10px",
        padding: "10px",
      }}
    >

<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "95%" ,marginLeft:"10px",marginRight:"10px"}}>
<h3>Question & Answer Session</h3>

<img
      src={plus}
      alt="plus-icon"
      style={{ height: "25px", width: "20px", cursor: "pointer" ,marginTop:"3px"}}
      onClick={() => handleSubmit()}
    />

</div>

      {/* <h3>Question & Answer Session</h3> */}

      {/* Question Input */}
      <Form.Item
        label="Question"
        validateStatus={errors.question_text ? "error" : ""}
        help={errors.question_text}
      >
        <Input
          placeholder="Enter question"
          value={questionData.question_text}
          onChange={handleQuestionChange}
        />
      </Form.Item>

   

<div
    style={{
      marginTop:"10px",
      display: "grid",
      gridTemplateColumns: "1fr 1fr", // Two columns
      gap: "20px", // Space between items
    }}
  >
    {questionData.options.map((option, index) => (
      <div style={{margin:"5px"}}>
          <Form.Item
        key={option.option_id}
        label={
          <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: "5px" }}>{`Option - ${index + 1}`}</span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "5px",
                paddingBottom: "5px",
                paddingLeft: "10px",
                paddingRight: "10px",
                marginLeft: "5px",
                marginRight: "5px",
                border: "1px solid #DCF2DF",
                borderRadius: "6px",
                backgroundColor: "#DCF2DF",
              }}
            >
              <Checkbox
                checked={option.is_correct}
                onChange={(e) => handleCheckboxChange1(e, option.option_id)}
              >
                Correct
              </Checkbox>
            </div>
          </div>
        }
        validateStatus={errors.options[option.option_id] ? "error" : ""}
        help={errors.options[option.option_id]}
      >
        <Input
          placeholder={`Option ${index + 1}`}
          value={option.option_text}
          onChange={(e) => handleOptionChange(e, option.option_id)}
        />
      </Form.Item>
      </div>
    
    ))}
  </div>


      {/* Checkbox Error */}
      {errors.checkbox && (
        <div style={{ color: "red", marginTop: "10px" }}>
          {errors.checkbox}
        </div>
      )}

    
    </div>

    {questionListErrow === true ? (
  questionDetailsList.length === 0 ? (
    <p>Please add question and answer</p>
  ) : (
    <div></div>
  )
) : (
  <div></div>
)}



      <div style={{marginTop:"10px"}}></div>
     
      {/* {questionDetailsList.map((q, index) => (
          <div key={index}>
            <p>{q.question_text}</p>
            <ul>
              {q.options.map((option, idx) => (
                <li key={idx}>
                  {option.option_text} - {option.is_correct ? "Correct" : "Wrong"}
                </li>
              ))}
            </ul>
          </div>
        ))} */}

 
  <Collapse accordion style={{ border: 'none',
    boxShadow: 'none'}}>
    {questionDetailsList.map((item, index) => (
      <Panel
        header={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{`Question ${index + 1}`}</span>
            <DeleteOutlined
              onClick={(e) => {
                e.stopPropagation();
                handleQuestionDelete(index);
              }}
              style={{ color: '#ff4d4f', cursor: 'pointer' }}
              title="Delete"
            />
          </div>
        }
        key={index}
        style={{
          marginBottom: '10px',
          border: '1px solid #d9d9d9',
          borderRadius: '8px',
          // padding: '5px',
          backgroundColor: '#f5f5f5'
        }}
      >
        <Col flex="auto">
          <Text strong>Question: </Text> <Text>{item.question_text}</Text>
          {/* <br />
          <Text strong>Answer: </Text> <Text>{item.answer}</Text> */}
        </Col>

        <ul>
              {item.options.map((option, idx) => (
                <li key={idx}>
                  {option.option_text} - {option.is_correct ? "Correct" : "Wrong"}
                </li>
              ))}
            </ul>

        {/* <List
          header={<Text strong>Options</Text>}
          dataSource={Object.entries(item.details)}
          renderItem={([option, value]) => (
            <List.Item style={{ padding: '5px 10px', borderBottom: '1px solid #f0f0f0' }}>
              <Text strong>{option}: </Text> <Text>{value || "No answer provided"}</Text>
            </List.Item>
          )}
          style={{ marginTop: '10px' }}
        /> */}
      </Panel>
    ))}
  </Collapse>


 
         
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "95%" ,marginLeft:"10px",marginRight:"10px"}}>
  <p>Preferences</p>
  {/* <img src={plus} alt="plus-icon" style={{ height: "25px", width: "20px" }}  onClick={() => handleCreateQuestionModal()}/> */}
</div>

<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
   marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 

<Form
          form={formPreferences}

          autoComplete="off"
          layout="vertical"
         
        >

<div style={{ width: "95%", margin: "20px auto" }}>
      <h3 style={{ marginBottom: "20px" }}>Add Preferences</h3>

      {/* Input Fields */}
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        {/* <input
          type="text"
          name="no_of_attempts"
          value={formData.no_of_attempts}
          placeholder="No of Attempts"
          onChange={handleInputChange}
          style={{
            padding: "8px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            width: "30%",
          }}
        /> */}
        <input
          type="number"
          name="pass_percentage"
          value={formData.pass_percentage}
          placeholder="Pass Percentage"
          onChange={(e) => handleInputChange(e, 3)} // Pass max length
          style={{
            padding: "8px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            width: "30%",
          }}
        />
        <input
          type="number"
          name="xp"
          value={formData.xp}
          placeholder="XP"
          onChange={(e) => handleInputChange(e, 3)} // Pass max length
          style={{
            padding: "8px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            width: "30%",
          }}
        />

<img
      src={plus}
      alt="plus-icon"
      style={{ height: "25px", width: "20px", cursor: "pointer" ,marginTop:"3px"}}
      onClick={() => handleAddPreference()}
    />
     
      </div>

      {/* Preferences List */}

     {preferencesListErrow === true ? (
  preferences.length === 0 ? (
    <p>Please add preferences</p>
  ) : (
    <div></div>
  )
) : (
  <div></div>
)}


      <h4>Preferences</h4>
      <div>
      <div>
        {preferences.map((pref, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "10px",
              border: "1px solid #ddd",
              borderRadius: "8px",
              marginBottom: "10px",
              backgroundColor: "#f9f9f9",
            }}
          >
            {/* <p style={{ margin: 0, fontWeight: "bold" }}>
              {pref.no_of_attempts}
            </p> */}
            <p style={{ margin: 0 }}>{pref.pass_percentage}%</p>
            <p style={{ margin: 0 }}>{pref.xp}</p>

            <DeleteOutlined
              onClick={(e) => {
                e.stopPropagation();
                handleDeletePreference(index);
              }}
              style={{ color: '#ff4d4f', cursor: 'pointer' }}
              title="Delete"
            />
            {/* <button
              onClick={() => handleDeletePreference(index)}
              style={{
                padding: "5px 10px",
                backgroundColor: "#ff4d4f",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Delete
            </button> */}
          </div>
        ))}
      </div>
      </div>
    </div>

        </Form>







        </Form></div>
</div>


<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '0.1px',            // Set the width of the line
      height:'40vh',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
      marginLeft:'10px',marginRight:"10px"     // Ensure the line spans the height of the flex container
    }}
  />  

<div style={{ flex: 1 }}>
<div style={{ minHeight: '200px', maxHeight: '300px', overflowY: 'auto' }}>
<Form
          form={formQuestionAndAns}
          onFinish={onFinishQuestionnAdd}

          autoComplete="off"
          layout="vertical"
         
        >

{/* <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,marginTop:"12px" }}>
            <Form.Item
              label="XP"
              name="xp"
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please enter xp',
              //   },
              // ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"XP"}
                name={"xp"}
                value={allQuestionAndAnser.xp}
                onChange={(e) => handleClassChange(e, { name: "xp" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div> */}
      <div

  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  }}
>
  <p style={{ margin: 0 }}>Badges</p>

  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

   { selectedId === null || selectedId === '' ? <div></div> :
       <button
       style={stylesCertificate.deleteButton}
       onClick={(e) => {
         e.stopPropagation(); // Prevent item click event when delete button is clicked
         handleClearBadgesModal();
       }}
     >
       Clear
     </button>}
    <img
      src={plus}
      alt="plus-icon"
      style={{ height: "25px", width: "20px", cursor: "pointer" }}
      onClick={() => handleAddBadgesModal()}
    />
  </div>
</div>


<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 

          
<div style={styles.gridContainer}>
      {badgesList?.map(item => (
        <div
          key={item.badge_id}
          style={{
            ...styles.gridItem,
            border: selectedId === item.badge_id ? "2px solid blue" : "1px solid #ddd",
            backgroundColor: selectedId === item.badge_id ? "#e0f7ff" : "#f9f9f9"
          }}
          onClick={() => handleItemClick(item.badge_id)}
        >
          <img src={item.image} alt={item.badge_name} style={styles.image} />
          <p style={styles.name}>{item.badge_name}</p>

          {/* <button
            style={styles.deleteButton}
            onClick={(e) => {
              e.stopPropagation(); // Prevent item click event when delete button is clicked
              deleteItem(item.badge_id);
            }}
          >
            Delete
          </button> */}

<img
      src={deleteIcon}
      alt="plus-icon"
      style={{
        height: "25px",
        width: "20px",
        cursor: "pointer",
        border: "1px solid  #0E60B2", // Adds a border
        borderRadius: "4px",       // Optional: Makes the corners rounded
      }}      onClick={() => deleteItem(item.badge_id)}
    />
        </div>
      ))}
    </div>

    <div
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  }}
>
  <p style={{ margin: 0 }}>Certificate</p>

  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

   { selectedCertificate === null || selectedCertificate === '' ? <div></div> :
       <button
       style={stylesCertificate.deleteButton}
       onClick={(e) => {
         e.stopPropagation(); // Prevent item click event when delete button is clicked
         handleClearCertificateModal();
       }}
     >
       Clear
     </button>}
    <img
      src={plus}
      alt="plus-icon"
      style={{ height: "25px", width: "20px", cursor: "pointer" }}
      onClick={() => handleAddCertificateModal()}
    />
  </div>
</div>


<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 





    <div style={stylesCertificate.gridContainer}>
  {certificateList?.map((item) => (
    <div
      key={item.id}
      style={{
        ...stylesCertificate.gridItem,
        border: selectedCertificate === item.certificate_id ? "2px solid blue" : "1px solid #ddd",
        backgroundColor: selectedCertificate === item.certificate_id ? "#e0f7ff" : "#f9f9f9",
      }}
      onClick={() => handleCertificateItemClick(item.certificate_id)}
    >
      <img src={item.image} alt={item.certificate_name} style={stylesCertificate.image} />
      {/* <p style={stylesCertificate.name}>{item.name}</p> */}

      <div style={stylesCertificate.actionContainer}>

      <img
      src={TableEyeIcon}
      alt="plus-icon"
      style={{
        height: "25px",
        width: "20px",
        cursor: "pointer",
        marginRight:"5px",
        border: "1px solid  #0E60B2", // Adds a border
        borderRadius: "4px",       // Optional: Makes the corners rounded
      }}      onClick={() => viewItemCertificate(item.certificate_name,item.image,item.signature_type,item.signature)}
    />




<img
      src={deleteIcon}
      alt="plus-icon"
      style={{
        height: "25px",
        width: "20px",
        cursor: "pointer",
        border: "1px solid  #0E60B2", // Adds a border
        borderRadius: "4px",       // Optional: Makes the corners rounded
      }}      onClick={() => deleteItemCertificate(item.badge_id)}
    />
        {/* <button
          style={stylesCertificate.viewButton}
          onClick={(e) => {
            e.stopPropagation(); // Prevent item click event when view button is clicked
            viewItemCertificate(item.certificate_name,item.image,item.signature_type,item.signature);
          }}
        >
          View
        </button>


        <button
          style={stylesCertificate.deleteButton}
          onClick={(e) => {
            e.stopPropagation(); // Prevent item click event when delete button is clicked
            deleteItemCertificate(item.certificate_id);
          }}
        >
          Delete
        </button> */}
      </div>
    </div>
  ))}
</div>

        </Form>
</div>

</div>




  </div>


  <div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 


<Form
          form={formQuestionAndAns}
          onFinish={onFinishQuestionnAdd}

          autoComplete="off"
          layout="vertical"

        
        >
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Form.Item>




              <Button htmlType='submit' type='primary' style={{ marginRight: '8px', width:"110px" }} onClick={handleClassChangeMultipleQuestion}>
                Update
              </Button>
            
              <Button
  htmlType="submit"
  onClick={handleCancel}
  style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px"
  }}
>
  Cancel
</Button>

            </Form.Item>
 </div>
        </Form>

        {addBadgesVisible ? 
  <ModalComponent title={"Add Badges"}
 
  content={<AddBadgesModal 
   handleCancel={handleDeleteCancel}
   addBadgesDetailsPayload = {addBadgesDetailsPayload}
   formBadges={formBadges}
onFinishBadges={onFinishBadges} 
handleClassBadges={handleClassBadges}
   />}
open={addBadgesVisible} 


handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>

:""}


{deleteContentVisible ?
              <ModalComponent title={"Delete Badges"} 
                  footer={deleteTitleDescriptionModalFooter} content={"Are you sure you want to delete this content?"}
                   open={deleteContentVisible} handleOk={deleteCourseContent} closeModal={closeDeleteModal}
                  showModal={handleTitleDeleteClick} handleCancel={handleDeleteCancel}/>: " "}
{deleteCertificateVisible ?
              <ModalComponent title={"Delete Certificate"} 
                  footer={deleteCertificateModalFooter} content={"Are you sure you want to delete this certificate?"}
                   open={deleteCertificateVisible} handleOk={deleteCertificateModal} closeModal={closeDeleteModal}
                  showModal={handleTitleDeleteClick} handleCancel={handleDeleteCancel}/>: " "}

{addCertificateVisible ? 
  <ModalComponent title={"Add Certificate"}
 
  content={<AddCertificateModal 
   handleCancel={handleDeleteCancel}
   addCertificateDetailsPayload = {addCertificateDetailsPayload}
   formCertificate={formCertificate}
   onFinishCertificate={onFinishCertificate} 
handleClassCertificate={handleClassCertificate}
   />}
open={addCertificateVisible} 


handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>

:""}


{viewCertificate ? 
  <ModalComponent title={"View Certificate"}
  width={1000}  height={1000}

  content={<CertificateViewModal 
    viewCertificateName={viewCertificateName}
    viewCertificateTemplate={viewCertificateTemplate}
    viewCertificateType={viewCertificateType}
    viewCertificateSignature={viewCertificateSignature}
   handleCancel={handleDeleteCancel}
   />}
open={viewCertificate} 
handleCancel={handleDeleteCancel}
closeModal={closeDeleteModal}

style={{
  maxHeight: '100px',
  minHeight: '100px',
  width: '80%', // You can adjust this value based on your preference
  maxWidth: '900px', // Optional: Set a maximum width if needed
}}
 ></ModalComponent>
:""}


      </div>

    )
}

const styles = {
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gap: "10px",
    padding: "15px"
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "0.3s" // Smooth transition for style change
  },
  image: {
    marginTop: "10px",
    width: "20px",
    height: "20px",
    objectFit: "cover",
    borderRadius: "5px"
  },
  name: {
    marginTop: "10px",
    fontSize: "10px",
    textAlign: "center",
    color: "#333"
  },
  deleteButton: {
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    backgroundColor: "#ff4d4f",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer"
  }
};


const stylesCertificate = {
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
    padding: "15px",

  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "0.3s" ,// Smooth transition for style change
    backgroundColor: "#f0f0f0", // Default background color

  },
  image: {
    marginTop: "10px",
    width: "150px",
    height: "80px",
    objectFit: "cover",
    borderRadius: "5px"
  },
  name: {
    marginTop: "10px",
    fontSize: "10px",
    textAlign: "center",
    color: "#333"
  },
  deleteButton: {
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    backgroundColor: "#ff4d4f",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer"
  },
  viewButton: {
    backgroundColor: "#4caf50",
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginLeft:"5px",
    marginRight:'5px'
  },
};

export default UpdateCoursesContentQuestionAndAnswer