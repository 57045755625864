import React, { useRef,useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {getAllActiveCoursesForAdminDetailsList} from '../../redux/coursesContent/action'
import { TableComponent,CheckboxComponent, 
    InputComponent, InputSelect,
    ModalComponent, ButtonComponent,ConfirmModalComponent } from "../../commonComponents";
import { course,edit,excel,plus,upload,TableEyeIcon,certificate_1,searchIcon, certificate_2,certificate_3,
  blank_certificate_1,blank_certificate_2,blank_certificate_3
} from "../../images";
import { Col, Form, Button,notification,DatePicker ,Select} from 'antd';
import { Tabs } from 'antd';

import CoursesManagement from '../../components/coursesContent/tabController/contentManagement'
import html2canvas from "html2canvas";

const { TabPane } = Tabs;

let AdminAllCourseContent = () =>{

  

    let dispatch = useDispatch();
    let navigate = useNavigate();


    const [coursesName, setCoursesName] = useState('');
    const [selectedCourseId, setSelectedCoursesId] = useState('');
    const [selectedBatchId, setSelectedBatchId] = useState('');



    let [allCoursesDetailsList,setAllCoursesDetailsList] = useState();
    const [searchEntry,setSearchEntry] = useState("");
    const [isVisible, setIsVisible] = useState(true);




    let allActiveCourses = useSelector((state)=> state.CoursesContentReducer?.getAllActiveCoursesForAdmin);

    useEffect(()=>{
        if (allActiveCourses) {
            setAllCoursesDetailsList(allActiveCourses)
        }
    },[allActiveCourses])

    useEffect(()=>{

        let payload = 
        {
            "operation_type":"retrieve_batch_active_courses",
            "tenant_id": window.localStorage.getItem("tenant_Id"),
        }

        dispatch(getAllActiveCoursesForAdminDetailsList(payload))
    },[])


    const columns = [

        // {
        //   title: 'ID',
        //   dataIndex: 'course_id',
        //   sorter: true,
        //   sorter: (a, b) => a.course_id - b.course_id,
    
        // },

        {
            title: 'Batch Name',
            dataIndex: 'batch_name',
            sorter: true,
            sorter: (a, b) => a.batch_name .localeCompare(b.batch_name),
    
          },

          {
            title: 'Instructor Name',
            dataIndex: 'instructor_name',
            sorter: true,
            sorter: (a, b) => a.instructor_name .localeCompare(b.instructor_name),
    
          },
    
          {
            title: 'Course Name',
            dataIndex: 'course_name',
            sorter: true,
            sorter: (a, b) => a.course_name .localeCompare(b.course_name),
    
          },
         
            {
              title: 'Language',
              dataIndex: 'course_language',
              sorter: true,
              sorter: (a, b) => a.course_language .localeCompare(b.course_language),
    
              filteredValue: [searchEntry],
              onFilter: (value,record)=>{
                 return String(record.course_language).toLowerCase().includes(value.toLowerCase())||
                        String(record.course_level).toLowerCase().includes(value.toLowerCase())||
                        String(record.course_duration).toLowerCase().includes(value.toLowerCase())||
                        String(record.course_name).toLowerCase().includes(value.toLowerCase())||
                        String(record.course_category).toLowerCase().includes(value.toLowerCase())||
                        String(record.course_id).toLowerCase().includes(value.toLowerCase())
                      }
            },
          {
            title: 'Level',
            dataIndex: 'course_level',
            sorter: true,
            sorter: (a, b) => a.course_level .localeCompare(b.course_level),
    
          },
          {
            title: 'Duration',
            dataIndex: 'course_duration',
            sorter: true,

    
          sorter: (a, b) => {
            // Extract numeric values from the duration strings
            const durationA = parseInt(a.course_duration);
            const durationB = parseInt(b.course_duration);
            
            // If durations are NaN (not a number), return 0 to maintain the original order
            if (isNaN(durationA) || isNaN(durationB)) {
                return 0;
            }
            
            return durationA - durationB;
        }
    

          },

          {
            title: 'Course Category',
            dataIndex: 'course_category',
            sorter: true,
            sorter: (a, b) => a.course_category .localeCompare(b.course_category),
    
          },

          {
            title:'Action',
            dataIndex: 'action',
            render: (text, record) => (
              <div className="actionIcons">
                  {/* <img src={edit} onClick={() => handleEditModal("Edit Course",record.course_id)} style={{marginRight:"5px"}}/> */}
                  <img src={TableEyeIcon} onClick={() => handleViewModal("View Course",record.course_name,record.course_id,record.batch_id,record.instructor_id)} style={{marginRight:"5px"}}/>
                  {/* <img src={add_icon} onClick={() => handleAddModal("Add Course",record.course_id)} /> */}

                  {/* <img src={deleteIcon} onClick={()=> handleDeleteModal(record.course_id)}/> */}
              </div>
             ),
          }
        ];



        const searchCourseByName= (e) =>{
            setSearchEntry(e?.target?.value)

        }




          let handleViewModal = async(type,name,id,batch_id,instructor_id) =>{

            window.localStorage.setItem("instructor_id",instructor_id);

         
            navigate(`/courseContentDetails/${name}/${id}/${batch_id}`);

          }

        

          const backMethod = () => {
            setIsVisible(true);
          };




          //---------------------------------------



          
          
        
          // const drawCertificate = () => {
          //   const canvas = canvasRef.current;
          //   const ctx = canvas.getContext("2d");
          
          //   const template = new Image();
          //   template.src = certificate_1; // Update to the correct path
          
          //   template.onload = () => {
          //     canvas.width = template.width;
          //     canvas.height = template.height;
          
          //     ctx.drawImage(template, 0, 0, canvas.width, canvas.height);
          
          //     ctx.font = "30px Arial";
          //     ctx.fillStyle = "black";
          //     ctx.textAlign = "center";
          //     ctx.fillText(certificateName, canvas.width / 2, 200);
          
          //     ctx.font = "24px Arial";
          //     ctx.fillText(courseName, canvas.width / 2, 250);
          
          //     if (signature) {
          //       const signatureImg = new Image();
          //       signatureImg.src = signature;
          //       signatureImg.onload = () => {
          //         ctx.drawImage(signatureImg, canvas.width - 200, canvas.height - 100, 150, 50);
          //       };
          //       signatureImg.onerror = () => console.error("Failed to load signature");
          //     }
          //   };
          
          //   template.onerror = () => {
          //     console.error("Failed to load template image");
          //   };
          // };
       

          const certificateRef = useRef();

          // State to store user inputs
          const [certificateTitle, setCertificateTitle] = useState("Certificate of Completion");
          const [certificateName, setCertificateName] = useState("THIS CERTIFICATE IS PROUDLY PRESENTED TO");
          // const [certificateName, setCertificateName] = useState("Excellence in Web Development");

          const [studentName, setStudentName] = useState("Damodar Bhuyan");
          const [signature, setSignature] = React.useState('Avni Bhuyan');
const [signatureImage, setSignatureImage] = React.useState(null);
        
          const today = new Date().toLocaleDateString();
        
          const downloadCertificate = () => {
            const element = certificateRef.current;
            html2canvas(element).then((canvas) => {
              const link = document.createElement("a");
              link.download = "certificate.png";
              link.href = canvas.toDataURL("image/png");
              link.click();
            });
          };
        



    return (


    
      <div>

{isVisible == true &&
<div>
      

      <div className="allCourses">
          <h3>Course</h3>
          <div className="searchCourse">
              <div className="searchC"><InputComponent className='searchField'
               placeholder={'Search'} onChange={(e)=>searchCourseByName(e)}/></div>
              <img src={searchIcon} className="searchIcon" style={{marginBottom:'45px',marginLeft:"10px"}}/>
          </div>
      </div>


      <TableComponent columns={columns} data={allCoursesDetailsList}/>

  </div>}

  {isVisible == false && (
  <div>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' , padding:"10px"}}>
  {/* <h3 style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
    <img 
      src={back} 
      alt="Back" 
      style={{ marginRight: '8px', cursor: 'pointer', height: '30px', width: '30px', display: 'inline-block' }} 
      onClick={() => backMethod()} 
    />
   {coursesName} Content
  </h3> */}


</div>
    {/* <CoursesTabController  courseNameList={coursesName} selectedCourseId={selectedCourseId}/> */}
    <CoursesManagement  coursesName={coursesName} selectedCourseId={selectedCourseId} selectedBatchId={selectedBatchId}/>

  </div>

)}







      </div>

    )
}

export default AdminAllCourseContent