import {STUDENT_CRUD_STATUS,GET_ALL_ADMIN_STUDENTS,STUDENT_REQUEST_APPROVED_ACTION,
    STUDENT_REQUEST_REJECTED_ACTION,CONTACT_US_DETAILS
 } from "./actionTypes";


 const AdminStudent = (state = [],action)=>{

    
    switch (action.type) {
        case  GET_ALL_ADMIN_STUDENTS:
            return {
                
                adminStudents: action.payload,
            }

        case STUDENT_CRUD_STATUS:
            return {
                studentCrudStatus: action.payload
            }

            case STUDENT_REQUEST_APPROVED_ACTION:
            return {
                approvedStatus: action.payload
            }

            case STUDENT_REQUEST_REJECTED_ACTION:
            return {
                rejectedStatus: action.payload
            }


            case CONTACT_US_DETAILS:
            return {
                contactUsDetailsList: action.payload
            }
        default:
            return state;
    }
}

export  default AdminStudent;