import {GET_STUDENT_COURSE,
} from "./actionTypes";

 const StudentCoursReducer = (state = [],action)=>{

    switch (action.type) {
            case GET_STUDENT_COURSE:
                return{
                    getStudentCours: action.payload
                }


    







        default:
            return state;
    }
}

export  default StudentCoursReducer;