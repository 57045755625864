import {GET_ACTIVE_COURSES_DETAILS,GET_ACTIVE_COURSES_DETAILS_BASED_ON_INSTRUTOR_ID,GET_COURSE_CONTENT,
    ADD_COURSE_CONTENT_SECTION,ADD_COURSE_CONTENT_ASSIGNMENT,ADD_COURSE_REFERENCE,COURSES_CONTENT_DRAG_AND_DROP,
    DELETE_COURSE_CONTENT,ADD_COURSE_CONTENT_DETAILD,ADD_COURSE_CONTENT_Q_AND_A,
    GET_COURSE_CONTENT_SECTION,GET_COURSE_CONTENT_DETAILD,GET_COURSE_CONTENT_ASSIGNMENT,GET_COURSE_REFERENCE,CONTENT_STATUS_UPDATE,
    GET_COURSE_QUESTION,UPDATE_COURSE_CONTENT_SECTION,UPDATE_COURSE_CONTENT_CONTENT,UPDATE_COURSE_CONTENT_ASSIGNMENT,
    UPDATE_COURSE_CONTENT_REFERENCE,UPDATE_COURSE_CONTENT_QUESTION,GET_STUDENT_COURSE_CONTENT,GET_ALL_COURSE_FOR_ADMIN,
    UPLOAD_STUDENT_ASSIGNMENT,GET_STUDENT_ASSIGNMENT,UPLOAD_STUDENT_QUIZ,GET_STUDENT_QUIZ,
    GET_UPLOAD_QUESTION_AND_ANSWER,GET_UPLOAD_ASSIGNMENT,UPDATE_ASSIGNMENT_STATUS

} from "./actionType";

 const CoursesContentReducer = (state = [],action)=>{

    switch (action.type) {
            case GET_ACTIVE_COURSES_DETAILS:

                return{
                    getActiveCoursesDetails: action.payload
                }

                case GET_ACTIVE_COURSES_DETAILS_BASED_ON_INSTRUTOR_ID:
                return{
                    getActiveCoursesDetailsdata: action.payload
                }

 case GET_COURSE_CONTENT:
 return{
getCoursesContent: action.payload
}

case ADD_COURSE_CONTENT_SECTION:
    return{
   addCoursesContentSection: action.payload
   }

   case ADD_COURSE_CONTENT_ASSIGNMENT:
    return{
   addCoursesContentAssignment: action.payload
   }

   case ADD_COURSE_REFERENCE:
    return{
   addCoursesContentReference: action.payload
   }

   case COURSES_CONTENT_DRAG_AND_DROP:
    return{
   updateDragAndDrop: action.payload
   }
   case DELETE_COURSE_CONTENT:
    return{
   deleteCoursesContent: action.payload
   }

   case ADD_COURSE_CONTENT_DETAILD:
    return{
   addCoursesContentDetails: action.payload
   }

   case ADD_COURSE_CONTENT_Q_AND_A:
    return{
   addCourseContentQuestionAndAnswer: action.payload
   }

   case GET_COURSE_CONTENT_SECTION:
    return{
   getCourseContentSection: action.payload
   }

   case GET_COURSE_CONTENT_DETAILD:
    return{
   getCoursesContantDetails: action.payload
   }

   case GET_COURSE_CONTENT_ASSIGNMENT:
    return{
   getCoursesContentAssignment: action.payload
   }

   case GET_COURSE_REFERENCE:
    return{
   getCourseReference: action.payload
   }

   case CONTENT_STATUS_UPDATE:
    return{
   contentStatusUpdate: action.payload
   }


   case GET_COURSE_QUESTION:
    return{
   getCoursesQuestion: action.payload
   }

   case UPDATE_COURSE_CONTENT_SECTION:
    return{
   updateCourseContentSection: action.payload
   }

   case UPDATE_COURSE_CONTENT_CONTENT:
    return{
   updateCourseContentContent: action.payload
   }

   case UPDATE_COURSE_CONTENT_ASSIGNMENT:
    return{
   updateCourseContentAssignment: action.payload
   }

   case UPDATE_COURSE_CONTENT_REFERENCE:
    return{
   updateCourseContentReference: action.payload
   }

   case UPDATE_COURSE_CONTENT_QUESTION:
    return{
   updateCourseContentQuestion: action.payload
   }

   case GET_STUDENT_COURSE_CONTENT:
    return{
   getStudentCourseContent: action.payload
   }

   case GET_ALL_COURSE_FOR_ADMIN:
    return{
   getAllActiveCoursesForAdmin: action.payload
   }

   case UPLOAD_STUDENT_ASSIGNMENT:
    return{
   uploadStudentAssignments: action.payload
   }

   case GET_STUDENT_ASSIGNMENT:
    return{
   getStudentAssignments: action.payload
   }


   case UPLOAD_STUDENT_QUIZ:
    return{
   uploadStudentQuiz: action.payload
   }

   case GET_STUDENT_QUIZ:
    return{
   getStudentQuiz: action.payload
   }

   case GET_UPLOAD_QUESTION_AND_ANSWER:
    return{
   getUploadQuestionAnswer: action.payload
   }

   case GET_UPLOAD_ASSIGNMENT:
    return{
   getUploadAssignment: action.payload
   }

   case UPDATE_ASSIGNMENT_STATUS:
    return{
   updateAssignmentStatus: action.payload
   }


        default:
            return state;
    }
}

export  default CoursesContentReducer;