



import { Button, DatePicker, Form, Select } from 'antd';
import React, { useRef,useEffect, useState } from 'react';


const CertificateViewModal = (props) => {

    // viewCertificateName={viewCertificateName}
    // viewCertificateTemplate={viewCertificateTemplate}
    // viewCertificateType={viewCertificateType}
    // viewCertificateSignature={viewCertificateSignature}

    const certificateRef = useRef();
    const today = new Date().toLocaleDateString();

    console.log(props.viewCertificateSignature)
    console.log(props.viewCertificateType)


  return (
    <div
    style={{
      border: "1px solid #ddd",
      borderRadius: "8px",
      padding: "10px",
      margin: "10px 0",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#f9f9f9",
    }}
  >
   
  
   <div style={{ textAlign: "center", margin: "20px" }}>
      {/* Input Fields for User to Provide Data */}
    

   

      {/* Certificate Preview */}
      <div
        ref={certificateRef}
        style={{
          position: "relative",
          width: "800px",
          height: "600px",
          margin: "0 auto",
          backgroundImage: `url(${props.viewCertificateTemplate})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Title */}

<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>

<div
          style={{
            top: "200px",
             marginTop: '100px',
            // left: "46%",
            // transform: "translate(-50%,-50%)", // Centers both horizontally and vertically
            fontSize: "36px",
            fontWeight: "bold",
            color: "#003366",
          }}
        >
        Certificate of Completion
        </div>


</div>
 


   

        {/* Certificate Name */}
        <div
          style={{
            position: "absolute",
            top: "200px",
            left: "50%",
            transform: "translateX(-50%)",
            fontSize: "24px",
            color: "#555",
          }}
        >
          {props.viewCertificateName}
        </div>

        {/* Student Name */}

        <div
          style={{
            position: "absolute",
            top: "300px",
            left: "50%",
            transform: "translateX(-50%)",
            fontSize: "28px",
            fontWeight: "bold",
            color: "#333",
          }}
        >
          STUDENT NAME
        </div>


   

        <div
  style={{
    position: "absolute",
    bottom: props.viewCertificateType == 'IMAGE' ? "120px":"150px",
    left: "200px",
    fontSize: "18px",
    color: "#333",
  }}
>
  {props.viewCertificateType == 'IMAGE' ? (
    <img
      src={props.viewCertificateSignature}
      alt="Uploaded Signature"
      style={{
        marginTop: "10px",
        width: "150px",
      }}
    />
  ) : (
    <div
      style={{
        marginTop: "10px",
        fontSize: "16px",
        fontStyle: "italic",
        color: "#000",
      }}
    >
      {props.viewCertificateSignature}
    </div>
  )}
</div>



      

        {/* Date */}
        <div
          style={{
            position: "absolute",
            bottom: "150px",
            right: "200px",
            fontSize: "18px",
            color: "#333",
          }}
        >
          {/* <div>Date:</div> */}
          <div style={{ marginTop: "20px" }}>{today}</div>
        </div>
      </div>

   
    </div>
  
   
  </div>
  
  );
}

export default CertificateViewModal;
