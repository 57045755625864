import {CRETATE_BADGES,DELETE_BADGES,GET_BADGES,CRETATE_CERTIFICATE,DELETE_CERTIFICATE,GET_CERTIFICATE} from "./actionTypes";

const initialStates = {
    loading:true,
    batchDetails:[],
}

 const badgesReducer = (state = initialStates,action)=>{

    switch (action.type) {
        case CRETATE_BADGES:
            return {
                ...state,
                crateBadges: action.payload
            }

            case DELETE_BADGES:
                return {
                    ...state,
                    deleteBadges: action.payload
                }

                case GET_BADGES:
                    return {
                        ...state,
                        getBadges: action.payload
                    }




                    case CRETATE_CERTIFICATE:
                        return {
                            ...state,
                            crateCertificate: action.payload
                        }
            
                        case DELETE_CERTIFICATE:
                            return {
                                ...state,
                                deleteCertificate: action.payload
                            }
            
                            case GET_CERTIFICATE:
                                return {
                                    ...state,
                                    getCertificate: action.payload
                                }

         default:
            return state;
    }
}

export  default badgesReducer;