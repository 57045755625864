import React, { useEffect } from "react";
import { useState } from 'react';

import { jwtDecode } from "jwt-decode";
import './login.css';


import InstructorRegisterComponent from "./Register/register";
import { faceBookIcon,googleIcon,logo } from "../../images";

import InstructorContactUsAdd from "../contactUs/instructorContactUs";
import ForgotPassWordPopUp from '../forgotPassword/forgotPasswordPopUp';



import { InputComponent,InputPassword,ButtonComponent,SwitchComponent ,ModalComponent} from "../../commonComponents";

import { useNavigate } from "react-router-dom";

import { Checkbox, Form, Input, notification } from "antd";
import { type } from "@testing-library/user-event/dist/type";

import { createRecordByFirebaseTokenInstructor, getInstructorLoginRole, instructorLogin ,instructorContactUs,forgotPasswordApiCallInstructor} from "../../redux/instructorLogin/action";
import { useDispatch,useSelector } from "react-redux";
import axios from 'axios'; // Import Axios
import {getTenantIdBasedOnName } from "../../redux/studentLogin/action";


// import { google } from 'googleapis';









let InstructorComponent = (props) =>{


  // const CLIENT_ID = '441086711957-tqqcvt359qthqbl0s0enjajs5lbmcjvv.apps.googleusercontent.com';
  // const CLIENT_SECRET = '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';
  // const REDIRECT_URI = 'https://botpi.arkatiss.com/';
  
  // const oauth2Client = new google.auth.OAuth2(
  //   CLIENT_ID,
  //   CLIENT_SECRET,
  //   REDIRECT_URI
  // );

  
  // const calendar = google.calendar({ version: 'v3', auth: oauth2Client });



    const [form] = Form.useForm();
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();

    let navigate = useNavigate();

    let dispatch = useDispatch();



    let jwtConvert = jwtDecode 

  let [checked, setChecked] = useState(false);

  let [studentDetails,setStudentDetails] = useState(true);

  let [instructorDetails,setInstructorDetails] = useState(false);

//   let [email,setEmail] = useState("");

let [forgotPassword,setForGotpassword] = useState(false);


//   let [studentpassword,setStudentPassword] = useState("");

let [register,setRegister] = useState(false)

  let [studentRegisterForm,setStudentRegisterForm] = useState(false);

  let [instructorLoginDetails,setInstructorLoginDetails] = useState({
    email:"",
    studentPassword:""
  });



  let loginStatusInstructor = useSelector((state)=>state.instructorLoginReducer?.token?.access_token);

  let check = useSelector((state)=>state.instructorLoginReducer?.token);

  let loginRequests = useSelector((state)=>state?.instructorLoginReducer.studentLogin);

  const [api, contextHolder] = notification.useNotification();


  let studentContactUsStatus = useSelector((state)=>state?.instructorLoginReducer.instructorContactUs);

  useEffect(()=>{

    let loginPayload ={
      "operation_type": "retrieve_id",
      "tenant_name": "intellobot" 
  }
    dispatch(getTenantIdBasedOnName(loginPayload))
  },[])

  useEffect(() => {

    console.log(studentContactUsStatus?.data?.msg,'studentContactUsStatus')
    if (studentContactUsStatus) {
       openNotification(studentContactUsStatus?.data?.msg, studentContactUsStatus?.data);

       if(studentContactUsStatus?.data?.res_status == true){
        setCancelClassModal(false)

        form.setFieldsValue({['first_name']:''})
        form.setFieldsValue({['last_name']:''})
        form.setFieldsValue({['email']:''})
        form.setFieldsValue({['phone']:''})
        form.setFieldsValue({['message']:''})
     
     
      
     
     
        setCancelClassDetails({
         "first_name":"",
         "last_name":"",
         "email":"",
         "phone":"",
         "message":"",
         "tenant_id":1
        })
       }else{

       }

    }
}, [studentContactUsStatus]);

  let handleChange = (e,type) =>{
    setInstructorLoginDetails({...instructorLoginDetails,[type.name]:e.target.value})
  }

  let studentRegister = () =>{
    setStudentRegisterForm(true)
    setRegister(true)
  }


  const onFinish = (values) => {
    if (values) {
        let loginPayload ={
          "operation_type": "instructor_login",
          "op_field_details":{
            "tenant_id":1,
          "username": instructorLoginDetails.email?.toLowerCase(),
          "password": instructorLoginDetails.studentPassword
      
          }
      }
        dispatch(instructorLogin(loginPayload))
      
    }
  };

  useEffect(()=>{
    if (loginRequests) {
      console.log( window.localStorage.getItem("fcmToken"),"fcmToken")
      console.log(loginRequests?.data?.role_type,"Role Type")

      openNotification(loginRequests?.data?.msg,loginRequests?.data)
      window.localStorage.setItem("token",loginRequests?.data?.access_token)
      
      if (loginRequests?.data?.access_token ?.access_token) {

        setTimeout(()=>{
          let decryptData = jwtConvert(loginRequests?.data?.instructor_data)
        let {instructor_id,email,instructor_first_name,instructor_last_name} = decryptData?.data
        window.localStorage.setItem("instructor_id",instructor_id);
        window.localStorage.setItem("role_Type",loginRequests?.data?.role_type);
        window.localStorage.setItem("tenant_Id",decryptData?.data?.tenant_id);
        window.localStorage.setItem("instructorEmail",email);
        window.localStorage.setItem("instructorFullName",instructor_first_name+" "+instructor_last_name)
          // window.location.reload()
          dispatch(getInstructorLoginRole(window.localStorage.getItem("role_Type")))
          // FirebaseInsert
          let data ={
            "username": window.localStorage.getItem("instructorEmail"),
            "fcmtype": "web",
            "fcmtoken": window.localStorage.getItem("fcmToken"),
            "tenant_id": window.localStorage.getItem("tenant_Id"),
            "project_name":"lms",
            "full_name":window.localStorage.getItem("instructorFullName")
        }
        
          dispatch(createRecordByFirebaseTokenInstructor(data))
        // end
        },1000)

        setTimeout(()=>{
            navigate("/instructorDashboard")
        },2000)
        
      }else{
          navigate("/")
      }

    }
  },[loginRequests])

  // useEffect(()=>{
  //   if (loginStatusInstructor) {
  //     console.log(loginStatusInstructor,"chekins")
  //       window.localStorage.setItem("token",loginStatusInstructor?.access_token?.access_token);
  //     navigate("/instructorDashboard")
  //      window.location.reload()
  //   }

  // },[loginStatusInstructor])


  // useEffect(()=>{
  //   if (check) {
  //       let decryptData = jwtConvert(check?.instructor_data)
  //       console.log(check?.role_type,"step1");
  //       console.log(check?.instructor_data,"step2")
  //       let {instructor_id} = decryptData?.data
  //       console.log(decryptData?.data,"hhh")
  //       window.localStorage.setItem("instructor_id",instructor_id);
  //       window.localStorage.setItem("role_Type",check?.role_type)
  //       console.log(instructor_id,check?.role_type,"instructorLogin");
  //       window.localStorage.setItem("tenant_Id",decryptData?.data?.tenant_id)
  //   }
  // },[check])

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const openNotification = (msg,status)=>{
    console.log(msg,status)
    if (msg && status?.res_status == true) {
      api.info({
        description:
          `${msg}`,
      });

      setForGotpassword(false)


    }
    if (msg && status?.res_status == false) {
      api.info({
        description:
          `${msg}`,
      });

    }
  }


 
  
  
 
  

  let handleClick = (type) =>{

    handleLogin();
    console.log('Div clicked!');


  }

  // const [meetLink, setMeetLink] = useState('');

  // const generateMeetLink = async () => {
  //   const event = {
  //     summary: 'Meeting',
  //     location: 'Online',
  //     start: {
  //       dateTime: new Date().toISOString(),
  //       timeZone: 'Your_Time_Zone',
  //     },
  //     end: {
  //       dateTime: new Date(new Date().getTime() + 60 * 60 * 1000).toISOString(),
  //       timeZone: 'Your_Time_Zone',
  //     },

  //     conferenceData: {
  //       createRequest: {
  //         requestId: 'Your_Request_Id',
  //       },
  //     },
  //   };

  //   try {
  //     const response = await calendar.events.insert({
  //       calendarId: 'primary',
  //       resource: event,
  //       conferenceDataVersion: 1,
  //     });
  //     setMeetLink(response.data.hangoutLink);
  //   } catch (error) {
  //     console.error('Error creating event:', error);
  //   }
  // };

  // console.log(meetLink,'Div clicked!');








//   function createGoogleMeetLink() {
//   // Generate a unique meeting ID (you can use any method to generate it)
//   const meetingId = generateUniqueId();

//   // Construct the Google Meet URL
//   const meetUrl = `https://meet.google.com/${meetingId}`;

//   // Log or use the meetUrl as needed
//   console.log('Google Meet Link:', meetUrl);
// }

// function generateUniqueId() {
//   // Generate a unique ID (you can use any method to generate it)
//   return Math.random().toString(36).substring(2, 8);
// }



const handleLogin = async () => {

  try {
    const response = await fetch('/create-meet-link'); // Send request to server
    const data = await response.json();
    // window.location.href = data.authUrl;
    console.log('Meeting Link:', data.meetLink);

    // You can now use the Meet link as needed (e.g., display it on the page)
} catch (error) {
    console.error('Error:', error);
}
  // try {
  //     const response = await fetch('/authorize-google');
  //     const data = await response.json();
  //     window.location.href = data.authUrl; // Redirect user to Google OAuth2 authorization URL
  // } catch (error) {
  //     console.error('Error during login:', error);
  // }
};








let [cancelClassModal,setCancelClassModal] = useState(false);

let[cancelClassDetails,setCancelClassDetails] = useState({
  "first_name":"",
  "last_name":"",
  "email":"",
  "phone":"",
  "message":"",
  "tenant_id":1
})


let studentContactUs= () =>{
  setCancelClassModal(true)
}

let onFinish1 = (values) =>{


  let cancelClassPayload ={

    
    "operation_type":"insert",
    "op_field_details":{
        "first_name":cancelClassDetails?.first_name,
        "last_name":cancelClassDetails?.last_name,
        "email":cancelClassDetails?.email,
        "phone":cancelClassDetails?.phone,
        "message":cancelClassDetails?.message,
        "tenant_id":1,
        "role_type":"Instructor"
    }
   
}


console.log(cancelClassPayload,'cancelClassPayload')


dispatch(instructorContactUs(cancelClassPayload))


}

const handleClassChange = (e,type) =>{

  if (type.name == "message") {
    setCancelClassDetails({...cancelClassDetails,[type.name]:e.target?.value})
}

if (type.name == "first_name") {
  setCancelClassDetails({...cancelClassDetails,[type.name]:e.target?.value})
}

if (type.name == "last_name") {
  setCancelClassDetails({...cancelClassDetails,[type.name]:e.target?.value})
}

if (type.name == "phone") {
  setCancelClassDetails({...cancelClassDetails,[type.name]:e.target?.value})
}

if (type.name == "email") {
  setCancelClassDetails({...cancelClassDetails,[type.name]:e.target?.value})
}

}


let handleCloseModal = () =>{

   setCancelClassModal(false)

   form.setFieldsValue({['first_name']:''})
   form.setFieldsValue({['last_name']:''})
   form.setFieldsValue({['email']:''})
   form.setFieldsValue({['phone']:''})
   form.setFieldsValue({['message']:''})


 


   setCancelClassDetails({
    "first_name":"",
    "last_name":"",
    "email":"",
    "phone":"",
    "message":"",
    "tenant_id":1
   })

 
}





let[forgotPasswordDetails,setForgotPasswordDetails] = useState({

  "email":""

})



let onFinish2 = (values) =>{


  let cancelClassPayload ={

    "operation_type":"instructor_forgot_password",
    "email":forgotPasswordDetails?.email,
    "tenant_id":1
   
}


console.log(cancelClassPayload,'cancelClassPayload')


dispatch(forgotPasswordApiCallInstructor(cancelClassPayload))


}

const handleClassChange2 = (e,type) =>{

  if (type.name == "email") {
    setForgotPasswordDetails({...cancelClassDetails,[type.name]:e.target?.value})
}





}

let handleCloseModal2 = () =>{

  setForGotpassword(false)


  form.setFieldsValue({['email']:''})






  setForgotPasswordDetails({
 
   "email":""

  })


}


let studentContactUs2= () =>{
  setForGotpassword(true)
}

let handleViewStudent1 = (e) =>{

  setChecked(e.target.checked);
  // setTermsError(!e.target.checked);
//   setChecked(e.target.checked)

//   if (!checked) {
//     setTermsError(false);

// } else {
//   setTermsError(true);

// }
  }

 
    return (



    //   <div>
    //   <p>Google Calendar API Quickstart</p>
    //   <button id="authorize_button" onClick={handleAuthClick}>
    //     Authorize
    //   </button>
    //   <button id="signout_button" onClick={handleSignoutClick}>
    //     Sign Out
    //   </button>
    //   <p>Google Calendar API Quickstart</p>
    //   <pre id="content" style={{ whiteSpace: 'pre-wrap' }}></pre>
    // </div>

        <>
        {!register  ?
        
        <Form 
          autoComplete="off"
          layout="vertical"
          form={form}
            onFinish={onFinish}>
              <>
              {contextHolder}
        <div className='fields'>
                    <Form.Item label="Email"
                name="email"
                rules={[
                    {
                    required: true,
                    message: 'Email is required',
                    type: "email",

                    },
                ]}>
            <InputComponent value={instructorLoginDetails.email} name={"email"} className='inputField'
              placeholder="Enter email"
              onChange={(e)=>handleChange(e,{name: "email"})}   />
              
            </Form.Item>

        </div>
                <div className='fields'>
                <Form.Item
                label="Password"
                name="password"
                
             rules={[
                    {

                      required: true,
                      message: 'Password is required',
                    },
                    {
                      min: 4,
                      message: 'Password should atleast 4 characters',
                    },
                  ]}
                >
                     
                        <Input.Password value={instructorLoginDetails.password} name={"studentPassword"}
                         className='' placeholder="Enter password"
                         onChange={(e)=>handleChange(e,{name: "studentPassword"})}/>
                    </Form.Item>
                    </div>

                   
                   
                    <div
      className="fields"
      style={{
        margin: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {/* Left Side: Checkbox with Label */}
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox checked={checked} onChange={handleViewStudent1} />
        <span
          style={{
            cursor: "pointer",
            color: "black",
            marginLeft: "10px",
          }}
        >
          Remember me
        </span>
      </div>

      {/* Right Side: Forgot Password Link */}
      <div className="registerLink">
        <p
          className="underLine"
          onClick={studentContactUs2}
          style={{
            margin: 0,
            cursor: "pointer",
            textDecoration: "underline",
            color: "blue",
          }}
        >
          <b>Forgot your password?</b>
        </p>
      </div>
    </div>

                    <div className='fields'>
                    <Form.Item>

                        <ButtonComponent type="primary"
                         className='btnLogin' value={'Login'}
                         htmlType="submit"></ButtonComponent>
                    </Form.Item>




                 

                    {/* <div>
      <button onClick={createGoogleMeetLink}>Generate Google Meet Link</button>
    </div> */}

                    </div>
                    {/* <div className='loginWithOthers'>
                        <div className='textCenter'>
                            <p><b>Login</b> with others</p>
                        </div>
                    </div> */}

                    {/* <div className='socialIcons'>
                        <div className='facebookIcon'>
                            <img src={faceBookIcon} />
                        </div>
                        <div className='googleIcon'  >
                        <img src={googleIcon} onClick={() => handleClick("Add Batch")}   alt="Google Icon" />

                        </div>

                    </div> */}

                   

                    <div className='registerLink'>
                        <div className='textCenter'>
                           <p>Don't have account? <b className='underLine' onClick={studentRegister}>Register Now</b></p>
                        </div>
                    </div>


                    <div className='registerLink'>
                        <div className='textCenter'>
                           <p><b className='underLine' onClick={studentContactUs}>Contact Us</b></p>
                        </div>
                    </div>
                    </>
            </Form>
          : <InstructorRegisterComponent/> }



{cancelClassModal ? 
         <ModalComponent title={"Contact Us"}
        
         content={<InstructorContactUsAdd 
          form2={form2} onFinish={onFinish1} 
          handleClassChange={handleClassChange}
          cancelClassDetails = {cancelClassDetails}/>}
       open={cancelClassModal} 
        handleCancel={handleCloseModal}
     //  footer= {categoryFooter}
     style={{ minHeight: `500px` }}
        ></ModalComponent>
    :""}



{forgotPassword ? 
         <ModalComponent title={"Forgot Password"}
        
         content={<ForgotPassWordPopUp 
          form1={form1} onFinish2={onFinish2} 
          handleClassChange2={handleClassChange2}
          forgotPasswordDetails = {forgotPasswordDetails}/>}
       open={forgotPassword} 
        handleCancel={handleCloseModal2}
     style={{ minHeight: `500px` }}
        ></ModalComponent>
    :""}
            </>
    )
}

export default InstructorComponent