import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { course, edit, excel, plus, upload, TableEyeIcon, searchIcon, add_icon, back, deleteIcon
  ,gold_image,silver_image,bronze_image,certificate_1,certificate_2,certificate_3
 } from "../../../images/index.js";
 import { Button, Form, Select ,Input,notification,DatePicker,Checkbox,Collapse, List, Typography,Row, Col } from 'antd';
import InputComponent from '../../../commonComponents/InputComponent/input.js';
import {addCoursesContentSectionDetails,getCoursesContentDetails
} from '../../../redux/coursesContent/action';
import AddBadgesModal from '../courseModel/addBadgesModal.js';
import {ModalComponent,TableComponent} from "../../../commonComponents/index.js";

import {createBadgesDetails,deleteBadgesDetails,getBadgesDetails,
  createCertificateDetails,deleteCertificateDetails,getCertificateDetails
} from '../../../redux/badges/action.js';

import AddCertificateModal from '../courseModel/addCertificateModal.js';
import CertificateViewModal from '../viewModal/certificateView.js';

let AddCoursesContentSection = ({coursesName, selectedCourseId, selectedBatchId }) =>{

  

    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [api, contextHolder] = notification.useNotification();

    const [formSection] = Form.useForm();
    const [formBadges] = Form.useForm();
    const [formCertificate] = Form.useForm();



    const [visibleNotification, setVisibleNotification] = useState(false);
    const [addMultipleRecord,setAddMultipleRecord] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedCertificate, setSelectedCertificate] = useState(null);

    const [addBadgesVisible, setAddBadgesVisible] = useState(false);
    const [deleteContentVisible,setDeleteContentVisible] = useState(false);
    const [selectedContentId,setSelectedCoursesContentId] = useState("");
    const [addCertificateVisible, setAddCertificateVisible] = useState(false);
    const [deleteCertificateVisible,setDeleteCertificateVisible] = useState(false);
    const [viewCertificate,setViewCertificate] = useState(false);

    const [viewCertificateName,setViewCertificateName] = useState("");
    const [viewCertificateTemplate, VsetiewCertificateTemplate] = useState(false);
    const [viewCertificateType,setViewCertificateType] = useState(false);
    const [viewCertificateSignature,setViewCertificateSignature] = useState(false);



    const [badgesList, setBadgesList] = useState([]);
    const [certificateList, setCertificateList] = useState([]);



    let[addBadgesDetailsPayload,setAddBadgesDetailsPayload] = useState({
      "name": "",
      "document": "",
      "batch_id":selectedBatchId,
      "instructor_id":window.localStorage.getItem("instructor_id"),
      "course_id":selectedCourseId,
      "tenant_id": window.localStorage.getItem("tenant_Id"),
   
      })

      let[addCertificateDetailsPayload,setAddCertificateDetailsPayload] = useState({
        "certificate_name": "",
        "certificate_template": "",
        "signature": "",
        "signature_template": "",
        "batch_id":selectedBatchId,
        "instructor_id":window.localStorage.getItem("instructor_id"),
        "course_id":selectedCourseId,
        "tenant_id": window.localStorage.getItem("tenant_Id"),
     
        })

    let addCourseContentSection = useSelector((state)=> state?.CoursesContentReducer?.addCoursesContentSection);

    let crateBadges = useSelector((state)=> state?.badgesReducer?.crateBadges);
    let getBadges = useSelector((state)=> state?.badgesReducer?.getBadges);
    let deleteBadges = useSelector((state)=> state?.badgesReducer?.deleteBadges);

    let crateCertificate = useSelector((state)=> state?.badgesReducer?.crateCertificate);
    let getCertificate = useSelector((state)=> state?.badgesReducer?.getCertificate);
    let deleteCertificate = useSelector((state)=> state?.badgesReducer?.deleteCertificate);

    useEffect(()=>{    
      if (deleteCertificate) {
  if(visibleNotification == true){
    openNotification(deleteCertificate?.msg)
  
    if(deleteCertificate?.res_status === true){

      setAddCertificateVisible(false)
      formCertificate.resetFields();

      formCertificate.setFieldsValue({['certificate_name']:''})
      formCertificate.setFieldsValue({['certificate_template']:''})

      formCertificate.setFieldsValue({['signature']:''})
      formCertificate.setFieldsValue({['signature_template']:''})


      setAddCertificateDetailsPayload({
        "certificate_name": "",
        "certificate_template": "",
        "signature": "",
        "signature_template": "",
      })

      let payload ={
        "operation_type":"retrieve",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCertificateDetails(payload))
  setSelectedCertificate(null)

  
    }
  
  }else{
  
  }
  
      }
  },[deleteCertificate])

    useEffect(()=>{    
      if (getCertificate) {

        setCertificateList(getCertificate)
  
      }
  },[getCertificate])
  

    useEffect(()=>{    
      if (crateCertificate) {
  if(visibleNotification == true){
    openNotification(crateCertificate?.msg)
  
    if(crateCertificate?.res_status === true){

      setAddCertificateVisible(false)
      formCertificate.resetFields();

      formCertificate.setFieldsValue({['certificate_name']:''})
      formCertificate.setFieldsValue({['certificate_template']:''})

      formCertificate.setFieldsValue({['signature']:''})
      formCertificate.setFieldsValue({['signature_template']:''})


      setAddCertificateDetailsPayload({
        "certificate_name": "",
        "certificate_template": "",
        "signature": "",
        "signature_template": "",
      })

      let payload ={
        "operation_type":"retrieve",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCertificateDetails(payload))
  setSelectedCertificate(null)

  
    }
  
  }else{
  
  }
  
      }
  },[crateCertificate])

    useEffect(()=>{

      let payload ={
        "operation_type":"retrieve",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCertificateDetails(payload))

  },[])

    useEffect(()=>{    
      if (deleteBadges) {
  if(visibleNotification == true){
    openNotification(deleteBadges?.msg)
  
    if(deleteBadges?.res_status === true){

      setAddBadgesVisible(false)
      formBadges.resetFields();

      formBadges.setFieldsValue({['name']:''})
      formBadges.setFieldsValue({['document']:''})


      setAddBadgesDetailsPayload({
        "name":"",
        "document":""
      })



 
  
      
      formBadges.setFieldsValue({['name']:''})
      formBadges.setFieldsValue({['document']:''})

    
      setAddBadgesDetailsPayload({
        "name":"",
        "document":"",
      })
  


    let payload ={
      "operation_type":"retrieve_badges_detail",
          "course_id":selectedCourseId,
          "tenant_id":window.localStorage.getItem("tenant_Id"),
          "instructor_id":window.localStorage.getItem("instructor_id"),
          "batch_id":selectedBatchId

  }
dispatch(getBadgesDetails(payload))
setAddMultipleRecord(null)

  
    }
  
  }else{
  
  }
  
      }
  },[deleteBadges])

    useEffect(()=>{    
      if (getBadges) {

        console.log(getBadges)
        setBadgesList(getBadges)
  
      }
  },[getBadges])


    useEffect(()=>{

      let payload ={
        "operation_type":"retrieve_badges_detail",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getBadgesDetails(payload))

  },[])

    useEffect(()=>{    
      if (crateBadges) {
  if(visibleNotification == true){
    openNotification(crateBadges?.msg)
  
    if(crateBadges?.res_status === true){

      setAddBadgesVisible(false)
      formBadges.resetFields();

      formBadges.setFieldsValue({['name']:''})
      formBadges.setFieldsValue({['document']:''})


      setAddBadgesDetailsPayload({
        "name":"",
        "document":""
      })



 
  
      
      formBadges.setFieldsValue({['name']:''})
      formBadges.setFieldsValue({['document']:''})

    
      setAddBadgesDetailsPayload({
        "name":"",
        "document":"",
      })
  


    let payload ={
      "operation_type":"retrieve_badges_detail",
          "course_id":selectedCourseId,
          "tenant_id":window.localStorage.getItem("tenant_Id"),
          "instructor_id":window.localStorage.getItem("instructor_id"),
          "batch_id":selectedBatchId

  }
dispatch(getBadgesDetails(payload))
setAddMultipleRecord(null)

  
    }
  
  }else{
  
  }
  
      }
  },[crateBadges])

    useEffect(()=>{    
      if (addCourseContentSection) {
  if(visibleNotification == true){
    openNotification(addCourseContentSection?.msg)
  
    if(addCourseContentSection?.res_status === true){

    if(addMultipleRecord === 1){

      formSection.resetFields();

      formSection.setFieldsValue({['title']:''})
      formSection.setFieldsValue({['description']:''})
      formSection.setFieldsValue({['category_name']:''})
      formSection.setFieldsValue({['xp']:''})

      setCoursesContentPayloadDetails({
        "title":"",
        "description":"",
        "category_name":"",
        "xp":"",
        "category":"Section"
    
      })

      navigate(`/courseContentDetails/${coursesName}/${selectedCourseId}/${selectedBatchId}`);


    }else{

  
      
      formSection.setFieldsValue({['title']:''})
      formSection.setFieldsValue({['description']:''})
      formSection.setFieldsValue({['category_name']:''})
      formSection.setFieldsValue({['xp']:''})
    
      setCoursesContentPayloadDetails({
        "title":"",
        "description":"",
        "category_name":"",
        "xp":"",
        "category":"Section"
    
      })
    }


    let payload ={
      "operation_type":"retrieve_all_content",
          "course_id":selectedCourseId,
          "tenant_id":window.localStorage.getItem("tenant_Id"),
          "instructor_id":window.localStorage.getItem("instructor_id"),
          "batch_id":selectedBatchId

  }
dispatch(getCoursesContentDetails(payload))
setAddMultipleRecord(null)
setSelectedId(null)

  
    }
  
  }else{
  
  }
  
      }
  },[addCourseContentSection])

 
  
    const { Panel } = Collapse;
  const { Text } = Typography;

  const openNotification = (msg) => {
  
    api.info({
      description:
      `${msg}`,
  
    });
  
    setVisibleNotification(false)
  
  };

    let[coursesContentPayloadDetails,setCoursesContentPayloadDetails] = useState({
      "title":"",
      "description":"",
      "category_name":"",
      "xp":"",
      "category":"Section"

      })


      const handleClassChange = (e, type) => {


  


        console.log('MULTIPLE CLICK')
        setAddMultipleRecord(2)
      
        if (!type || !type.name) {
          console.error("Type or type.name is undefined:", type);
          return; // Exit early to avoid the error
        }
      
        let value;
      
        if (e && e.target) {
          value = e.target.value; // For event objects
        } else {
          value = e; // For direct values
        }
      
      
      
        // Set the state based on the type
        if (["title", "description", "category_name", "xp"].includes(type.name)) {
          setCoursesContentPayloadDetails(prevDetails => ({
            ...prevDetails,
            [type.name]: value
          }));
        }
      };

      let onFinishSectionAdd= (values) =>{

        let payload = {
          "operation_type":"insert",
          "op_field_details":{
              "category":"Section",
              "title": coursesContentPayloadDetails.title,
              "description":coursesContentPayloadDetails.description,
              "xp":coursesContentPayloadDetails.xp === '' ? null :coursesContentPayloadDetails.xp,
              "batch_id":selectedBatchId,
              "instructor_id":window.localStorage.getItem("instructor_id"),
              "course_id":selectedCourseId,
              "tenant_id":window.localStorage.getItem("tenant_Id"),
              "badge_id":selectedId,
              "certificate_id":selectedCertificate
          }
      }


        
          dispatch(addCoursesContentSectionDetails(payload))
      
          setVisibleNotification(true)
      
      
      }

      let  closeDeleteModal = () =>{
        setDeleteContentVisible(false)
        setDeleteCertificateVisible(false)
        setViewCertificate(false)

      
      
      }
      
      let deleteCourseContent= ()=>{
      
        setDeleteContentVisible(false)
      
        let payload = {
          "operation_type":"delete",
          "op_field_details":{
              "badge_id":[selectedContentId]
          }
      }
        
        
          dispatch(deleteBadgesDetails(payload))
      
          setVisibleNotification(true)
      
      
        
      
      } 

      let deleteCertificateModal= ()=>{
      
        setDeleteCertificateVisible(false)
      
        let payload = {
          "operation_type":"delete",
          "op_field_details":{
              "certificate_id":[selectedCertificate]
          }
      }
        
        
          dispatch(deleteCertificateDetails(payload))
      
          setVisibleNotification(true)
      
      
        
      
      } 

      let deleteTitleDescriptionModalFooter = [
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' ,marginBottom:"10px"}}>
        <Button   style={{
          border: '1px solid red',  // Red border
          color: 'red',              // Red text color
          backgroundColor: 'transparent',  // No background color
        }} default value={'Cancel'}  onClick={closeDeleteModal}>
              Cancel</Button>
      
              <div style={{marginLeft:"10px"}}></div>
      
              <Button   style={{
          border: '1px solid red',  // Red border
          color: 'white',              // Red text color
          backgroundColor: 'red',  // No background color
        }}  danger value={'Delete'}  onClick={deleteCourseContent}>Delete</Button>
         
        
        </div>
      ]

      
      let deleteCertificateModalFooter = [
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' ,marginBottom:"10px"}}>
        <Button   style={{
          border: '1px solid red',  // Red border
          color: 'red',              // Red text color
          backgroundColor: 'transparent',  // No background color
        }} default value={'Cancel'}  onClick={closeDeleteModal}>
              Cancel</Button>
      
              <div style={{marginLeft:"10px"}}></div>
      
              <Button   style={{
          border: '1px solid red',  // Red border
          color: 'white',              // Red text color
          backgroundColor: 'red',  // No background color
        }}  danger value={'Delete'}  onClick={deleteCertificateModal}>Delete</Button>
         
        
        </div>
      ]
 
      const deleteItem = (id) => {
        setDeleteContentVisible(true)
        setSelectedCoursesContentId(id)

      };

      const deleteItemCertificate = (id) => {

        setDeleteCertificateVisible(true)
      
        setSelectedCertificate(id)
      
      
      
      };

      const viewItemCertificate = (name,template,type,signature) => {



        setViewCertificateName(name)
        VsetiewCertificateTemplate(template)
        setViewCertificateType(type)
        setViewCertificateSignature(signature)

        setViewCertificate(true)

      
      };

      let handleTitleDeleteClick = (values) =>{
        deleteContentVisible(false)
    
      }

      const handleItemClick = (id) => {
        setSelectedId(id); // Set the clicked item's id as selected
      };

      const handleCertificateItemClick = (id) => {
        setSelectedCertificate(id); 
      };

      const handleClearCertificateModal = () => {
        setSelectedCertificate(null)
      };
	  
	    const handleClearBadgesModal = () => {
     
     setSelectedId(null)
    };

      const handleAddBadgesModal = () => {
       
        setAddBadgesVisible(true)
      };

      const handleAddCertificateModal = () => {
        setAddCertificateVisible(true)

      };

      const handleClassBadges= (e, type)=>{
        if (!type || !type.name) {
          console.error("Type or type.name is undefined:", type);
          return;
        }
      
        let value = e && e.target ? e.target.value : e; // Handle both events and direct values
        console.log(type.name )
  
        console.log(e)
  
    
        // Update other fields
        if (["name", "document"].includes(type.name)) {
          setAddBadgesDetailsPayload((prevDetails) => ({
            ...prevDetails,
            [type.name]: value,
          }));
        };
                  
              
                };

                const handleClassCertificate= (e, type)=>{
                 
                  if (!type || !type.name) {
                    console.error("Type or type.name is undefined:", type);
                    return;
                  }
                
                  let value = e && e.target ? e.target.value : e; // Handle both events and direct values
                  console.log(type.name )
            
                  console.log(e)
                
                  // Update other fields
                  if (["certificate_name", "certificate_template","signature","signature_template"].includes(type.name)) {
                    setAddCertificateDetailsPayload((prevDetails) => ({
                      ...prevDetails,
                      [type.name]: value,
                    }));
                  };
                  
                        
                          };

      let handleDeleteCancel= ()=>{

        setAddBadgesVisible(false)
        setDeleteContentVisible(false)
        setAddCertificateVisible(false)
        setSelectedId(null)
        setSelectedCertificate(null)
        setDeleteCertificateVisible(false)
        setViewCertificate(false)


       
    
        
    
      } 

      let handleDeleteClear= ()=>{

        setAddBadgesVisible(false)
        setDeleteContentVisible(false)
        setAddCertificateVisible(false)
        setSelectedId(null)
        setSelectedCertificate(null)
        setDeleteCertificateVisible(false)
        setViewCertificate(false)


        formSection.resetFields();

        formSection.setFieldsValue({['title']:''})
        formSection.setFieldsValue({['description']:''})
        formSection.setFieldsValue({['category_name']:''})
        formSection.setFieldsValue({['xp']:''})
  
        setCoursesContentPayloadDetails({
          "title":"",
          "description":"",
          "category_name":"",
          "xp":"",
          "category":"Section"
      
        })
    
        
    
      } 

      const handleClassChangeSingleAdd = (e, type) => {

        console.log('SINGLE CLICK')
      
        setAddMultipleRecord(1)
      
      
        if (!type || !type.name) {
          console.error("Type or type.name is undefined:", type);
          return; // Exit early to avoid the error
        }
      
        let value;
      
        if (e && e.target) {
          value = e.target.value; // For event objects
        } else {
          value = e; // For direct values
        }
      
        // Set the state based on the type
        if (["title", "description", "category_name", "xp"].includes(type.name)) {
          setCoursesContentPayloadDetails(prevDetails => ({
            ...prevDetails,
            [type.name]: value
          }));
        }
      };

      let onFinishBadges= ()=>{

        let payload =  {
          "operation_type":"insert",
          "op_field_details":{
             "badge_name":addBadgesDetailsPayload.name,
             "batch_id":selectedBatchId,
             "image":addBadgesDetailsPayload.document,
             "instructor_id":window.localStorage.getItem("instructor_id"),
             "course_id":selectedCourseId,
             "tenant_id":window.localStorage.getItem("tenant_Id")
          }
       }

  
          dispatch(createBadgesDetails(payload))
      
          setVisibleNotification(true)
      
          
            
        
          } 

          let onFinishCertificate= ()=>{

            console.log(addCertificateDetailsPayload)

            // Completion of Python Course

           let payload =  {
            "operation_type": "insert",
            "op_field_details": {
               "certificate_name": addCertificateDetailsPayload.certificate_name,
               "image":addCertificateDetailsPayload.certificate_template,
               "signature": addCertificateDetailsPayload.signature?  addCertificateDetailsPayload.signature : addCertificateDetailsPayload.signature_template,
               "signature_type":addCertificateDetailsPayload.signature? 'TEXT':"IMAGE",
               "batch_id":selectedBatchId,
               "instructor_id":window.localStorage.getItem("instructor_id"),
               "course_id":selectedCourseId,
               "tenant_id":window.localStorage.getItem("tenant_Id")
            }
         }
    

         console.log(payload)
         
              dispatch(createCertificateDetails(payload))
          
              setVisibleNotification(true)
          
              
                
            
              } 


    return (
     <div>
 {contextHolder}
<div style={{ display: 'flex', flexDirection: 'row' }}>

<div style={{ flex: 1 }}>
<div style={{ minHeight: '200px', maxHeight: '300px', overflowY: 'auto' }}>
  <Form
    form={formSection}
    onFinish={onFinishSectionAdd}
    autoComplete="off"
    layout="vertical"
  
  >
    <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
      <Form.Item
        label="Title"
        name="title"
        rules={[
          {
            required: true,
            message: 'Please enter title',
          },
        ]}
        style={{ flex: 1 }}
      >
        <InputComponent
          placeholder="Title"
          name="title"
          value={coursesContentPayloadDetails.title}
          onChange={(e) => handleClassChange(e, { name: "title" })}
          style={{ width: '100%' }}
        />
      </Form.Item>
    </div>
    
    <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: 'Please enter description',
          },
        ]}
        style={{ flex: 1 }}
      >
        <Input.TextArea
          placeholder="Description"
          name="description"
          value={coursesContentPayloadDetails.description}
          onChange={(e) => handleClassChange(e, { name: "description" })}
          style={{ width: '100%' }}
          autoSize={{ minRows: 3, maxRows: 6 }}
        />
      </Form.Item>
    </div>
  </Form></div>
</div>


<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '0.1px',            // Set the width of the line
      height:'40vh',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
      marginLeft:'10px',marginRight:"10px"     // Ensure the line spans the height of the flex container
    }}
  />  

<div style={{ flex: 1 }}>
<div style={{ minHeight: '200px', maxHeight: '300px', overflowY: 'auto' }}>
<Form
          form={formSection}
          // onFinish={onFinishSectionAdd}

          autoComplete="off"
          layout="vertical"
        
        >

<div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,marginTop:"12px" }}>
            <Form.Item
              label="XP"
              name="xp"
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"XP"}
                name={"xp"}
                value={coursesContentPayloadDetails.xp}
                onChange={(e) =>handleClassChange(e, { name: "xp" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>


          <div
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  }}
>
  <p style={{ margin: 0 }}>Badges</p>

  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

   { selectedId === null || selectedId === '' ? <div></div> :
       <button
       style={stylesCertificate.deleteButton}
       onClick={(e) => {
         e.stopPropagation(); // Prevent item click event when delete button is clicked
         handleClearBadgesModal();
       }}
     >
       Clear
     </button>}
    <img
      src={plus}
      alt="plus-icon"
      style={{ height: "25px", width: "20px", cursor: "pointer" }}
      onClick={() => handleAddBadgesModal()}
    />
  </div>
</div>


<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 


<div style={styles.gridContainer}>
      {badgesList?.map(item => (
        <div
          key={item.badge_id}
          style={{
            ...styles.gridItem,
            border: selectedId === item.badge_id ? "2px solid blue" : "1px solid #ddd",
            backgroundColor: selectedId === item.badge_id ? "#e0f7ff" : "#f9f9f9"
          }}
          onClick={() => handleItemClick(item.badge_id)}
        >
          <img src={item.image} alt={item.badge_name} style={styles.image} />
          <p style={styles.name}>{item.badge_name}</p>

          {/* <button
            style={styles.deleteButton}
            onClick={(e) => {
              e.stopPropagation(); // Prevent item click event when delete button is clicked
              deleteItem(item.badge_id);
            }}
          >
            Delete
          </button> */}

          <img
      src={deleteIcon}
      alt="plus-icon"
      style={{
        height: "25px",
        width: "20px",
        cursor: "pointer",
        border: "1px solid  #0E60B2", // Adds a border
        borderRadius: "4px",       // Optional: Makes the corners rounded
      }}      onClick={() => deleteItem(item.badge_id)}
    />
        </div>
      ))}
    </div>
        
    <div
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  }}
>
  <p style={{ margin: 0 }}>Certificate</p>

  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

   { selectedCertificate === null || selectedCertificate === '' ? <div></div> :


    <button
    style={stylesCertificate.deleteButton}
    onClick={(e) => {
      e.stopPropagation(); // Prevent item click event when delete button is clicked
      handleClearCertificateModal();
    }}
  >
    Clear
  </button>
    
    
    }
    <img
      src={plus}
      alt="plus-icon"
      style={{ height: "25px", width: "20px", cursor: "pointer" }}
      onClick={() => handleAddCertificateModal()}
    />
  </div>
</div>


<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 





    <div style={stylesCertificate.gridContainer}>
  {certificateList?.map((item) => (
    <div
      key={item.id}
      style={{
        ...stylesCertificate.gridItem,
        border: selectedCertificate === item.certificate_id ? "2px solid blue" : "1px solid #ddd",
        backgroundColor: selectedCertificate === item.certificate_id ? "#e0f7ff" : "#f9f9f9",
      }}
      onClick={() => handleCertificateItemClick(item.certificate_id)}
    >
      <img src={item.image} alt={item.certificate_name} style={stylesCertificate.image} />
      {/* <p style={stylesCertificate.name}>{item.name}</p> */}

      <div style={{marginLeft:"10px",marginRight:"10px"}}>


<img
      src={TableEyeIcon}
      alt="plus-icon"
      style={{
        height: "25px",
        width: "20px",
        cursor: "pointer",
        marginRight:"5px",
        border: "1px solid  #0E60B2", // Adds a border
        borderRadius: "4px",       // Optional: Makes the corners rounded
      }}      onClick={() => viewItemCertificate(item.certificate_name,item.image,item.signature_type,item.signature)}
    />




<img
      src={deleteIcon}
      alt="plus-icon"
      style={{
        height: "25px",
        width: "20px",
        cursor: "pointer",
        border: "1px solid  #0E60B2", // Adds a border
        borderRadius: "4px",       // Optional: Makes the corners rounded
      }}      onClick={() => deleteItemCertificate(item.badge_id)}
    />

    
      </div>
    </div>
  ))}
</div>



        </Form>
</div>

</div>




  </div>


  <div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 


<Form
          form={formSection}
          onFinish={onFinishSectionAdd}

          autoComplete="off"
          layout="vertical"
          initialValues={{
          }}
        >
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Form.Item>


<Button htmlType='submit'   style={{
    border: '1px solid #0E60B2',  // Red border
    color: '#0E60B2',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px",marginRight:"10px"
  }} onClick={handleClassChangeSingleAdd}>
                Save
              </Button>

              <Button htmlType='submit' type='primary' style={{ marginRight: '8px', width:"110px" }} onClick={handleClassChange}>
                Save & New
              </Button>
            
              <Button
  htmlType="submit"
  onClick={handleDeleteClear}
  style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px"
  }}
>
  Clear
</Button>

            </Form.Item>
 </div>
        </Form>


        {addBadgesVisible ? 
  <ModalComponent title={"Add Badges"}
 
  content={<AddBadgesModal 
   handleCancel={handleDeleteCancel}
   addBadgesDetailsPayload = {addBadgesDetailsPayload}
   formBadges={formBadges}
onFinishBadges={onFinishBadges} 
handleClassBadges={handleClassBadges}
   />}
open={addBadgesVisible} 


handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>

:""}


{deleteContentVisible ?
              <ModalComponent title={"Delete Badges"} 
                  footer={deleteTitleDescriptionModalFooter} content={"Are you sure you want to delete this badges?"}
                   open={deleteContentVisible} handleOk={deleteCourseContent} closeModal={closeDeleteModal}
                  showModal={handleTitleDeleteClick} handleCancel={handleDeleteCancel}/>: " "}

{deleteCertificateVisible ?
              <ModalComponent title={"Delete Certificate"} 
                  footer={deleteCertificateModalFooter} content={"Are you sure you want to delete this certificate?"}
                   open={deleteCertificateVisible} handleOk={deleteCertificateModal} closeModal={closeDeleteModal}
                  showModal={handleTitleDeleteClick} handleCancel={handleDeleteCancel}/>: " "}

{addCertificateVisible ? 
  <ModalComponent title={"Add Certificate"}
 
  content={<AddCertificateModal 
   handleCancel={handleDeleteCancel}
   addCertificateDetailsPayload = {addCertificateDetailsPayload}
   formCertificate={formCertificate}
   onFinishCertificate={onFinishCertificate} 
handleClassCertificate={handleClassCertificate}
   />}
open={addCertificateVisible} 


handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>

:""}


{viewCertificate ? 
  <ModalComponent title={"View Certificate"}
  width={1000}  height={1000}

  content={<CertificateViewModal 
    viewCertificateName={viewCertificateName}
    viewCertificateTemplate={viewCertificateTemplate}
    viewCertificateType={viewCertificateType}
    viewCertificateSignature={viewCertificateSignature}
   handleCancel={handleDeleteCancel}
   />}
open={viewCertificate} 
handleCancel={handleDeleteCancel}
closeModal={closeDeleteModal}

style={{
  maxHeight: '100px',
  minHeight: '100px',
  width: '80%', // You can adjust this value based on your preference
  maxWidth: '900px', // Optional: Set a maximum width if needed
}}
 ></ModalComponent>
:""}


      </div>

    )
}

const styles = {
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gap: "10px",
    padding: "15px"
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "0.3s" // Smooth transition for style change
  },
  image: {
    marginTop: "10px",
    width: "20px",
    height: "20px",
    objectFit: "cover",
    borderRadius: "5px"
  },
  name: {
    marginTop: "10px",
    fontSize: "10px",
    textAlign: "center",
    color: "#333"
  },
  deleteButton: {
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    backgroundColor: "#ff4d4f",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer"
  }
};

const stylesCertificate = {
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
    padding: "15px",

  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "0.3s" ,// Smooth transition for style change
    backgroundColor: "#f0f0f0", // Default background color

  },
  image: {
    marginTop: "10px",
    width: "150px",
    height: "80px",
    objectFit: "cover",
    borderRadius: "5px"
  },
  name: {
    marginTop: "10px",
    fontSize: "10px",
    textAlign: "center",
    color: "#333"
  },
  deleteButton: {
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    backgroundColor: "#ff4d4f",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer"
  },
  viewButton: {
    backgroundColor: "#4caf50",
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginLeft:"5px",
    marginRight:'5px'
  },
};

export default AddCoursesContentSection