
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { course, edit, excel, plus, upload, TableEyeIcon, searchIcon, add_icon, back, deleteIcon
  ,gold_image,silver_image,bronze_image
 } from "../../../images/index.js";import { Button, Form, Select ,Input,notification,DatePicker,Checkbox,Collapse, List, Typography,Row, Col } from 'antd';
import InputComponent from '../../../commonComponents/InputComponent/input.js';
import {addCoursesContentDetails,getCoursesContentDetails,updateCoursesContentContentDetails
} from '../../../redux/coursesContent/action';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {ModalComponent,TableComponent} from "../../../commonComponents/index.js";
import AddBadgesModal from '../courseModel/addBadgesModal.js';

import {createBadgesDetails,deleteBadgesDetails,getBadgesDetails,
  createCertificateDetails,deleteCertificateDetails,getCertificateDetails
} from '../../../redux/badges/action.js';

import AddCertificateModal from '../courseModel/addCertificateModal.js';
import CertificateViewModal from '../viewModal/certificateView.js';


let UpdateCoursesContentContent = ({handleCancel, coursesContentData,coursesName, selectedCourseId, selectedBatchId ,mainContentId}) =>{

  

    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [api, contextHolder] = notification.useNotification();

    const [formContent] = Form.useForm();
    const [formBadges] = Form.useForm();
    const [formCertificate] = Form.useForm();

    const [viewCertificateName,setViewCertificateName] = useState("");
    const [viewCertificateTemplate, VsetiewCertificateTemplate] = useState(false);
    const [viewCertificateType,setViewCertificateType] = useState(false);
    const [viewCertificateSignature,setViewCertificateSignature] = useState(false);
    const [addCertificateVisible, setAddCertificateVisible] = useState(false);

    const [selectedCertificate, setSelectedCertificate] = useState(coursesContentData[0].certificate_image 
      === null ? null : coursesContentData[0].certificate_id );


      const [selectedId, setSelectedId] = useState(coursesContentData[0].badge_name 
        === null ? null : coursesContentData[0].badge_id
      );

    const [certificateList, setCertificateList] = useState([]);
    const [viewCertificate,setViewCertificate] = useState(false);
    const [deleteCertificateVisible,setDeleteCertificateVisible] = useState(false);

    let[addCertificateDetailsPayload,setAddCertificateDetailsPayload] = useState({
      "certificate_name": "",
      "certificate_template": "",
      "signature": "",
      "signature_template": "",
      "batch_id":selectedBatchId,
      "instructor_id":window.localStorage.getItem("instructor_id"),
      "course_id":selectedCourseId,
      "tenant_id": window.localStorage.getItem("tenant_Id"),
   
      })

      useEffect(()=>{
        formContent.setFieldsValue({['title']:coursesContentData[0].title})
        formContent.setFieldsValue({['content_description']:coursesContentData[0].content_description})
        formContent.setFieldsValue({['xp']:coursesContentData[0].xp || ''})
      
    },[coursesContentData])


    const [visibleNotification, setVisibleNotification] = useState(false);
    const [addMultipleRecord,setAddMultipleRecord] = useState(null);

    const [badgesList, setBadgesList] = useState([]);
    const [addBadgesVisible, setAddBadgesVisible] = useState(false);
    const [deleteContentVisible,setDeleteContentVisible] = useState(false);
    const [selectedContentId,setSelectedCoursesContentId] = useState("");

    const [content, setContent] = useState(coursesContentData[0].content_description || "");
    // const [content, setContent] = useState('<p><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGUAAAB5CAYAAAAgaO8vAAAACXBIWXMAAAsSAAALEgHS3X78AAADQElEQVR4nO2dy23bQBRFnwXvtHBKcAd2A4KdDtKBU4LXWmWlOpwOUoIDNmB1oHQgLbhmQIMOAomgOcN5w0vyHEA7USB1OJ93ZwheVVVlIaw32y9m9svMHoIOnCa/zexbWeyOOc9+FXHM80KEWHOdr82NmI0YKUvjLrcYpPQjq5jrBL+xb7q0ufDdzJ5aruVDzKP3GJNCyrEsdq8JfkeC+k/vOI8sYui+wnHvypDyOX/M7HT2LVcx1wurO2I4NGNm3UXf/He8W1e2WljdEUVZ7N7M7DFXi6H76klOMUgJIJeYNil13fG14/Mz5YVOjRxi2uqUzrrjk3n8IqjFNP+Dy+BP9xWJZ4tBygC8xCBlIB5ikJKA1GJSBJKdDEwM6pmgeyqbgpSDf46WMiQxqC/oR+LzcSNVi5lC93UvcA69SSGGMcWBoWLGkLKIxGCIGPeBvoXFJAaxgz/dlzMxLQYpGQgVg5RMhIhBSkb6ihljoJ8aD+vNNmzDdRz/Bn9aihbvCcYYLSXXnTdV7mkpl7y09PlZmcKYkjUhLovdYb3Z3mbM3C72LueQ8tIkxTc9vnvOaYyUuKmys+yPbksw3KUMvPPeprCWkpos3VfOO28OMNALghRBxl6jv1iDn9lTAPvm6eJDyEFjr9G3rcHP6SmAqD0GCt3XrcA5eCK576urQm6rQ0avqBMSVWeNXadc1CEjVNSeRNVZknXK0usapsSCIEUQpAiCFEGQIghSBCH78oXsSxCyL1HIvsQg+xKE7GsuMCUWBCmCIEUQpAiCFEGQIgjZly9kX4KQfYlC9iUG2ZcgZF9zgSmxIEgRBCmCIEUQpAiCFEHIvnwh+xKE7EsUsi8xyL4EIfuaC0yJBUGKIEgRBCmCIEUQpAhC9uUL2ZcgZF+ikH2JQfYlCNnXXGBKLAhSBEGKIEgRBCmCIEUQpAiCFEGQIghSBEGKIEgRJEUgyRtOE0NLEQQpehxjpMxpZVCNU9Rbtme2MujB+buA981mkD68r1RGDfRLXxnsouVdwMey2AX9V4wpgiBFEKQIghRBkCIIUgRpmxKTZY0MLUUQpPgTvJd41TygQ5blQ9Su+6uqqj6eniLLSk/4rnsz+wufVvDoUTdNrQAAAABJRU5ErkJggg=="></p><p><br></p><p>hello damodar</p>');

    const [wordCount, setWordCount] = useState(coursesContentData[0].content_description.split(/\s+/).filter(word => word).length || 0);
    const [charCount, setCharCount] = useState(coursesContentData[0].content_description.length || 0);
    const characterLimit = 1000;
    // setWordCount(value.split(/\s+/).filter(word => word).length);
    // setCharCount(value.length);

    let[coursesContentListPayloadDetails,setCoursesContentListPayloadDetails] = useState({
      "category":"Content",
      "title": coursesContentData[0].title,
      "description":"",
      "content_description":coursesContentData[0].content_description,
      "xp":coursesContentData[0].xp || '',

      })

      
    let[addBadgesDetailsPayload,setAddBadgesDetailsPayload] = useState({
      "name": "",
      "document": "",
      "batch_id":selectedBatchId,
      "instructor_id":window.localStorage.getItem("instructor_id"),
      "course_id":selectedCourseId,
      "tenant_id": window.localStorage.getItem("tenant_Id")
      })


      let addCoursesContentDetailsList = useSelector((state)=> state?.CoursesContentReducer?.addCoursesContentDetails);
      let updateCourseContentContent = useSelector((state)=> state?.CoursesContentReducer?.updateCourseContentContent);


      let crateBadges = useSelector((state)=> state?.badgesReducer?.crateBadges);
      let getBadges = useSelector((state)=> state?.badgesReducer?.getBadges);
      let deleteBadges = useSelector((state)=> state?.badgesReducer?.deleteBadges);

      let crateCertificate = useSelector((state)=> state?.badgesReducer?.crateCertificate);
      let getCertificate = useSelector((state)=> state?.badgesReducer?.getCertificate);
      let deleteCertificate = useSelector((state)=> state?.badgesReducer?.deleteCertificate);

      useEffect(()=>{    
        if (updateCourseContentContent) {
    if(visibleNotification == true){
      openNotification(updateCourseContentContent?.msg)
    
      if(updateCourseContentContent?.res_status === true){


        formContent.resetFields();

        formContent.setFieldsValue({['title']:''})
        formContent.setFieldsValue({['description']:''})
        formContent.setFieldsValue({['content_description']:''})
        formContent.setFieldsValue({['xp']:''})

        setCoursesContentListPayloadDetails({
          "title":"",
          "description":"",
          "content_description":"",
          "xp":"",
          "category":"Content"
      
        })



      

      setContent('');
      setWordCount(0);
      setCharCount(0);

      let payload ={
        "operation_type":"retrieve_all_content",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCoursesContentDetails(payload))
  setAddMultipleRecord(null)

    
      }
    
    }else{
    
    }
    
        }
    },[updateCourseContentContent])

      useEffect(()=>{    
        if (deleteCertificate) {
    if(visibleNotification == true){
      openNotificationBadgeAndCertificate(deleteCertificate?.msg)
    
      if(deleteCertificate?.res_status === true){
  
        setAddCertificateVisible(false)
        formCertificate.resetFields();
  
        formCertificate.setFieldsValue({['certificate_name']:''})
        formCertificate.setFieldsValue({['certificate_template']:''})
  
        formCertificate.setFieldsValue({['signature']:''})
        formCertificate.setFieldsValue({['signature_template']:''})
  
  
        setAddCertificateDetailsPayload({
          "certificate_name": "",
          "certificate_template": "",
          "signature": "",
          "signature_template": "",
        })
  
        let payload ={
          "operation_type":"retrieve",
              "course_id":selectedCourseId,
              "tenant_id":window.localStorage.getItem("tenant_Id"),
              "instructor_id":window.localStorage.getItem("instructor_id"),
              "batch_id":selectedBatchId
  
      }
    dispatch(getCertificateDetails(payload))
    setSelectedCertificate(null)
  
    
      }
    
    }else{
    
    }
    
        }
    },[deleteCertificate])
  
      useEffect(()=>{    
        if (getCertificate) {
  
          setCertificateList(getCertificate)
    
        }
    },[getCertificate])
    
  
      useEffect(()=>{    
        if (crateCertificate) {
    if(visibleNotification == true){
      openNotificationBadgeAndCertificate(crateCertificate?.msg)
    
      if(crateCertificate?.res_status === true){
  
        setAddCertificateVisible(false)
        formCertificate.resetFields();
  
        formCertificate.setFieldsValue({['certificate_name']:''})
        formCertificate.setFieldsValue({['certificate_template']:''})
  
        formCertificate.setFieldsValue({['signature']:''})
        formCertificate.setFieldsValue({['signature_template']:''})
  
  
        setAddCertificateDetailsPayload({
          "certificate_name": "",
          "certificate_template": "",
          "signature": "",
          "signature_template": "",
        })
  
        let payload ={
          "operation_type":"retrieve",
              "course_id":selectedCourseId,
              "tenant_id":window.localStorage.getItem("tenant_Id"),
              "instructor_id":window.localStorage.getItem("instructor_id"),
              "batch_id":selectedBatchId
  
      }
    dispatch(getCertificateDetails(payload))
    setSelectedCertificate(null)
  
    
      }
    
    }else{
    
    }
    
        }
    },[crateCertificate])
  
      useEffect(()=>{
  
        let payload ={
          "operation_type":"retrieve",
              "course_id":selectedCourseId,
              "tenant_id":window.localStorage.getItem("tenant_Id"),
              "instructor_id":window.localStorage.getItem("instructor_id"),
              "batch_id":selectedBatchId
  
      }
    dispatch(getCertificateDetails(payload))
  
    },[])
  
      useEffect(()=>{    
        if (deleteBadges) {
    if(visibleNotification == true){
      openNotificationBadgeAndCertificate(deleteBadges?.msg)
    
      if(deleteBadges?.res_status === true){
  
        setAddBadgesVisible(false)
        formBadges.resetFields();
  
        formBadges.setFieldsValue({['name']:''})
        formBadges.setFieldsValue({['document']:''})
  
  
        setAddBadgesDetailsPayload({
          "name":"",
          "document":""
        })
  
  
  
   
    
        
        formBadges.setFieldsValue({['name']:''})
        formBadges.setFieldsValue({['document']:''})
  
      
        setAddBadgesDetailsPayload({
          "name":"",
          "document":"",
        })
    
  
  
      let payload ={
        "operation_type":"retrieve_badges_detail",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId
  
    }
  dispatch(getBadgesDetails(payload))
  setAddMultipleRecord(null)
  
    
      }
    
    }else{
    
    }
    
        }
    },[deleteBadges])
  
      useEffect(()=>{    
        if (getBadges) {
  
          console.log(getBadges)
          setBadgesList(getBadges)
    
        }
    },[getBadges])
  
  
      useEffect(()=>{
  
        let payload ={
          "operation_type":"retrieve_badges_detail",
              "course_id":selectedCourseId,
              "tenant_id":window.localStorage.getItem("tenant_Id"),
              "instructor_id":window.localStorage.getItem("instructor_id"),
              "batch_id":selectedBatchId
  
      }
    dispatch(getBadgesDetails(payload))
  
    },[])
  
      useEffect(()=>{    
        if (crateBadges) {
    if(visibleNotification == true){
      openNotificationBadgeAndCertificate(crateBadges?.msg)
    
      if(crateBadges?.res_status === true){
  
        setAddBadgesVisible(false)
        formBadges.resetFields();
  
        formBadges.setFieldsValue({['name']:''})
        formBadges.setFieldsValue({['document']:''})
  
  
        setAddBadgesDetailsPayload({
          "name":"",
          "document":""
        })
  
  
  
   
    
        
        formBadges.setFieldsValue({['name']:''})
        formBadges.setFieldsValue({['document']:''})
  
      
        setAddBadgesDetailsPayload({
          "name":"",
          "document":"",
        })
    
  
  
      let payload ={
        "operation_type":"retrieve_badges_detail",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId
  
    }
  dispatch(getBadgesDetails(payload))
  setAddMultipleRecord(null)
  
    
      }
    
    }else{
    
    }
    
        }
    },[crateBadges])

    const openNotificationBadgeAndCertificate = (msg) => {
  
      api.info({
        description:
        `${msg}`,
    
      });
    
      setVisibleNotification(false)

   
    
    };


      useEffect(()=>{    
        if (addCoursesContentDetailsList) {
    if(visibleNotification == true){
      openNotification(addCoursesContentDetailsList?.msg)
    
      if(addCoursesContentDetailsList?.res_status === true){

      if(addMultipleRecord === 1){

        formContent.resetFields();

        formContent.setFieldsValue({['title']:''})
        formContent.setFieldsValue({['description']:''})
        formContent.setFieldsValue({['content_description']:''})
        formContent.setFieldsValue({['xp']:''})

        setCoursesContentListPayloadDetails({
          "title":"",
          "description":"",
          "content_description":"",
          "xp":"",
          "category":"Content"
      
        })

        navigate(`/courseContentDetails/${coursesName}/${selectedCourseId}/${selectedBatchId}`);


      }else{

    
        
        formContent.setFieldsValue({['title']:''})
        formContent.setFieldsValue({['description']:''})
        formContent.setFieldsValue({['content_description']:''})
        formContent.setFieldsValue({['xp']:''})
      
        setCoursesContentListPayloadDetails({
          "title":"",
          "description":"",
          "content_description":"",
          "xp":"",
          "category":"Content"
      
        })
      }

      setContent('');
      setWordCount(0);
      setCharCount(0);

      let payload ={
        "operation_type":"retrieve_all_content",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCoursesContentDetails(payload))
  setAddMultipleRecord(null)

    
      }
    
    }else{
    
    }
    
        }
    },[addCoursesContentDetailsList])

    const openNotification = (msg) => {
  
      api.info({
        description:
        `${msg}`,
    
      });
    
      setVisibleNotification(false)

      setTimeout(() => {
        handleCancel()
      }, 1000);
    
    };

      const handleClassSingleCoursesContentChange = (e, type) => {
 

        setAddMultipleRecord(1)
        console.log('SINGLE')
      
        if (!type || !type.name) {
          console.error("Type or type.name is undefined:", type);
          return;
        }
      
        let value = e && e.target ? e.target.value : e; // Handle both events and direct values
        console.log(type.name )
  
        console.log(e)
  
    
        // Update other fields
        if (["title", "description","xp","content_description"].includes(type.name)) {
          setCoursesContentListPayloadDetails((prevDetails) => ({
            ...prevDetails,
            [type.name]: value,
          }));
        }
      };
      
      const handleClassMultipleCoursesContent = (e, type) => {
        console.log('MULTIPLE')
  
        setAddMultipleRecord(2)
  
     
      
        if (!type || !type.name) {
          console.error("Type or type.name is undefined:", type);
          return;
        }
      
        let value = e && e.target ? e.target.value : e;
  
        console.log(type.name )
  
  
      
        if (["title", "description","xp","content_description"].includes(type.name)) {
          setCoursesContentListPayloadDetails((prevDetails) => ({
            ...prevDetails,
            [type.name]: value,
          }));
        }
      };

    let onFinishContextAdd= (values) =>{

      let payload = {
        "operation_type":"update",
        "op_field_details":{
          "category":"Content",
          "title": coursesContentListPayloadDetails.title,
          "description":"",
          "content_description":coursesContentListPayloadDetails.content_description,
            "xp":coursesContentListPayloadDetails.xp === '' ? null :parseInt(coursesContentListPayloadDetails.xp, 10),
            "batch_id":selectedBatchId,
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "badge_id":selectedId,
            "certificate_id":selectedCertificate,
"content_id":mainContentId

        }
    }
      
      
        dispatch(updateCoursesContentContentDetails(payload))
    
        setVisibleNotification(true)
    
    }

    const handleChange = (value) => {
      setContent(value);
      setWordCount(value.split(/\s+/).filter(word => word).length);
      setCharCount(value.length);

      handleClassSingleCoursesContentChange(value, { name: "content_description" })
    handleClassMultipleCoursesContent(value, { name: "content_description" })

      console.log(value)
    };

      // Save content to local storage
      useEffect(() => {
        localStorage.setItem('editorContent', content);
      }, [content]);
    
      // Clear editor content
      const handleClearContent = () => {
        setContent('');
        setWordCount(0);
        setCharCount(0);
        notification.success({ message: 'Content cleared!' });

        // props.handleClassSingleCoursesContentChange("", { name: "content_description" })
        // props.handleClassMultipleCoursesContent("", { name: "content_description" })
      };

      let handleDeleteCancel= ()=>{
        setViewCertificate(false)
        setAddCertificateVisible(false)


        setDeleteContentVisible(false)
        setAddBadgesVisible(false)

    
    
      } 



      let  closeDeleteModal = () =>{
        setDeleteContentVisible(false)
        setViewCertificate(false)
      
      
      }
      
      let deleteCourseContent= ()=>{
      
        setDeleteContentVisible(false)
      
        let payload = {
          "operation_type":"delete",
          "op_field_details":{
              "badge_id":[selectedContentId]
          }
      }
        
        
          dispatch(deleteBadgesDetails(payload))
      
          setVisibleNotification(true)
      
      
        
      
      } 

      let deleteTitleDescriptionModalFooter = [
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' ,marginBottom:"10px"}}>
        <Button   style={{
          border: '1px solid red',  // Red border
          color: 'red',              // Red text color
          backgroundColor: 'transparent',  // No background color
        }} default value={'Cancel'}  onClick={closeDeleteModal}>
              Cancel</Button>
      
              <div style={{marginLeft:"10px"}}></div>
      
              <Button   style={{
          border: '1px solid red',  // Red border
          color: 'white',              // Red text color
          backgroundColor: 'red',  // No background color
        }}  danger value={'Delete'}  onClick={deleteCourseContent}>Delete</Button>
         
        
        </div>
      ]

      const deleteItem = (id) => {
        setDeleteContentVisible(true)
        setSelectedCoursesContentId(id)

      };

      const handleClassBadges= (e, type)=>{
        if (!type || !type.name) {
          console.error("Type or type.name is undefined:", type);
          return;
        }
      
        let value = e && e.target ? e.target.value : e; // Handle both events and direct values
        console.log(type.name )
  
        console.log(e)
  
    
        // Update other fields
        if (["name", "document"].includes(type.name)) {
          setAddBadgesDetailsPayload((prevDetails) => ({
            ...prevDetails,
            [type.name]: value,
          }));
        };
                  
              
                };


      let onFinishBadges= ()=>{

        let payload =  {
          "operation_type":"insert",
          "op_field_details":{
             "badge_name":addBadgesDetailsPayload.name,
             "batch_id":selectedBatchId,
             "image":addBadgesDetailsPayload.document,
             "instructor_id":window.localStorage.getItem("instructor_id"),
             "course_id":selectedCourseId,
             "tenant_id":window.localStorage.getItem("tenant_Id")
          }
       }

  
          dispatch(createBadgesDetails(payload))
      
          setVisibleNotification(true)
      } 

      const handleClearCertificateModal = () => {
        setSelectedCertificate(null)
      };
	  
	    const handleClearBadgesModal = () => {
     
     setSelectedId(null)
    };

      const handleAddBadgesModal = () => {
       
        setAddBadgesVisible(true)
      };

      const handleItemClick = (id) => {
        setSelectedId(id); // Set the clicked item's id as selected
      };

      let handleTitleDeleteClick = (values) =>{
        deleteContentVisible(false)
    
      }


      //------------

      const deleteItemCertificate = (id) => {

        setDeleteCertificateVisible(true)
      
        setSelectedCertificate(id)
      };

      const handleClassCertificate= (e, type)=>{
                 
        if (!type || !type.name) {
          console.error("Type or type.name is undefined:", type);
          return;
        }
      
        let value = e && e.target ? e.target.value : e; // Handle both events and direct values
        console.log(type.name )
  
        console.log(e)
      
        // Update other fields
        if (["certificate_name", "certificate_template","signature","signature_template"].includes(type.name)) {
          setAddCertificateDetailsPayload((prevDetails) => ({
            ...prevDetails,
            [type.name]: value,
          }));
        };
        
              
                };

                let onFinishCertificate= ()=>{

                  console.log(addCertificateDetailsPayload)
      
                  // Completion of Python Course
      
                 let payload =  {
                  "operation_type": "insert",
                  "op_field_details": {
                     "certificate_name": addCertificateDetailsPayload.certificate_name,
                     "image":addCertificateDetailsPayload.certificate_template,
                     "signature": addCertificateDetailsPayload.signature?  addCertificateDetailsPayload.signature : addCertificateDetailsPayload.signature_template,
                     "signature_type":addCertificateDetailsPayload.signature? 'TEXT':"IMAGE",
                     "batch_id":selectedBatchId,
                     "instructor_id":window.localStorage.getItem("instructor_id"),
                     "course_id":selectedCourseId,
                     "tenant_id":window.localStorage.getItem("tenant_Id")
                  }
               }
          
      
               console.log(payload)
               
                    dispatch(createCertificateDetails(payload))
                
                    setVisibleNotification(true)
}

const handleAddCertificateModal = () => {
  setAddCertificateVisible(true)
};

const viewItemCertificate = (name,template,type,signature) => {



  setViewCertificateName(name)
  VsetiewCertificateTemplate(template)
  setViewCertificateType(type)
  setViewCertificateSignature(signature)

  setViewCertificate(true)


};

let deleteCertificateModal= ()=>{
      
  setDeleteCertificateVisible(false)

  let payload = {
    "operation_type":"delete",
    "op_field_details":{
        "certificate_id":[selectedCertificate]
    }
}
  
  
    dispatch(deleteCertificateDetails(payload))

    setVisibleNotification(true)


  

} 

let deleteCertificateModalFooter = [
  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' ,marginBottom:"10px"}}>
  <Button   style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
  }} default value={'Cancel'}  onClick={closeDeleteModal}>
        Cancel</Button>

        <div style={{marginLeft:"10px"}}></div>

        <Button   style={{
    border: '1px solid red',  // Red border
    color: 'white',              // Red text color
    backgroundColor: 'red',  // No background color
  }}  danger value={'Delete'}  onClick={deleteCertificateModal}>Delete</Button>
   
  
  </div>
]

const handleCertificateItemClick = (id) => {
  setSelectedCertificate(id); 
};


    return (
     <div>
 {contextHolder}
<div style={{ display: 'flex', flexDirection: 'row' }}>

<div style={{ flex: 1 }}>
<div style={{ minHeight: '200px', maxHeight: '300px', overflowY: 'auto' }}>
<Form
          form={formContent}
          onFinish={onFinishContextAdd}

          autoComplete="off"
          layout="vertical"
         
        >
 
 <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please enter title',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"Title"}
                name={"title"}
                value={coursesContentListPayloadDetails.title}
                onChange={(e) => handleClassMultipleCoursesContent(e, { name: "title" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>

          {/* <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
         <Form.Item
           label="Description"
           name="description"
           rules={[
             {
               required: true,
               message: 'Please enter description',
             },
           ]}
           style={{ flex: 1 }}
         >
           <Input.TextArea
             placeholder="Description"
             name="description"
             value={coursesContentListPayloadDetails.description} // Adjust this to the appropriate state variable if necessary
             onChange={(e) => handleClassMultipleCoursesContent(e, { name: "description" })}
             style={{ width: '100%' }}
             autoSize={{ minRows: 3, maxRows: 6 }} // Allows the textarea to expand as needed
           />
         </Form.Item>
         </div> */}

{/* <h2>Quill Rich Text Editor</h2> */}
<p>Content</p>
      <ReactQuill
        value={content}
        label="Description"
        onChange={handleChange}
        theme="snow"
        placeholder="Start typing here..."
        modules={{
          toolbar: [
            // [{ 'header': '1'}, { 'header': '2'}, { 'font': [] }],
            // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            // ['bold', 'italic', 'underline'],
            // [{ 'color': [] }, { 'background': [] }],
            // ['link', 'image'],
            // ['clean']
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  // More header options
            [{ 'font': [] }],  // Allows font selection
            [{ 'size': ['small', false, 'large', 'huge'] }],  // Text size options
            [{ 'align': [] }],  // Text alignment options
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],  // Ordered and bullet lists
            [{ 'script': 'sub' }, { 'script': 'super' }],  // Subscript and superscript
            ['bold', 'italic', 'underline', 'strike'],  // Text styles
            [{ 'color': [] }, { 'background': [] }],  // Text color and background
            [{ 'indent': '-1' }, { 'indent': '+1' }],  // Indentation options
            [{ 'direction': 'rtl' }],  // Text direction (RTL/LTR)
            ['blockquote', 'code-block'],  // Blockquote and code block
            ['link', 'image', 'video'],  // Media embedding (link, image, video)
            ['clean']
          ]
        }}
      />

      
      <div style={{ marginTop: '20px' }}>
        <Button onClick={handleClearContent} type="primary" danger>
          Clear Content
        </Button>
        <div style={{ marginTop: '10px' }}>
          <h3>Editor Content:</h3>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        <div style={{ marginTop: '10px' }}>
          <p>Word Count: {wordCount}</p>
          <p>Character Count: {charCount} / {characterLimit}</p>
          {charCount > characterLimit && (
            <p style={{ color: 'red' }}>Character limit exceeded!</p>
          )}
        </div>
      </div>

        </Form></div>
</div>


<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '0.1px',            // Set the width of the line
      height:'40vh',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
      marginLeft:'10px',marginRight:"10px"     // Ensure the line spans the height of the flex container
    }}
  />  

<div style={{ flex: 1 }}>
<div style={{ minHeight: '200px', maxHeight: '300px', overflowY: 'auto' }}>
<Form
          // initialValues={props.cancelClassDetails}
          form={formContent}
          onFinish={onFinishContextAdd}
          // onFinish2={props?.onFinish}

          autoComplete="off"
          layout="vertical"
        
        >

<div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,marginTop:"12px" }}>
            <Form.Item
              label="XP"
              name="xp"
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please enter xp',
              //   },
              // ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"XP"}
                name={"xp"}
                value={coursesContentListPayloadDetails.xp}
                onChange={(e) => handleClassMultipleCoursesContent(e, { name: "xp" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>

          <div
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  }}
>
  <p style={{ margin: 0 }}>Badges</p>

  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

   { selectedId === null || selectedId === '' ? <div></div> :
       <button
       style={stylesCertificate.deleteButton}
       onClick={(e) => {
         e.stopPropagation(); // Prevent item click event when delete button is clicked
         handleClearBadgesModal();
       }}
     >
       Clear
     </button>}
    <img
      src={plus}
      alt="plus-icon"
      style={{ height: "25px", width: "20px", cursor: "pointer" }}
      onClick={() => handleAddBadgesModal()}
    />
  </div>
</div>


<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 

          
<div style={styles.gridContainer}>
      {badgesList?.map(item => (
        <div
          key={item.badge_id}
          style={{
            ...styles.gridItem,
            border: selectedId === item.badge_id ? "2px solid blue" : "1px solid #ddd",
            backgroundColor: selectedId === item.badge_id ? "#e0f7ff" : "#f9f9f9"
          }}
          onClick={() => handleItemClick(item.badge_id)}
        >
          <img src={item.image} alt={item.badge_name} style={styles.image} />
          <p style={styles.name}>{item.badge_name}</p>
          <img
      src={deleteIcon}
      alt="plus-icon"
      style={{
        height: "25px",
        width: "20px",
        cursor: "pointer",
        border: "1px solid  #0E60B2", // Adds a border
        borderRadius: "4px",       // Optional: Makes the corners rounded
      }}      onClick={() => deleteItem(item.badge_id)}
    />

          {/* <button
            style={styles.deleteButton}
            onClick={(e) => {
              e.stopPropagation(); // Prevent item click event when delete button is clicked
              deleteItem(item.badge_id);
            }}
          >
            Delete
          </button> */}
        </div>
      ))}
    </div>

    <div
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  }}
>
  <p style={{ margin: 0 }}>Certificate</p>

  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

   { selectedCertificate === null || selectedCertificate === '' ? <div></div> :
      <button
      style={stylesCertificate.deleteButton}
      onClick={(e) => {
        e.stopPropagation(); // Prevent item click event when delete button is clicked
        handleClearCertificateModal();
      }}
    >
      Clear
    </button>}
    <img
      src={plus}
      alt="plus-icon"
      style={{ height: "25px", width: "20px", cursor: "pointer" }}
      onClick={() => handleAddCertificateModal()}
    />
  </div>
</div>



<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 





    <div style={stylesCertificate.gridContainer}>
  {certificateList?.map((item) => (
    <div
      key={item.id}
      style={{
        ...stylesCertificate.gridItem,
        border: selectedCertificate === item.certificate_id ? "2px solid blue" : "1px solid #ddd",
        backgroundColor: selectedCertificate === item.certificate_id ? "#e0f7ff" : "#f9f9f9",
      }}
      onClick={() => handleCertificateItemClick(item.certificate_id)}
    >
      <img src={item.image} alt={item.certificate_name} style={stylesCertificate.image} />
      {/* <p style={stylesCertificate.name}>{item.name}</p> */}

      <div style={stylesCertificate.actionContainer}>

      <img
      src={TableEyeIcon}
      alt="plus-icon"
      style={{
        height: "25px",
        width: "20px",
        cursor: "pointer",
        marginRight:"5px",
        border: "1px solid  #0E60B2", // Adds a border
        borderRadius: "4px",       // Optional: Makes the corners rounded
      }}      onClick={() => viewItemCertificate(item.certificate_name,item.image,item.signature_type,item.signature)}
    />




<img
      src={deleteIcon}
      alt="plus-icon"
      style={{
        height: "25px",
        width: "20px",
        cursor: "pointer",
        border: "1px solid  #0E60B2", // Adds a border
        borderRadius: "4px",       // Optional: Makes the corners rounded
      }}      onClick={() => deleteItemCertificate(item.badge_id)}
    />
        {/* <button
          style={stylesCertificate.viewButton}
          onClick={(e) => {
            e.stopPropagation(); // Prevent item click event when view button is clicked
            viewItemCertificate(item.certificate_name,item.image,item.signature_type,item.signature);
          }}
        >
          View
        </button>


        <button
          style={stylesCertificate.deleteButton}
          onClick={(e) => {
            e.stopPropagation(); // Prevent item click event when delete button is clicked
            deleteItemCertificate(item.certificate_id);
          }}
        >
          Delete
        </button> */}
      </div>
    </div>
  ))}
</div>


        </Form>
</div>

</div>




  </div>


  <div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 


<Form
          form={formContent}
          onFinish={onFinishContextAdd}

          autoComplete="off"
          layout="vertical"
         
        >
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Form.Item>




              <Button htmlType='submit' type='primary' style={{ marginRight: '8px', width:"110px" }} onClick={handleClassMultipleCoursesContent}>
                Update
              </Button>
            
              <Button
  htmlType="submit"
  onClick={handleCancel}
  style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px"
  }}
>
  Cancel
</Button>

            </Form.Item>
 </div>
        </Form>

        {addBadgesVisible ? 
  <ModalComponent title={"Add Badges"}
 
  content={<AddBadgesModal 
   handleCancel={handleDeleteCancel}
   addBadgesDetailsPayload = {addBadgesDetailsPayload}
   formBadges={formBadges}
onFinishBadges={onFinishBadges} 
handleClassBadges={handleClassBadges}
   />}
open={addBadgesVisible} 


handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>

:""}


{deleteContentVisible ?
              <ModalComponent title={"Delete Badges"} 
                  footer={deleteTitleDescriptionModalFooter} content={"Are you sure you want to delete this content?"}
                   open={deleteContentVisible} handleOk={deleteCourseContent} closeModal={closeDeleteModal}
                  showModal={handleTitleDeleteClick} handleCancel={handleDeleteCancel}/>: " "}


{deleteCertificateVisible ?
              <ModalComponent title={"Delete Certificate"} 
                  footer={deleteCertificateModalFooter} content={"Are you sure you want to delete this certificate?"}
                   open={deleteCertificateVisible} handleOk={deleteCertificateModal} closeModal={closeDeleteModal}
                  showModal={handleTitleDeleteClick} handleCancel={handleDeleteCancel}/>: " "}

{addCertificateVisible ? 
  <ModalComponent title={"Add Certificate"}
 
  content={<AddCertificateModal 
   handleCancel={handleDeleteCancel}
   addCertificateDetailsPayload = {addCertificateDetailsPayload}
   formCertificate={formCertificate}
   onFinishCertificate={onFinishCertificate} 
handleClassCertificate={handleClassCertificate}
   />}
open={addCertificateVisible} 


handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>

:""}


{viewCertificate ? 
  <ModalComponent title={"View Certificate"}
  width={1000}  height={1000}

  content={<CertificateViewModal 
    viewCertificateName={viewCertificateName}
    viewCertificateTemplate={viewCertificateTemplate}
    viewCertificateType={viewCertificateType}
    viewCertificateSignature={viewCertificateSignature}
   handleCancel={handleDeleteCancel}
   />}
open={viewCertificate} 
handleCancel={handleDeleteCancel}
closeModal={closeDeleteModal}

style={{
  maxHeight: '100px',
  minHeight: '100px',
  width: '80%', // You can adjust this value based on your preference
  maxWidth: '900px', // Optional: Set a maximum width if needed
}}
 ></ModalComponent>
:""}

      </div>

    )
}

const styles = {
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gap: "10px",
    padding: "15px"
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "0.3s" // Smooth transition for style change
  },
  image: {
    marginTop: "10px",
    width: "20px",
    height: "20px",
    objectFit: "cover",
    borderRadius: "5px"
  },
  name: {
    marginTop: "10px",
    fontSize: "10px",
    textAlign: "center",
    color: "#333"
  },
  deleteButton: {
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    backgroundColor: "#ff4d4f",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer"
  }
};

const stylesCertificate = {
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
    padding: "15px",

  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "0.3s" ,// Smooth transition for style change
    backgroundColor: "#f0f0f0", // Default background color

  },
  image: {
    marginTop: "10px",
    width: "150px",
    height: "80px",
    objectFit: "cover",
    borderRadius: "5px"
  },
  name: {
    marginTop: "10px",
    fontSize: "10px",
    textAlign: "center",
    color: "#333"
  },
  deleteButton: {
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    backgroundColor: "#ff4d4f",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer"
  },
  viewButton: {
    backgroundColor: "#4caf50",
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginLeft:"5px",
    marginRight:'5px'
  },
};

export default UpdateCoursesContentContent