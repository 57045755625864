import { CRETATE_BADGES,DELETE_BADGES,GET_BADGES,CRETATE_CERTIFICATE,DELETE_CERTIFICATE,GET_CERTIFICATE

} from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';



export const createBadges = (data)=>{
    return{
        type: CRETATE_BADGES,
        payload: data
    }
}

export const createBadgesDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/badges",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(createBadges(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(createBadges(res.data));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const deleteBadges = (data)=>{
    return{
        type: DELETE_BADGES,
        payload: data
    }
}

export const deleteBadgesDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/badges",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(deleteBadges(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(deleteBadges(res.data));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const getBadges = (data)=>{
    return{
        type: GET_BADGES,
        payload: data
    }
}

export const getBadgesDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/badges",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getBadges(res?.data?.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getBadges([]));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const createCertificate = (data)=>{
    return{
        type: CRETATE_CERTIFICATE,
        payload: data
    }
}

export const createCertificateDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/certificate",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(createCertificate(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(createCertificate(res.data));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const deleteCertificate = (data)=>{
    return{
        type: DELETE_CERTIFICATE,
        payload: data
    }
}

export const deleteCertificateDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/certificate",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(deleteCertificate(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(deleteCertificate(res.data));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const getCertificate = (data)=>{
    return{
        type: GET_CERTIFICATE,
        payload: data
    }
}

export const getCertificateDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/certificate",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getCertificate(res?.data?.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getCertificate([]));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}