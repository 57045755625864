import React, { useEffect, useMemo } from "react";

import { useSelector, useDispatch } from "react-redux";
import { InputSelect, InputComponent, TableComponent, ModalComponent, PopOverComponent } from "../../commonComponents";
import InStructoreDetail from "../../components/AllStudents/StudentDetail/instructureDetails";
import { NavLink, Outlet } from "react-router-dom";

import { useState } from "react";
import './allInstructors.css';
import CourseTypes from "../courseTypes/courseTypes";

import { course, edit, excel, plus, upload, deleteIcon, searchIcon,TableEyeIcon } from "../../images";
import AddInstructor from "./addInstructor/addInstructor";
import { notification, Space,Button, Form } from "antd";
import validateForm from "./addInstructor/validateForm";
import { createInstructor, deleteInstructor, emailExistsInstructors, emailExistss, getAllInstructors, 
  getInstructors, updateInstructor } from "../../redux/allInstructors/action";
import {  getCategories, updateCategory } from "../../redux/allCategories/action";
import Notifications from "../../commonComponents/Notification/notification";
import { adminInstructorBulkDelete, createAdminInstructor, deleteAdminInstructor, instructorCrudStatuss, 
  retrieveAdminInstructors, updateAdminInstructor, uploadAdminBulkAction,instructorApproved,instructorRejected } from "../../redux/adminInstructor/action";
import { getCourses } from "../../redux/allCourses/action";
import moment from "moment";
import * as XLSX from 'xlsx';
import ExportToExcel from '../../commonComponents/XlsxFile/ExportToExcel'
let AllInstructors = () => {





  const [form] = Form.useForm();

  let dispatch = useDispatch();

  let [instructors,setInstructors] = useState()
  let [studentDetails,setStudentDetails] = useState(false);
  let [studentRecord,setStudentRecord] = useState([]);
  let [studentId, setStudentId] = useState();

  const [popoverVisible, setPopoverVisible] = useState(false);

  let allInstructors = useSelector((state) => state.instructor?.instructor?.data);

  let getAllCategories = useSelector((state)=> state.categories?.category?.data);


  let checkStatusInstructor = useSelector((state)=> state.instructor?.verify);
  
  const [api, contextHolder] = notification.useNotification();
  let [studentDOb,setStudentDob] = useState("");

  const [selectedRowsKeys, setSelectedRowKeys] = useState([]);
  let [categoryDropdown,setCategoryDropdown] = useState();
  let [isInstructorModalVisible, setIsInstructorModalVisible] = useState(false);


  let [isDeleteModalVisible, setIsDeleteModalVisile] = useState(false);

  let [modalType, setModalType] = useState(" ");

  let [errors, setErrors] = useState();

  let [instructorId,setInstructorId] = useState();

  let[searchEntry,setSearchEntry] = useState("");

  let [emailStatus,setEmailStatus] = useState();
  let [instructorRecords, setInstructorRecords] = useState()

  //  let bulkActions = [
  //   {label: "ACTIVE",value:true},
  //   {label: "INACTIVE",value:false},
  //   {label: "DELETE",value: "Delete"}
  // ]

   //state
   const [selectedDropdown,setSelectedDropdown] = useState("Select")
   const [instructorFirstName,setInstructorFirstName] = useState("");
    const [instructorLastName,setInstructorLastName] = useState("");
    const [gender,setGender] = useState("");
    const [age,setAge] = useState("");
    const [instructorTopic,setInstructorTopic] = useState("");
    const [resumeUpload,setResumeUpload] = useState("");
    const [phoneNumber,setPhoneNumber] = useState("");
    const [email,setEmail] = useState("");
    const [address,setAddress] = useState("");
    const [instructorStatus,setInstructorStatus] = useState("");

    const [instructorCourseId,setInstructorCourseId] = useState("");

    const [adminInstructors,setAdminInstructors] = useState();

    const [courseDefaultValue,setCourseDefaultValue] = useState("Select")

    const [loader,setLoader] = useState(false)
   //endState

   let allAdminInstructors = useSelector((state)=>state?.AdminInstructor?.adminInstructors);

   let instructorCrudStatus = useSelector((state)=>state?.AdminInstructor?.instructorCrudStatus)

   let AllinstructorApprovedStatus = useSelector((state)=>state?.AdminInstructor?.instructorApprovedStatus)


   let AllinstructorRejectedStatus = useSelector((state)=>state?.AdminInstructor?.instructorRejectedStatus)

   useEffect(()=>{

    console.log(AllinstructorApprovedStatus,'instructorApprovedStatus')

    if (AllinstructorApprovedStatus) {

      console.log(AllinstructorApprovedStatus,'instructorApprovedStatus')


      api.info({
        type: 'error',
        description:
          `${AllinstructorApprovedStatus.msg}`,
      });
    }
 },[AllinstructorApprovedStatus])

 useEffect(()=>{

  console.log(AllinstructorRejectedStatus,'instructorRejectedStatus')

  if (AllinstructorRejectedStatus) {

    console.log(AllinstructorRejectedStatus,'instructorRejectedStatus')
    api.info({
      type: 'error',
      description:
        `${AllinstructorRejectedStatus.msg}`,
    });
  }
},[AllinstructorRejectedStatus])



   let img = [
    <div className='statusClick'>
                <span className='dotPopUp'></span>
                <span className='dotPopUp'></span>
                <span className='dotPopUp'></span>
  </div>
  ]

   useEffect(()=>{
      if (allAdminInstructors) {
        setAdminInstructors(allAdminInstructors?.data)
      }else{
        setAdminInstructors([])

      }
   },[allAdminInstructors])

   let bulkActions = [
    {label: "ACTIVE",value:true},
    {label: "INACTIVE",value:false},
    {label: "DELETE",value: "Delete"}
  ]


  const courseTypeValues = [
    {label:"ALL",value:allAdminInstructors?.data?.length},
    {label:"ACTIVE",value:allAdminInstructors?.data?.filter((item)=> item.is_active == 'true').length},
    {label:"INACTIVE",value:allAdminInstructors?.data?.filter((item)=> item.is_active == 'false').length},
    {label:"PENDING",value:allAdminInstructors?.data?.filter((item)=> item.is_active == 'pending').length},

    // {label:"TRASH",value:0},
    
  ]

  const [actionType,setActionType] = useState("");

  const handleCourseTypes = (type) =>{
    setActionType(type.target?.getAttribute("label"))
    if (type.target?.getAttribute("label") == "ACTIVE") {
       let array=[]
       allAdminInstructors?.data && allAdminInstructors?.data.filter((item,index)=>{
         if (item.is_active == 'true') {
          array.push(item)
         }
      })
      setAdminInstructors(array)
      }

    if (type.target?.getAttribute("label") == "INACTIVE") {
      let array = []
      allAdminInstructors?.data && allAdminInstructors?.data.filter((item,index)=>{
        if (item.is_active == 'false') {
          array.push(item)
        }
     })
     setAdminInstructors(array)
    }

    if (type.target?.getAttribute("label") == "PENDING") {
      let array = []
      allAdminInstructors?.data && allAdminInstructors?.data.filter((item,index)=>{
        if (item.is_active == 'pending') {
          array.push(item)
        }
     })
     setAdminInstructors(array)
    }

    if (type.target?.getAttribute("label") == "ALL") {
      setAdminInstructors(allAdminInstructors?.data)
    }
  }

  let [formValues, setFormValues] = useState({
    instructor_first_name: " ",
    instructor_last_name: " ",
    phone_no: " ",
    email: " ", 
    address: " ",
    age: " ",
    gender: " ",
    course_id: " ",
    // fileUpload: " ",
    tenand_id: 1,
    // is_active: true
  });


  useEffect(() =>{
      if (allInstructors) {
        setInstructors(allInstructors)
      }
  },[allInstructors])


    useEffect(()=>{
      dispatch(getCategories());
  },[isInstructorModalVisible == true])

      useEffect(()=>{
        if (getAllCategories) {
    setCategoryDropdown(getAllCategories);

        }
      },[getAllCategories])

 
  useEffect(() => {
    setInstructorRecords(allInstructors)
  }, [allInstructors])

  const rowSelection = {
    // selectedRowKeys: allInstructors && allInstructors.filter(item => item.is_active).map((item=>item.instructor_id)),
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selectedRowsKeys.length !== 0) {
        setSelectedRowKeys([selected]);
      }
    },
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys([selectedRowKeys]);
    }
  };

  
  useEffect(() => {
    dispatch(getInstructors())
  }, [isInstructorModalVisible == true])

  
  let handleInputChange = (e, type) => {
    if (type.name == "instructorFirstName") {
      setInstructorFirstName(e.target?.value)
    }

    if (type.name == "instructorLastName") {
      setInstructorLastName(e.target?.value)
    }

    if (type.name == "address") {
      setAddress(e.target?.value)
    }

    if (type.name == "phoneNumber") {
      setPhoneNumber(e.target?.value)
    }

    if (type.name == "email") {
      setEmail(e.target?.value)
    }
  }

  const handleDropdowns = (e,type) =>{

      if (type.name == "age") {
        form.setFieldsValue({["age"]:e});
        setAge(e)
      }

      if (type.name == "gender") {
        form.setFieldsValue({["gender"]:e});
        setGender(e)
      }

      if (type.name == "instructorTopic") {
        form.setFieldsValue({["instructorTopic"]:e});
        setInstructorTopic(e)
        setCourseDefaultValue("select")
        // setInstructorCourseId("")
        setCategoryBasedCourses([])
        form.setFieldsValue({["instructorCourseId"]:""})
      }

      if (type.name == "instructorStatus") {
        form.setFieldsValue({["instructorStatus"]:e})
        setInstructorStatus(e)
      }

      if (type.name == "instructorCourseId") {
        setInstructorCourseId(e);
        form.setFieldValue({["instructorCourseId"]:e})
      }
      console.log(instructorCourseId,instructorTopic)
  }
  let getAllCourses = useSelector((state)=>state.courses.courses);
  const [allCourses,setAllCourses] = useState("");

  const [categoryBasedCourses,setCategoryBasedCourses] = useState("")
  useEffect(()=>{
    dispatch(getCourses())
  },[])

  useEffect(()=>{
      if (getAllCourses) {
        setAllCourses(getAllCourses?.data)
        console.log(getAllCourses?.data,"inst")
      }
  },[getAllCourses])

  useEffect(()=>{
    let arr = []
    if (instructorTopic) {
      setCategoryBasedCourses([])
      allCourses && allCourses.filter((item,index)=>{
        if (item.course_category_id == instructorTopic) {
          arr.push(item)
        }
      })
      setCategoryBasedCourses(arr)
      setCourseDefaultValue("select")
    }
  },[instructorTopic])

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) {
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
      }
     
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  let  onFileChange = async(event,type)=>{
    const file = event.target.files[0];
  const base64 = await convertToBase64(file);
  console.log(base64,"ppp")
  setResumeUpload(base64);
   }

   const [resumeData,setResumeData] = useState()

   
function base64ToPDF(base64String, fileName) {
  // Decode the Base64 string to text
  const decodedString = atob(base64String);

  // Create a Blob from the text data
  const blob = new Blob([decodedString], { type: 'text/plain;charset=utf-8' });

  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName || 'file.txt'; // Set the filename, fallback to 'file.txt' if not provided

  // Append the link to the DOM and trigger the click event
  document.body.appendChild(link);
  link.click();

  // Clean up
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
}

  let handleInstructorModal = (type,id) => {
    setCategoryDropdown(getCategories)
    setIsInstructorModalVisible(true)
    setIsDeleteModalVisile(false)
    setModalType(type)
    setInstructorId(id)
    form.setFieldsValue({["instructorCourseId"]:""});
    setInstructorCourseId("");
    form.setFieldsValue({["address"]:""})
    setAddress("")
    if( id){
      adminInstructors && adminInstructors.filter((item,index)=>{
      if(item.instructor_id == id){//date_of_birth
        console.log(item,"test")
          setInstructorFirstName(item?.instructor_first_name);
          setInstructorLastName(item?.instructor_last_name);
          setEmail(item?.email);
          setAge(item?.age);
          setGender(item?.gender);
          setPhoneNumber(item?.phone_no);
          setAddress(item?.address);

          setInstructorTopic(item?.course_category_id);

          setInstructorCourseId(item?.course_id)

          setResumeUpload(item?.upload_resume);

          setInstructorStatus(item?.is_active);

          setStudentDob(item?.date_of_birth)

          form.setFieldsValue({["dob"]: item?.date_of_birth})

          
          form.setFieldsValue({["instructorFirstName"]: item?.instructor_first_name});

          form.setFieldsValue({["instructorLastName"]: item?.instructor_last_name});

          form.setFieldsValue({["email"]: item?.email});

          form.setFieldsValue({["age"]: item?.age});

          form.setFieldsValue({["gender"]: item?.gender});

          form.setFieldsValue({["phoneNumber"]: item?.phone_no});

          form.setFieldsValue({["address"]: item?.address});
          
          form.setFieldsValue({["instructorTopic"]: item?.course_category_id});

          form.setFieldsValue({["instructorCourseId"]:item?.course_id})

          form.setFieldsValue({["resumeUpload"]: item?.upload_resume})
          // base64ToPDF(item?.upload_resume,"resume")


        }
    })
  }

  }

  let closeCreateModal = () => {
    setIsInstructorModalVisible(false)
    setInstructorFirstName("");
    setInstructorLastName("");
    setAddress("");
    setEmail("");
    setAge("");
    setPhoneNumber("");
    setInstructorStatus("")

    setIsInstructorModalVisible(false);
    setLoader(false)
    setInstructorFirstName("");
    setInstructorLastName("");
    setAge("");
    setGender("");
    setAddress("");
    setEmail("");
    setPhoneNumber("");
    setInstructorTopic("");
  }


  let handleDeleteModal = (id) => {
    setInstructorId(id)
    setIsInstructorModalVisible(false);
    setIsDeleteModalVisile(true);
    setModalType("Delete Instructor")
  }


  let closeDeleteModal = () => {
    setIsDeleteModalVisile(false)
  }

  useEffect(()=>{
    dispatch(retrieveAdminInstructors())
  },[])

  let onFinish = () => {
        if ( modalType == "Add Instructor") {
            let createPayload = {
              "operation_type": "instructor_enquiry",
              "op_field_details": {
                "instructor_first_name": instructorFirstName,
                "instructor_last_name": instructorLastName,
                "email": email?.toLowerCase(),
                "address": address,
                "gender": gender,
                "phone_no": phoneNumber,
                "tenant_id": window.localStorage.getItem("tenant_Id"),
                "course_category_id": instructorTopic,
                "course_id":instructorCourseId,
                "file":resumeUpload,
                "date_of_birth":studentDOb,
              }
            }
            console.log(createPayload,"createPayload")
      dispatch(createAdminInstructor(createPayload))
      setLoader(true)

    }

      if (modalType == "Edit Instructor") {
        
        let editPayload = {
          "operation_type":"update",
          "op_field_details":{
                "instructor_id": instructorId,
                "instructor_first_name": instructorFirstName,
                "instructor_last_name": instructorLastName,
                "email": email?.toLowerCase(),
                "address": address,
                "age": age,
                "gender": gender,
                "phone_no": phoneNumber,
                "is_active":instructorStatus,
                "tenant_id":window.localStorage.getItem("tenant_Id"),
                "course_category_id": instructorTopic,
                "course_id":instructorCourseId,
                "file":resumeUpload,
                "date_of_birth":studentDOb,

          }
       }


       console.log(editPayload,'editPayload')

       dispatch(updateAdminInstructor(editPayload))
       setAddress("");
       form.setFieldsValue({["address"]:""});
       form.setFieldsValue({["resumeUpload"]:""})

      }
  
  
  }

  let handleCancelForm = () => {
    setIsInstructorModalVisible(false)
    setErrors({});
    setFormValues({instructor_first_name: "",instructor_last_name:"",age:"",gender:"",email:"",course_id:"",address:""})
  }
  
  let handleDeleteOk = ()=>{
    let deletePayload=
    {
        "operation_type": "delete",
        "op_field_details": {
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":[instructorId]
        }
    }
    // dispatch(deleteInstructor(deletePayload))
    dispatch(deleteAdminInstructor(deletePayload))
    setIsDeleteModalVisile(false)
  }
  
  useEffect(()=>{
    
  },[])
const searchStudentByEmail = (e)=>{
  let data = e.target.value;
  setSearchEntry(data)
// if (data == "") {
// setAdminInstructors(adminInstructors)
// }

// if (!data) return; // Handle empty search term
// const filteredArray = adminInstructors.filter(record => 
// record.email.toLowerCase().includes(data.toLowerCase())
// );
// setAdminInstructors(filteredArray); /
}

  const columns = [

    {
      title: 'ID',
      dataIndex: 'instructor_id',
      sorter: true,
          sorter: (a, b) => a.instructor_id - b.instructor_id,
          filteredValue: [searchEntry],
          filteredValue: [searchEntry],
          onFilter: (value,record)=>{
            return String(record.instructor_first_name).toLowerCase().includes(value.toLowerCase())||
                   String(record.instructor_last_name).toLowerCase().includes(value.toLowerCase())||
                   String(record.phone_no).toLowerCase().includes(value.toLowerCase())||
                   String(record.email).toLowerCase().includes(value.toLowerCase())||
                   String(record.gender).toLowerCase().includes(value.toLowerCase())||
                   String(record.age).includes(value)||
                   String(record.instructor_id).includes(value)
                 }

    },
    {
      title: 'Instructor Name',
      dataIndex: 'instructor_first_name',
      sorter: true,
      sorter: (a, b) => a.instructor_first_name.localeCompare(b.instructor_first_name),
      render: (text, record) => (
        <div className="actionIcons">
            <span>{record.instructor_first_name+" "+record.instructor_last_name}</span>
        </div>
       ),
    },
    // {
    //   title: 'Last Name',
    //   dataIndex: 'instructor_last_name',
    //   sorter: (a, b) => a.instructor_last_name .localeCompare(b.instructor_last_name),

    // },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      sorter: (a, b) => a.email .localeCompare(b.email),

    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_no',
      sorter: true,
      sorter: (a, b) => a.phone_no .localeCompare(b.phone_no),

    },
    {
      title: 'Registered Date and Time',
      dataIndex: 'created_date',
      sorter: true,
      render: (text, record) => (
        <div className="actionIcons">
            <span>{(moment(record.created_date, "DD-MM-YYYY HH:mm:ss"))
            ? (moment(record.created_date, "DD-MM-YYYY HH:mm:ss")).format("DD-MM-YYYY hh:mm:ss A") : "" }</span>
        </div>
       ),
       sorter: (a, b) => a.created_date.localeCompare(b.created_date),

    },
    {
      title: 'Age',
      dataIndex: 'calculated_age',
      sorter: true,
      sorter: (a, b) => a.calculated_age - b.calculated_age,  

    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      sorter: true,
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      sorter: (a, b) => a.gender .localeCompare(b.gender),

    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      sorter: true,
      sorter: (a, b) => b.is_active - a.is_active,
      render: (text, record1) => (
        <div className="actionIcons">
          {record1.is_active == 'pending' ? 
              <div className={"statusInactivePending"}><span class="dotInactivepending"></span> Pending</div>
                    
          : record1.is_active == 'false' ? 
          <div className={"statusInactive"}><span class="dotInactive"></span> Inactive</div>
          : record1.is_active == 'inactive' ? 
          <div className={"statusInactive"}><span class="dotInactive"></span> Inactive</div>
                
      :<div className={"statusActive"}><span class="dotActive" ></span> Active</div>}
        </div>
       ),

      // render: (text, record1) => (
      //   <div className="actionIcons">
      //         {record1.is_active == true ? 
      //               <div className={"statusActive"}><span className="dotActive"></span> Active</div>
                          
      //           : <div className={"statusInactive"}><span className="dotInactive"></span> In Active</div>}          </div>
      // ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      // render: (text, record) => (
      //   <div className="actionIcons">
      //     <img src={edit} onClick={() => handleInstructorModal("Edit Instructor",record.instructor_id)} />
      //     <img src={deleteIcon} onClick={() => handleDeleteModal(record.instructor_id)} />
      //   </div>
      // ),

      render: (text, record) => (
        <div className="actionIcons">

        {record.is_active == 'pending' ? 
        <> 
         <img src={TableEyeIcon} onClick={()=> handleViewStudent(record.instructor_id)}/>

        <div className="actionIconsReschedule">
        <PopOverComponent popUp={popUpDataOriginal(record.instructor_id,record.instructor_first_name,record.instructor_last_name,record.email,record.tenant_id)} element = {img} trigger="click"
         visible={popoverVisible}
         onVisibleChange={setPopoverVisible} />
        </div>                          
          </>  :  record.is_active == 'false' ? 
        <> 
         <img src={TableEyeIcon} onClick={()=> handleViewStudent(record.instructor_id)}/>


          </>   :  record.is_active == 'inactive' ? 
        <> 
 <img src={deleteIcon} onClick={()=>handleDeleteModal(record.instructor_id)}/>  
            <img src={TableEyeIcon} onClick={()=> handleViewStudent(record.instructor_id)}/>
            <img src={edit} onClick={() => handleInstructorModal("Edit Instructor",record.instructor_id)} />


          </> 
          
          :  <> 
          
            {/* <img src={edit} onClick={()=>handleEditModal("Edit Student",record.student_id)}/> */}
            <img src={deleteIcon} onClick={()=>handleDeleteModal(record.instructor_id)}/>  
            <img src={TableEyeIcon} onClick={()=> handleViewStudent(record.instructor_id)}/>

            <img src={edit} onClick={() => handleInstructorModal("Edit Instructor",record.instructor_id)} />

          
          </> }
          

            
        </div>
       ),
    }
  ];

  let handleViewStudent = (Id) =>{
    setStudentId(Id);
    adminInstructors.filter((item,index)=>{
      if(item.instructor_id == Id){
        setStudentRecord(item)
      }
    })
    setStudentDetails(true)
    }
 
  const popUpDataOriginal = (student_id,first_name,last_name,email,tenant_id) =>{
    return(
      <div className='popUpContainer'>
                        <NavLink  title='Approve' className={'menutitle'}>

      <p  style={{color:"black"}} onClick={()=>handleApprove(student_id,first_name,last_name,email,tenant_id)} className='approveClick'>Approve</p>
     
      </NavLink>
 <NavLink  title='Reject' className={'menutitle'}>

      <p  style={{color:"black"}} onClick={()=>hanldeReject(student_id,first_name,last_name,email,tenant_id)} className='approveClick'>Reject</p>
      </NavLink>

  </div>
    )
}

const handleApprove = (student_id,first_name,last_name,email,tenant_id) =>{


  
  
  let payload1={
    "operation_type": "instructor_approval_action",
    "action": "approved",
    "op_field_details": [
      {
        "instructor_id": student_id,
        "instructor_first_name": first_name,
        "instructor_last_name": last_name,
        "email": email,
        "tenant_id": window.localStorage.getItem("tenant_Id")
      }
    ]
//    "operation_type": "student_approval_action",
// "action": "approved",
// "op_field_details": [
// {
//  "student_id": student_id,
//  "first_name": first_name,
//  "last_name": last_name,
//  "email":email,
//  "tenant_id": window.localStorage.getItem("tenant_Id")
// }
//]
}
dispatch(instructorApproved(payload1))
setPopoverVisible(false);
 }

 const hanldeReject = (student_id,first_name,last_name,email,tenant_id) =>{

 

 let payload={
  "operation_type": "instructor_approval_action",
  "action": "rejected",
  "instructor_id":[student_id],
  "tenant_id":  window.localStorage.getItem("tenant_Id")
}
dispatch(instructorRejected(payload))
setPopoverVisible(false);

 }

  let deleteModalFooter = [
    <div className="editCourse">
      {/* <div>
          <CheckboxComponent/>
      </div> */}
      <div>
        <Button type={"secondary"} default value={'Cancel'} className={'cancel ant-btn-default'} onClick={closeDeleteModal}>
          Cancel</Button>

        </div>
      <div className="alignRight">
        {/* <div>
        <Button type={"secondary"} default value={'Cancel'} className={'cancel ant-btn-default'} onClick={closeDeleteModal}>
          Cancel</Button>

        </div> */}
        <div>
        <Button type={"primary"}  danger value={'Delete'} className={'submit'} onClick={handleDeleteOk}>Delete</Button>
          
        </div>
     
      </div>
    </div>
]

useEffect(()=>{
  if (instructorCrudStatus) {
    openNotification(instructorCrudStatus?.msg,instructorCrudStatus)
    console.log(instructorCrudStatus,"crudStatus")
    dispatch(instructorCrudStatuss(undefined))
  }
},[instructorCrudStatus])

const openNotification = (msg,instructorCrudStatus) => {
  if (msg  && instructorCrudStatus?.res_status == false) {
    api.info({
      type: 'error',
      description:
        `${msg}`,
    });
  }
  
  if (msg && instructorCrudStatus?.res_status == true) {
        setIsInstructorModalVisible(false);
        setLoader(false)
        setInstructorFirstName("");
        setInstructorLastName("");
        setAge("");
        setGender("");
        setAddress("");
        setEmail("");
        setPhoneNumber("");
        setInstructorTopic("");
        setStudentDob(null)
        api.info({
          description:
          `${msg}`,

        });
      }
      
}

let onChangeBulkActions = (type) =>{

  console.log(type,"anil")
  console.log(selectedRowsKeys,"anil")

  if(selectedRowsKeys.length != 0){
    if (type !=="Delete") {
      let payload ={
        "operation_type":"multi_record_update",
        "op_field_details":{
            "instructor_id":selectedRowsKeys[0],
            "is_active":type == true ? 'true' : 'inactive'
        }
    }
     dispatch(uploadAdminBulkAction(payload))
    // setSelectedDropdown("Select");
    }else{
      let deletePayload = {
        "operation_type": "delete",
        "op_field_details":{
            "instructor_id": selectedRowsKeys[0],
            "tenant_id": window.localStorage.getItem("tenant_Id")
        }
    }
     dispatch(adminInstructorBulkDelete(deletePayload))
    }
  }else{
    openNotification1('Select instructor id')

  }


  
}

const openNotification1 = (status) => {
  // if (status == true) {
  //   api.info({
  //     // message: `Category ${details.course_category_name}`,
  //     description:
  //       'Registered successfully please verify',
  //   });

    if (status) {
      api.info({
          description:
          `${status}`,

        });
  }

  

};

let handleStudentCancel = () =>{
  setStudentDetails(false)

}

let handleDropdownChange = (e,type)=>{

  console.log(e,"body")
  console.log(type,"body")

  if (type.name == "dob") {

    console.log(e,"body")
    console.log(type,"body")

    setStudentDob(e);
    form.setFieldsValue({[type.name]:e})

  }
}



const exportToExcel = (data, fileName) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};


const transformedItems = adminInstructors?.map((item) => {
 

  return { 
     INSTRUCTOR_ID: item.instructor_id,
     INSTRUCTOR_NAME:  item.instructor_first_name,
    EMAIL:  item.email,
    PHONE_NO: item.phone_no,
    CREATED_DATE:  item.created_date,
   AGE:  item.calculated_age,
  

   GENDER:  item.gender,
   STATUS:  item.is_active == 'pending' ? 'pending' :
   item.is_active == 'inactive' ? 'In Active' :
   item.is_active == 'false' ? 'In Active' : 'Active'

  
  };
});

let handleCategoryModal1 = (type,id) =>{
   
  exportToExcel(transformedItems, 'instructor');

  
}
     
  return (
    <> 


      <div className="allStudentsHeader">
       {contextHolder}
            <div><h3 className="allStudents">All Instructors</h3></div>
            {/* <div className="defaultSelect"><InputSelect defaultValue={"Select"}/></div> */}

            <div className="searchCourse">
                <div className="searchC"><InputComponent
                onChange={(e)=>searchStudentByEmail(e)} className='searchField' placeholder={'Search'}/></div>
                <img src={searchIcon} className="searchIconStudents"/>
            </div>
        </div>
      <div className="allCoursesSelections">
        <CourseTypes handleModal={() => handleInstructorModal("Add Instructor",null)}
        handleModal1={()=>handleCategoryModal1("")}
        bulkActions={bulkActions} onChangeBulkActions={onChangeBulkActions} actionType={actionType}
        selectedDropdown={selectedDropdown} handleCourseTypes={handleCourseTypes}
        courseTypeValues={courseTypeValues} 
         />
      </div>


      <div className="instructorsTable">
        <TableComponent columns={columns} data={adminInstructors} sorter={true}
        rowSelection={rowSelection} selectedRowKeys= {selectedRowsKeys} rowKey={"instructor_id"}
        loading={!adminInstructors ? true : false }
         />

      </div>

      {isInstructorModalVisible ?
      
        <ModalComponent title={modalType} content={<AddInstructor modalType={modalType}
        loader={loader}
          formValues={formValues}
           onFinish ={onFinish}
            instructorFirstName={instructorFirstName}
            instructorLastName={instructorLastName}
            gender = {gender}
            age={age}
            instructorTopic={instructorTopic}
            resumeUpload={resumeUpload}
            phoneNumber={phoneNumber}
            email={email}
            address={address}
           categoryDropdown={getAllCategories} form={form}
           handleInputChange={handleInputChange}
           handleDropdowns = {handleDropdowns}
           handleDropdownChange={handleDropdownChange}
           onFileChange={onFileChange}
           resumeData={resumeData}
           closeCreateModal={closeCreateModal}
           instructorStatus={instructorStatus}
           categoryBasedCourses={categoryBasedCourses}
           instructorCourseId={instructorCourseId}
           courseDefaultValue={courseDefaultValue}
           studentDOb={studentDOb}
             />}
          open={isInstructorModalVisible} handleCancel={closeCreateModal}  />
        : " "}

      {isDeleteModalVisible ? <ModalComponent title={"Delete Instructor"} content={"Are you sure you want to delete this instructor?"} open={isDeleteModalVisible}
        handleCancel={closeDeleteModal}  footer={deleteModalFooter}/>
        : " "}

{studentDetails ? 
        <ModalComponent title={"Instructor Details"} content = {<InStructoreDetail studentRecord = {studentRecord}/>}
        open={handleViewStudent} handleCancel={handleStudentCancel}/>
    : ""}
    </>
  )
}

export default AllInstructors;