import React from 'react';
import InputComponent from '../../../commonComponents/InputComponent/input';
import { Button, Form } from 'antd';

const AddBadgesModal = (props) => {

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          if (file) {
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
              resolve(fileReader.result);
            };
          }
         
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };


      let  onFileChange = async(event,type)=>{
        const file = event.target.files[0];
      const base64 = await convertToBase64(file);
      console.log(base64,"ppp")
    
      props.handleClassBadges(base64, { name: "document" })

       }

  return (
    <div className='instructorCancelClassContainer'>
      <div className='cancelClassContainer'>
        <Form
          initialValues={props.addBadgesDetailsPayload}
          form={props.formBadges}
          onFinish={props?.onFinishBadges}
          autoComplete="off"
          layout="vertical"
        >
          <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter name',
                },
              ]}
              style={{ flex: '1' }} // Make the Form.Item take up available space
            >
              <InputComponent
                placeholder={"Badges Name"}
                name={"name"}
                value={props.name}
                onChange={(e) => props.handleClassBadges(e, { name: "name" })}
                style={{ width: '100%' }} // Ensure the input takes full width of the Form.Item
              />
            </Form.Item>
          </div>

          <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,}}>
<Form.Item
 style={{ flex: 1 }}
label="Upload Image"
                     name="document"
                     rules={[
                       {
                         required: true,
                         message: 'Image  is required',
               
                       },
                     ]}>
                   <div className="instructorResumeUpload">
                  <InputComponent type="file" name={"document"}  style={{"display": "none"}}
                  onChange={(e)=>onFileChange(e,{name: "document"})}
                  className="instructorResumeUpload"/>
                  </div>
                  </Form.Item>
</div>

          {/* Button Container */}
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
            <Form.Item>
              <Button htmlType='submit' type='primary' style={{ marginRight: '8px' }}>
                Submit
              </Button>
              <Button htmlType='button' onClick={props.handleCancel}>
                Cancel
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AddBadgesModal;
