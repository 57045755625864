import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Button, Form, Select ,Input,notification,DatePicker,Checkbox,Collapse, List, Typography,Row, Col } from 'antd';

import { excel, plus, searchIcon,back_icon_png,TableEyeIcon
} from "../../../images";

import { TableComponent,CheckboxComponent, 
    InputComponent, InputSelect,
    ModalComponent, ButtonComponent,ConfirmModalComponent } from "../../../commonComponents";

import {getUploadAssignemntDetails} from '../../../redux/coursesContent/action';

import StudUploadAssignmentViewModal from '../viewModal/studUploadAssignmentViewModal';


const StudentUploadAssignmentView = () => {

    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [api, contextHolder] = notification.useNotification();
    const [searchEntry,setSearchEntry] = useState("");
    const [viewReferenceVisible, setViewSReferenceVisible] = useState(false);
    const [assignmentUploadData, setAssignmentUploadData] = useState("");
    const [assignmentStatus, setAssignmentStatus] = useState("");



    const { coursesName, selectedCourseId, selectedBatchId,selectedContentId } = useParams();
    const [data, setData] = useState([]);

    let getUploadAssignment = useSelector((state)=> state?.CoursesContentReducer?.getUploadAssignment);

    useEffect(()=>{
        if (getUploadAssignment) {
    
            setData(getUploadAssignment)
        }
    },[getUploadAssignment])

    
    useEffect(()=>{

        let payload ={
            "operation_type": "retrieve_stud_assignment_for_inst",
            "op_field_details": {
                "content_id": selectedContentId,
                "batch_id":selectedBatchId,
                "course_id":selectedCourseId,
                "tenant_id":window.localStorage.getItem("tenant_Id")
            }
        }
    dispatch(getUploadAssignemntDetails(payload))
  
    },[])

    const backMethod = () => {
        navigate(`/courseContentDetails/${coursesName}/${selectedCourseId}/${selectedBatchId}`);

                };

                const columns = [

                    {
                      title: 'Content Id',
                      dataIndex: 'content_id',
                      sorter: true,
                      sorter: (a, b) => a.content_id - b.content_id,
                
                    },
                
                      {
                        title: 'Student Id',
                        dataIndex: 'student_id',
                        sorter: true,
                        sorter: (a, b) => a.student_id .localeCompare(b.student_id),
                
                      },
                     
                        {
                          title: 'Student Name',
                          dataIndex: 'student_name',
                          sorter: true,
                          sorter: (a, b) => a.student_name .localeCompare(b.student_name),
                
                        },
                      {
                        title: 'Title',
                        dataIndex: 'title',
                        sorter: true,
                        sorter: (a, b) => a.title .localeCompare(b.title),
                
                      },
                    
            
                   
            
                      {
                        title: 'Description',
                        dataIndex: 'assignment_description',
                        sorter: true,
                        sorter: (a, b) => a.assignment_description .localeCompare(b.assignment_description),
                
                      },

                      {
                        title: 'XP',
                        dataIndex: 'xp',
                        sorter: true,
                        sorter: (a, b) => a.xp .localeCompare(b.xp),
                
                      },


                      {
                        title: 'Status',
                        dataIndex: 'is_active',
                        sorter: true,
            
            
                        
                        // sorter: (a, b,c) => c.is_active - b.is_active - a.is_active,
            
                        sorter: (a, b) => {
            
                    
                          const statusOrder = {
                            'Approved': 1,
                            'Rejected': 2,
                            'Pending': 3
                          };
                      
                          // Retrieve numerical values for comparison
                          const statusA = statusOrder[a.is_active];
                          const statusB = statusOrder[b.is_active];
                      
                          // Compare status values
                          return   statusB - statusA;
                        },
            
                        
            
                        render: (text, record1) => (
                          <div className="actionIcons">
                            {record1.status == 'pending' ? 
                                <div className={"statusInactivePending"}><span class="dotInactivepending"></span> Pending</div>
                                      
                            : record1.status == 'rejected' ? 
                            <div className={"statusInactive"}><span class="dotInactive"></span> Rejected</div>
            
                            : record1.status == 'rejected' ? 
                            <div className={"statusInactive"}><span class="dotInactive"></span> Rejected</div>
                                  
                        :<div className={"statusActive"}><span class="dotActive" ></span> Approved</div>}
                          </div>
                         ),
                      },
            
                      {
                        title:'Action',
                        dataIndex: 'action',
                        render: (text, record) => (
                          <div className="actionIcons">
                              <img src={TableEyeIcon} onClick={() => handleViewModal(record.document,record.student_id)} style={{marginRight:"5px"}}/>
            
                          </div>
                         ),
                      }
                    ];


                    let handleViewModal = async(type,status) =>{
                        setAssignmentUploadData(type)
                        
                        setAssignmentStatus(status)
                        setViewSReferenceVisible(true)
                      }

                      let handleDeleteCancel= ()=>{

                        setViewSReferenceVisible(false)
                        let payload ={
                          "operation_type": "retrieve_stud_assignment_for_inst",
                          "op_field_details": {
                              "content_id": selectedContentId,
                              "batch_id":selectedBatchId,
                              "course_id":selectedCourseId,
                              "tenant_id":window.localStorage.getItem("tenant_Id")
                          }
                      }
                  dispatch(getUploadAssignemntDetails(payload))
                    
                      } 
                  
    return (
        <div>

  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
  <h3 style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
    <img 
      src={back_icon_png} 
      alt="Back" 
      style={{ marginRight: '8px', cursor: 'pointer', height: '25px', width: '25px' }} 
      onClick={() => backMethod()} 
    />
    View Assignment
  </h3>

 
</div>


<h3>Student Submissions</h3>

<TableComponent columns={columns} data={data}/>




{viewReferenceVisible ? 
  <ModalComponent title={"View Assignment"}

  content={<StudUploadAssignmentViewModal 
   handleCancel={handleDeleteCancel}
   assignmentUploadData={assignmentUploadData}
   assignmentStatus={assignmentStatus}
   selectedContentId={selectedContentId}
   />}
open={viewReferenceVisible} 
handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>
:""}


        </div>
    );
}

export default StudentUploadAssignmentView;
