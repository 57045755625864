import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {getStudentCourseDetailsList} from '../../redux/studentCourseContent/action'
import { TableComponent,CheckboxComponent, 
    InputComponent, InputSelect,
    ModalComponent, ButtonComponent,ConfirmModalComponent } from "../../commonComponents";
import { course,edit,excel,plus,upload,TableEyeIcon,searchIcon,add_icon,back,deleteIcon } from "../../images";
import { Col, Form, Button,notification,DatePicker ,Select} from 'antd';
import CoursesManagement from '../../components/coursesContent/tabController/contentManagement.js'
import { Tabs } from 'antd';

const { TabPane } = Tabs;

let StudentCoursesContent = () =>{



  let dispatch = useDispatch();
  let navigate = useNavigate();


    const [coursesName, setCoursesName] = useState('');
    const [selectedCourseId, setSelectedCoursesId] = useState('');
    const [selectedBatchId, setSelectedBatchId] = useState('');



    let [allCoursesDetailsList,setAllCoursesDetailsList] = useState();
    const [searchEntry,setSearchEntry] = useState("");
    const [isVisible, setIsVisible] = useState(true);




    let allActiveCourses = useSelector((state)=> state.StudentCoursReducer?.getStudentCours);

    useEffect(()=>{
        if (allActiveCourses) {
            setAllCoursesDetailsList(allActiveCourses)
        }
    },[allActiveCourses])

    useEffect(()=>{

        let payload = 
        {
            "operation_type":"retrieve_course_based_on_stud",
            "tenant_id": window.localStorage.getItem("tenant_Id"),
            "student_id": window.localStorage.getItem("student_id"),
        }

        dispatch(getStudentCourseDetailsList(payload))
    },[])


  const columns = [

    {
      title: 'ID',
      dataIndex: 'course_id',
      sorter: true,
      sorter: (a, b) => a.course_id - b.course_id,

    },

      {
        title: 'Name',
        dataIndex: 'course_name',
        sorter: true,
        sorter: (a, b) => a.course_name .localeCompare(b.course_name),

      },
     
        {
          title: 'Language',
          dataIndex: 'course_language',
          sorter: true,
          sorter: (a, b) => a.course_language .localeCompare(b.course_language),

          filteredValue: [searchEntry],
          onFilter: (value,record)=>{
             return String(record.course_language).toLowerCase().includes(value.toLowerCase())||
                    String(record.course_level).toLowerCase().includes(value.toLowerCase())||
                    String(record.course_duration).toLowerCase().includes(value.toLowerCase())||
                    String(record.course_name).toLowerCase().includes(value.toLowerCase())||
                    String(record.course_category).toLowerCase().includes(value.toLowerCase())||
                    String(record.course_id).toLowerCase().includes(value.toLowerCase())
                  }
        },
      {
        title: 'Level',
        dataIndex: 'course_level',
        sorter: true,
        sorter: (a, b) => a.course_level .localeCompare(b.course_level),

      },
      {
        title: 'Duration',
        dataIndex: 'course_duration',
        sorter: true,


      sorter: (a, b) => {
        // Extract numeric values from the duration strings
        const durationA = parseInt(a.course_duration);
        const durationB = parseInt(b.course_duration);
        
        // If durations are NaN (not a number), return 0 to maintain the original order
        if (isNaN(durationA) || isNaN(durationB)) {
            return 0;
        }
        
        return durationA - durationB;
    }


      },

      {
        title: 'Course Category',
        dataIndex: 'course_category',
        sorter: true,
        sorter: (a, b) => a.course_category .localeCompare(b.course_category),

      },

      {
        title:'Action',
        dataIndex: 'action',
        render: (text, record) => (
          <div className="actionIcons">
              {/* <img src={edit} onClick={() => handleEditModal("Edit Course",record.course_id)} style={{marginRight:"5px"}}/> */}
              <img src={TableEyeIcon} onClick={() => handleViewModal("View Course",record.course_name,record.course_id,record.batch_id)} style={{marginRight:"5px"}}/>
              {/* <img src={add_icon} onClick={() => handleAddModal("Add Course",record.course_id)} /> */}

              {/* <img src={deleteIcon} onClick={()=> handleDeleteModal(record.course_id)}/> */}
          </div>
         ),
      }
    ];

    
    const searchCourseByName= (e) =>{
      setSearchEntry(e?.target?.value)

  }




    let handleViewModal = async(type,name,id,batchId) =>{
      
      navigate(`/studCourseContentDetails/${name}/${id}/${batchId}`);


    }

  

    const backMethod = () => {
      setIsVisible(true);
    };




    return (



      <div>



<div>
      

      <div className="allCourses">
          <h3>Course</h3>
          <div className="searchCourse">
              <div className="searchC"><InputComponent className='searchField'
               placeholder={'Search'} onChange={(e)=>searchCourseByName(e)}/></div>
              <img src={searchIcon} className="searchIcon" style={{marginBottom:'45px',marginLeft:"10px"}}/>
          </div>
      </div>


      <TableComponent columns={columns} data={allCoursesDetailsList}/>

  </div>

  




      </div>

    )
}

export default StudentCoursesContent