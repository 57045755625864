import {GET_STUDENT_COURSE
} from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';



export const getStudentCouresDetails = (data)=>{
    return{
        type: GET_STUDENT_COURSE,
        payload: data?.data
    }
}

export const getStudentCourseDetailsList = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/batch",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getStudentCouresDetails(res.data));
            }
            if (res.data?.res_status == false) {
                dispatch(getStudentCouresDetails([]))
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


