import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Button, Form, Select ,Input,notification,DatePicker,Checkbox,Collapse, List, Typography,Row, Col } from 'antd';

import { back_icon_png,expand ,collapse} from "../../../images"; 

import {getUploadQuestionAndAnswerDetails} from '../../../redux/coursesContent/action'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Table } from 'antd';



const StudentUploadQuestionView = () => {

    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const { coursesName, selectedCourseId, selectedBatchId,selectedContentId } = useParams();

    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const [data, setData] = useState([]);


    let getUploadQuestionAnswer = useSelector((state)=> state?.CoursesContentReducer?.getUploadQuestionAnswer);

    useEffect(()=>{
        if (getUploadQuestionAnswer) {
    
            setData(getUploadQuestionAnswer)
        }
    },[getUploadQuestionAnswer])

    
    useEffect(()=>{

        let payload ={
            "operation_type": "retrieve_stud_quiz_resp_for_inst",
            "op_field_details": {
                "content_id": selectedContentId,
                "batch_id":selectedBatchId,
                "course_id":selectedCourseId,
                "tenant_id":window.localStorage.getItem("tenant_Id")
            }
        }
    dispatch(getUploadQuestionAndAnswerDetails(payload))
  
    },[])


    const backMethod = () => {
        navigate(`/courseContentDetails/${coursesName}/${selectedCourseId}/${selectedBatchId}`);

                };





                  const columns1 = [
                    {
                      title: 'Content Id ',
                      dataIndex: 'content_id',
                      key: 'content_id',
                      sorter: true,
                      sorter: (a, b) => a.content_id - b.content_id,
                
                    },
                    {
                      title: 'Student Id',
                      dataIndex: 'student_id',
                      key: 'student_id',
                      sorter: true,
                      sorter: (a, b) => a.student_id .localeCompare(b.student_id),
                
                
                    },
                    {
                      title: 'Student Name',
                      dataIndex: 'student_name',
                      key: 'student_name',
                      sorter: true,
                      sorter: (a, b) => a.student_name - (b.student_name),
                
                    },

                    {
                        title: 'Attempt',
                        dataIndex: 'attempt_number',
                        key: 'attempt_number',
                        sorter: true,
                        sorter: (a, b) => a.attempt_number - (b.attempt_number),
                  
                      },
                      {
                        title: 'Persentage',
                        dataIndex: 'percentage',
                        key: 'percentage',
                        sorter: true,
                        sorter: (a, b) => a.percentage - (b.percentage),
                  
                      },
                    
                      {
                        title: 'Status',
                        dataIndex: 'pass_status',
                        key: 'pass_status',
                        sorter: true,
                        sorter: (a, b) => a.pass_status - (b.pass_status),
                  
                      },

                      {
                        title: 'XP',
                        dataIndex: 'xp',
                        key: 'xp',
                        sorter: true,
                        sorter: (a, b) => a.xp - (b.xp),
                  
                      },
                  ];

                  const expandedRowRender = (rowData,expanded) => {  
                    return (
                    
                    <div>
                    <DataTable value={rowData.questions} responsiveLayout="scroll">
                        <Column field="question_id" header="Question ID" />
                        <Column field="question_text" header="Question" />
                        <Column
                            header="Options"
                            body={(row) =>
                                row.options.map((option, index) => (
                                    <div key={index}>
                                        <span>{`${index + 1}. ${option.option_text}`}</span> 
                                        {option.is_correct && <strong>(Correct)</strong>}
                                    </div>
                                ))
                            }
                        />
                    </DataTable>
                </div>
                    );
                  };     
                  
                  
                  let handleExpand = (expanded, record) => {
                    if (expanded) {
                      setExpandedRowKeys([])
                      setExpandedRowKeys([record.content_id]);
                    //   setBatchId(record.batch_id)
                    } else {
                      setExpandedRowKeys(expandedRowKeys.filter(key => key !== record.content_id));
                    }
                  };



    return (
        <div>

  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
  <h3 style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
    <img 
      src={back_icon_png} 
      alt="Back" 
      style={{ marginRight: '8px', cursor: 'pointer', height: '25px', width: '25px' }} 
      onClick={() => backMethod()} 
    />
    View Q&A
  </h3>

 
</div>


<h3>Student Submissions</h3>
<Table
        dataSource={data}
        columns={columns1}
        pageSize={10}
        expandable={{expandedRowRender,expandedRowKeys, expandIcon: ({ expanded, onExpand, record }) =>
        expanded ? (
         
          <img src={expand} style={{ fontSize: '20px' }} className='instructorBatchesExpand'
          onClick={(e) => onExpand(record, e)}/>
        ) : (
          <img src={collapse} style={{ fontSize: '20px' }} className='instructorBatchesExpand'
          onClick={(e) => onExpand(record, e)}/>
        ),}}
        rowKey= {'content_id'}
        onExpand={handleExpand}
        size="small"
      />




        </div>
    );
}

export default StudentUploadQuestionView;
