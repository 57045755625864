
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { course, edit, excel, plus, upload, TableEyeIcon, searchIcon, add_icon, back, deleteIcon
  ,gold_image,silver_image,bronze_image
 } from "../../../images/index.js";import { Button, Form, Select ,Input,notification,DatePicker,Checkbox,Collapse, List, Typography,Row, Col } from 'antd';
import InputComponent from '../../../commonComponents/InputComponent/input.js';
import {addCoursesContentReferenceDetails,getCoursesContentDetails,updateCoursesContentReferenceDetails
} from '../../../redux/coursesContent/action';
import {ModalComponent,TableComponent} from "../../../commonComponents/index.js";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {createBadgesDetails,deleteBadgesDetails,getBadgesDetails,
  createCertificateDetails,deleteCertificateDetails,getCertificateDetails
} from '../../../redux/badges/action.js';

import AddCertificateModal from '../courseModel/addCertificateModal.js';
import CertificateViewModal from '../viewModal/certificateView.js';import AddBadgesModal from '../courseModel/addBadgesModal.js';


let UpdateCoursesContentReference = ({handleCancel, coursesContentData,coursesName, selectedCourseId, selectedBatchId ,mainContentId}) =>{

  

    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [api, contextHolder] = notification.useNotification();

    const [formReference] = Form.useForm();
    const [formReferenceTable] = Form.useForm();
    const [formBadges] = Form.useForm();

    const [formCertificate] = Form.useForm();

    const [viewCertificateName,setViewCertificateName] = useState("");
    const [viewCertificateTemplate, VsetiewCertificateTemplate] = useState(false);
    const [viewCertificateType,setViewCertificateType] = useState(false);
    const [viewCertificateSignature,setViewCertificateSignature] = useState(false);
    const [addCertificateVisible, setAddCertificateVisible] = useState(false);
    const [selectedCertificate, setSelectedCertificate] = useState(coursesContentData[0].certificate_image 
      === null ? null : coursesContentData[0].certificate_id );


      const [selectedId, setSelectedId] = useState(coursesContentData[0].badge_name 
        === null ? null : coursesContentData[0].badge_id
      );    const [certificateList, setCertificateList] = useState([]);
    const [viewCertificate,setViewCertificate] = useState(false);
    const [deleteCertificateVisible,setDeleteCertificateVisible] = useState(false);

    let[addCertificateDetailsPayload,setAddCertificateDetailsPayload] = useState({
      "certificate_name": "",
      "certificate_template": "",
      "signature": "",
      "signature_template": "",
      "batch_id":selectedBatchId,
      "instructor_id":window.localStorage.getItem("instructor_id"),
      "course_id":selectedCourseId,
      "tenant_id": window.localStorage.getItem("tenant_Id"),
   
      })

    const [visibleNotification, setVisibleNotification] = useState(false);
    const [addMultipleRecord,setAddMultipleRecord] = useState(null);
    let[allCoursesContentReferenceList,setAllCoursesContentReferenceList] = useState([])
    const [preferenceTypeVisible, setPreferenceTypeVisible] = useState(coursesContentData[0].reference_type === 'File' ? '1' : '2');
    const [getPreferenceDocument, setGetPreferenceDocument] = useState("");
    const [getPreferenceDocumentDaseData, setGetPreferenceDocumentBaseData] = useState("");

    const [badgesList, setBadgesList] = useState([]);
    const [addBadgesVisible, setAddBadgesVisible] = useState(false);
    const [deleteContentVisible,setDeleteContentVisible] = useState(false);
    const [selectedContentId,setSelectedCoursesContentId] = useState("");

    useEffect(()=>{
      formReference.setFieldsValue({['title']:coursesContentData[0].title})
      formReference.setFieldsValue({['description']:coursesContentData[0].description})
      formReference.setFieldsValue({['xp']:coursesContentData[0].xp || ''})
      formReference.setFieldsValue({['reference_type']:coursesContentData[0].reference_type === 'File' ? '1' : '2'})
      formReference.setFieldsValue({['reference_document']:coursesContentData[0].reference_document || ''})

    
  },[coursesContentData])

    let[addBadgesDetailsPayload,setAddBadgesDetailsPayload] = useState({
      "name": "",
      "document": "",
      "batch_id":selectedBatchId,
      "instructor_id":window.localStorage.getItem("instructor_id"),
      "course_id":selectedCourseId,
      "tenant_id": window.localStorage.getItem("tenant_Id")
      })

    let addCoursesContentReference = useSelector((state)=> state?.CoursesContentReducer?.addCoursesContentReference);
    let updateCourseContentReference = useSelector((state)=> state?.CoursesContentReducer?.updateCourseContentReference);


    let crateBadges = useSelector((state)=> state?.badgesReducer?.crateBadges);
    let getBadges = useSelector((state)=> state?.badgesReducer?.getBadges);
    let deleteBadges = useSelector((state)=> state?.badgesReducer?.deleteBadges);

    let crateCertificate = useSelector((state)=> state?.badgesReducer?.crateCertificate);
    let getCertificate = useSelector((state)=> state?.badgesReducer?.getCertificate);
    let deleteCertificate = useSelector((state)=> state?.badgesReducer?.deleteCertificate);

    useEffect(()=>{    
      if (updateCourseContentReference) {
  if(visibleNotification == true){
    openNotification(updateCourseContentReference?.msg)
  
    if(updateCourseContentReference?.res_status === true){


      formReference.resetFields();

      formReference.setFieldsValue({['title']:''})
      formReference.setFieldsValue({['description']:''})
      formReference.setFieldsValue({['reference_type']:null})
      formReference.setFieldsValue({['reference_document']:''})

      formReference.setFieldsValue({['xp']:''})


      setAllCoursesContentReference({
        "category": "Reference",
    "title": "",
    "description": "",
    "reference_type": null,
    "reference_document": "",
    "xp": null,
    "batch_id":selectedBatchId,
    "instructor_id":window.localStorage.getItem("instructor_id"),
    "course_id":selectedCourseId,
    "tenant_id": window.localStorage.getItem("tenant_Id")
    
      })

  


    


    let payload ={
      "operation_type":"retrieve_all_content",
          "course_id":selectedCourseId,
          "tenant_id":window.localStorage.getItem("tenant_Id"),
          "instructor_id":window.localStorage.getItem("instructor_id"),
          "batch_id":selectedBatchId

  }
dispatch(getCoursesContentDetails(payload))
setAddMultipleRecord(null)

  
    }
  
  }else{
  
  }
  
      }
  },[updateCourseContentReference])

    useEffect(()=>{    
      if (deleteCertificate) {
  if(visibleNotification == true){
    openNotificationBadgeAndCertificate(deleteCertificate?.msg)
  
    if(deleteCertificate?.res_status === true){

    

      let payload ={
        "operation_type":"retrieve",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCertificateDetails(payload))
  setSelectedCertificate(null)

  
    }
  
  }else{
  
  }
  
      }
  },[deleteCertificate])

    useEffect(()=>{    
      if (getCertificate) {

        setCertificateList(getCertificate)
  
      }
  },[getCertificate])
  

    useEffect(()=>{    
      if (crateCertificate) {
  if(visibleNotification == true){
    openNotificationBadgeAndCertificate(crateCertificate?.msg)
  
    if(crateCertificate?.res_status === true){

      formCertificate.resetFields();

      formCertificate.setFieldsValue({['certificate_name']:''})
      formCertificate.setFieldsValue({['certificate_template']:''})

      formCertificate.setFieldsValue({['signature']:''})
      formCertificate.setFieldsValue({['signature_template']:''})


      setAddCertificateDetailsPayload({
        "certificate_name": "",
        "certificate_template": "",
        "signature": "",
        "signature_template": "",
      })

      let payload ={
        "operation_type":"retrieve",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCertificateDetails(payload))
  setSelectedCertificate(null)

  
    }
  
  }else{
  
  }
  
      }
  },[crateCertificate])

    useEffect(()=>{

      let payload ={
        "operation_type":"retrieve",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getCertificateDetails(payload))

  },[])

    useEffect(()=>{    
      if (deleteBadges) {
  if(visibleNotification == true){
    openNotificationBadgeAndCertificate(deleteBadges?.msg)
  
    if(deleteBadges?.res_status === true){

      setAddBadgesVisible(false)
      formBadges.resetFields();

      formBadges.setFieldsValue({['name']:''})
      formBadges.setFieldsValue({['document']:''})


      setAddBadgesDetailsPayload({
        "name":"",
        "document":""
      })



 
  
      
      formBadges.setFieldsValue({['name']:''})
      formBadges.setFieldsValue({['document']:''})

    
      setAddBadgesDetailsPayload({
        "name":"",
        "document":"",
      })
  


    let payload ={
      "operation_type":"retrieve_badges_detail",
          "course_id":selectedCourseId,
          "tenant_id":window.localStorage.getItem("tenant_Id"),
          "instructor_id":window.localStorage.getItem("instructor_id"),
          "batch_id":selectedBatchId

  }
dispatch(getBadgesDetails(payload))
setAddMultipleRecord(null)

  
    }
  
  }else{
  
  }
  
      }
  },[deleteBadges])

    useEffect(()=>{    
      if (getBadges) {

        console.log(getBadges)
        setBadgesList(getBadges)
  
      }
  },[getBadges])


    useEffect(()=>{

      let payload ={
        "operation_type":"retrieve_badges_detail",
            "course_id":selectedCourseId,
            "tenant_id":window.localStorage.getItem("tenant_Id"),
            "instructor_id":window.localStorage.getItem("instructor_id"),
            "batch_id":selectedBatchId

    }
  dispatch(getBadgesDetails(payload))

  },[])

    useEffect(()=>{    
      if (crateBadges) {
  if(visibleNotification == true){
    openNotificationBadgeAndCertificate(crateBadges?.msg)
  
    if(crateBadges?.res_status === true){

      setAddBadgesVisible(false)
      formBadges.resetFields();

      formBadges.setFieldsValue({['name']:''})
      formBadges.setFieldsValue({['document']:''})


      setAddBadgesDetailsPayload({
        "name":"",
        "document":""
      })



 
  
      
      formBadges.setFieldsValue({['name']:''})
      formBadges.setFieldsValue({['document']:''})

    
      setAddBadgesDetailsPayload({
        "name":"",
        "document":"",
      })
  


    let payload ={
      "operation_type":"retrieve_badges_detail",
          "course_id":selectedCourseId,
          "tenant_id":window.localStorage.getItem("tenant_Id"),
          "instructor_id":window.localStorage.getItem("instructor_id"),
          "batch_id":selectedBatchId

  }
dispatch(getBadgesDetails(payload))
setAddMultipleRecord(null)

  
    }
  
  }else{
  
  }
  
      }
  },[crateBadges])

    useEffect(()=>{    
      if (addCoursesContentReference) {
  if(visibleNotification == true){
    openNotification(addCoursesContentReference?.msg)
  
    if(addCoursesContentReference?.res_status === true){

    if(addMultipleRecord === 1){

      formReference.resetFields();

      formReference.setFieldsValue({['title']:''})
      formReference.setFieldsValue({['description']:''})
      formReference.setFieldsValue({['reference_type']:null})
      formReference.setFieldsValue({['reference_document']:''})

      formReference.setFieldsValue({['xp']:''})


      setAllCoursesContentReference({
        "category": "Reference",
    "title": "",
    "description": "",
    "reference_type": null,
    "reference_document": "",
    "xp": null,
    "batch_id":selectedBatchId,
    "instructor_id":window.localStorage.getItem("instructor_id"),
    "course_id":selectedCourseId,
    "tenant_id": window.localStorage.getItem("tenant_Id")
    
      })

      navigate(`/courseContentDetails/${coursesName}/${selectedCourseId}/${selectedBatchId}`);
      setAllCoursesContentReferenceList([]);


    }else{

  
      
      formReference.setFieldsValue({['title']:''})
      formReference.setFieldsValue({['description']:''})
      formReference.setFieldsValue({['reference_type']:null})
      formReference.setFieldsValue({['reference_document']:''})

      formReference.setFieldsValue({['xp']:''})
    
      setAllCoursesContentReference({
        "category": "Reference",
    "title": "",
    "description": "",
    "reference_type": null,
    "reference_document": "",
    "xp": null,
    "batch_id":selectedBatchId,
    "instructor_id":window.localStorage.getItem("instructor_id"),
    "course_id":selectedCourseId,
    "tenant_id": window.localStorage.getItem("tenant_Id")
    
      })
    }

    setAllCoursesContentReferenceList([]);

    let payload ={
      "operation_type":"retrieve_all_content",
          "course_id":selectedCourseId,
          "tenant_id":window.localStorage.getItem("tenant_Id"),
          "instructor_id":window.localStorage.getItem("instructor_id"),
          "batch_id":selectedBatchId

  }
dispatch(getCoursesContentDetails(payload))
setAddMultipleRecord(null)

  
    }
  
  }else{
  
  }
  
      }
  },[addCoursesContentReference])

    const referenceType = [
        { value: '1', label: 'File', description: 'Description for Tag 1' },
        { value: '2', label: 'Link', description: 'Description for Tag 2' },
      ];

    let[allCoursesContentReference,setAllCoursesContentReference] = useState({
        "category": "Reference",
        "title": coursesContentData[0].title,
        "description": coursesContentData[0].description,
        "reference_type": coursesContentData[0].reference_type,
        "reference_document": coursesContentData[0].reference_document,
        "xp": coursesContentData[0].xp || '',
        "badge_id":coursesContentData[0].badge_id || null,
        "certificate_id": coursesContentData[0].certificate_id || null,
        "batch_id":selectedBatchId,
        "instructor_id":window.localStorage.getItem("instructor_id"),
        "course_id":selectedCourseId,
        "tenant_id": window.localStorage.getItem("tenant_Id")
        })

        const openNotification = (msg) => {
  
            api.info({
              description:
              `${msg}`,
          
            });
          
            setVisibleNotification(false)

            setTimeout(() => {
              handleCancel()
            }, 1000);
          
          };

          const openNotificationBadgeAndCertificate = (msg) => {
  
            api.info({
              description:
              `${msg}`,
          
            });
          
            setVisibleNotification(false)

         
          
          };

    const addContentReference = (e,type) => {

        if (!type || !type.name) {
          console.error("Type or type.name is undefined:", type);
          return; // Exit early to avoid the error
        }
      
        let value;
      
        if (e && e.target) {
          value = e.target.value; // For event objects
        } else {
          value = e; // For direct values
        }
      
      
      

      
        if (["category", "title", "description", "reference_type", "reference_document", "xp", "batch_id", "instructor_id", "course_id", "tenant_id","badge_id","certificate_id"].includes(type.name)) {
          setAllCoursesContentReference(prevDetails => ({
            ...prevDetails,
            [type.name]:    (type.name === "xp" || type.name === "badge_id" || type.name === "certificate_id") 
            ? (!value || String(value).trim() === "" ? null : parseInt(value, 10)) // Convert to integer if "xp" or "badge_id"
            : value
          }));
        }

      
        
       
      };

      let handleDeleteCancel= ()=>{
        setDeleteContentVisible(false)
        setAddBadgesVisible(false)
        setViewCertificate(false)
        setAddCertificateVisible(false)

     

      
      } 

      let onFinishReferenceAdd= (values) =>{

        let payload = {
          "operation_type":"update",
          "op_field_details":{

            "category":"Reference",
            "title": allCoursesContentReference.title,
            "description":allCoursesContentReference.description,
            "reference_type":allCoursesContentReference.reference_type,
            "reference_document":allCoursesContentReference.reference_document,
              "xp":allCoursesContentReference.xp === '' ? null :parseInt(allCoursesContentReference.xp, 10),
              "batch_id":selectedBatchId,
              "instructor_id":window.localStorage.getItem("instructor_id"),
              "course_id":selectedCourseId,
              "tenant_id":window.localStorage.getItem("tenant_Id"),
              "badge_id":selectedId,
              "certificate_id":selectedCertificate,
              "content_id":mainContentId
  
          }
      }
        
        
          dispatch(updateCoursesContentReferenceDetails(payload))
      
          setVisibleNotification(true)

      }

      let onFinishReferenceAddTable= (values) =>{
      }


      const overalAddReference = () => {
        
       

        const {
          category,
          title,
          description,

          reference_type ,
          reference_document
        } = allCoursesContentReference;
      
        // Check if all required fields are filled
        if (
          category &&
          title &&
          description &&
          reference_type &&
          reference_document 
        ) {
          // If all data is present, proceed to insert
          const updatedList = [...allCoursesContentReferenceList, allCoursesContentReference];
          setAllCoursesContentReferenceList(updatedList);


          formReference.resetFields();

          formReference.setFieldsValue({['title']:''})
          formReference.setFieldsValue({['description']:''})
          formReference.setFieldsValue({['reference_type']:null})
          formReference.setFieldsValue({['reference_document']:''})
          formReference.setFieldsValue({['xp']:null})

          setPreferenceTypeVisible(null)
          // Reset the input fields for a new reference entry
          setAllCoursesContentReference({
            category: "Reference",
            title: "",
            description: "",
            reference_type: "",
            reference_document: "",
            xp: null,
            batch_id:selectedBatchId,
            instructor_id:window.localStorage.getItem("instructor_id"),
            course_id:selectedCourseId,
            tenant_id: window.localStorage.getItem("tenant_Id")
          });
        } else {
          // Handle missing data (e.g., show an error message)
          console.error("Please fill in all required fields.");
        }






      };

      const columns = [

        {
          title: 'TITLE',
          dataIndex: 'title',
          sorter: true,
          sorter: (a, b) => a.title - b.title,
          width: 100,
    
        },
    
          {
            title: 'DESCRIPTION',
            dataIndex: 'description',
            sorter: true,
            sorter: (a, b) => a.description .localeCompare(b.description),
            width: 100,
          },
         
            {
              title: 'Type',
              dataIndex: 'reference_type',
              sorter: true,
              sorter: (a, b) => a.reference_type .localeCompare(b.reference_type),
              width: 100,
            },
          {
            title: 'DOCUMENT',
            dataIndex: 'reference_document',
            sorter: true,
            sorter: (a, b) => a.reference_document .localeCompare(b.reference_document),
            width: 100,
          },

          {
            title: 'XP',
            dataIndex: 'xp',
            sorter: true,
            sorter: (a, b) => a.xp .localeCompare(b.xp),
            width: 100,
          },


          {
            title:'Action',
            dataIndex: 'action',
            render: (text, record,index) => (
              <div className="actionIcons">
                
                  <img src={deleteIcon} onClick={()=> handleCourseContentReferenceDeleteModal(index)}/>
              </div>
             ),
             width: 100,
          }
        ];

        const handleCourseContentReferenceDeleteModal = (index) => {
            // Delete the link at the specified index
            const updatedList = allCoursesContentReferenceList.filter((item, i) => i !== index);
            setAllCoursesContentReferenceList(updatedList);
  
         
  
          };

        let handleAllPreferenceTypeChange = (value, type) => {
            if (value) {
              console.log('Selected title:', value);
              setPreferenceTypeVisible(value);
              // props.handleClassSingleReferenceChange(value, { name: "reference_type" })
  
              if(value == '1'){
                addContentReference('File', { name: "reference_type" })
              }else if(value == '2'){
                addContentReference('Link', { name: "reference_type" })
              }
  
             
  
              // "category", "title", "description", "reference_type","reference_document","xp"
  
  
              // props.handleClassChange(value, { name: "tag_id" })
            } else {
              console.log('Selection cleared');
              setPreferenceTypeVisible(null);
            }
          };

        const convertToBase64 = (file) => {
            return new Promise((resolve, reject) => {
              const fileReader = new FileReader();
              if (file) {
                fileReader.readAsDataURL(file);
                fileReader.onload = () => {
                  resolve(fileReader.result);
                };
              }
             
              fileReader.onerror = (error) => {
                reject(error);
              };
            });
          };
  
  
          let  onFileChange = async(event,type)=>{
            const file = event.target.files[0];
          const base64 = await convertToBase64(file);
          console.log(base64,"ppp")
          setGetPreferenceDocumentBaseData(base64)
          setGetPreferenceDocument(file.name)
  
  
          addContentReference(base64, { name: "reference_document" })
         
           }

        const handleClassSingleReferenceChange = (e, type) => {
          
          if(allCoursesContentReferenceList.length === 0 ){

          }else{
            setAddMultipleRecord(1)



            let payload ={
              "operation_type": "insert",
              "op_field_details": allCoursesContentReferenceList
               
          }

          dispatch(addCoursesContentReferenceDetails(payload))
          setVisibleNotification(true)
          }
          

        

          
          };
      
          const handleClassMultipleReference = (e, type) => {

            if(allCoursesContentReferenceList.length === 0 ){

            }else{
              setAddMultipleRecord(2)
  
  
  
              let payload ={
                "operation_type": "insert",
                "op_field_details": allCoursesContentReferenceList
                 
            }
  
            dispatch(addCoursesContentReferenceDetails(payload))
            setVisibleNotification(true)
            }
          
          };


          
   


      let  closeDeleteModal = () =>{
        setDeleteContentVisible(false)
        setAddBadgesVisible(false)
      
      }
      
      let deleteCourseContent= ()=>{
      
        setDeleteContentVisible(false)
      
        let payload = {
          "operation_type":"delete",
          "op_field_details":{
              "badge_id":[selectedContentId]
          }
      }
        
        
          dispatch(deleteBadgesDetails(payload))
      
          setVisibleNotification(true)
      
      
        
      
      } 

      let deleteTitleDescriptionModalFooter = [
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' ,marginBottom:"10px"}}>
        <Button   style={{
          border: '1px solid red',  // Red border
          color: 'red',              // Red text color
          backgroundColor: 'transparent',  // No background color
        }} default value={'Cancel'}  onClick={closeDeleteModal}>
              Cancel</Button>
      
              <div style={{marginLeft:"10px"}}></div>
      
              <Button   style={{
          border: '1px solid red',  // Red border
          color: 'white',              // Red text color
          backgroundColor: 'red',  // No background color
        }}  danger value={'Delete'}  onClick={deleteCourseContent}>Delete</Button>
         
        
        </div>
      ]

      const deleteItem = (id) => {
        setDeleteContentVisible(true)
        setSelectedCoursesContentId(id)

      };

      const handleClassBadges= (e, type)=>{
        if (!type || !type.name) {
          console.error("Type or type.name is undefined:", type);
          return;
        }
      
        let value = e && e.target ? e.target.value : e; // Handle both events and direct values
        console.log(type.name )
  
        console.log(e)
  
    
        // Update other fields
        if (["name", "document"].includes(type.name)) {
          setAddBadgesDetailsPayload((prevDetails) => ({
            ...prevDetails,
            [type.name]: value,
          }));
        };
                  
              
                };


      let onFinishBadges= ()=>{

        let payload =  {
          "operation_type":"insert",
          "op_field_details":{
             "badge_name":addBadgesDetailsPayload.name,
             "batch_id":selectedBatchId,
             "image":addBadgesDetailsPayload.document,
             "instructor_id":window.localStorage.getItem("instructor_id"),
             "course_id":selectedCourseId,
             "tenant_id":window.localStorage.getItem("tenant_Id"),
          }
       }

  
          dispatch(createBadgesDetails(payload))
      
          setVisibleNotification(true)
      } 

      const handleClearCertificateModal = () => {
        setSelectedCertificate(null)
      };
	  
	    const handleClearBadgesModal = () => {
     
     setSelectedId(null)
    };

      const handleAddBadgesModal = () => {
       
        setAddBadgesVisible(true)
      };

      const handleItemClick = (id) => {
        setSelectedId(id); // Set the clicked item's id as selected
        addContentReference(id, { name: "badge_id" })

      };

      let handleTitleDeleteClick = (values) =>{
        deleteContentVisible(false)
    
      }


      const deleteItemCertificate = (id) => {

        setDeleteCertificateVisible(true)
      
        setSelectedCertificate(id)
      };

      const handleClassCertificate= (e, type)=>{
                 
        if (!type || !type.name) {
          console.error("Type or type.name is undefined:", type);
          return;
        }
      
        let value = e && e.target ? e.target.value : e; // Handle both events and direct values
        console.log(type.name )
  
        console.log(e)
      
        // Update other fields
        if (["certificate_name", "certificate_template","signature","signature_template"].includes(type.name)) {
          setAddCertificateDetailsPayload((prevDetails) => ({
            ...prevDetails,
            [type.name]: value,
          }));
        };
        
              
                };

                let onFinishCertificate= ()=>{

                  console.log(addCertificateDetailsPayload)
      
                  // Completion of Python Course
      
                 let payload =  {
                  "operation_type": "insert",
                  "op_field_details": {
                     "certificate_name": addCertificateDetailsPayload.certificate_name,
                     "image":addCertificateDetailsPayload.certificate_template,
                     "signature": addCertificateDetailsPayload.signature?  addCertificateDetailsPayload.signature : addCertificateDetailsPayload.signature_template,
                     "signature_type":addCertificateDetailsPayload.signature? 'TEXT':"IMAGE",
                     "batch_id":selectedBatchId,
                     "instructor_id":window.localStorage.getItem("instructor_id"),
                     "course_id":selectedCourseId,
                     "tenant_id":window.localStorage.getItem("tenant_Id")
                  }
               }
          
      
               console.log(payload)
               
                    dispatch(createCertificateDetails(payload))
                
                    setVisibleNotification(true)
}

const handleAddCertificateModal = () => {
  setAddCertificateVisible(true)
};

const viewItemCertificate = (name,template,type,signature) => {



  setViewCertificateName(name)
  VsetiewCertificateTemplate(template)
  setViewCertificateType(type)
  setViewCertificateSignature(signature)

  setViewCertificate(true)


};

let deleteCertificateModal= ()=>{
      
  setDeleteCertificateVisible(false)

  let payload = {
    "operation_type":"delete",
    "op_field_details":{
        "certificate_id":[selectedCertificate]
    }
}
  
  
    dispatch(deleteCertificateDetails(payload))

    setVisibleNotification(true)


  

} 

let deleteCertificateModalFooter = [
  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' ,marginBottom:"10px"}}>
  <Button   style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
  }} default value={'Cancel'}  onClick={closeDeleteModal}>
        Cancel</Button>

        <div style={{marginLeft:"10px"}}></div>

        <Button   style={{
    border: '1px solid red',  // Red border
    color: 'white',              // Red text color
    backgroundColor: 'red',  // No background color
  }}  danger value={'Delete'}  onClick={deleteCertificateModal}>Delete</Button>
   
  
  </div>
]

const handleCertificateItemClick = (id) => {
  setSelectedCertificate(id); 
  addContentReference(id, { name: "certificate_id" })

};

    return (
     <div>
 {contextHolder}
<div style={{ display: 'flex', flexDirection: 'row' }}>

<div style={{ flex: 1 }}>
<div style={{ minHeight: '300px', maxHeight: '300px', overflowY: 'auto' }}>  
           <Form
          form={formReference}
          onFinish={onFinishReferenceAdd}

          autoComplete="off"
          layout="vertical"
         
        >
           <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap', marginTop: '10px' }}>
    <Form.Item
       label="Type"
      name="reference_type"
      style={{ flex: 1, minWidth: '250px', marginBottom: 0 }}
      rules={[
        {
          required: true,
          message: 'Type is required',
        },
      ]}
    >
      <Select
        size="small"
        className="custom-select-height"
        onChange={handleAllPreferenceTypeChange}
        allowClear
        placeholder="Reference Type"
        optionLabelProp="label"
      >
        {referenceType.map((item) => (
          <Select.Option key={item.value} value={item.value} label={item.label}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{item.label}</span>
              <div style={{ display: 'flex', gap: '5px', marginLeft: '5px' }}>
                {/* Additional content can go here if needed */}
              </div>
            </div>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  </div>


  <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1}}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please enter title',
                },
              ]}
              style={{ flex: 1 }}
            >

              <InputComponent
                placeholder={"Title"}
                name={"title"}
                value={allCoursesContentReference.title}
                onChange={(e) => addContentReference(e, { name: "title" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>


  <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
  <Form.Item
    label="Description"
    name="description"
    rules={[
      {
        required: true,
        message: 'Please enter description',
      },
    ]}
    style={{ flex: 1 }}

  >
    <Input.TextArea
      placeholder="Description"
      name="description"
      value={allCoursesContentReference.description} // Adjust this to the appropriate state variable if necessary
      onChange={(e) =>addContentReference(e, { name: "description" })}
      style={{ width: '100%' }}
      autoSize={{ minRows: 3, maxRows: 6 }} // Allows the textarea to expand as needed
    />
  </Form.Item>
  </div>

  {preferenceTypeVisible == "1" ? ( 

<div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,}}>
<Form.Item
 style={{ flex: 1 }}
label="Upload File"
                     name="reference_document"
                     rules={[
                       {
                         required: true,
                         message: 'File  is required',
               
                       },
                     ]}>
                   <div className="instructorResumeUpload">
                  <InputComponent type="file" name={"reference_document"}  style={{"display": "none"}}
                  onChange={(e)=>onFileChange(e,{name: "reference_document"})}
                  className="instructorResumeUpload"/>
                  </div>
                  </Form.Item>
</div>
    ) : preferenceTypeVisible == '2' ? (   
                    
                    <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
           

<Form.Item
  label="Link"
  name="reference_document"
  rules={[
    {
      required: true,
      message: 'Please enter a link',
    },
    {
      validator: (_, value) => {
        const urlPattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
          '((([a-zA-Z0-9\\-]+)\\.)+[a-zA-Z]{2,})' + // domain name and extension
          '(\\:[0-9]{1,5})?' + // port (optional)
          '(\\/.*)?$' // path (optional)
        );
        if (!value || urlPattern.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter a valid URL'));
      },
    },
  ]}
  style={{ flex: 1 }}
>


  <InputComponent
    placeholder={"Link"}
    name={"reference_document"}
    value={allCoursesContentReference.reference_document}
    onChange={(e) => addContentReference(e, { name: "reference_document" })}
    style={{ width: '100%' }}
  />
</Form.Item>

          </div>) : ( <div></div>)}


          {coursesContentData[0].reference_type === 'File' ? 

          (<div style={{ height: '100vh', width: '100%' ,marginTop:"20px" }}>
            <iframe
              src={coursesContentData[0].reference_document }
              title="PDF Viewer"
              style={{ width: '100%', height: '100%', border: 'none' }}
            />
          </div> ) : (<div>
            <p>{coursesContentData[0].reference_document }</p>
          </div>)

          }


        </Form> </div>
</div>


<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '0.1px',            // Set the width of the line
      height:'40vh',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
      marginLeft:'10px',marginRight:"10px"     // Ensure the line spans the height of the flex container
    }}
  />  

<div style={{ flex: 1 }}>
<div style={{ minHeight: '200px', maxHeight: '300px', overflowY: 'auto' }}>
<Form
          form={formReference}
          onFinish={onFinishReferenceAdd}

          autoComplete="off"
          layout="vertical"
         
        >

<div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,marginTop:"12px" }}>
            <Form.Item
              label="XP"
              name="xp"
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"XP"}
                name={"xp"}
                value={allCoursesContentReference.xp}
                onChange={(e) => addContentReference(e, { name: "xp" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>


    <div
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  }}
>
  <p style={{ margin: 0 }}>Badges</p>

  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

   { selectedId === null || selectedId === '' ? <div></div> :
        <button
        style={stylesCertificate.deleteButton}
        onClick={(e) => {
          e.stopPropagation(); // Prevent item click event when delete button is clicked
          handleClearBadgesModal();
        }}
      >
        Clear
      </button>}
    <img
      src={plus}
      alt="plus-icon"
      style={{ height: "25px", width: "20px", cursor: "pointer" }}
      onClick={() => handleAddBadgesModal()}
    />
  </div>
</div>


<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 

          
<div style={styles.gridContainer}>
      {badgesList?.map(item => (
        <div
          key={item.badge_id}
          style={{
            ...styles.gridItem,
            border: selectedId === item.badge_id ? "2px solid blue" : "1px solid #ddd",
            backgroundColor: selectedId === item.badge_id ? "#e0f7ff" : "#f9f9f9"
          }}
          onClick={() => handleItemClick(item.badge_id)}
        >
          <img src={item.image} alt={item.badge_name} style={styles.image} />
          <p style={styles.name}>{item.badge_name}</p>

          <img
      src={deleteIcon}
      alt="plus-icon"
      style={{
        height: "25px",
        width: "20px",
        cursor: "pointer",
        border: "1px solid  #0E60B2", // Adds a border
        borderRadius: "4px",       // Optional: Makes the corners rounded
      }}      onClick={() => deleteItem(item.badge_id)}
    />

          {/* <button
            style={styles.deleteButton}
            onClick={(e) => {
              e.stopPropagation(); // Prevent item click event when delete button is clicked
              deleteItem(item.badge_id);
            }}
          >
            Delete
          </button> */}
        </div>
      ))}
    </div>

    
    <div
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  }}
>
  <p style={{ margin: 0 }}>Certificate</p>

  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

   { selectedCertificate === null || selectedCertificate === '' ? <div></div> :
        <button
        style={stylesCertificate.deleteButton}
        onClick={(e) => {
          e.stopPropagation(); // Prevent item click event when delete button is clicked
          handleClearCertificateModal();
        }}
      >
        Clear
      </button>}
    <img
      src={plus}
      alt="plus-icon"
      style={{ height: "25px", width: "20px", cursor: "pointer" }}
      onClick={() => handleAddCertificateModal()}
    />
  </div>
</div>


<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 





    <div style={stylesCertificate.gridContainer}>
  {certificateList?.map((item) => (
    <div
      key={item.id}
      style={{
        ...stylesCertificate.gridItem,
        border: selectedCertificate === item.certificate_id ? "2px solid blue" : "1px solid #ddd",
        backgroundColor: selectedCertificate === item.certificate_id ? "#e0f7ff" : "#f9f9f9",
      }}
      onClick={() => handleCertificateItemClick(item.certificate_id)}
    >
      <img src={item.image} alt={item.certificate_name} style={stylesCertificate.image} />
      {/* <p style={stylesCertificate.name}>{item.name}</p> */}

      <div style={stylesCertificate.actionContainer}>

      <img
      src={TableEyeIcon}
      alt="plus-icon"
      style={{
        height: "25px",
        width: "20px",
        cursor: "pointer",
        marginRight:"5px",
        border: "1px solid  #0E60B2", // Adds a border
        borderRadius: "4px",       // Optional: Makes the corners rounded
      }}      onClick={() => viewItemCertificate(item.certificate_name,item.image,item.signature_type,item.signature)}
    />




<img
      src={deleteIcon}
      alt="plus-icon"
      style={{
        height: "25px",
        width: "20px",
        cursor: "pointer",
        border: "1px solid  #0E60B2", // Adds a border
        borderRadius: "4px",       // Optional: Makes the corners rounded
      }}      onClick={() => deleteItemCertificate(item.badge_id)}
    />
        {/* <button
          style={stylesCertificate.viewButton}
          onClick={(e) => {
            e.stopPropagation(); // Prevent item click event when view button is clicked
            viewItemCertificate(item.certificate_name,item.image,item.signature_type,item.signature);
          }}
        >
          View
        </button>


        <button
          style={stylesCertificate.deleteButton}
          onClick={(e) => {
            e.stopPropagation(); // Prevent item click event when delete button is clicked
            deleteItemCertificate(item.certificate_id);
          }}
        >
          Delete
        </button> */}
      </div>
    </div>
  ))}
</div>

        </Form>
</div>

</div>




  </div>


  <div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 


<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>

<Form
          form={formReference}
          onFinish={onFinishReferenceAdd}

          autoComplete="off"
          layout="vertical"
         
        >
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Form.Item>


             

              <Button htmlType='submit' type='primary' style={{ marginRight: '8px', width:"110px" }} onClick={addContentReference}>
                Update
              </Button>
            
         

            </Form.Item>
 </div>
        </Form>

        <Form
          form={formReference}
          onFinish={onFinishReferenceAdd}

          autoComplete="off"
          layout="vertical"
         
        >
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Form.Item>


          
            
              <Button
  htmlType="submit"
  onClick={handleCancel}
  style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px"
  }}
>
  Cancel
              </Button>

            </Form.Item>
 </div>
        </Form>

</div>

{addBadgesVisible ? 
  <ModalComponent title={"Add Badges"}
 
  content={<AddBadgesModal 
   handleCancel={handleDeleteCancel}
   addBadgesDetailsPayload = {addBadgesDetailsPayload}
   formBadges={formBadges}
onFinishBadges={onFinishBadges} 
handleClassBadges={handleClassBadges}
   />}
open={addBadgesVisible} 


handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>

:""}


{deleteContentVisible ?
              <ModalComponent title={"Delete Badges"} 
                  footer={deleteTitleDescriptionModalFooter} content={"Are you sure you want to delete this content?"}
                   open={deleteContentVisible} handleOk={deleteCourseContent} closeModal={closeDeleteModal}
                  showModal={handleTitleDeleteClick} handleCancel={handleDeleteCancel}/>: " "}




{/* <Form
          form={formReference}
          onFinish={onFinishReferenceAdd}

          autoComplete="off"
          layout="vertical"
         
        >
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Form.Item>


              <Button htmlType='submit'   style={{
    border: '1px solid #0E60B2',  // Red border
    color: '#0E60B2',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px",marginRight:"10px"
  }} onClick={handleClassSingleReferenceChange}>
                Save
              </Button>

              <Button htmlType='submit' type='primary' style={{ marginRight: '8px', width:"110px" }} onClick={handleClassMultipleReference}>
                Save & New
              </Button>
            
              <Button
  htmlType="submit"
  onClick={handleDeleteCancel}
  style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px"
  }}
>
  Clear
              </Button>

            </Form.Item>
 </div>
        </Form> */}

{deleteCertificateVisible ?
              <ModalComponent title={"Delete Certificate"} 
                  footer={deleteCertificateModalFooter} content={"Are you sure you want to delete this certificate?"}
                   open={deleteCertificateVisible} handleOk={deleteCertificateModal} closeModal={closeDeleteModal}
                  showModal={handleTitleDeleteClick} handleCancel={handleDeleteCancel}/>: " "}

{addCertificateVisible ? 
  <ModalComponent title={"Add Certificate"}
 
  content={<AddCertificateModal 
   handleCancel={handleDeleteCancel}
   addCertificateDetailsPayload = {addCertificateDetailsPayload}
   formCertificate={formCertificate}
   onFinishCertificate={onFinishCertificate} 
handleClassCertificate={handleClassCertificate}
   />}
open={addCertificateVisible} 


handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>

:""}


{viewCertificate ? 
  <ModalComponent title={"View Certificate"}
  width={1000}  height={1000}

  content={<CertificateViewModal 
    viewCertificateName={viewCertificateName}
    viewCertificateTemplate={viewCertificateTemplate}
    viewCertificateType={viewCertificateType}
    viewCertificateSignature={viewCertificateSignature}
   handleCancel={handleDeleteCancel}
   />}
open={viewCertificate} 
handleCancel={handleDeleteCancel}
closeModal={closeDeleteModal}

style={{
  maxHeight: '100px',
  minHeight: '100px',
  width: '80%', // You can adjust this value based on your preference
  maxWidth: '900px', // Optional: Set a maximum width if needed
}}
 ></ModalComponent>
:""}



      </div>

    )
}

const styles = {
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gap: "10px",
    padding: "15px"
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "0.3s" // Smooth transition for style change
  },
  image: {
    marginTop: "10px",
    width: "20px",
    height: "20px",
    objectFit: "cover",
    borderRadius: "5px"
  },
  name: {
    marginTop: "10px",
    fontSize: "10px",
    textAlign: "center",
    color: "#333"
  },
  deleteButton: {
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    backgroundColor: "#ff4d4f",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer"
  }
};

const stylesCertificate = {
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
    padding: "15px",

  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "0.3s" ,// Smooth transition for style change
    backgroundColor: "#f0f0f0", // Default background color

  },
  image: {
    marginTop: "10px",
    width: "150px",
    height: "80px",
    objectFit: "cover",
    borderRadius: "5px"
  },
  name: {
    marginTop: "10px",
    fontSize: "10px",
    textAlign: "center",
    color: "#333"
  },
  deleteButton: {
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    backgroundColor: "#ff4d4f",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer"
  },
  viewButton: {
    backgroundColor: "#4caf50",
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginLeft:"5px",
    marginRight:'5px'
  },
};


export default UpdateCoursesContentReference