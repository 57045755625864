import { configureStore,combineReducers } from "@reduxjs/toolkit";

import studentReducer from "./student/studentReducer";

import instructorReducer from "./allInstructors/instructorReducer";

import courseReducer from "./allCourses/courseReducer";

import categoryReducer from "./allCategories/categoryReducer";

import batchReducer from "./batches/batchReducer";

import studentRegisterReducer from "./studentRegister/studentRegisterReducer";

import studentLoginReducer from "./studentLogin/studentLoginReducer";

import instructorRegisterReducer from "./instructorRegister/instructorRegisterReducer"

import instructorLoginReducer from "./instructorLogin/instructorLoginReducer";
import studentAllCoursesReducer from './studentViewAllCourses/studentallCoursesReducer';

import liveStudentCoursesReducer from './studentDashboard/liveCourses/liveCoursesReducer'

import upcomingCoursesReducer from './studentDashboard/upcomingCourses/upcomingCoursesReducer';

import getStudentRegCourses from './studentRegisteredCourses/studentRegCoursesReducer';

import studentCourseProgressReducer from './studentDashboard/taskProgress/taskProgressReducer';

import relatedCoursesReducer from './studentDashboard/relatedCourses/relatedCoursesReducer'

import studentProfileReducer from './studentProfile/studentProfileReducer';

import instructorBatchReducer from './instructorBatches/instructorBatchReducer'

import instructorStudentReducer from './instructorStudents/instructorStudentReducer'

import instructorScheduleReducer from './instructorSchedules/instructorScheduleReducer';

import instructorDashboardReducer from './instructorDashboard/instructorDashboardReducer'

import instructorProfileReducer from "./instructorProfile/instructorProfileReducer";

import adminAnnouncementReducer from './adminAnnouncements/adminAnnouncementReducer'

import superAdminReducer from "./superAdmin/superAdminReducer";

import AdminCourseFeeReducer from "./adminCourseFee/adminCourseFeeReducer";

import AdminDashboardReducer from './adminDashboard/adminDashboardReducer';

import instructorRescheduleClassReducer from './instructorRescheduleClass/instrucrorRescheduleClassReducer';

import instructorCancelClassReducer from './instructorCancelClass/instructorCancelClassReducer';

import adminReducer from './admin/adminReducer'

import tenantManagementreducer from './tenantManagement/tenantManagementReducer';

import tenantAdminReducer from './tenantAdmin/tenantAdminReducer';
import AdminInstructor from './adminInstructor/adminInstrucrorReducer'

import AdminStudent from "./adminStudent/adminStudentReducer";

import AdminScheduleReducer from './adminSchedules/adminScheduleReducer';

import AdminCoursePriceReducer from './coursePrice/coursePriceReducer';

import StudentReportsReducer from './adminReports/studentReports/studentReportsReducer';

import EnrolledReportsReducer from './adminReports/enrolledReports/enrolledReportsReducer';

import ActiveUserReportsReducer from './adminReports/activeUserReports/activeUserReportsReducer';

import AllReportsReducer from './adminReports/allAdminReports/allReportsReducer';

import groupRetrieveReducer from './commonGroupRetrieve/groupRetrieveReducer';

import AdminDemoClassDetails from './adminDemoClass/adminDemoClassReducer';

import CoursesContentReducer from './coursesContent/coursesContentReducer';

import StudentCoursReducer from './studentCourseContent/studentCoursesContentReducer';

import badgesReducer from './badges/badgeReducer';


import logger from "redux-logger";

import { thunk } from 'redux-thunk';

let middleware = [thunk,logger];

const rootReducer  = combineReducers({
    student: studentReducer,
    instructor: instructorReducer, courses: courseReducer,
categories: categoryReducer, batchcourseDetails: batchReducer,
studentRegisterReducer:studentRegisterReducer,studentLoginReducer:studentLoginReducer,
instructorRegisterReducer:instructorRegisterReducer,
instructorLoginReducer:instructorLoginReducer,
studentAllCoursesReducer:studentAllCoursesReducer,
liveStudentCoursesReducer: liveStudentCoursesReducer,
upcomingCoursesReducer: upcomingCoursesReducer,
getStudentRegCourses: getStudentRegCourses,
studentCourseProgressReducer: studentCourseProgressReducer,
relatedCoursesReducer:relatedCoursesReducer,
studentProfileReducer:studentProfileReducer,
instructorBatchReducer: instructorBatchReducer,
instructorStudentReducer: instructorStudentReducer,
instructorScheduleReducer:instructorScheduleReducer,
instructorDashboardReducer:instructorDashboardReducer,
instructorProfileReducer:instructorProfileReducer,
adminAnnouncementReducer:adminAnnouncementReducer,
superAdminReducer:superAdminReducer,
AdminCourseFeeReducer:AdminCourseFeeReducer,
AdminDashboardReducer:AdminDashboardReducer,
instructorRescheduleClassReducer:instructorRescheduleClassReducer,
instructorCancelClassReducer:instructorCancelClassReducer,
adminReducer:adminReducer,
tenantManagementreducer:tenantManagementreducer,
tenantAdminReducer:tenantAdminReducer,
AdminInstructor:AdminInstructor,
AdminStudent:AdminStudent,
AdminScheduleReducer:AdminScheduleReducer,
AdminCoursePriceReducer:AdminCoursePriceReducer,
StudentReportsReducer:StudentReportsReducer,
EnrolledReportsReducer:EnrolledReportsReducer,
ActiveUserReportsReducer:ActiveUserReportsReducer,
AllReportsReducer:AllReportsReducer,
groupRetrieveReducer:groupRetrieveReducer,
AdminDemoClassDetails:AdminDemoClassDetails,
CoursesContentReducer:CoursesContentReducer,
StudentCoursReducer:StudentCoursReducer,
badgesReducer:badgesReducer

})

export const store = configureStore({reducer: rootReducer,...middleware})


export default store;