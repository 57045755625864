import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Button, Form, Table ,Input,notification,DatePicker,Checkbox,Collapse, List, Typography,Row, Col } from 'antd';
import moment from 'moment';

import { excel, plus, searchIcon,back_icon_png,no_data_image,download_icon,
} from "../../images";

import {getCoursesContentQuestionDetails,uploadStudentQuizDetails,getStudentQuizDetails
    } from '../../redux/coursesContent/action.js'
    import DownloadCertificate from '../coursesContent/viewModal/downloadCertificate.js';

    import {ModalComponent,TableComponent} from "../../commonComponents/index.js";



const StudentUploadQuestionAndAnswer = () => {


    let dispatch = useDispatch();
    let navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();

    const { coursesName, selectedCourseId, selectedBatchId,selectedContentId } = useParams();

    const [coursesContentData, setCoursesContentDataList] = useState({});
    const [onlyQuestion, setOnlyQuestion] = useState([]);
    const [userSelected, setUserSelected] = useState([]);
    const [visibleNotification, setVisibleNotification] = useState(false);
    const [onlyQuestionResult, setOnlyQuestionResult] = useState([]);
    const [onlyQuestionAndAnswerFormat, setOnlyQuestionAndAnswerFormat] = useState([]);
    const [viewCertificate,setViewCertificate] = useState(false);

    const [validationCertificateId,setValidationCertificateId] = useState('');


    const [viewCertificateName,setViewCertificateName] = useState("");
    const [viewCertificateTemplate, VsetiewCertificateTemplate] = useState("");
    const [viewCertificateType,setViewCertificateType] = useState("");
    const [viewCertificateSignature,setViewCertificateSignature] = useState("");


    let getCoursesQuestion = useSelector((state)=> state?.CoursesContentReducer?.getCoursesQuestion);
    let uploadStudentQuiz = useSelector((state)=> state?.CoursesContentReducer?.uploadStudentQuiz);
    let getStudentQuiz = useSelector((state)=> state?.CoursesContentReducer?.getStudentQuiz);

  //   useEffect(()=>{



  //     if (getStudentQuiz) {

  //       if(getStudentQuiz === []){

  //       }

  //       console.log(getStudentQuiz)
  //       setOnlyQuestion(getStudentQuiz[0]?.questions)

  //     }
  // },[getStudentQuiz])

  useEffect(() => {
    if (getStudentQuiz) {


      console.log(getStudentQuiz,'DAMODAR');
      // setOnlyQuestion(getStudentQuiz[0]?.questions);
      setOnlyQuestionResult(getStudentQuiz)
      setOnlyQuestionAndAnswerFormat(getStudentQuiz?.questions)


    } 
  }, [getStudentQuiz]);


  useEffect(()=>{

    let payload ={
      "operation_type":"retrieve",
      "category":"Q&A",
      "content_id":selectedContentId

  }
dispatch(getCoursesContentQuestionDetails(payload))


},[])

  useEffect(()=>{

    let payload = {
      "operation_type": "retrieve",
      "op_field_details": {
        "student_id": window.localStorage.getItem("student_id"),
        "batch_id":selectedBatchId,
        "course_id":selectedCourseId,
          "content_id": selectedContentId,
          "tenant_id": window.localStorage.getItem("tenant_Id")
      }
  }
        
  
  dispatch(getStudentQuizDetails(payload))

},[])

    useEffect(()=>{    
      if (uploadStudentQuiz) {
  if(visibleNotification == true){
  
    if(uploadStudentQuiz?.res_status === true){

      openNotification(uploadStudentQuiz?.msg)

      navigate(`/studCourseContentDetails/${coursesName}/${selectedCourseId}/${selectedBatchId}`);

  
    }else{
      openNotification(uploadStudentQuiz?.msg)

    }
  
  }else{

  }
  
      }
  },[uploadStudentQuiz])


  const openNotification = (msg) => {
  
    api.info({
      description:
      `${msg}`,
  
    });
  
    setVisibleNotification(false)


  
  };


    useEffect(()=>{
        if (getCoursesQuestion) {

          setViewCertificateName(getCoursesQuestion?.content_data[0]?.certificate_name)
          VsetiewCertificateTemplate(getCoursesQuestion?.content_data[0]?.certificate_image)
          setViewCertificateType(getCoursesQuestion?.content_data[0]?.signature_type)
          setViewCertificateSignature(getCoursesQuestion?.content_data[0]?.signature)
          setValidationCertificateId(getCoursesQuestion?.content_data[0]?.certificate_image)

      

        
            setCoursesContentDataList(getCoursesQuestion)

            const updatedQuestions = getCoursesQuestion?.questions?.map((question) => ({
              ...question,
              options: question.options.map((option) => ({
                ...option,
                is_correct: false, // Update all `is_correct` to `false`
              })),
            }));
  
            setOnlyQuestion(updatedQuestions)
        

        



         }
    },[getCoursesQuestion])

 

    const backMethod = () => {
        navigate(`/studCourseContentDetails/${coursesName}/${selectedCourseId}/${selectedBatchId}`);

                };


                const [selectedAnswers, setSelectedAnswers] = useState({});
                const [previewMode, setPreviewMode] = useState(false);
                const handlePreview = () => {
                    setPreviewMode(true);
                  };

                // Handle the change when a user selects an option
                const handleOptionChange = (questionId, optionId) => {
                    setOnlyQuestion((prevQuestions) =>
                      prevQuestions.map((question) =>
                        question.question_id === questionId
                          ? {
                              ...question,
                              options: question.options.map((option) =>
                                option.option_id === optionId
                                  ? { ...option, is_correct: !option.is_correct }
                                  : option
                              ),
                            }
                          : question
                      )
                    );
                  };
                  
                  


                const handleSubmit = () => {
                    console.log("Selected Answers:", selectedAnswers);
                    // You can process the selected answers here, e.g., check correctness
                    onlyQuestion?.forEach((question) => {
                      const userAnswer = selectedAnswers[question.question_id];
                      const correctOption = question.options.find((opt) => opt.is_correct);
                      if (correctOption?.option_id === userAnswer) {
                        console.log(`Question ${question.question_id}: Correct`);
                      } else {
                        console.log(`Question ${question.question_id}: Incorrect`);
                      }
                    });
                  };

                  let handleDeleteCancel= ()=>{

                 
                    navigate(`/studCourseContentDetails/${coursesName}/${selectedCourseId}/${selectedBatchId}`);

                
                  } 



                  let onFinishSectionAdd= (values) =>{


                    let payload = {
                      "operation_type": "insert",
                      "op_field_details": {
                          "student_id": window.localStorage.getItem("student_id"),
                          "content_id": selectedContentId,
                          "questions": onlyQuestion,
                          "batch_id": selectedBatchId,
                          "course_id": selectedCourseId,
                          "instructor_id": window.localStorage.getItem("instructor_id"),
                          "tenant_id": window.localStorage.getItem("tenant_Id")
                      }
                  }

                  console.log(payload)

                  //   let payload = {
                  //     "operation_type": "insert",
                  //     "op_field_details": {
                  //         "student_id": window.localStorage.getItem("student_id"),
                  //         "content_id": selectedContentId,
                  //         "document": basePdfData,
                  //         "batch_id": selectedBatchId,
                  //         "course_id": selectedCourseId,
                  //         "instructor_id": window.localStorage.getItem("instructor_id"),
                  //         "tenant_id": window.localStorage.getItem("tenant_Id")
                  //     }
                  // }

                 
            
            
                    
                      dispatch(uploadStudentQuizDetails(payload))
                  
                      setVisibleNotification(true)
                  
                  
                  }


                  let handleAddCoursesComponent= () =>{
                    setViewCertificate(true)

            }

            let  closeDeleteModal = () =>{
              setViewCertificate(false)
            }
 
    return (
        <div>

  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
  <h3 style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
    <img 
      src={back_icon_png} 
      alt="Back" 
      style={{ marginRight: '8px', cursor: 'pointer', height: '25px', width: '25px' }} 
      onClick={() => backMethod()} 
    />
    Upload Q&A
  </h3>

 
</div>

<div>
 {contextHolder}
<div style={{ display: 'flex', flexDirection: 'row' }}>

<div style={{ flex: 1 }}>
<div style={{ minHeight: '200px', maxHeight: '300px', overflowY: 'auto' }}>
  <Form
    // form={formSection}
    // onFinish={onFinishSectionAdd}
    autoComplete="off"
    layout="vertical"
  
  >


{coursesContentData?.content_data?.map((content, index) => (
                        <div key={index} style={{ marginBottom: '20px' }}>
                          
                            <p><strong>XP : </strong> {content.xp || 'N/A'}</p>
                            <p><strong>Due Date : </strong> {new Date(content.due_date).toLocaleDateString()}</p>
                            <p><strong>Duration : </strong> {content.duration || 'N/A'}</p>
                            <p><strong>Badges : </strong> {content.badge_name || 'N/A'}</p>
                            <p><strong>Certificate : </strong> {content.certificate_image === null ? 'N/A' : 'Available'}</p>

                            <p><strong>Min Pass Percentage : </strong> {content.min_pass_percentage || 'N/A'}</p>
                            <p><strong>Title : </strong> {content.title}</p>
                            <p><strong>Description : </strong> {content.description}</p>


                        </div>
                    ))}


<h3>Preferences : </h3>


<Table
                        dataSource={coursesContentData?.preferences}
                        columns={[
                            { title: 'No of Attempts', dataIndex: 'no_of_attempts', key: 'no_of_attempts' },
                            { title: 'Pass Percentage', dataIndex: 'pass_percentage', key: 'pass_percentage' },
                            { title: 'XP', dataIndex: 'xp', key: 'xp' },
                        ]}
                        rowKey={(record, index) => index}
                        pagination={false}
                    />

<h3>Quiz : </h3>

<div    style={{
      border: "1px solid #ddd",
      borderRadius: "8px",
      padding: "10px",

      margin: "10px",
    //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#f9f9f9",
    }}>
{onlyQuestion?.map((question,index) => (
      <div key={question.question_id}>
<h3>{`Question ${index + 1}: ${question.question_text}`}</h3>
<div style={{ display: "flex", flexWrap: "wrap" }}>
<div>
  {question.options.map((option, index) => (
    <div key={option.option_id} style={{ marginBottom: "10px" }}>
    <span>{`${index + 1}.`}</span>

      <input
        type="checkbox"
        checked={option.is_correct}
        onChange={() => handleOptionChange(question.question_id, option.option_id)}
        style={{ marginRight: "10px" }}
      />
      {option.option_text}
    </div>
  ))}
</div>


</div>


      </div>
    ))}
    </div>






{/* <h2>Selected Answers:</h2> */}
{/* <pre>{JSON.stringify(onlyQuestion, null, 2)}</pre> */}





  
  </Form></div>
</div>


<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '0.1px',            // Set the width of the line
      height:'40vh',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
      marginLeft:'10px',marginRight:"10px"     // Ensure the line spans the height of the flex container
    }}
  />  

<div style={{ flex: 1 }}>
<div style={{ minHeight: '200px', maxHeight: '300px', overflowY: 'auto' }}>




{ onlyQuestionAndAnswerFormat?.length > 0 ?
    

    <div style={{ height: '100vh', width: '100%' }}>
    
      
    
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
    <h3>Preview</h3>
    </div>

    <div    style={{
      // border: "1px solid #ddd",
      borderRadius: "8px",
      padding: "10px",

      margin: "10px",
    //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#f9f9f9",
    }}>

<p><strong>Status : </strong> {onlyQuestionResult?.pass_status  || 'N/A'}</p>
<p><strong>Attempt No : </strong> {onlyQuestionResult?.attempt_number  || 'N/A'}</p>
<p><strong>Percentage : </strong> {onlyQuestionResult?.percentage  || 'N/A'}</p>
<p><strong>XP : </strong> {onlyQuestionResult?.xp  || 'N/A'}</p>


{onlyQuestionResult?.pass_status === 'Pass' && validationCertificateId != null
? (<div style={{ display: 'flex', alignItems: 'center' }}>
  <strong>Certificate : </strong>
  <img 
    src={download_icon} 
    alt="Add" 
            onClick={() => handleAddCoursesComponent()} 

    style={{ height: '25px', width: '25px', cursor: 'pointer', marginLeft: '5px' }} 
  />
</div>) : (<div></div>)}








</div>


    
    
    
    <div    style={{
      border: "1px solid #ddd",
      borderRadius: "8px",
      padding: "10px",

      margin: "10px",
    //   boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#f9f9f9",
    }}>
   {onlyQuestionAndAnswerFormat?.map((question,index) => (
          <div key={question.question_id}>
    <h3>{`Question ${index + 1}: ${question.question_text}`}</h3>
    <div style={{ display: "flex", flexWrap: "wrap" }}>
    <div>
      {question.options.map((option, index) => (
        <div key={option.option_id} style={{ marginBottom: "10px" }}>
        <span>{`${index + 1}.`}</span>
    
          <input
            type="checkbox"
            checked={option.is_correct}
            // onChange={() => handleOptionChange(question.question_id, option.option_id)}
            style={{ marginRight: "10px" }}
          />
          {option.option_text}
        </div>
      ))}
    </div>
    
    
    </div>
    
    
          </div>
        ))}
    </div>
       
</div>
    
 
       :
       
       <div style={{ textAlign: 'center', padding: '20px' }}>
         <img src={no_data_image} alt="No Data Available" style={{ width: '200px', height: 'auto' }} />
         <p>Preview not available</p>
       </div>
      }



  






      
</div>

</div>




  </div>


  <div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 


<Form
        //   form={formSection}
          onFinish={onFinishSectionAdd}

          autoComplete="off"
          layout="vertical"
          initialValues={{
          }}
        >
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Form.Item>


            {onlyQuestionResult?.pass_status === 'Pass' ? 
             <div></div>  : <Button htmlType='submit' type='primary' style={{ marginRight: '8px', width:"110px" }} >
             Save
           </Button> }


          
            
              <Button
  htmlType="submit"
  onClick={handleDeleteCancel}
  style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px"
  }}
>
  Cancel
</Button>

            </Form.Item>
 </div>
        </Form>


      </div>


      {viewCertificate ? 
  <ModalComponent title={"Download Certificate"}
  width={1000}  height={1000}

  content={<DownloadCertificate 
    viewCertificateName={viewCertificateName}
    viewCertificateTemplate={viewCertificateTemplate}
    viewCertificateType={viewCertificateType}
    viewCertificateSignature={viewCertificateSignature}
   handleCancel={closeDeleteModal}
   />}
open={viewCertificate} 
handleCancel={closeDeleteModal}
closeModal={closeDeleteModal}

style={{
  maxHeight: '100px',
  minHeight: '100px',
  width: '80%', // You can adjust this value based on your preference
  maxWidth: '900px', // Optional: Set a maximum width if needed
}}
 ></ModalComponent>
:""}


        </div>
    );
}

export default StudentUploadQuestionAndAnswer;
