import React, { useEffect, useState } from 'react';
import { deleteIcon, edit, plus, searchIcon ,excel} from '../../images';

import './coursePrice.css'
import { TableComponent,ModalComponent, InputComponent } from '../../commonComponents';
import AddCoursePrice from './addCoursePrice/addCoursePrice';
import { Form, notification } from 'antd';
import { getCourses } from '../../redux/allCourses/action';
import { useDispatch, useSelector } from 'react-redux';
import { type } from '@testing-library/user-event/dist/type';
import { createAdminCoursePrice, deleteAdminCoursePrice, retrieveAdminCoursePrices, updateAdminCoursePrice } from '../../redux/coursePrice/action';
import moment from 'moment';
import dayjs from 'dayjs';

import * as XLSX from 'xlsx';
import ExportToExcel from '../../commonComponents/XlsxFile/ExportToExcel'
import { jsPDF } from 'jspdf';
import { saveAs } from 'file-saver';

const CoursePrice = () => {
  const [api, contextHolder] = notification.useNotification();

  let dispatch = useDispatch();
  let getAllCourses = useSelector((state)=>state.courses.courses);

  let allCoursePrices = useSelector((state)=>state?.AdminCoursePriceReducer?.adminCoursePrices);

  let coursePriceCrud = useSelector((state)=>state?.AdminCoursePriceReducer?.adminCoursePriceCrudStatus)

  const [courseFeeId,setCourseFeeId] = useState("")
  const [allCourses,setAllCourses] = useState("");

  const [allAdminCoursePrices,setAllAdminCoursePrices] = useState("")

  const [courseId,setCourseId] = useState("");

  const [price,setPrice] = useState("")

  const [startDate,setStartDate] = useState("");

  const [endDate,setEndate] = useState("");

  const [modalType,setModalType] = useState("");
  const [form] = Form.useForm();

  const [feeModal,setFeeModal] = useState(false);

  const [searchEntry,setSearchEntry] = useState("");


  const customCompare = (a, b) => {
    if (a === null && b === null) {
      return 0;
    }
    if (a === null) {
      return 1; // Null values at the end
    }
    if (b === null) {
      return -1; // Null values at the beginning
    }
    // Compare non-null values
    return a - b; // Adjust this comparison based on your data type
  };

  useEffect(()=>{
    dispatch(getCourses())
  },[])

  useEffect(()=>{
    dispatch(retrieveAdminCoursePrices())
  },[])

  useEffect(()=>{
    setAllCourses(getAllCourses?.data)
  },[getAllCourses])


  useEffect(()=>{
    if (allCoursePrices) {
      setAllAdminCoursePrices(allCoursePrices)
      console.log(allCoursePrices,"pricess")
    }else{
      setAllAdminCoursePrices([])

    }
  },[allCoursePrices])


  useEffect(()=>{
      if (coursePriceCrud) {
        openNotification(coursePriceCrud?.msg,coursePriceCrud)
      }
  },[coursePriceCrud])

  const openNotification = (msg,response)=>{
    if (msg && response?.res_status== false) {
      api.info({
          description:
          `${msg}`,
        });
  }
  
  if (msg && response?.res_status == true) {
    api.info({
        description:
        `${msg}`,
      });
      setFeeModal(false)
  }
  }
  const handleModal = (type,id) =>{
    setStartDate("");
    setEndate("");
    setPrice("");
    setCourseId("");
    setCourseFeeId(id)
    form.setFieldsValue({["startDate"]:""});
    form.setFieldsValue({["endDate"]:""});
    form.setFieldsValue({["price"]:""});
    form.setFieldsValue({["courseId"]:""})
      setModalType(type);
      setFeeModal(true)
      if (type == "Edit Course Fee") {
        allAdminCoursePrices && allAdminCoursePrices.filter((item,index)=>{
          if (item.course_fees_id == id) {

           setCourseId(item?.course_id);
           setPrice(item?.course_fee);

           setStartDate(dayjs(item?.start_date,"DD-MM-YYYY"));

            if (item.end_date !=null) {
              setEndate(dayjs(item?.end_date,"DD-MM-YYYY"));
              form.setFieldsValue({["endDate"]:dayjs(item?.end_date,"DD-MM-YYYY")});
            }
           form.setFieldsValue({["startDate"]:dayjs(item?.start_date,"DD-MM-YYYY")});
          form.setFieldsValue({["price"]: item?.course_fee});
          form.setFieldsValue({["courseId"]:item?.course_id})

          }
        })
      }
  }

  const handleDeleteModal = (id)=>{
    let payload ={
      "operation_type": "delete",
      "op_field_details": {
          "course_fees_id": id
      }
  }
  dispatch(deleteAdminCoursePrice(payload))
  }

  const closeModal = ()=>{
    setFeeModal(false)
  }
  
  const handlePriceAndCourse = (e,type)=>{
    if (type.name == "price") {
      setPrice(e.target.value)
      form.setFieldsValue({[type.name]:e?.target?.value})
    }

    if (type.name == "courseId") {
      setCourseId(e)
      form.setFieldsValue({[type.name]:e})
    }
  }

  const handleDates = (e,type)=>{
    if (type.name == "startDate") {
      console.log(e,"start")
      setStartDate(e);
      form.setFieldsValue({[type.name]:e})
    }

    if (type.name == "endDate") {
      setEndate(e)
      form.setFieldsValue({[type.name]:e})
    }

  }

  const onFinish =()=>{
    console.log(courseId,price,startDate,endDate,"formValues")

    if (modalType == "Add Course Fee") {
      let payload =
      {
          "operation_type": "insert",
          "op_field_details": {
             "course_id": courseId,
            //  "course_name":"yoga",
             "start_date":startDate,
             "tenant_id":window.localStorage.getItem("tenant_Id"),
             "course_fee":price
          }
      }

      dispatch(createAdminCoursePrice(payload))
    }

    if (modalType != "Add Course Fee") {
      let payload ={
        "operation_type": "update",
        "op_field_details": {
            "course_fees_id":courseFeeId,
           "end_date": endDate
        }
    }
    dispatch(updateAdminCoursePrice(payload))
    }
  }

  const searchCoursePrice = (e) =>{
    setSearchEntry(e?.target?.value)
  }
    const columns = [
        
            {
                title: 'ID',
                dataIndex: 'course_fees_id',
                sorter: true,
                sorter: (a, b) => a.course_fees_id - b.course_fees_id,
                filteredValue: [searchEntry],
                onFilter: (value,record)=>{
                   return String(record.course_fees_id).toLowerCase().includes(value.toLowerCase())||
                          String(record.course_id).toLowerCase().includes(value.toLowerCase())||
                          String(record.course_fee).toLowerCase().includes(value.toLowerCase())||
                          String(record.course_name).toLowerCase().includes(value.toLowerCase())||
                          String(record.start_date).toLowerCase().includes(value.toLowerCase())||
                          String(record.end_date).toLowerCase().includes(value.toLowerCase())
                        }

              },
              {
                title: 'Course ID',
                dataIndex: 'course_id',
                sorter: true,
                sorter: (a, b) => a.course_id - b.course_id,

              },


              {
                title: 'Price',
                dataIndex: 'course_fee',
                sorter: true,
                sorter: (a, b) => a.course_fee - b.course_fee,

              },
              {
                title: 'Course Name',
                dataIndex: 'course_name',
                sorter: true,
                sorter: (a, b) => a.course_name .localeCompare(b.course_name),

              },
              {
                title: 'Start Date',
                dataIndex: 'start_date',
                sorter: true,
                render: (text, record) => (
                  <div className="actionIcons">
                      <span>{(moment(record.start_date, "DD-MM-YYYY HH:mm:ss"))
                      ? (moment(record.start_date, "DD-MM-YYYY HH:mm:ss")).format("DD-MM-YYYY") : "" }</span>
                  </div>
                 ),
                 sorter: (a, b) => a.start_date .localeCompare(b.start_date),

              },
              {
                title: 'End Date',
                dataIndex: 'end_date',
                sorter: true,
                render: (text, record) => (
                  <div className="actionIcons">
                    {record.end_date !=null ? 
                      <span>{(moment(record.end_date, "DD-MM-YYYY HH:mm:ss"))
                      ? (moment(record.end_date, "DD-MM-YYYY HH:mm:ss")).format("DD-MM-YYYY") : "" }</span>
                      : ""}
                      </div>
                 ),
                  // sorter: (a, b) => customCompare(a.end_date-b.end_date),
                  sorter: (a, b) => {
                    if (a.end_date === null || b.end_date === null) {
                      return a.end_date === null ? -1 : 1; // Handle null values
                    }
                    return a.end_date.localeCompare(b.end_date); // Example sorting for non-null values
                  },

              },
              {
                title: 'Status',
                dataIndex: 'is_active',
                sorter: true,
                sorter: (a, b) => b.is_active - a.is_active,
    
                render: (text, record1) => (
                  <div className="actionIcons">
                    {record1.is_active == true ? 
                        <div className={"statusActive"}><span class="dotActive"></span> Active</div>
                              
                    : <div className={"statusInactive"}><span class="dotInactive"></span> Inactive</div>}
                      {/* <p className={record1.is_active == true ? "activeClass" :"inActiveClass"}>{record1.is_active == true ? "ACTIVE" : "INACTIVE"}</p> */}
                  </div>
                 ),
              },
              {
                title: 'Action',
                dataIndex: 'action',
                render: (text, record) => (
                  <div className="actionIcons">
                     <img src={edit} onClick={()=>handleModal("Edit Course Fee",record.course_fees_id)} />
                     <img src={deleteIcon} onClick={() => handleDeleteModal(record.course_fees_id)} />
                  </div>
                ),
              }
        ]

        const exportToExcel = (data, fileName) => {
          const ws = XLSX.utils.json_to_sheet(data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
          XLSX.writeFile(wb, `${fileName}.xlsx`);
        };
        

        
        
       
        
        let handleCategoryModal1 = (type,id) =>{
          const transformedItems = allAdminCoursePrices?.map((item) => {
         
        
            return { 
               COURSE_FEE_ID: item.course_fees_id,
               COURSE_ID:  item.course_id,
              PRICE:  item.course_fee,
              COURSE_NAME: item.course_name,
              STAERT_DATE:  item.start_date,
             END_DATE:  item.end_date,
             STATUS:  item.is_active == true ? 'Active' : 'In Active',
  
            
            };
          });

          
          exportToExcel(transformedItems, 'course_fee');
        
          
        }








        // const data = [
        //   {
        //     title: "Java",
        //     content: "Java Course",
        //     tag: "java",
        //     level: "Beginner"
        //   },
        //   {
        //     title: "Java",
        //     content: "Java Course",
        //     tag: "java",
        //     level: "Beginner"
        //   },
        //   {
        //     title: "Java",
        //     content: "Java Course",
        //     tag: "java",
        //     level: "Beginner"
        //   },
        //   {
        //     title: "Java",
        //     content: "Java Course",
        //     tag: "java",
        //     level: "Beginner"
        //   }
        // ];
        


        //   const downloadPDF = () => {
        //     const doc = new jsPDF();
        //     doc.setFontSize(12);
          
        //     let yOffset = 10; // Starting y-coordinate for the first text block
          
        //     data.forEach((item, index) => {
        //       // Print each item at the current yOffset and then increment yOffset for the next item
        //       doc.text(`Title: ${item.title}`, 10, yOffset);
        //       yOffset += 10;
        //       doc.text(`Content: ${item.content}`, 10, yOffset);
        //       yOffset += 10;
        //       doc.text(`Tag: ${item.tag}`, 10, yOffset);
        //       yOffset += 10;
        //       doc.text(`Level: ${item.level}`, 10, yOffset);
        //       yOffset += 20; // Add extra space before the next block of data
        //     });
          
        //     doc.save('course-doc.pdf');
        //   };
        
        //   const downloadTextFile = () => {
        //     const content = data.map(item =>
        //       `Title: ${item.title}\nContent: ${item.content}\nTag: ${item.tag}\nLevel: ${item.level}\n\n`
        //     ).join('');
        //     const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
        //     saveAs(blob, 'course-doc.txt');
        //   };

        const data = [
          {
            "tag": "Java",
            "details": [
              {
                "title": "Java",
                "content": "Java Course",
                "level": "Beginner"
              },
              {
                "title": "Java",
                "content": "Java Course",
                "level": "Beginner"
              },
              {
                "title": "Java",
                "content": "Java Course",
                "level": "Beginner"
              }
            ]
          },
          {
            "tag": "Python",
            "details": [
              {
                "title": "Java",
                "content": "Java Course",
                "level": "Beginner"
              },
              {
                "title": "Java",
                "content": "Java Course",
                "level": "Beginner"
              }
            ]
          }
        ];
        
        const downloadPDF = () => {
          const doc = new jsPDF();
          doc.setFontSize(12);
        
          let yOffset = 10; // Starting y-coordinate for the text
        
          data.forEach((group, groupIndex) => {
            // Print the group tag
            // doc.text(`Tag: ${group.tag}`, 10, yOffset);
            doc.setFont('helvetica', 'bold'); 
            doc.text(`Tag ${groupIndex + 1}: ${group.tag}`, 10, yOffset);

            yOffset += 10; // Add space below the tag
        
            group.details.forEach((item, itemIndex) => {
              doc.setFont('helvetica', 'normal');
              doc.text(` • Title: ${item.title}`, 10, yOffset);
              yOffset += 10;
              doc.setFont('helvetica', 'normal');
              doc.text(` • Content: ${item.content}`, 10, yOffset);

              yOffset += 10;
              doc.setFont('helvetica', 'normal');
              doc.text(` • Level: ${item.level}`, 10, yOffset);
              yOffset += 15; // Add extra space before the next detail
        
              // Check if yOffset exceeds page height and create a new page if needed
              if (yOffset > 270) { // Adjust 270 as needed for page margin
                doc.addPage();
                yOffset = 10; // Reset yOffset for the new page
              }
            });
        
            yOffset += 10; // Space between different groups
          });
        
          doc.save('course-doc.pdf');
        };

        // const downloadTextFile = () => {
        //   let textContent = '';
        
        //   data.forEach((group, groupIndex) => {
        //     // Add numbered group tag (e.g., "Tag 1: Java")
        //     textContent += `Tag ${groupIndex + 1}: ${group.tag}\n\n`;
        
        //     group.details.forEach((item, itemIndex) => {
        //       textContent += `• Title: ${item.title}\n`;
        //       textContent += `• Content: ${item.content}\n`;
        //       textContent += `• Tag: ${item.tag}\n`;
        //       textContent += `• Level: ${item.level}\n\n`;
        //     });
        
        //     textContent += '-------------------\n'; // Separator between groups
        //   });
        
        //   const blob = new Blob([textContent], { type: 'text/plain' });
        //   const link = document.createElement('a');
        //   link.href = URL.createObjectURL(blob);
        //   link.download = 'course-data.txt';
        //   document.body.appendChild(link);
        //   link.click();
        //   document.body.removeChild(link);
        // };
        

        const downloadTextFile = () => {
          let textContent = '';
        
          data.forEach((group, groupIndex) => {
            // Add numbered group tag (e.g., "Tag 1: Java")
            textContent += `Tag ${groupIndex + 1}: ${group.tag}\n\n`;
        
            group.details.forEach((item, itemIndex) => {
              // Add bold headers for the fields
              textContent += `• Title:\n   ${item.title}\n`; // Indented value
              textContent += `• Content:\n   ${item.content}\n`;
              textContent += `• Tag:\n   ${item.tag}\n`;
              textContent += `• Level:\n   ${item.level}\n\n`;
            });
        
            textContent += '-------------------\n'; // Separator between groups
          });
        
          const blob = new Blob([textContent], { type: 'text/plain' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'course-data.txt';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        };
        
        

        // const downloadTextFile = () => {
        //   const data = [
        //     {
        //       "tag": "Java",
        //       "details": [
        //         {
        //           "title": "Java",
        //           "content": "Java Course",
        //           "level": "Beginner"
        //         },
        //         {
        //           "title": "Java",
        //           "content": "Java Course",
        //           "level": "Beginner"
        //         },
        //         {
        //           "title": "Java",
        //           "content": "Java Course",
        //           "level": "Beginner"
        //         }
        //       ]
        //     },
        //     {
        //       "tag": "Python",
        //       "details": [
        //         {
        //           "title": "Java",
        //           "content": "Java Course",
        //           "level": "Beginner"
        //         },
        //         {
        //           "title": "Java",
        //           "content": "Java Course",
        //           "level": "Beginner"
        //         }
        //       ]
        //     }
        //   ];
        
        //   let textContent = '';
        
        //   data.forEach((group,groupIndex) => {
        //     textContent += `Tag ${groupIndex + 1}: ${group.tag}\n\n`;

        //     // textContent += `Group ${groupIndex + 1}: Tag: ${group.tag}\n\n`; // Numbered group
        //     group.details.forEach((item) => {
        //       textContent += `Title: ${item.title}\nContent: ${item.content}\nLevel: ${item.level}\n\n`;
        //     });
        //     textContent += '-------------------\n'; // Separator between groups
        //   });
        
        //   const blob = new Blob([textContent], { type: 'text/plain' });
        //   const link = document.createElement('a');
        //   link.href = URL.createObjectURL(blob);
        //   link.download = 'course-data.txt';
        //   document.body.appendChild(link);
        //   link.click();
        //   document.body.removeChild(link);
        // };
        
        

    return (
        <div className='coursePriceContainer'>
          {contextHolder}
             <div className='coursePrice'>
            <div>
            <h3>Course Fee</h3>
            </div>
            <div className='alignRight'>
            <div className='searchBatch alignRight'>
                          <InputComponent onChange={(e)=>searchCoursePrice(e)} 
                          className='searchField' placeholder={'Search'}/>
                          <img src={searchIcon} className="searchIconBatches" style={{marginBottom:'15px',}}/>

                      </div>
                <img src={plus} className='exportIcons' onClick={(e)=>handleModal("Add Course Fee","")}/>
           
                <div style={{marginTop:"10px"}}>

<img src={excel} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  />

{/* <button onClick={downloadPDF}>Download PDF</button>
<button onClick={downloadTextFile}>Download Text File</button> */}

</div>
            </div>
        </div>
        <div>
        <TableComponent columns={columns} data={allAdminCoursePrices}/>

        </div>
        {feeModal ?
        <ModalComponent title={modalType}
        content={<AddCoursePrice
        courseId={courseId}
        allCourses={allCourses}
        price={price}
        startDate={startDate}
        endDate={endDate}
        handlePriceAndCourse={handlePriceAndCourse}
        handleDates={handleDates}
        onFinish={onFinish}
        modalType={modalType}
        form={form}
        closeModal={closeModal}/>}
          open={feeModal}
        closeModal={closeModal}
       />
        :""}
        </div>
       
    );
}

export default CoursePrice;
