import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Button, Form, Select ,Input,notification,DatePicker,Checkbox,Collapse, List, Typography,Row, Col } from 'antd';

import  InputComponent  from '../../commonComponents/InputComponent/input.js';
import { excel, plus, searchIcon,back_icon_png,no_data_image,download_icon
} from "../../images";

import moment from 'moment';
import {ModalComponent,TableComponent} from "../../commonComponents/index.js";
import DownloadCertificate from '../coursesContent/viewModal/downloadCertificate.js';

import {getCoursesContentAssignmentDetails,uploadStudentAssignmentDetails,getStudentAssifnemntDetails
    } from '../../redux/coursesContent/action.js'



const StudentUploadAssignment = () => {

    let dispatch = useDispatch();
    let navigate = useNavigate();

    const [formSection] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();


    const { coursesName, selectedCourseId, selectedBatchId,selectedContentId } = useParams();

    const [coursesContentData, setCoursesContentDataList] = useState([]);

    const [basePdfData, setBasePdfData] = useState("");
    const [visibleNotification, setVisibleNotification] = useState(false);
    const [assignmentStatusDetails, setAssignmentStatusDetails] = useState("");
    const [viewCertificate,setViewCertificate] = useState(false);

    const [viewCertificateName,setViewCertificateName] = useState("");
    const [viewCertificateTemplate, VsetiewCertificateTemplate] = useState("");
    const [viewCertificateType,setViewCertificateType] = useState("");
    const [viewCertificateSignature,setViewCertificateSignature] = useState("");
    const [validationCertificateId,setValidationCertificateId] = useState('');


    let getCoursesContentAssignment = useSelector((state)=> state?.CoursesContentReducer?.getCoursesContentAssignment);
    let uploadStudentAssignments = useSelector((state)=> state?.CoursesContentReducer?.uploadStudentAssignments);
    let getStudentAssignments = useSelector((state)=> state?.CoursesContentReducer?.getStudentAssignments);

    useEffect(()=>{
      if (getStudentAssignments) {
        setAssignmentStatusDetails(getStudentAssignments)
        setBasePdfData(getStudentAssignments[0]?.document)
      }
  },[getStudentAssignments])

  useEffect(()=>{

    let payload = {
      "operation_type": "retrieve",
      "op_field_details": {
          "content_id": selectedContentId,
          "tenant_id": window.localStorage.getItem("tenant_Id"),
          "student_id": window.localStorage.getItem("student_id")

      }
  }
        
  
  dispatch(getStudentAssifnemntDetails(payload))

},[])


    useEffect(()=>{    
      if (uploadStudentAssignments) {
  if(visibleNotification == true){
  
    if(uploadStudentAssignments?.res_status === true){

      openNotification(uploadStudentAssignments?.msg)

      navigate(`/studCourseContentDetails/${coursesName}/${selectedCourseId}/${selectedBatchId}`);

  
    }else{
      openNotification(uploadStudentAssignments?.msg)

    }
  
  }else{

  }
  
      }
  },[uploadStudentAssignments])

  const openNotification = (msg) => {
  
    api.info({
      description:
      `${msg}`,
  
    });
  
    setVisibleNotification(false)


  
  };

    useEffect(()=>{
        if (getCoursesContentAssignment) {
          setCoursesContentDataList(getCoursesContentAssignment)

          setViewCertificateName(getCoursesContentAssignment[0]?.certificate_name)
          VsetiewCertificateTemplate(getCoursesContentAssignment[0]?.certificate_image)
          setViewCertificateType(getCoursesContentAssignment[0]?.signature_type)
          setViewCertificateSignature(getCoursesContentAssignment[0]?.signature)
          setValidationCertificateId(getCoursesContentAssignment[0]?.certificate_image)

      
        }
    },[getCoursesContentAssignment])

    useEffect(()=>{
        
        let payload ={
            "operation_type":"retrieve",
            "category":"Assignment",
            "content_id":selectedContentId
    
        }
      dispatch(getCoursesContentAssignmentDetails(payload))
  
    },[])

    const backMethod = () => {
        navigate(`/studCourseContentDetails/${coursesName}/${selectedCourseId}/${selectedBatchId}`);

                };


                // const convertToBase64 = (file) => {
                //     return new Promise((resolve, reject) => {
                //       const fileReader = new FileReader();
                //       if (file) {
                //         fileReader.readAsDataURL(file);
                //         fileReader.onload = () => {
                //           resolve(fileReader.result);
                //         };
                //       }
                     
                //       fileReader.onerror = (error) => {
                //         reject(error);
                //       };
                //     });
                //   };
          
          
                //   let  onFileChange = async(event,type)=>{
                //     const file = event.target.files[0];
                //   const base64 = await convertToBase64(file);
                //   console.log(base64,"ppp")
                 
                //    }            

 
                const onFileChange = async (event, type) => {
                    const file = event.target.files[0];
                  
                    // Validate if the file is a PDF
                    const allowedFileType = "application/pdf"; // Only PDF allowed
                    if (file && file.type !== allowedFileType) {
                      // Show an alert or a message to the user if the file is not a PDF
                      alert("Only PDF files are allowed!");
                      
                      
                      // Clear the file input safely by resetting it to an empty string
                      event.target.value = '';  // This resets the input field
                      
                      return;
                    }
                  
                    // Proceed with converting to Base64 if the file is valid
                    const base64 = await convertToBase64(file);
                    console.log(base64, "ppp");
                  };
                  
                  const convertToBase64 = (file) => {
                    return new Promise((resolve, reject) => {
                      const fileReader = new FileReader();
                      if (file) {
                        fileReader.readAsDataURL(file);
                        fileReader.onload = () => {
                          resolve(fileReader.result);
                        };
                      }
                  
                      fileReader.onerror = (error) => {
                        reject(error);
                      };
                    });
                  };
                
                  
                  const [file, setFile] = useState(null);

                  // Handle file selection
                //   const handleFileChange = (event) => {
                //     const selectedFile = event.target.files[0];
                //     if (selectedFile && selectedFile.type === "application/pdf") {
                //       setFile(selectedFile);
                //       console.log("PDF file selected:", selectedFile);
                //     } else {
                //       alert("Please upload a valid PDF file.");
                //     }
                //   };

                const handleFileChange = (event) => {
                    const selectedFile = event.target.files[0];
                    
                    if (selectedFile && selectedFile.type === "application/pdf") {
                      // Create a new FileReader instance
                      const reader = new FileReader();

                      const base64 =  convertToBase64(selectedFile);

                      console.log("Base64 encoded file:", base64);
                  
                      // Define what to do once the file is successfully read
                      reader.onloadend = () => {
                        // The result is the Base64 string of the file
                        const base64String = reader.result.split(',')[1]; // Remove the data URL prefix
                        const dataUrl = `data:application/pdf;base64,${base64String}`;
                        console.log("Base64 encoded file:", dataUrl);

                        setBasePdfData(dataUrl)
                        // You can now use this Base64 string (base64String) for further processing
                        setFile({
                          file: selectedFile,
                          base64: base64String,
                        });
                  
                        console.log("Base64 encoded file:", base64String);
                      };
                  
                      // Start reading the file as a Data URL (Base64)
                      reader.readAsDataURL(selectedFile);
                    } else {
                      alert("Please upload a valid PDF file.");
                    }
                  };


                  let handleDeleteCancel= ()=>{

                 
                    navigate(`/studCourseContentDetails/${coursesName}/${selectedCourseId}/${selectedBatchId}`);

                
                  } 
                  

                  let onFinishSectionAdd= (values) =>{

                    let payload = {
                      "operation_type": "insert",
                      "op_field_details": {
                          "student_id": window.localStorage.getItem("student_id"),
                          "content_id": selectedContentId,
                          "document": basePdfData,
                          "batch_id": selectedBatchId,
                          "course_id": selectedCourseId,
                          "instructor_id": window.localStorage.getItem("instructor_id"),
                          "tenant_id": window.localStorage.getItem("tenant_Id")
                      }
                  }

                  //   let payload = {
                  //     "operation_type":"insert",
                  //     "op_field_details":{
                  //         "category":"Section",
                  //         "title": coursesContentPayloadDetails.title,
                  //         "description":coursesContentPayloadDetails.description,
                  //         "xp":coursesContentPayloadDetails.xp === '' ? null :coursesContentPayloadDetails.xp,
                  //         "batch_id":selectedBatchId,
                  //         "instructor_id":window.localStorage.getItem("instructor_id"),
                  //         "course_id":selectedCourseId,
                  //         "tenant_id":window.localStorage.getItem("tenant_Id"),
                  //         "badge_id":selectedId,
                  //         "certificate_id":selectedCertificate
                  //     }
                  // }
            
            
                    
                      dispatch(uploadStudentAssignmentDetails(payload))
                  
                      setVisibleNotification(true)
                  
                  
                  }


                  let handleAddCoursesComponent= () =>{
                    setViewCertificate(true)

            }

            let  closeDeleteModal = () =>{
              setViewCertificate(false)
            }
                  
    return (
        <div>

  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
  <h3 style={{ display: 'flex', alignItems: 'center', margin: 0 }}>
    <img 
      src={back_icon_png} 
      alt="Back" 
      style={{ marginRight: '8px', cursor: 'pointer', height: '25px', width: '25px' }} 
      onClick={() => backMethod()} 
    />
    Upload Assignment
  </h3>

 
</div>


<div>
 {contextHolder}
<div style={{ display: 'flex', flexDirection: 'row' }}>

<div style={{ flex: 1 }}>
<div style={{ minHeight: '200px', maxHeight: '300px', overflowY: 'auto' }}>
  <Form
    form={formSection}
    onFinish={onFinishSectionAdd}
    autoComplete="off"
    layout="vertical"
  
  >







    <p><strong>XP:</strong> {coursesContentData[0]?.xp ?? 'N/A'}</p>
    <p>
  <strong>Due Date:</strong>{" "}
  {coursesContentData?.[0]?.due_date
    ? moment(coursesContentData[0].due_date).format("YYYY-MM-DD")
    : "N/A"}
</p>
<p><strong>Badges : </strong> {coursesContentData[0]?.badge_name || 'N/A'}</p>
     <p><strong>Certificate : </strong> {coursesContentData[0]?.certificate_image === null ? 'N/A' : 'Available'}</p>

     <p><strong>Title:</strong> {coursesContentData[0]?.title}</p> 
     <p><strong>Description:</strong> {coursesContentData[0]?.description}</p>

   

     <div
 style={{
  border: "1px solid #ddd",
  borderRadius: "8px",
  padding: "2px",
  margin: "10px",
  backgroundColor: "#f9f9f9",
}}
>
<Form.Item
        // label="Upload a PDF File"
        name="reference_document"
        rules={[
          {
            required: true,
            message: 'Please upload a file!',
          },
        ]}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            style={{ flex: 1 }}
          />
          {file && (
            <span style={{ marginLeft: '10px', color: '#1890ff' }}>
              {file.name}
            </span>
          )}
        </div>
      </Form.Item>

</div>





  </Form></div>
</div>


<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '0.1px',            // Set the width of the line
      height:'40vh',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
      marginLeft:'10px',marginRight:"10px"     // Ensure the line spans the height of the flex container
    }}
  />  

<div style={{ flex: 1 }}>
<div style={{ minHeight: '200px', maxHeight: '300px', overflowY: 'auto' }}>
<Form
        //   form={formSection}
        //   onFinish={onFinishSectionAdd}

          autoComplete="off"
          layout="vertical"
        
        >

<div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
    <h3>Preview</h3>
    </div>

    {assignmentStatusDetails[0]?.status === 'pending' ?
     (<div></div>) :
     ( <div>

<p><strong>XP:</strong> {assignmentStatusDetails[0]?.xp}</p> 
<p><strong>Status:</strong> {assignmentStatusDetails[0]?.status === 'pending' ? "Pending" :
assignmentStatusDetails[0]?.status === 'approved' ? 'Approved':'Rejected'}</p> 

{assignmentStatusDetails[0]?.status === 'approved' && validationCertificateId != null
? (<div style={{ display: 'flex', alignItems: 'center' }}>
  <strong>Certificate : </strong>
  <img 
    src={download_icon} 
    alt="Add" 
            onClick={() => handleAddCoursesComponent()} 

    style={{ height: '25px', width: '25px', cursor: 'pointer', marginLeft: '5px' }} 
  />
</div>) : (<div></div>)}

      </div>)
     }

     


<div style={{marginBottom:"10px"}}></div>


    {basePdfData === '' || basePdfData === null ?
     <div style={{ textAlign: 'center', padding: '20px' }}>
     <img src={no_data_image} alt="No Data Available" style={{ width: '200px', height: 'auto' }} />
     <p>Preview not available</p>
   </div>:<div style={{ height: '100vh', width: '100%' }}>
      <iframe
        src={basePdfData}
        title="PDF Viewer"
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    </div>
  }



  
   





        </Form>
</div>

</div>




  </div>


  <div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 


<Form
          form={formSection}
          onFinish={onFinishSectionAdd}

          autoComplete="off"
          layout="vertical"
          initialValues={{
          }}
        >
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Form.Item>



              <Button htmlType='submit' type='primary' style={{ marginRight: '8px', width:"110px" }} >
                Upload
              </Button>
            
              <Button
  htmlType="submit"
  onClick={handleDeleteCancel}
  style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px"
  }}
>
  Cancel
</Button>

            </Form.Item>
 </div>
        </Form>


      </div>

      {viewCertificate ? 
  <ModalComponent title={"Download Certificate"}
  width={1000}  height={1000}

  content={<DownloadCertificate 
    viewCertificateName={viewCertificateName}
    viewCertificateTemplate={viewCertificateTemplate}
    viewCertificateType={viewCertificateType}
    viewCertificateSignature={viewCertificateSignature}
   handleCancel={closeDeleteModal}
   />}
open={viewCertificate} 
handleCancel={closeDeleteModal}
closeModal={closeDeleteModal}

style={{
  maxHeight: '100px',
  minHeight: '100px',
  width: '80%', // You can adjust this value based on your preference
  maxWidth: '900px', // Optional: Set a maximum width if needed
}}
 ></ModalComponent>
:""}


        </div>
    );
}

export default StudentUploadAssignment;
