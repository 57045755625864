



import { Button, DatePicker, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import {ModalComponent,TableComponent} from "../../../commonComponents/index.js";
import CertificateViewModal from '../viewModal/certificateView.js';


const SectionViewModal = (props) => {


  const [viewCertificateName,setViewCertificateName] = useState(props.coursesContentData[0]?.certificate_name);
  const [viewCertificateTemplate, VsetiewCertificateTemplate] = useState(props.coursesContentData[0]?.certificate_image);
  const [viewCertificateType,setViewCertificateType] = useState(props.coursesContentData[0]?.signature_type);
  const [viewCertificateSignature,setViewCertificateSignature] = useState(props.coursesContentData[0]?.signature);
  const [viewCertificate,setViewCertificate] = useState(false);

  const viewItemCertificate = () => {
 setViewCertificate(true)
};

let handleDeleteCancel= ()=>{
setViewCertificate(false)
} 

let  closeDeleteModal = () =>{
  setViewCertificate(false)
}



 

  return (
    <div
    style={{
      border: "1px solid #ddd",
      borderRadius: "8px",
      padding: "10px",
      margin: "10px 0",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#f9f9f9",
    }}
  >
   
  
    <div style={{ marginBottom: "10px" }}>
      <h4 style={{ margin: "0", color: "#555" }}>Title:</h4>
      <p style={{ margin: "5px 0", color: "#333",  fontSize: "14px" }}>
        {props.coursesContentData[0]?.title || "No title available"}
      </p>
    </div>
  
    <div>
      <h4 style={{ margin: "0", color: "#555" }}>Description:</h4>
      <p style={{ margin: "5px 0", color: "#333", fontSize: "14px" }}>
        {props.coursesContentData[0]?.description || "No description available"}
      </p>
    </div>

    <div>
      <h4 style={{ margin: "0", color: "#555" }}>XP:</h4>
      <p style={{ margin: "5px 0", color: "#333", fontSize: "14px" }}>
        {props.coursesContentData[0]?.xp || "No xp available"}
      </p>
    </div>

    <div>
      <h4 style={{ margin: "0", color: "#555" }}>Badges Name:</h4>
      <p style={{ margin: "5px 0", color: "#333", fontSize: "14px" }}>
        {props.coursesContentData[0]?.badge_name || "No badges name available"}
      </p>
    </div>


    <div>
      <h4 style={{ margin: "0", color: "#555" }}>Badges Image:</h4>
      {
  (!props.coursesContentData[0]?.image || props.coursesContentData[0]?.image === "") ? (
    <p style={{ margin: "5px 0", color: "#333", fontSize: "14px" }}>
      {"No badges image available"}
    </p>
  ) : (
    <img
      src={props.coursesContentData[0]?.image}
      style={{ height: "50px", width: "50px", marginTop: "10px" }}
    />
  )
}


    </div>

    <div>
      <h4 style={{ margin: "0", color: "#555" }}>Certificate:</h4>

      {props.coursesContentData[0]?.certificate_id === null || props.coursesContentData[0]?.certificate_id === ''
      ? <p style={{ margin: "5px 0", color: "#333", fontSize: "14px" }}>
      {"No certificate available"}
    </p> : <div> 
        

    <button
          style={stylesCertificate.viewButton}
          onClick={(e) => {
            e.stopPropagation(); // Prevent item click event when view button is clicked
            viewItemCertificate();
          }}
        >
          View
        </button>

       </div>}

     
    </div>




    {viewCertificate ? 
  <ModalComponent title={"View Certificate"}
  width={1000}  height={1000}

  content={<CertificateViewModal 
    viewCertificateName={viewCertificateName}
    viewCertificateTemplate={viewCertificateTemplate}
    viewCertificateType={viewCertificateType}
    viewCertificateSignature={viewCertificateSignature}
   handleCancel={handleDeleteCancel}
   />}
open={viewCertificate} 
handleCancel={handleDeleteCancel}
closeModal={closeDeleteModal}

style={{
  maxHeight: '100px',
  minHeight: '100px',
  width: '80%', // You can adjust this value based on your preference
  maxWidth: '900px', // Optional: Set a maximum width if needed
}}
 ></ModalComponent>
:""}

  </div>
  
  );
}

const stylesCertificate = {
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
    padding: "15px",

  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "0.3s" ,// Smooth transition for style change
    backgroundColor: "#f0f0f0", // Default background color

  },
  image: {
    marginTop: "10px",
    width: "150px",
    height: "80px",
    objectFit: "cover",
    borderRadius: "5px"
  },
  name: {
    marginTop: "10px",
    fontSize: "10px",
    textAlign: "center",
    color: "#333"
  },
  deleteButton: {
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    backgroundColor: "#ff4d4f",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer"
  },
  viewButton: {
    backgroundColor: "#4caf50",
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginLeft:"5px",
    marginRight:'5px'
  },
};

export default SectionViewModal;
