import {bell,
    messageIcon,
    profileIcon,
    searchIcon,logo,dashboard,
    allStudents,
    allInstructors,
    announcements,
    accounts,
    roles,
    settings,
    schedules,
    allCourses,
    batchIcon,
    studentDahboardIcon,
    studentAllCoursesIcon,

    studentRegCoursesIcon,
    studentSchedules,
    studentAssignments,
    studentChatIcon,
    studentBatches,
    instructorStudents,
    income,
    adminCourseIcon,
    adminSchedulesIcon,
    new_messages,new_earing,new_schedule,home_dash_board,new_batches,new_students,new_all_courses,new_registred_courses,
    new_assignments


} from '../../../images';
    
    let studentMenuDetails = {
            Schedules:new_schedule,
            Messages:new_messages,
            "All Courses":new_all_courses,
            "Registered Courses":new_registred_courses,
            Assignments:new_assignments,
            Dashboard: home_dash_board,
            "Batches":new_batches,
            "Students":new_students,
            "Earnings": new_earing,
            "Tenant Approval" :instructorStudents,
            "Tenant Management" :studentBatches,
            "Courses":adminCourseIcon,
            "Students":allStudents,
            "Instructors":allInstructors,
            "Course Fee":allCourses,
            "Course Category":accounts,
            "Classes Info":allStudents,
            "Announcements":announcements,
            "Course Fee":accounts,
            "Settings":settings,
            "Reports":roles,
            "Student Fee":accounts,
            "Enquiries":accounts,
            "Demo Class":allStudents,
            "Course Content":adminCourseIcon


        }

export default studentMenuDetails