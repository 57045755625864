import React, { useDebugValue, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { faceBookIcon,googleIcon,logo,bannerIcon } from "../../../images"

import { InputComponent,ButtonComponent,SwitchComponent,InputSelect,ModalComponent } from "../../../commonComponents";
import { Col, Form, Row,notification,DatePicker ,Select,Checkbox,Alert} from 'antd';
import PrivacyPolicyStudent from '../../Register/privacyPolicyStudent';
import moment from 'moment';


import './register.css'
import LoginComponent from "../../Login/login";
import { NavLink } from "react-router-dom";
// import VerifyEmail from "./verifyEmail";

import confirmPassword from './confirmPassword';
import ConfirmPassword from "./confirmPassword";
import InstructorComponent from "../login";
import { getCategories1 } from "../../../redux/allCategories/action";
import { useDispatch,useSelector } from "react-redux";
import { createInstructor } from "../../../redux/instructorRegister/action";
import { type } from "@testing-library/user-event/dist/type";
import { courseNameBasedOnCourseCategory, studentRegistrtionSuccess ,retrieveInterestedCourses,
  privacyPolicy} from "../../../redux/studentRegister/action";


let RegisterComponent = () =>{

    let[login,setLogin] = useState(false);


    const [form] = Form.useForm();
    let dispatch = useDispatch()
    let navigate = useNavigate();
    let studentLogin = () =>{
        setLogin(true)
    }
    let [msg, setMsg] = useState(false);
    const [date, setDate] = useState(null); // State to manage the selected date

    const [api, contextHolder] = notification.useNotification();


    let allCategories = useSelector((state)=> state.categories?.category?.data);

    let registrationSuccess = useSelector((state)=>state.instructorRegisterReducer.instructorReg);
    
    let registrationEmail = useSelector((state)=>state.instructorRegisterReducer.instructorReg?.email);
    let interestedCoursesDetails = useSelector((state)=>state.studentRegisterReducer.retrieveCoursesDetails);

    let [categories,setCategories] = useState([]);
  let [checked, setChecked] = useState(false);

  let [studentDetails,setStudentDetails] = useState(true);
  let [interestedId,setInterestedId] = useState();

  let [courseCategoryId,setCourseCategoryId] = useState();


  let [categoryDropdown,setCategoryDropdown] = useState();

  let [instructorDetails,setInstructorDetails] = useState(false);

  let [email,setEmail] = useState(" ");

  let [password,setPassword] = useState(" ");

  let [studentRegisterForm,setStudentRegisterForm] = useState(false)

  let[verifyPage,setVerifyPage] = useState(false)

  let [startDate,setStartDate] = useState("");

  


  let [studentRegDetails,setStudentRegDetails] = useState({
    first_name:"",
    last_name:"",
    gender:"",
    age:"",
    phone_number:"",
    email:"",
    address:"",
    uploadResume:"",
    course_category_id:""
  })

  let handleChange =(e,type)=>{

   

    if (type.name !="gender" &&  type.name !="age" && type.name!="course_category_id") {
      setStudentRegDetails({...studentRegDetails,[type.name]:e.target.value})
    }else{
      setStudentRegDetails({...studentRegDetails,[type.name]:e})
      form.setFieldsValue({[type.name]: e})


    }

    console.log(studentRegDetails,"regForm")
  }

  useEffect(()=>{


    if (registrationSuccess) {

      console.log(registrationSuccess,"enquiery")

      openNotification(registrationSuccess)


      // navigate("/instructorVerify",{state:{email:registrationEmail}})

    }
  },[registrationSuccess])



  // useEffect(()=>{
   
  //   let registrationPayload = {
  //     "operation_type":"course_retrieve",
  //     "tenant_id":1
  // }

  // console.log(registrationPayload,'registrationPayload')
  //  dispatch(retrieveInterestedCourses(registrationPayload)) 
  // },[])

  useEffect(()=>{
    if (interestedCoursesDetails) {
      setCategoryDropdown(interestedCoursesDetails)
      // navigate("/verifyEmail",{state:{email:registrationEmail}})
      console.log(interestedCoursesDetails,"stages")
    }
  },[interestedCoursesDetails])

  const openNotification = (status) => {
    
    console.log(msg.res_status,'srikant')
    console.log(msg,"srikant")

    if(msg == true){
      if(status.res_status == true){

   
        setTermsError(false);
        setChecked(false)
        form.resetFields();
    
        setStudentRegDetails({
          first_name:"",
          last_name:"",
          gender:"",
          age:"",
          phone_number:"",
          email:"",
          address:"",
          uploadResume:"",
          course_category_id:""
        })
    
        setDate(null); 
        setStartDate(null); // Clear the startDate state
        form.setFieldsValue({ startDate: null });

        setMsg(false)

    
        api.info({
          description:
          `${status.msg}`,
    
        });
        
      
       }else{
        api.info({
          description:
          `${status.msg}`,
    
        });
       }
    }else{

    }

    


 
};

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) {
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
      }
     
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  let  onFileChange = async(event,type)=>{
    const file = event.target.files[0];
  const base64 = await convertToBase64(file);
  console.log(base64,"ppp")
  setStudentRegDetails({ ...studentRegDetails, [type.name]: base64});
    // decodeBase64(base64)
      console.log(studentRegDetails,'regForm')
   }


   const convertDateFormat = (dateString) => {
    // Split the date string by '-' to get [YYYY, MM, DD]
    const [year, month, day] = dateString.split('-');
    // Return the date in 'DD-MM-YYYY' format
    return `${day}-${month}-${year}`;
  };

  const onFinish = (values) => {
    console.log('Success:', values);
    // setVerifyPage(true)

    if (!checked) {
      setTermsError(true);
      return;
  } else {
      setTermsError(false);
  }

    if(checked == true){
      if (values) {

        setMsg(true)
        // setVerifyPage(true)
        // navigate("/instructorVerify")
        let createPayload = {
          "operation_type":"instructor_enquiry",
          "op_field_details":{
                "instructor_first_name": studentRegDetails.first_name,
                "instructor_last_name": studentRegDetails.last_name,
                "email": studentRegDetails.email?.toLowerCase(),
                "address": studentRegDetails.address,
                "gender": studentRegDetails.gender,
                "phone_no": studentRegDetails.phone_number,
                "tenant_id":window.localStorage.getItem("login_tenant_id"),
                "course_category_id": courseCategoryId,
                "file": studentRegDetails.uploadResume,
                "date_of_birth":moment(startDate).format("YYYY-MM-DD"),
                "course_id": interestedId,
  
          }
       }
  
       console.log(createPayload,"createPayload")
        dispatch(createInstructor(createPayload))
      }
    }else{

    }

   
  };

  let dummy = ()=>{
    setVerifyPage(true)
  }

  let handleEmailChange = (e) =>{
      setEmail(e.target.value)
  }

  let handlePasswordChange = (e) =>{
    setPassword(e.target.value)
  }

  let studentForm = () =>{
    setInstructorDetails(false);
    setStudentDetails(true)
  }

  let instructorForm = () =>{
    setInstructorDetails(true);
    setStudentDetails(false)
  }

  let studentRegister = () =>{
    setStudentRegisterForm(true)
  }

  let registerStudent = () =>{
    // navigate("/confirmPassword")
    navigate("/verifyEmail",{state:{email:registrationEmail}})
  }

  useEffect(()=>{
    dispatch(getCategories1())
  },[])
  let list = []
  for(let x=18;x<=50;x++){
   let newObj= {};
      newObj["value"] = x;
      newObj["label"] = x;
    list.push(newObj)
 }

 useEffect(()=>{
  let obj=[]

  allCategories && Object.values(allCategories).map((i,Index)=>{
   let newObj= {};
   newObj["value"] = i.course_category_id;
   newObj["label"] = i.course_category;
 obj.push(newObj)
 })
 console.log(allCategories,"ppp")
 setCategories(obj)

 },[allCategories])

 const [dateError, setDateError] = useState('');

 let handleDateChange = (dt,e,type)=>{

  const age = moment().diff(e, 'years');

  console.log(e,age,"DOB")

  if (age < 18) {
    setDateError('You must be at least 18 years old.');
    // form.setFieldsValue({ startDate: null });
  } else {
    setDateError('');
    setStartDate(e)
    setDate(dt); 

    console.log(e,"DOB")
    form.setFieldsValue({ startDate: e })  
  }
  


  
}

let handleCategoryNameChange = (e,type) =>{
 

  setInterestedId(e)
  form.setFieldsValue({[type.name]:e})





}



let handleChangeCategoryId = (e,type) =>{
 

  console.log(e,'id')
  setCourseCategoryId(e)
    
  let registrationPayload = {
    "operation_type": "course_detail",
    "tenant_id":window.localStorage.getItem("login_tenant_id"),
    "course_category_id":e
}

console.log(registrationPayload,'registrationPayload')
 dispatch(courseNameBasedOnCourseCategory(registrationPayload)) 
  // setInterestedId(e)
  form.setFieldsValue({[type.name]:e})





}

let privacyPolicyDetails = useSelector((state)=>state.studentRegisterReducer.privacyPolicy);


let [privacyPolicyData,setPrivacyPolicyData] = useState();

useEffect(()=>{
  let registrationPayload = {
    
    "operation_type":"retrieve",
    "role_type":"student",
    "tenant_id":window.localStorage.getItem("login_tenant_id")
}

 dispatch(privacyPolicy(registrationPayload)) 
  
},[])


useEffect(()=>{

  if(privacyPolicyDetails){
    setPrivacyPolicyData(privacyPolicyDetails)
  }
  
},[privacyPolicyDetails])

let [tearmAndCondition,setTearmAndCondition] = useState(false);


const handleClick = () => {
  setTearmAndCondition(true)
};

let handleStudentCancel = () =>{
  setTearmAndCondition(false)
  setChecked(false);
  setTermsError(true);
 
}

const [termsError, setTermsError] = useState(false);


let handleViewStudent = (Id) =>{
 
  setTearmAndCondition(true)
  }


  let handleViewStudent1 = (e) =>{

    setChecked(e.target.checked);
    setTermsError(!e.target.checked);

    setTearmAndCondition(true)

  //   setChecked(e.target.checked)

  //   if (!checked) {
  //     setTermsError(false);

  // } else {
  //   setTermsError(true);

  // }
    }

    let handleSubMit = () =>{
      setTearmAndCondition(false)

      setChecked(true);
      setTermsError(false);
     
    }


    const disabledDate = (current) => {
      // // Get the current date minus 18 years
      // const eighteenYearsAgo = moment().subtract(18, 'years');
      // // Disable dates that are after the date 18 years ago (age < 18)
      // return current && current.isAfter(eighteenYearsAgo);

      const today = moment();
      // Disable dates that are in the future
      return current && current.isAfter(today, 'day');
    };

    return (
        <>

{contextHolder}
        {!login ?
        <div className="">


        {/* <div className='logo'>
           <img src= {bannerIcon} className='bannerImage'/>
        </div> */}
        <div className=''>
          <div className='form'>
          
  
               <div className="registerForm">
                <Form 
                  autoComplete="off"
                  layout="vertical"
                  form={form}
                    onFinish={onFinish}
                    >
                    <Row  className="regForm" >
                    <Form.Item
                     label="First Name"
                     name="first_name"
                     rules={[
                       {
                         required: true,
                         message: 'First name is required',
                         
               
                       },
                     ]}>
                  <div> <Col xs={2} sm={4} md={6} lg={24} xl={24}>
                  <div className='regInput'><InputComponent name={"first_name"}
                  onChange={(e)=>handleChange(e,{name: "first_name"})} 
                  value={studentRegDetails.first_name} placeholder={"First Name"}/></div>
                  </Col>
                  </div>
                  </Form.Item>

                  <Form.Item
                     label="Last Name"
                     name="last_name"
                     rules={[
                       {
                         required: true,
                         message: 'Last name is required',
                         
               
                       },
                     ]}>
                  <div>
                 
                    <Col xs={2} sm={4} md={6} lg={24} xl={24}>
  
                  {/* <div className="formLabel"><label>Last Name <span className="star">*</span></label></div>  */}
                  <div className='regInput'><InputComponent name={"last_name"}
                      onChange={(e)=>handleChange(e,{name: "last_name"})} 
                      value={studentRegDetails.last_name}
                      placeholder={"Last Name"}/></div>
                    
                  </Col>
                  
                  </div>
                  </Form.Item>
              </Row>
  
              <Row className="genderContainer">
              <Form.Item
                     label="Gender"
                     name="gender"
                     rules={[
                       {
                         required: true,
                         message: 'Gender is required',
                         
               
                       },
                     ]}>    
                  <Col xs={2} sm={4} md={6} lg={8} xl={5}>
                 
                  <div className="selectGender">
                  <InputSelect 
                  name={"gender"}
                  onChange={(e)=>handleChange(e,{name: "gender"})}
                  defaultValue={studentRegDetails.gender ? studentRegDetails.gender : "Select"}
                  value={studentRegDetails.gender ? studentRegDetails.gender : ""}
                     options={[
                      {
                          value: "Male",
                          label: 'Male',
                        },
                        {
                          value: "Female",
                          label: 'Female',
                        },
                    
                    ]}/></div>
                  </Col>
                  </Form.Item>

                  <Form.Item
                     label="Course Category"
                     name="course_category_id"
                    //  className="courseCategoryInstructor"

                     rules={[
                       {
                         required: true,
                         message: 'Course category is required',
               
                       },
                     ]}>   
                  <Col xs={20} sm={16} md={12} lg={8} xl={5}>
                  <div className=""><InputSelect
                  name={"course_category_id"}
                  className="courseCategoryInstructor"
                  onChange={(e)=>handleChangeCategoryId(e,{name: "course_category_id"})}
                    defaultValue={studentRegDetails.course_category_id ? studentRegDetails.course_category_id : "Select"}
                    value={studentRegDetails.course_category_id ? studentRegDetails.course_category_id : ""}
                     
                     options={categories}/></div>
                  </Col>
                  </Form.Item>

                  <Form.Item
                       label="Course Name"
                       name="course_id"
                        rules={[
                          { required: true, message: 'Please select course name' }
                          ]}>
                      <Select name={"course_category_id"} 
                      // value={formValues.course_category_id} 
                      // defaultValue={studentRegDetails.course_id ? studentRegDetails.course_id : "Select"}
                      onChange={(e)=>handleCategoryNameChange(e,{name:"course_id"})}>
                          {categoryDropdown && categoryDropdown.data?.map((item,index)=>{
                          return  <Select.Option value={item.course_id}>{item.course_name}</Select.Option>
                          })}
                        </Select>

                      
                      </Form.Item>

               


                  



              </Row>

              <Row className="genderContainer">

              <div >


            


              <Form.Item
                         label="DOB"
                          name="startDate"
                          rules={[{ required: true, message: 'Please select dob.' }]}>
                          <div className='batchDate'>
                        <DatePicker name='startDate'
                        disabledDate={disabledDate}
                        // onChange={handleDateChange}

                      onChange={(e,dateString)=>handleDateChange(e,dateString,{name:"start_date"})}

                        //  onChange={(date,dateString)=>setStartDate(dateString)}
                        value={date} 
                          />

                        </div>
                        </Form.Item>
                        {dateError && <Alert message={dateError} type="error" showIcon />}

                  </div>

            

             
              </Row>
                    
              <Row className="">
                  <Form.Item label="Upload Resume"
                     name="uploadResume"
                     rules={[
                       {
                         required: true,
                         message: 'File  is required',
               
                       },
                     ]}>
                   <div className="instructorResumeUpload">
                  <InputComponent type="file" name={"uploadResume"}  style={{"display": "none"}}
                  onChange={(e)=>onFileChange(e,{name: "uploadResume"})}
                  className="instructorResumeUpload"/>
                  </div>
                  </Form.Item>
              </Row>
  
              <Row  className="regForm">
              <Form.Item
                     label="Phone number"
                     name="phone_number"
                     rules={[
                       {
                         required: true,
                         message: 'Phone number is required only numbers allowed',
                         pattern: new RegExp(/^[0-9]+$/),
                        
                       },
                       {
                        max: 10,
                        min:10,
                        message: "10 digit mobile number is required",
                      },

                     ]}>  
                  <div><Col xs={20} sm={16} md={12} lg={8} xl={8}>
                 
                  <div className='regInput'><InputComponent 
                   name={"phone_number"}
                   onChange={(e)=>handleChange(e,{name: "phone_number"})}
                     defaultValue={studentRegDetails.phone_number} placeholder={"Phone Number"} /></div>
                  </Col>
                  
                  </div>
                  </Form.Item>
                  <Form.Item
                     label="Email"
                     name="email"
                     rules={[
                       {
                         required: true,
                         message: 'Email is required',
                         type:"email"
               
                       },
                     ]}>
                  <div><Col xs={20} sm={16} md={12} lg={8} xl={8}>
                  <div className='regInput'><InputComponent name={"email"} 
                   onChange={(e)=>handleChange(e,{name: "email"})}
                   value={studentRegDetails.email}
                    placeholder={"Email"}/>
                   </div>
                  </Col>
                  </div>
                  </Form.Item>
              </Row>
  
              <Row className="addressSelection">
              <Form.Item
                     label="Address"
                     name="address"
                     rules={[
                       {
                         required: true,
                         message: 'Address is required',
                         
               
                       },
                     ]}>
                  <Col xs={2} sm={4} md={6} lg={24} xl={24}>
                  <div className="addressField"><InputComponent
                  name={"address"}
                  onChange={(e)=>handleChange(e,{name: "address"})}
                  value={studentRegDetails.address}
                  placeholder={"Enter Address"}/></div>
                  </Col>
                  </Form.Item>
              </Row>

              <Form.Item 
              >
              
                                        <div>
                                            <Checkbox checked={checked} onChange={(e) => handleViewStudent1(e)}>
                                             
                                            </Checkbox>

                                            <span onClick={handleViewStudent} 
                                                style={{ cursor: "pointer", color: "black", marginLeft:"10px"}}>I agree to the  Terms and Conditions</span>
                                            {termsError && <div style={{ color: "red" }}>You must agree to the terms and conditions</div>}
                                        </div>
                                    </Form.Item>
  
               {/* <div className="terms">
                  <div className='switch'>
                  <div><SwitchComponent /><span className='rememberMe underLine' onClick={handleClick}>Terms and conditions</span></div>
  
                  </div>
              </div> */}
                     
                     <Form.Item>
              <div className="regsiter">
                 {/* <NavLink to={'/confirmPassword'}> */}
                  <ButtonComponent value={'REGISTER'} htmlType={'Submit'} className={"btnLogin"} />
                  {/* </NavLink>  */}
              </div>
                </Form.Item>
                {/* <div className="textCenter loginNow">
                      <p><b>Register</b> with Others</p>
                  </div>
              <div className='socialIcons'>
                          <div className='facebookIcon'>
                              <img src={faceBookIcon}  className="socialIcon"/>
                          </div>
                          <div className='googleIcon'>
                              <img src={googleIcon}  className="socialIcon"/>
                          </div>
                      </div> */}
  
                  <div className="textCenter loginNow" style={{marginTop:"10px",marginBottom:"10px"}}>
                      <p onClick={dummy}>You have account ?<b className="underLine" onClick={studentLogin}>Login Now</b></p>
                  </div>
                  </Form>

         </div>
             
          </div>
        </div>
       </div>
       : <InstructorComponent/>}


{tearmAndCondition ? 
        <ModalComponent title={""}  content = {<PrivacyPolicyStudent privacyPolicyData = {privacyPolicyData}     handleSubMit={handleSubMit}
/>}
        open={handleViewStudent} handleCancel={handleStudentCancel} handleSubMit={handleSubMit}/>
    : ""}
       </>
    )
}

export default RegisterComponent