export const GET_ACTIVE_COURSES_DETAILS = "GET_ACTIVE_COURSES_DETAILS"
export const GET_ACTIVE_COURSES_DETAILS_BASED_ON_INSTRUTOR_ID = "GET_ACTIVE_COURSES_DETAILS_BASED_ON_INSTRUTOR_ID"
export const GET_COURSE_CONTENT = "GET_COURSE_CONTENT"

export const ADD_COURSE_CONTENT_SECTION = "ADD_COURSE_CONTENT_SECTION"
export const ADD_COURSE_CONTENT_ASSIGNMENT = "ADD_COURSE_CONTENT_ASSIGNMENT"
export const ADD_COURSE_REFERENCE = "ADD_COURSE_REFERENCE"

export const COURSES_CONTENT_DRAG_AND_DROP = "COURSES_CONTENT_DRAG_AND_DROP"

export const DELETE_COURSE_CONTENT = "DELETE_COURSE_CONTENT"

export const ADD_COURSE_CONTENT_DETAILD = "ADD_COURSE_CONTENT_DETAILD"

export const ADD_COURSE_CONTENT_Q_AND_A = "ADD_COURSE_CONTENT_Q_AND_A"

export const GET_COURSE_CONTENT_SECTION = "GET_COURSE_CONTENT_SECTION"
export const GET_COURSE_CONTENT_DETAILD = "GET_COURSE_CONTENT_DETAILD"
export const GET_COURSE_CONTENT_ASSIGNMENT = "GET_COURSE_CONTENT_ASSIGNMENT"
export const GET_COURSE_REFERENCE = "GET_COURSE_REFERENCE"

export const CONTENT_STATUS_UPDATE = "CONTENT_STATUS_UPDATE"

export const GET_COURSE_QUESTION = "GET_COURSE_QUESTION"

export const UPDATE_COURSE_CONTENT_SECTION = "UPDATE_COURSE_CONTENT_SECTION"
export const UPDATE_COURSE_CONTENT_CONTENT = "UPDATE_COURSE_CONTENT_CONTENT"
export const UPDATE_COURSE_CONTENT_ASSIGNMENT = "UPDATE_COURSE_CONTENT_ASSIGNMENT"
export const UPDATE_COURSE_CONTENT_REFERENCE = "UPDATE_COURSE_CONTENT_REFERENCE"
export const UPDATE_COURSE_CONTENT_QUESTION = "UPDATE_COURSE_CONTENT_QUESTION"

export const GET_STUDENT_COURSE_CONTENT = "GET_STUDENT_COURSE_CONTENT"

export const GET_ALL_COURSE_FOR_ADMIN = "GET_ALL_COURSE_FOR_ADMIN"

export const UPLOAD_STUDENT_ASSIGNMENT = "UPLOAD_STUDENT_ASSIGNMENT"
export const GET_STUDENT_ASSIGNMENT = "GET_STUDENT_ASSIGNMENT"


export const UPLOAD_STUDENT_QUIZ = "UPLOAD_STUDENT_QUIZ"
export const GET_STUDENT_QUIZ = "GET_STUDENT_QUIZ"

export const GET_UPLOAD_QUESTION_AND_ANSWER = "GET_UPLOAD_QUESTION_AND_ANSWER"
export const GET_UPLOAD_ASSIGNMENT = "GET_UPLOAD_ASSIGNMENT"

export const UPDATE_ASSIGNMENT_STATUS = "UPDATE_ASSIGNMENT_STATUS"



















