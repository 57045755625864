import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputComponent from '../../../commonComponents/InputComponent/input.js';
import { Button, Form, Select ,Input,notification,DatePicker,Checkbox,Collapse, List, Typography,Row, Col } from 'antd';
import { course, edit, excel, plus, upload, TableEyeIcon, searchIcon, add_icon, back, deleteIcon
  ,gold_image,silver_image,bronze_image
 } from "../../../images/index.js";
import {ModalComponent,TableComponent} from "../../../commonComponents/index.js";
import AddBadgesModal from './addBadgesModal.js';
import { DeleteOutlined } from '@ant-design/icons';





import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {addCoursesContentReferenceDetails,getCoursesContentDetails} from '../../../redux/coursesContent/action.js';

import moment from 'moment';



const AddCourseContentModal = (props) => {
  let dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();

  const [form2] = Form.useForm();
  const [formReference] = Form.useForm();
  const [formBadges] = Form.useForm();

  const [formQuestionAndAns] = Form.useForm();

  
  const data = [
    { id: 1, name: "Gold", imgSrc: gold_image },
    { id: 2, name: "Silver", imgSrc: silver_image },
    { id: 3, name: "Bronze", imgSrc:bronze_image },

  ];

  const { Panel } = Collapse;
const { Text } = Typography;
  
  const questions = [
    {
      question: "What is your name?",
      answer: "option2",
      details: {
        option1: "John",
        option2: "Doe",
        option3: "Jane",
        option4: "Smith"
      }
    },
    {
      question: "Where do you live?",
      answer: "option3",
      details: {
        option1: "New York",
        option2: "Los Angeles",
        option3: "Chicago",
        option4: "Houston"
      }
    },
    {
      question: "What is your favorite color?",
      answer: "option1",
      details: {
        option1: "Red",
        option2: "Blue",
        option3: "Green",
        option4: "Yellow"
      }
    }
  ];



  const [fieldsVisible, setFieldsVisible] = useState('1');
  const [preferenceTypeVisible, setPreferenceTypeVisible] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const [visibleNotification, setVisibleNotification] = useState(false);
  const [addMultipleRecord,setAddMultipleRecord] = useState(1);
  const [addBadgesVisible, setAddBadgesVisible] = useState(false);

  const [selectedId, setSelectedId] = useState(null);

  const [preferenceLinkList, setPreferenceLinkList] = useState([]);

  const [getPreferenceLink, setGetPreferenceLink] = useState("");

  const [preferenceDocumentList, setPreferenceDocumentList] = useState([]);

  const [getPreferenceDocument, setGetPreferenceDocument] = useState("");

  const [getPreferenceDocumentDaseData, setGetPreferenceDocumentBaseData] = useState("");

  const [preferenceDocumentListBase, setPreferenceDocumentListBase] = useState([]);

  let[allCoursesContentReferenceList,setAllCoursesContentReferenceList] = useState([])

  let[allCoursesContentReference,setAllCoursesContentReference] = useState({
    "category": "Reference",
    "title": "",
    "description": "",
    "reference_type": "",
    "reference_document": "",
    "xp": "",
    "batch_id":props.selectedBatchId,
    "instructor_id":window.localStorage.getItem("instructor_id"),
    "course_id":props.selectedCourseId,
    "tenant_id": window.localStorage.getItem("tenant_Id")
    })

    let[addBadgesDetailsPayload,setAddBadgesDetailsPayload] = useState({
      "name": "",
      "document": "",
      "batch_id":props.selectedBatchId,
      "instructor_id":window.localStorage.getItem("instructor_id"),
      "course_id":props.selectedCourseId,
      "tenant_id": window.localStorage.getItem("tenant_Id")
      })

      
      
      
      

      let[allOnlyQuestionAndAnser,setAllOnlyQuestionAndAnser] = useState({
        "third_option_correct":"",
        "first_option_correct":"",
        "second_option_correct":"",
        "fourth_option_correct":"",
        "question":"",
        "first_option":"",
        "second_option":"",
        "third_option":"",
        "fourth_option":"",
      })

      let[allQuestionAndAnser,setAllQuestionAndAnser] = useState({
        "category": "Q&A",
        "title":"",
        "description":"",
        "duration":"",
        "min_pass_percentage":"",
        "first_attempt":"",
        "first_xp":"",
        "second_attempt":"",
        "second_xp":"",
        "third_attempt":"",
        "third_xp":"",
        "details":[],
        "batch_id":props.selectedBatchId,
        "instructor_id":window.localStorage.getItem("instructor_id"),
        "course_id":props.selectedCourseId,
        "tenant_id": window.localStorage.getItem("tenant_Id")
        })

    let addCoursesContentReference = useSelector((state)=> state?.CoursesContentReducer?.addCoursesContentReference);

    useEffect(()=>{    
      if (addCoursesContentReference) {
  if(visibleNotification == true){
    openNotification(addCoursesContentReference?.msg)
  
    if(addCoursesContentReference?.res_status === true){

    if(addMultipleRecord === 1){

      formReference.resetFields();

      formReference.setFieldsValue({['title']:''})
      formReference.setFieldsValue({['description']:''})
      formReference.setFieldsValue({['reference_type']:null})
      formReference.setFieldsValue({['reference_document']:''})

      formReference.setFieldsValue({['xp']:''})


      setAllCoursesContentReference({
        "category": "Reference",
    "title": "",
    "description": "",
    "reference_type": null,
    "reference_document": "",
    "xp": "",
    "batch_id":props.selectedBatchId,
    "instructor_id":window.localStorage.getItem("instructor_id"),
    "course_id":props.selectedCourseId,
    "tenant_id": window.localStorage.getItem("tenant_Id")
    
      })

      props.handleCancel()
    }else{

  
      
      formReference.setFieldsValue({['title']:''})
      formReference.setFieldsValue({['description']:''})
      formReference.setFieldsValue({['reference_type']:null})
      formReference.setFieldsValue({['reference_document']:''})

      formReference.setFieldsValue({['xp']:''})
    
      setAllCoursesContentReference({
        "category": "Reference",
    "title": "",
    "description": "",
    "reference_type": null,
    "reference_document": "",
    "xp": "",
    "batch_id":props.selectedBatchId,
    "instructor_id":window.localStorage.getItem("instructor_id"),
    "course_id":props.selectedCourseId,
    "tenant_id": window.localStorage.getItem("tenant_Id")
    
      })
    }


    let payload ={
      "operation_type":"retrieve_all_content",
          "course_id":props.selectedCourseId,
          "tenant_id":window.localStorage.getItem("tenant_Id"),
          "instructor_id":window.localStorage.getItem("instructor_id"),
          "batch_id":props.batch_id

  }
dispatch(getCoursesContentDetails(payload))
  
  
    }
  
  }else{
  
  }
  
      }
  },[addCoursesContentReference])



const openNotification = (msg) => {
  
  api.info({
    description:
    `${msg}`,

  });

  setVisibleNotification(false)

};




 









        const contentList = [
          { value: '1', label: 'Section', description: 'Description for Tag 1' },
          { value: '2', label: 'Content', description: 'Description for Tag 2' },
          { value: '3', label: 'Reference', description: 'Description for Tag 3' },
          { value: '4', label: 'Assignment', description: 'Description for Tag 2' },
          { value: '5', label: 'Q&A', description: 'Description for Tag 3' },
        ];

        const referenceType = [
          { value: '1', label: 'File', description: 'Description for Tag 1' },
          { value: '2', label: 'Link', description: 'Description for Tag 2' },
        ];


        let handleAllMainContentChange = (value, type) => {
          if (value) {
            console.log('Selected title:', value);

            setFieldsVisible(value);
      

            if(value == '1'){
              props.handleClassChange('Section', { name: "category_name" })



            }else if(value == '2'){
              props.handleClassChange('Content', { name: "category_name" })

            }
            else if(value == '3'){
              addContentReference('reference', { name: "reference_type" })
              // props.handleClassChange("Reference", { name: "category_name" })

            }
            else if(value == '4'){
              props.handleClassChange('Assignment', { name: "category_name" })

            }else if(value == '5'){
              props.handleClassChange('Q&A', { name: "category_name" })

            }


      
            // props.handleClassChange(value, { name: "tag_id" })
          } else {
            console.log('Selection cleared');
            setFieldsVisible(null);
          }
        };

        let handleAllPreferenceTypeChange = (value, type) => {
          if (value) {
            console.log('Selected title:', value);
            setPreferenceTypeVisible(value);
            // props.handleClassSingleReferenceChange(value, { name: "reference_type" })

            if(value == '1'){
              addContentReference('File', { name: "reference_type" })
            }else if(value == '2'){
              addContentReference('Link', { name: "reference_type" })
            }

           

            // "category", "title", "description", "reference_type","reference_document","xp"


            // props.handleClassChange(value, { name: "tag_id" })
          } else {
            console.log('Selection cleared');
            setPreferenceTypeVisible(null);
          }
        };

        const [content, setContent] = useState(localStorage.getItem('editorContent') || '');
        // const [content, setContent] = useState('<p><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGUAAAB5CAYAAAAgaO8vAAAACXBIWXMAAAsSAAALEgHS3X78AAADQElEQVR4nO2dy23bQBRFnwXvtHBKcAd2A4KdDtKBU4LXWmWlOpwOUoIDNmB1oHQgLbhmQIMOAomgOcN5w0vyHEA7USB1OJ93ZwheVVVlIaw32y9m9svMHoIOnCa/zexbWeyOOc9+FXHM80KEWHOdr82NmI0YKUvjLrcYpPQjq5jrBL+xb7q0ufDdzJ5aruVDzKP3GJNCyrEsdq8JfkeC+k/vOI8sYui+wnHvypDyOX/M7HT2LVcx1wurO2I4NGNm3UXf/He8W1e2WljdEUVZ7N7M7DFXi6H76klOMUgJIJeYNil13fG14/Mz5YVOjRxi2uqUzrrjk3n8IqjFNP+Dy+BP9xWJZ4tBygC8xCBlIB5ikJKA1GJSBJKdDEwM6pmgeyqbgpSDf46WMiQxqC/oR+LzcSNVi5lC93UvcA69SSGGMcWBoWLGkLKIxGCIGPeBvoXFJAaxgz/dlzMxLQYpGQgVg5RMhIhBSkb6ihljoJ8aD+vNNmzDdRz/Bn9aihbvCcYYLSXXnTdV7mkpl7y09PlZmcKYkjUhLovdYb3Z3mbM3C72LueQ8tIkxTc9vnvOaYyUuKmys+yPbksw3KUMvPPeprCWkpos3VfOO28OMNALghRBxl6jv1iDn9lTAPvm6eJDyEFjr9G3rcHP6SmAqD0GCt3XrcA5eCK576urQm6rQ0avqBMSVWeNXadc1CEjVNSeRNVZknXK0usapsSCIEUQpAiCFEGQIghSBCH78oXsSxCyL1HIvsQg+xKE7GsuMCUWBCmCIEUQpAiCFEGQIgjZly9kX4KQfYlC9iUG2ZcgZF9zgSmxIEgRBCmCIEUQpAiCFEHIvnwh+xKE7EsUsi8xyL4EIfuaC0yJBUGKIEgRBCmCIEUQpAhC9uUL2ZcgZF+ikH2JQfYlCNnXXGBKLAhSBEGKIEgRBCmCIEUQpAiCFEGQIghSBEGKIEgRJEUgyRtOE0NLEQQpehxjpMxpZVCNU9Rbtme2MujB+buA981mkD68r1RGDfRLXxnsouVdwMey2AX9V4wpgiBFEKQIghRBkCIIUgRpmxKTZY0MLUUQpPgTvJd41TygQ5blQ9Su+6uqqj6eniLLSk/4rnsz+wufVvDoUTdNrQAAAABJRU5ErkJggg=="></p><p><br></p><p>hello damodar</p>');

        const [wordCount, setWordCount] = useState(0);
        const [charCount, setCharCount] = useState(0);
        const characterLimit = 1000;
      
        const handleChange = (value) => {
        setContent(value);
        setWordCount(value.split(/\s+/).filter(word => word).length);
        setCharCount(value.length);

        props.handleClassSingleCoursesContentChange(value, { name: "content_description" })
        props.handleClassMultipleCoursesContent(value, { name: "content_description" })

        console.log(value)
      };
    
      // Save content to local storage
      useEffect(() => {
        localStorage.setItem('editorContent', content);
      }, [content]);
    
      // Clear editor content
      const handleClearContent = () => {
        setContent('');
        setWordCount(0);
        setCharCount(0);
        notification.success({ message: 'Content cleared!' });

        props.handleClassSingleCoursesContentChange("", { name: "content_description" })
        props.handleClassMultipleCoursesContent("", { name: "content_description" })
      };




  



        const convertToBase64 = (file) => {
          return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            if (file) {
              fileReader.readAsDataURL(file);
              fileReader.onload = () => {
                resolve(fileReader.result);
              };
            }
           
            fileReader.onerror = (error) => {
              reject(error);
            };
          });
        };


        let  onFileChange = async(event,type)=>{
          const file = event.target.files[0];
        const base64 = await convertToBase64(file);
        console.log(base64,"ppp")
        setGetPreferenceDocumentBaseData(base64)
        setGetPreferenceDocument(file.name)


        addContentReference(base64, { name: "reference_document" })
        // addContentReference(file.name, { name: "reference_file_name" })

        props.handleClassSingleReferenceChange(base64, { name: "reference_file" })
        props.handleClassSingleAssignmentChange(base64, { name: "assignment_documents" })

         }


         const convertToBase64Pdf = (file) => {
          return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            if (file) {
              fileReader.readAsDataURL(file);
              fileReader.onload = () => {
                resolve(fileReader.result);
              };
            }
            fileReader.onerror = (error) => {
              reject(error);
            };
          });
        };
        
        let onFileChangePdf = async (event, type) => {
          const file = event.target.files[0];
          
          // Check if the file is a PDF
          if (file && file.type === "application/pdf") {
            const base64 = await convertToBase64Pdf(file);
            console.log(base64, "Base64 PDF content");
        
            props.handleClassSingleReferenceChange(base64, { name: "reference_file" });
            props.handleClassSingleAssignmentChange(base64, { name: "assignment_documents" });
          } else {
            // Handle non-PDF file
            alert("Please upload only PDF files.");
          }
        };
        

         const disabledDate = (current) => {
        
          return current && current < moment().endOf('day');
        
        
        }

        useEffect(() => {
          if (props.assignmentDate) {
            props.formAssignment.setFieldsValue({ due_date: moment(props.assignmentDate) });
          }
        }, [props.assignmentDate]);

        const [tagDetails, setTagDetails] = useState([]);
        const [tagId, setTagId] = useState(null);


        const handleAddLinkModal = async () => {
          // Add the new link to the preference link list
          setPreferenceLinkList(prevList => {
            const updatedList = [...prevList, getPreferenceLink];
            console.log(updatedList);  // This will now show the updated list immediately
            return updatedList;
          });
      
          // Clear the input value immediately
          setGetPreferenceLink("");
      
          // Clear the form field using form2.setFieldsValue
          form2.setFieldsValue({ ['reference_link']: "" });
        };
      
        const handleAddLinkMethod = (e) => {
          setGetPreferenceLink(e.target.value);
        };

        const handleDelete = (index) => {
          // Delete the link at the specified index
          const updatedList = preferenceLinkList.filter((item, i) => i !== index);
          setPreferenceLinkList(updatedList);
        };
      
        const handleUpdate = (index, newLink) => {
          // Update the link at the specified index
          const updatedList = preferenceLinkList.map((item, i) => i === index ? newLink : item);
          setPreferenceLinkList(updatedList);
        };

        
        

        const handleCourseContentReferenceDeleteModal = (index) => {
          // Delete the link at the specified index
          const updatedList = allCoursesContentReferenceList.filter((item, i) => i !== index);
          setAllCoursesContentReferenceList(updatedList);

          props.handleClassMultipleReference(updatedList, { name: "reference_details" })
          props.handleClassSingleReferenceChange(updatedList, { name: "reference_details" })


        };


        const handleAddReferenceModal = async () => {
          // Add the new link to the preference link list
          setPreferenceDocumentList(prevList => {
            const updatedList = [...prevList,getPreferenceDocument ];
            console.log(updatedList);  // This will now show the updated list immediately
            return updatedList;
          });

          setPreferenceDocumentListBase(prevList => {
            const updatedList = [...prevList, getPreferenceDocumentDaseData];
            console.log(updatedList);  // This will now show the updated list immediately
            return updatedList;
          });
      
          // Clear the input value immediately
          // getPreferenceDocument("");
      
          // // Clear the form field using form2.setFieldsValue
          // form2.setFieldsValue({ ['reference_file']: "" });
        };


        const handleDeleteDocument = (index) => {
          // Delete the link at the specified index
          const updatedList = preferenceDocumentList.filter((item, i) => i !== index);
          setPreferenceDocumentList(updatedList);

            // Delete the link at the specified index
            const updatedList1 = preferenceDocumentListBase.filter((item, i) => i !== index);
            setPreferenceDocumentListBase(updatedList1);
        };

        const addContentReference = (e,type) => {

          if (!type || !type.name) {
            console.error("Type or type.name is undefined:", type);
            return; // Exit early to avoid the error
          }
        
          let value;
        
          if (e && e.target) {
            value = e.target.value; // For event objects
          } else {
            value = e; // For direct values
          }
        
        

        
          // Set the state based on the type
          // if (["category", "title", "description", "reference_type","reference_document","xp","batch_id","instructor_id","course_id","tenant_id"].includes(type.name)) {
          //   setAllCoursesContentReference(prevDetails => ({
          //     ...prevDetails,
          //     [type.name]: value
          //   }));
          // }

          // if (["category", "title", "description", "reference_type", "reference_document", "xp", "batch_id", "instructor_id", "course_id", "tenant_id"].includes(type.name)) {
          //   setAllCoursesContentReference(prevDetails => ({
          //     ...prevDetails,
          //     [type.name]: type.name === "xp" && value === "" ? null : value
          //   }));
          // }
          
          if (["category", "title", "description", "reference_type", "reference_document", "xp", "batch_id", "instructor_id", "course_id", "tenant_id"].includes(type.name)) {
            setAllCoursesContentReference(prevDetails => ({
              ...prevDetails,
              [type.name]: type.name === "xp" 
                ? (value.trim() === "" ? null : parseInt(value, 10))  // Convert to integer if "xp", otherwise keep as string
                : value
            }));
          }
          
         
        };

        const overalAddReference = () => {
          // Destructure fields for readability
          const {
            category,
            title,
            description,
            reference_type,
            reference_document
          } = allCoursesContentReference;
        
          // Check if all required fields are filled
          if (
            category &&
            title &&
            description &&
            reference_type &&
            reference_document 
          ) {
            // If all data is present, proceed to insert
            const updatedList = [...allCoursesContentReferenceList, allCoursesContentReference];
            setAllCoursesContentReferenceList(updatedList);
        
            // Reset the input fields for a new reference entry
            setAllCoursesContentReference({
              category: "Reference",
              title: "",
              description: "",
              reference_type: "",
              reference_document: "",
              xp: "",
              batch_id:props.selectedBatchId,
              instructor_id:window.localStorage.getItem("instructor_id"),
              course_id:props.selectedCourseId,
              tenant_id: window.localStorage.getItem("tenant_Id")
            });
          } else {
            // Handle missing data (e.g., show an error message)
            console.error("Please fill in all required fields.");
          }
        };
        

        const columns = [

          {
            title: 'TITLE',
            dataIndex: 'title',
            sorter: true,
            sorter: (a, b) => a.title - b.title,
            width: 100,
      
          },
      
            {
              title: 'DESCRIPTION',
              dataIndex: 'description',
              sorter: true,
              sorter: (a, b) => a.description .localeCompare(b.description),
              width: 100,
            },
           
              {
                title: 'Type',
                dataIndex: 'reference_type',
                sorter: true,
                sorter: (a, b) => a.reference_type .localeCompare(b.reference_type),
                width: 100,
              },
            {
              title: 'DOCUMENT',
              dataIndex: 'reference_document',
              sorter: true,
              sorter: (a, b) => a.reference_document .localeCompare(b.reference_document),
              width: 100,
            },

            {
              title: 'XP',
              dataIndex: 'xp',
              sorter: true,
              sorter: (a, b) => a.xp .localeCompare(b.xp),
              width: 100,
            },

  
            {
              title:'Action',
              dataIndex: 'action',
              render: (text, record,index) => (
                <div className="actionIcons">
                  
                    <img src={deleteIcon} onClick={()=> handleCourseContentReferenceDeleteModal(index)}/>
                </div>
               ),
               width: 100,
            }
          ];


          const handleClassSingleReferenceChange = (e, type) => {
            console.log(allCoursesContentReferenceList)
          

            setAddMultipleRecord(1)



            let payload ={
              "operation_type": "insert",
              "op_field_details": allCoursesContentReferenceList
               
          }

          dispatch(addCoursesContentReferenceDetails(payload))
          setVisibleNotification(true)

          
          };
      
          const handleClassMultipleReference = (e, type) => {

            setAddMultipleRecord(2)

            let payload ={
              "operation_type": "insert",
              "op_field_details": allCoursesContentReferenceList
               
          }
      
            dispatch(addCoursesContentReferenceDetails(payload))

            setVisibleNotification(true)

          
          };

          let onFinishReferenceAdd= (values) =>{


          
          }


          const onDateChange = (date, dateString) => {
            // Update the form's due_date field value
            props.formReference.setFieldsValue({ due_date: date });
          
            // Trigger any additional changes needed in the parent component
            props.handleClassSingleAssignmentChange(dateString, { name: "due_date" });
          };


          

          const handleAddBadgesModal = () => {
       
            setAddBadgesVisible(true)
          };


          let handleDeleteCancel= ()=>{


        setAddBadgesVisible(false)
          
            
        
          } 
          
          let onFinishBadges= ()=>{


            console.log(addBadgesDetailsPayload)
              
                
            
              } 

  const handleClassBadges= (e, type)=>{
      if (!type || !type.name) {
        console.error("Type or type.name is undefined:", type);
        return;
      }
    
      let value = e && e.target ? e.target.value : e; // Handle both events and direct values
      console.log(type.name )

      console.log(e)

  
      // Update other fields
      if (["name", "document"].includes(type.name)) {
        setAddBadgesDetailsPayload((prevDetails) => ({
          ...prevDetails,
          [type.name]: value,
        }));
      };
                
            
              };


              const handleItemClick = (id) => {
                setSelectedId(id); // Set the clicked item's id as selected
              };

              const deleteItem = (id) => {
               
              };            




   const handleCreateQuestionModal = () => {
       
      setAddBadgesVisible(true)
    };    


    let onFinishQuestionnAdd= (values) =>{
      console.log(allQuestionAndAnser,"FIRST")
      console.log(allOnlyQuestionAndAnser,"SECOND")
    }


    const handleClassChangeSingleQuestion = (e, type) => {
 

      setAddMultipleRecord(1)
      console.log('SINGLE')
    
      if (!type || !type.name) {
        console.error("Type or type.name is undefined:", type);
        return;
      }
    
      let value = e && e.target ? e.target.value : e; // Handle both events and direct values
      console.log(type.name )

      console.log(e)

  
      // Update other fields
      if (["title", "description","duration","min_pass_percentage","first_attempt",
        "first_xp","second_attempt","second_xp","third_xp","third_attempt"].includes(type.name)) {
        setAllQuestionAndAnser((prevDetails) => ({
          ...prevDetails,
          [type.name]: value,
        }));
      }

      if (["answer", "question","first_option","second_option","third_option",
        "fourth_option"].includes(type.name)) {
          setAllOnlyQuestionAndAnser((prevDetails) => ({
          ...prevDetails,
          [type.name]: value,
        }));
      }
    };
 


    const handleClassChangeMultipleQuestion = (e, type) => {
      console.log('MULTIPLE')

      setAddMultipleRecord(2)

   
    
      if (!type || !type.name) {
        console.error("Type or type.name is undefined:", type);
        return;
      }
    
      let value = e && e.target ? e.target.value : e;

      console.log(type.name )
   
    
      if (["title", "description","duration","min_pass_percentage","first_attempt",
        "first_xp","second_attempt","second_xp","third_xp","third_attempt"
      ].includes(type.name)) {
        setAllQuestionAndAnser((prevDetails) => ({
          ...prevDetails,
          [type.name]: value,
        }));
      }

      if (["answer", "question","first_option","second_option","third_option",
        "fourth_option"].includes(type.name)) {
          setAllOnlyQuestionAndAnser((prevDetails) => ({
          ...prevDetails,
          [type.name]: value,
        }));
      }
    };


    const handleCheckboxChange = (e, fieldName) => {
      const isChecked = e.target.checked;

      if (e.target.checked) {
        setSelectedOption(fieldName);
      } else {
        setSelectedOption(null); // Unselect if unchecked
      }
    
      setAllOnlyQuestionAndAnser((prevDetails) => ({
        ...prevDetails,
        [fieldName]: isChecked,
      }));

      // setAllOnlyQuestionAndAnser((prevDetails) => ({
      //   ...prevDetails,
      //   first_option_correct: fieldName === 'first_option_correct' ? isChecked : false,
      //   second_option_correct: fieldName === 'second_option_correct' ? isChecked : false,
      //   third_option_correct: fieldName === 'third_option_correct' ? isChecked : false,
      //   fourth_option_correct: fieldName === 'fourth_option_correct' ? isChecked : false,
      // }));
    };

    const validateCheckboxSelection = () => {
      return selectedOption !== null;
    };

 
  return (
    <div className='instructorCancelClassContainer' style={{ display: 'flex', flexDirection: 'column' }}>
         {contextHolder}

         <div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 


        <div style={{ display: 'flex', flexDirection: 'row' }}>

        <div style={{ flex: 1 }}>
        <div className='cancelClassContainer' style={{ flex: 1 }}>
        <Form
          form={props.form2}
          onFinish={props?.onFinish}

          autoComplete="off"
          layout="vertical"
          initialValues={{
            selected_id: contentList[0].value, // Default to first item's value
            ...props.cancelClassDetails, // Spread the values from cancelClassDetails
            ...props.referencePayloadDetails, 
            ...props.assignmentsPayloadDetails, 
          }}
        >

<div style={{ display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap', marginTop: '10px' }}>
    <Form.Item
    label='Category'
      name="selected_id"
      style={{ flex: 1, minWidth: '250px', marginBottom: 0 }}
      rules={[
        {
          required: true,
          message: 'Select is required',
        },
      ]}
    >
      <Select
        size="small"
        className="custom-select-height"
        onChange={handleAllMainContentChange}
        allowClear
        placeholder="Section"
        optionLabelProp="label"
      >
        {contentList.map((item) => (
          <Select.Option key={item.value} value={item.value} label={item.label}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{item.label}</span>
              <div style={{ display: 'flex', gap: '5px', marginLeft: '5px' }}>
                {/* Additional content can go here if needed */}
              </div>
            </div>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  </div>

        </Form>


        {fieldsVisible == '1' ? ( 
          <div style={{ minHeight: '200px', maxHeight: '300px', overflowY: 'auto' }}>
  <Form
    form={props.formSection}
    onFinish={props?.onFinishSectionAdd}
    autoComplete="off"
    layout="vertical"
    initialValues={{
      selected_id: contentList[0].value,
      ...props.cancelClassDetails,
      ...props.referencePayloadDetails,
      ...props.assignmentsPayloadDetails,
    }}
  >
    <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
      <Form.Item
        label="Title"
        name="title"
        rules={[
          {
            required: true,
            message: 'Please enter title',
          },
        ]}
        style={{ flex: 1 }}
      >
        <InputComponent
          placeholder="Title"
          name="title"
          value={props.coursesContentPayloadDetails.title}
          onChange={(e) => props.handleClassChange(e, { name: "title" })}
          style={{ width: '100%' }}
        />
      </Form.Item>
    </div>
    
    <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: 'Please enter description',
          },
        ]}
        style={{ flex: 1 }}
      >
        <Input.TextArea
          placeholder="Description"
          name="description"
          value={props.coursesContentPayloadDetails.description}
          onChange={(e) => props.handleClassChange(e, { name: "description" })}
          style={{ width: '100%' }}
          autoSize={{ minRows: 3, maxRows: 6 }}
        />
      </Form.Item>
    </div>
  </Form></div>) 

: fieldsVisible == '2' ? ( 

<div  > 

 <Form
          form={props.formContent}
          onFinish={props?.onFinishContextAdd}

          autoComplete="off"
          layout="vertical"
          initialValues={{
            selected_id: contentList[0].value, // Default to first item's value
            ...props.coursesContentListPayloadDetails, // Spread the values from cancelClassDetails
          }}
        >
 
 <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please enter title',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"Title"}
                name={"title"}
                value={props.coursesContentListPayloadDetails.title}
                onChange={(e) => props.handleClassMultipleCoursesContent(e, { name: "title" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>

          <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
         <Form.Item
           label="Description"
           name="description"
           rules={[
             {
               required: true,
               message: 'Please enter description',
             },
           ]}
           style={{ flex: 1 }}
         >
           <Input.TextArea
             placeholder="Description"
             name="description"
             value={props.coursesContentListPayloadDetails.description} // Adjust this to the appropriate state variable if necessary
             onChange={(e) => props.handleClassMultipleCoursesContent(e, { name: "description" })}
             style={{ width: '100%' }}
             autoSize={{ minRows: 3, maxRows: 6 }} // Allows the textarea to expand as needed
           />
         </Form.Item>
         </div>

{/* <h2>Quill Rich Text Editor</h2> */}
<p>Content</p>
      <ReactQuill
        value={content}
        label="Description"
        onChange={handleChange}
        theme="snow"
        placeholder="Start typing here..."
        modules={{
          toolbar: [
            // [{ 'header': '1'}, { 'header': '2'}, { 'font': [] }],
            // [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            // ['bold', 'italic', 'underline'],
            // [{ 'color': [] }, { 'background': [] }],
            // ['link', 'image'],
            // ['clean']
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],  // More header options
            [{ 'font': [] }],  // Allows font selection
            [{ 'size': ['small', false, 'large', 'huge'] }],  // Text size options
            [{ 'align': [] }],  // Text alignment options
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],  // Ordered and bullet lists
            [{ 'script': 'sub' }, { 'script': 'super' }],  // Subscript and superscript
            ['bold', 'italic', 'underline', 'strike'],  // Text styles
            [{ 'color': [] }, { 'background': [] }],  // Text color and background
            [{ 'indent': '-1' }, { 'indent': '+1' }],  // Indentation options
            [{ 'direction': 'rtl' }],  // Text direction (RTL/LTR)
            ['blockquote', 'code-block'],  // Blockquote and code block
            ['link', 'image', 'video'],  // Media embedding (link, image, video)
            ['clean']
          ]
        }}
      />

      
      <div style={{ marginTop: '20px' }}>
        <Button onClick={handleClearContent} type="primary" danger>
          Clear Content
        </Button>
        <div style={{ marginTop: '10px' }}>
          <h3>Editor Content:</h3>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        <div style={{ marginTop: '10px' }}>
          <p>Word Count: {wordCount}</p>
          <p>Character Count: {charCount} / {characterLimit}</p>
          {charCount > characterLimit && (
            <p style={{ color: 'red' }}>Character limit exceeded!</p>
          )}
        </div>
      </div>

        </Form></div>) 
        
: fieldsVisible == '3' ? (<div style={{ minHeight: '200px', maxHeight: '200px', overflowY: 'auto' }}>  
           <Form
          form={formReference}
          onFinish={onFinishReferenceAdd}

          autoComplete="off"
          layout="vertical"
          initialValues={{
            selected_id: contentList[0].value, // Default to first item's value
            ...props.referencePayloadDetails, 
          }}
        >
           <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap', marginTop: '10px' }}>
    <Form.Item
       label="Type"
      name="reference_type"
      style={{ flex: 1, minWidth: '250px', marginBottom: 0 }}
      rules={[
        {
          required: true,
          message: 'Type is required',
        },
      ]}
    >
      <Select
        size="small"
        className="custom-select-height"
        onChange={handleAllPreferenceTypeChange}
        allowClear
        placeholder="Reference Type"
        optionLabelProp="label"
      >
        {referenceType.map((item) => (
          <Select.Option key={item.value} value={item.value} label={item.label}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{item.label}</span>
              <div style={{ display: 'flex', gap: '5px', marginLeft: '5px' }}>
                {/* Additional content can go here if needed */}
              </div>
            </div>
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  </div>


  <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1}}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please enter title',
                },
              ]}
              style={{ flex: 1 }}
            >

              <InputComponent
                placeholder={"Title"}
                name={"title"}
                value={props.title}
                onChange={(e) => addContentReference(e, { name: "title" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>


  <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
  <Form.Item
    label="Description"
    name="description"
    rules={[
      {
        required: true,
        message: 'Please enter description',
      },
    ]}
    style={{ flex: 1 }}

  >
    <Input.TextArea
      placeholder="Description"
      name="description"
      value={props.description} // Adjust this to the appropriate state variable if necessary
      onChange={(e) =>addContentReference(e, { name: "description" })}
      style={{ width: '100%' }}
      autoSize={{ minRows: 3, maxRows: 6 }} // Allows the textarea to expand as needed
    />
  </Form.Item>
  </div>

  {preferenceTypeVisible == "1" ? ( 

<div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,}}>
<Form.Item
 style={{ flex: 1 }}
label="Upload File"
                     name="reference_document"
                     rules={[
                       {
                         required: true,
                         message: 'File  is required',
               
                       },
                     ]}>
                   <div className="instructorResumeUpload">
                  <InputComponent type="file" name={"reference_document"}  style={{"display": "none"}}
                  onChange={(e)=>onFileChange(e,{name: "reference_document"})}
                  className="instructorResumeUpload"/>
                  </div>
                  </Form.Item>
</div>
    ) : preferenceTypeVisible == '2' ? (   
                    
                    <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
           

<Form.Item
  label="Link"
  name="reference_document"
  rules={[
    {
      required: true,
      message: 'Please enter a link',
    },
    {
      validator: (_, value) => {
        const urlPattern = new RegExp(
          '^(https?:\\/\\/)?' + // protocol
          '((([a-zA-Z0-9\\-]+)\\.)+[a-zA-Z]{2,})' + // domain name and extension
          '(\\:[0-9]{1,5})?' + // port (optional)
          '(\\/.*)?$' // path (optional)
        );
        if (!value || urlPattern.test(value)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter a valid URL'));
      },
    },
  ]}
  style={{ flex: 1 }}
>


  <InputComponent
    placeholder={"Link"}
    name={"reference_document"}
    value={props.reference_document}
    onChange={(e) => addContentReference(e, { name: "reference_document" })}
    style={{ width: '100%' }}
  />
</Form.Item>

          </div>) : ( <div></div>)}







<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' ,marginBottom:"10px"}}>
            <Form.Item>




              <Button htmlType='submit' type='primary' style={{ marginRight: '8px', width:"110px" ,}}onClick={overalAddReference}>
                Add
              </Button>
            
           

            </Form.Item>
 </div>



<div style={{width:"500px",}}>
<TableComponent columns={columns} data={allCoursesContentReferenceList}/>

</div>


        </Form> </div>) : fieldsVisible == '4' ?( <Form
          form={props.formAssignment}
          onFinish={props?.onFinishAssignmentAdd}

          autoComplete="off"
          layout="vertical"
          initialValues={{
            // selected_id: contentList[0].value, // Default to first item's value
            ...props.assignmentsPayloadDetails, 
            due_date: props.assignmentDate ? moment(props.assignmentDate) : null, // Set initial due date if available

          }}

        
        >
           <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please enter title',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"Title"}
                name={"title"}
                value={props.title}
                onChange={(e) => props.handleClassSingleAssignmentChange(e, { name: "title" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>


     

          <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
  <Form.Item
    label="Description"
    name="description"
    rules={[
      {
        required: true,
        message: 'Please enter description',
      },
    ]}
    style={{ flex: 1 }}
  >
    <Input.TextArea
      placeholder="Description"
      name="description"
      value={props.description} // Adjust this to the appropriate state variable if necessary
      onChange={(e) => props.handleClassSingleAssignmentChange(e, { name: "description" })}
      style={{ width: '100%' }}
      autoSize={{ minRows: 3, maxRows: 6 }} // Allows the textarea to expand as needed
    />
  </Form.Item>
  </div>




<div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
  <Form.Item
    label="Due Date"
    name="due_date"
    rules={[{ required: true, message: 'Please select due date.' }]}
    style={{ flex: 1 }}
  >
    <DatePicker
      disabledDate={disabledDate}
      onChange={(date) => props.handleClassSingleAssignmentChange(date, { name: "due_date" })}
      value={props.assignmentDate ? moment(props.assignmentDate) : null} // Ensure it's a moment object or null
      style={{ flex: 1, height: "40px" }}
    />
  </Form.Item>
</div>


        </Form>) : fieldsVisible == '5' ? (
          <div style={{ minHeight: '200px', maxHeight: '200px', overflowY: 'auto' }}>  
          <Form
          form={formQuestionAndAns}
          onFinish={onFinishQuestionnAdd}

          autoComplete="off"
          layout="vertical"
          initialValues={{
            selected_id: contentList[0].value, // Default to first item's value
            allQuestionAndAnser, // Spread the values from cancelClassDetails
          }}
        >
 
 <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please enter title',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"Title"}
                name={"title"}
                value={allQuestionAndAnser.title}
                onChange={(e) => handleClassChangeSingleQuestion(e, { name: "title" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>


     

          <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
  <Form.Item
    label="Description"
    name="description"
    rules={[
      {
        required: true,
        message: 'Please enter description',
      },
    ]}
    style={{ flex: 1 }}
  >
    <Input.TextArea
      placeholder="Description"
      name="description"
      value={allQuestionAndAnser.description} // Adjust this to the appropriate state variable if necessary
      onChange={(e) => handleClassChangeSingleQuestion(e, { name: "description" })}
      style={{ width: '100%' }}
      autoSize={{ minRows: 3, maxRows: 6 }} // Allows the textarea to expand as needed
    />
  </Form.Item>
  </div>



  <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
            <Form.Item
              label="Duration (minutes)"
              name="duration"
              rules={[
                {
                  required: true,
                  message: 'Please enter duration',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"Duration"}
                name={"duration"}
                value={allQuestionAndAnser.duration}
                onChange={(e) => handleClassChangeSingleQuestion(e, { name: "duration" })}
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item
              label="Min Pass Percentage (%)"
              name="min_pass_percentage"
              rules={[
                {
                  required: true,
                  message: 'Please enter min pass pencentage',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"Min Pass Percentage (%)"}
                name={"min_pass_percentage"}
                value={allQuestionAndAnser.min_pass_percentage}
                onChange={(e) => handleClassChangeSingleQuestion(e, { name: "min_pass_percentage" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>


              <div style={{border:"1px solid #ddd" ,borderRadius:"10px" ,marginTop:'10px'}}>

              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "95%" ,marginLeft:"10px",marginRight:"10px"}}>
  <p>Question & Answer Session</p>
  <img src={plus} alt="plus-icon" style={{ height: "25px", width: "20px" }}  onClick={() => handleCreateQuestionModal()}/>
</div>

<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 

                <div style={{margin:"10px"}}>





                <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
            <Form.Item
              label="Question"
              name="question"
              rules={[
                {
                  required: true,
                  message: 'Please enter question',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"Question"}
                name={"question"}
                value={allOnlyQuestionAndAnser.question}
                onChange={(e) => handleClassChangeSingleQuestion(e, { name: "question" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>




<div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,marginTop:'10px'}}>
  <Form.Item
    label={
      <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '5px' }}>Option - 1</span>
              

                <div style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingLeft: '10px',
          paddingRight: '10px',
          marginLeft:"5px",marginRight:"5px",
          border: '1px solid #DCF2DF',
          borderRadius: '6px',
          backgroundColor: '#DCF2DF',
        }}>
          <Checkbox    
            // checked={selectedOption === 'first_option_correct'}
 onChange={(e) => handleCheckboxChange(e, 'first_option_correct')}
           style={{
            color: '#28861F',
            border:"#28861F" // Change text color based on status

            // color: isChecked ? '#28861F' : '#ccc', // Change text color based on status
          }} />
          <span style={{ marginLeft: '5px', fontSize: '14px', color: '#28861F', fontWeight: 'bold'  }}>Correct</span>
        </div>

      </div>
    }
   
    name="first_option"
    rules={[
      {
        required: true,
        message: 'Please enter first option',
      },
      () => ({
        validator(_, value) {
          if (validateCheckboxSelection()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please select at least one correct option'));
        },
      }),
    ]}
    style={{ flex: 1 }}
    colon={false} // Removes the colon after the label

  >
    <InputComponent
      placeholder="First Option"
      name="first_option"
      value={allOnlyQuestionAndAnser.first_option}
      onChange={(e) => handleClassChangeSingleQuestion(e, { name: 'first_option' })}
      style={{ width: '100%' }}
    />
  </Form.Item>

  <Form.Item
    label={
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '5px' }}>Option - 2</span>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingLeft: '10px',
          paddingRight: '10px',
          marginLeft:"5px",marginRight:"5px",
          border: '1px solid #DCF2DF',
          borderRadius: '6px',
          backgroundColor: '#DCF2DF',
        }}>
          <Checkbox  
          //  checked={selectedOption === 'second_option_correct'} 
          onChange={(e) => handleCheckboxChange(e, 'second_option_correct')}
           style={{
            color: '#28861F',
            border:"#28861F" // Change text color based on status

            // color: isChecked ? '#28861F' : '#ccc', // Change text color based on status
          }} />
          <span style={{ marginLeft: '5px', fontSize: '14px', color: '#28861F', fontWeight: 'bold'  }}>Correct</span>
        </div>      </div>
    }
    name="details[0].second_option"
    rules={[
      {
        required: true,
        message: 'Please enter second option',
      },() => ({
        validator(_, value) {
          if (validateCheckboxSelection()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please select at least one correct option'));
        },
      }),
    ]}
    style={{ flex: 1 }}
    colon={false} 
  >
    <InputComponent
      placeholder="Second Option"
      name="second_option"
      value={allOnlyQuestionAndAnser.second_option}
      onChange={(e) => handleClassChangeSingleQuestion(e, { name: 'second_option' })}
      style={{ width: '100%' }}
    />
  </Form.Item>
</div>


<div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,marginTop:'10px'}}>
  <Form.Item
    label={
      <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ marginRight: '5px' }}>Option - 3</span>
              

                <div style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingLeft: '10px',
          paddingRight: '10px',
          marginLeft:"5px",marginRight:"5px",

          border: '1px solid #DCF2DF',
          borderRadius: '6px',
          backgroundColor: '#DCF2DF',
        }}>
          <Checkbox
          //  checked={selectedOption === 'third_option_correct'} 
          onChange={(e) => handleCheckboxChange(e, 'third_option_correct')}
           style={{
            color: '#28861F',
            border:"#28861F" // Change text color based on status

            // color: isChecked ? '#28861F' : '#ccc', // Change text color based on status
          }} />
          <span style={{ marginLeft: '5px', fontSize: '14px', color: '#28861F', fontWeight: 'bold'  }}>Correct</span>
        </div>

      </div>
    }
    name="third_option"   

    rules={[
      {
        required: true,
        message: 'Please enter third option',
      },() => ({
        validator(_, value) {
          if (validateCheckboxSelection()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please select at least one correct option'));
        },
      }),
    ]}
    
    style={{ flex: 1 }}
    colon={false} // Removes the colon after the label

  >
    <InputComponent
      placeholder="Third Option"
      name="third_option"
      value={allOnlyQuestionAndAnser.third_option}
      onChange={(e) => handleClassChangeSingleQuestion(e, { name: 'third_option' })}
      style={{ width: '100%' }}
    />
  </Form.Item>

  <Form.Item
    label={
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ marginRight: '5px' }}>Option - 4</span>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: '5px',
          paddingBottom: '5px',
          paddingLeft: '10px',
          paddingRight: '10px',
          marginLeft:"5px",marginRight:"5px",
          border: '1px solid #DCF2DF',
          borderRadius: '6px',
          backgroundColor: '#DCF2DF',
        }}>
          <Checkbox 
          // checked={selectedOption === 'fourth_option_correct'} 
          onChange={(e) => handleCheckboxChange(e, 'fourth_option_correct')}
           style={{
            color: '#28861F',
            border:"#28861F" // Change text color based on status

            // color: isChecked ? '#28861F' : '#ccc', // Change text color based on status
          }} />
          <span style={{ marginLeft: '5px', fontSize: '14px', color: '#28861F', fontWeight: 'bold'  }}>Correct</span>
        </div>      </div>
    }
    name="fourth_option"
    rules={[
      {
        required: true,
        message: 'Please enter fourth option',
      },() => ({
        validator(_, value) {
          if (validateCheckboxSelection()) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('Please select at least one correct option'));
        },
      }),
    ]}
    style={{ flex: 1 }}
    colon={false} 
  >
    <InputComponent
      placeholder="Fourth Option"
      name="fourth_option"
      value={allOnlyQuestionAndAnser.fourth_option}
      onChange={(e) => handleClassChangeSingleQuestion(e, { name: 'fourth_option' })}
      style={{ width: '100%' }}
    />
  </Form.Item>
</div>


                </div>
             
              </div>





      <div style={{marginTop:"10px"}}></div>



 
  <Collapse accordion style={{ border: 'none',
    boxShadow: 'none'}}>
    {questions.map((item, index) => (
      <Panel
        header={
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{`Question ${index + 1}`}</span>
            <DeleteOutlined
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(index);
              }}
              style={{ color: '#ff4d4f', cursor: 'pointer' }}
              title="Delete"
            />
          </div>
        }
        key={index}
        style={{
          marginBottom: '10px',
          border: '1px solid #d9d9d9',
          borderRadius: '8px',
          // padding: '5px',
          backgroundColor: '#f5f5f5'
        }}
      >
        <Col flex="auto">
          <Text strong>Question: </Text> <Text>{item.question}</Text>
          <br />
          <Text strong>Answer: </Text> <Text>{item.answer}</Text>
        </Col>

        <List
          header={<Text strong>Options</Text>}
          dataSource={Object.entries(item.details)}
          renderItem={([option, value]) => (
            <List.Item style={{ padding: '5px 10px', borderBottom: '1px solid #f0f0f0' }}>
              <Text strong>{option}: </Text> <Text>{value || "No answer provided"}</Text>
            </List.Item>
          )}
          style={{ marginTop: '10px' }}
        />
      </Panel>
    ))}
  </Collapse>


 
         
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "95%" ,marginLeft:"10px",marginRight:"10px"}}>
  <p>Preferences</p>
  {/* <img src={plus} alt="plus-icon" style={{ height: "25px", width: "20px" }}  onClick={() => handleCreateQuestionModal()}/> */}
</div>

<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
   marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 


<div style={{border:"1px solid #ddd" ,borderRadius:"10px" ,marginTop:'10px'}}>
  <div style={{margin:"5px"}}>




  <p>Student First Attempt : </p>
  <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
            <Form.Item
              label="Pass Persentage (%)"
              name="first_attempt"
              rules={[
                {
                  required: true,
                  message: 'Please enter pass persentage',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"Pass Persentage (%)"}
                name={"first_attempt"}
                value={allQuestionAndAnser.first_attempt}
                onChange={(e) => handleClassChangeSingleQuestion(e, { name: "first_attempt" })}
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item
              label="XP"
              name="first_xp"
              rules={[
                {
                  required: true,
                  message: 'Please enter xp',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"XP"}
                name={"first_xp"}
                value={allQuestionAndAnser.first_xp}
                onChange={(e) => handleClassChangeSingleQuestion(e, { name: "first_xp" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>

    

          <p>Student Second Attempt : </p>
  <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 }}>
            <Form.Item
              label="Pass Persentage (%)"
              name="second_attempt"
              rules={[
                {
                  required: true,
                  message: 'Please enter pass persentage',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"Pass Persentage (%)"}
                name={"second_attempt"}
                value={allQuestionAndAnser.second_attempt}
                onChange={(e) => handleClassChangeSingleQuestion(e, { name: "second_attempt" })}
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item
              label="XP"
              name="second_xp"
              rules={[
                {
                  required: true,
                  message: 'Please enter xp',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"XP"}
                name={"second_xp"}
                value={allQuestionAndAnser.second_xp}
                onChange={(e) => handleClassChangeSingleQuestion(e, { name: "second_xp" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>
         
          <p>Student Third Attempt : </p>
  <div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1, marginBottom:"20px"}}>
            <Form.Item
              label="Pass Persentage (%)"
              name="third_attempt"
              rules={[
                {
                  required: true,
                  message: 'Please enter pass persentage',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"Pass Persentage (%)"}
                name={"third_attempt"}
                value={allQuestionAndAnser.third_attempt}
                onChange={(e) => handleClassChangeSingleQuestion(e, { name: "third_attempt" })}
                style={{ width: '100%' }}
              />
            </Form.Item>

            <Form.Item
              label="XP"
              name="third_xp"
              rules={[
                {
                  required: true,
                  message: 'Please enter xp',
                },
              ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"XP"}
                name={"third_xp"}
                value={allQuestionAndAnser.third_xp}
                onChange={(e) => handleClassChangeSingleQuestion(e, { name: "third_xp" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>



  </div>
</div>



        </Form>
        </div>
      ):(<div></div>)}
      

      

      

       

        

      

      </div>
        </div>


            <div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '0.1px',            // Set the width of the line
      height:fieldsVisible == '1' ? '40vh' :
      fieldsVisible == '2' ? '80vh' : 
      fieldsVisible == '3' ? '50vh' :
      fieldsVisible == '4' ? '50vh' :
      '40vh',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
      marginLeft:'10px',marginRight:"10px"     // Ensure the line spans the height of the flex container
    }}
  />        
    




        <div style={{ flex: 1 }}>

        {fieldsVisible == '1' ?( 
          
          <Form
          // initialValues={props.cancelClassDetails}
          form={props.formSection}
          onFinish={props?.onFinishSectionAdd}
          // onFinish2={props?.onFinish}

          autoComplete="off"
          layout="vertical"
          initialValues={{
            selected_id: contentList[0].value, // Default to first item's value
            ...props.cancelClassDetails, // Spread the values from cancelClassDetails

          }}
        >

<div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,marginTop:"12px" }}>
            <Form.Item
              label="XP"
              name="xp"
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"XP"}
                name={"xp"}
                value={props.xp}
                onChange={(e) => props.handleClassChange(e, { name: "xp" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>


          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" ,marginTop:"10px",marginBottom:"10px"}}>
  <p style={{ margin: 0 }}>Badges</p>
  <img src={plus} alt="plus-icon" style={{ height: "25px", width: "20px" }}  onClick={() => handleAddBadgesModal()}/>
</div>


<div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 

{/* <div style={styles.gridContainer}>
      {data.map(item => (
        <div key={item.id} style={styles.gridItem}>
          <img src={item.imgSrc} alt={item.name} style={styles.image} />
          <p style={styles.name}>{item.name}</p>
        </div>
      ))}
    </div> */}

<div style={styles.gridContainer}>
      {data.map(item => (
        <div
          key={item.id}
          style={{
            ...styles.gridItem,
            border: selectedId === item.id ? "2px solid blue" : "1px solid #ddd",
            backgroundColor: selectedId === item.id ? "#e0f7ff" : "#f9f9f9"
          }}
          onClick={() => handleItemClick(item.id)}
        >
          <img src={item.imgSrc} alt={item.name} style={styles.image} />
          <p style={styles.name}>{item.name}</p>

          <button
            style={styles.deleteButton}
            onClick={(e) => {
              e.stopPropagation(); // Prevent item click event when delete button is clicked
              deleteItem(item.id);
            }}
          >
            Delete
          </button>
        </div>
      ))}
    </div>
        


        </Form>):fieldsVisible == '2' ?( <Form
          // initialValues={props.cancelClassDetails}
          form={props.formContent}
          onFinish={props?.onFinishContextAdd}
          // onFinish2={props?.onFinish}

          autoComplete="off"
          layout="vertical"
          initialValues={{
            selected_id: contentList[0].value, // Default to first item's value
            ...props.coursesContentListPayloadDetails, // Spread the values from cancelClassDetails

          }}
        >

<div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,marginTop:"12px" }}>
            <Form.Item
              label="XP"
              name="xp"
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please enter xp',
              //   },
              // ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"XP"}
                name={"xp"}
                value={props.xp}
                onChange={(e) => props.handleClassMultipleCoursesContent(e, { name: "xp" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>


        </Form>): fieldsVisible == '3' ?( <Form
          // initialValues={props.cancelClassDetails}
          form={props.formReference}
          onFinish={props?.onFinishReferenceAdd}
          // onFinish2={props?.onFinish}

          autoComplete="off"
          layout="vertical"
          initialValues={{
            selected_id: contentList[0].value, // Default to first item's value
            ...props.referencePayloadDetails, // Spread the values from cancelClassDetails

          }}
        >

<div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,marginTop:"12px" }}>
            <Form.Item
              label="XP"
              name="xp"
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"XP"}
                name={"xp"}
                value={props.xp}
                onChange={(e) => addContentReference(e, { name: "xp" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>


        </Form>): fieldsVisible == '4' ?( <Form
          // initialValues={props.cancelClassDetails}
          form={props.formAssignment}
          onFinish={props?.onFinishAssignmentAdd}
          // onFinish2={props?.onFinish}

          autoComplete="off"
          layout="vertical"
          initialValues={{
            selected_id: contentList[0].value, // Default to first item's value
            ...props.assignmentsPayloadDetails, // Spread the values from cancelClassDetails

          }}
        >

<div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,marginTop:"12px" }}>
            <Form.Item
              label="XP"
              name="xp"
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"XP"}
                name={"xp"}
                value={props.xp}
                onChange={(e) => props.handleClassSingleAssignmentChange(e, { name: "xp" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>


        </Form>): fieldsVisible == '5' ?( <Form
          // initialValues={props.cancelClassDetails}
          form={formQuestionAndAns}
          onFinish={onFinishQuestionnAdd}
          // onFinish2={props?.onFinish}

          autoComplete="off"
          layout="vertical"
          initialValues={{
            selected_id: contentList[0].value, // Default to first item's value
            allQuestionAndAnser, // Spread the values from cancelClassDetails

          }}
        >

<div className="studentNames" style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: 1 ,marginTop:"12px" }}>
            <Form.Item
              label="XP"
              name="xp"
              // rules={[
              //   {
              //     required: true,
              //     message: 'Please enter xp',
              //   },
              // ]}
              style={{ flex: 1 }}
            >
              <InputComponent
                placeholder={"XP"}
                name={"xp"}
                value={props.xp}
                onChange={(e) => props.handleClassChange(e, { name: "xp" })}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </div>


        </Form>): 

        (<div> </div>)
        
      }


       

    
        </div>

      
        


        </div>


        <div
    style={{
      backgroundColor: '#D3D3D3', // Set the color of the vertical line
      width: '100%',            // Set the width of the line
      height: '0.1px',          // Set the height of the line (or use a specific pixel value)
      alignSelf: 'stretch' ,
     marginTop:'10px',marginBottom:"10px"    // Ensure the line spans the height of the flex container
    }}
  /> 

  {fieldsVisible == '1' ? (<Form
          form={props.formSection}
          onFinish={props?.onFinishSectionAdd}

          autoComplete="off"
          layout="vertical"
          initialValues={{
            selected_id: contentList[0].value, // Default to first item's value
            ...props.cancelClassDetails, // Spread the values from cancelClassDetails
          }}
        >
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Form.Item>


<Button htmlType='submit'   style={{
    border: '1px solid #0E60B2',  // Red border
    color: '#0E60B2',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px",marginRight:"10px"
  }} onClick={props.handleClassChangeSingleAdd}>
                Save
              </Button>

              <Button htmlType='submit' type='primary' style={{ marginRight: '8px', width:"110px" }} onClick={props.handleClassChange}>
                Save & New
              </Button>
            
              <Button
  htmlType="submit"
  onClick={props.handleCancel}
  style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px"
  }}
>
  Cancel
</Button>

            </Form.Item>
 </div>
        </Form>) : fieldsVisible == '2' ? (<Form
          form={props.formContent}
          onFinish={props?.onFinishContextAdd}

          autoComplete="off"
          layout="vertical"
          initialValues={{
            selected_id: contentList[0].value, // Default to first item's value
            ...props.coursesContentListPayloadDetails, // Spread the values from cancelClassDetails
          }}
        >
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Form.Item>


<Button htmlType='submit'   style={{
    border: '1px solid #0E60B2',  // Red border
    color: '#0E60B2',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px",marginRight:"10px"
  }} onClick={props.handleClassSingleCoursesContentChange}>
                Save
              </Button>

              <Button htmlType='submit' type='primary' style={{ marginRight: '8px', width:"110px" }} onClick={props.handleClassMultipleCoursesContent}>
                Save & New
              </Button>
            
              <Button
  htmlType="submit"
  onClick={props.handleCancel}
  style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px"
  }}
>
  Cancel
</Button>

            </Form.Item>
 </div>
        </Form>) :fieldsVisible == '3' ? (<Form
          form={props.formReference}
          onFinish={props?.onFinishReferenceAdd}

          autoComplete="off"
          layout="vertical"
          initialValues={{
            selected_id: contentList[0].value, // Default to first item's value
            ...props.referencePayloadDetails, // Spread the values from cancelClassDetails
          }}
        >
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Form.Item>


<Button htmlType='submit'   style={{
    border: '1px solid #0E60B2',  // Red border
    color: '#0E60B2',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px",marginRight:"10px"
  }} onClick={handleClassSingleReferenceChange}>
                Save
              </Button>

              <Button htmlType='submit' type='primary' style={{ marginRight: '8px', width:"110px" }} onClick={handleClassMultipleReference}>
                Save & New
              </Button>
            
              <Button
  htmlType="submit"
  onClick={props.handleCancel}
  style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px"
  }}
>
  Cancel
</Button>

            </Form.Item>
 </div>
        </Form>) :fieldsVisible == '4' ? (<Form
          form={props.formAssignment}
          onFinish={props?.onFinishAssignmentAdd}

          autoComplete="off"
          layout="vertical"
          initialValues={{
            selected_id: contentList[0].value, // Default to first item's value
            ...props.assignmentsPayloadDetails, // Spread the values from cancelClassDetails
          }}
        >
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Form.Item>


<Button htmlType='submit'   style={{
    border: '1px solid #0E60B2',  // Red border
    color: '#0E60B2',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px",marginRight:"10px"
  }} onClick={props.handleClassMultipleAssignment}>
                Save
              </Button>

              <Button htmlType='submit' type='primary' style={{ marginRight: '8px', width:"110px" }} onClick={props.handleClassSingleAssignmentChange}>
                Save & New
              </Button>
            
              <Button
  htmlType="submit"
  onClick={props.handleCancel}
  style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px"
  }}
>
  Cancel
</Button>

            </Form.Item>
 </div>
        </Form>) :fieldsVisible == '5' ? (<Form
          form={formQuestionAndAns}
          onFinish={onFinishQuestionnAdd}

          autoComplete="off"
          layout="vertical"
          initialValues={{
            selected_id: contentList[0].value, // Default to first item's value
            allQuestionAndAnser, // Spread the values from cancelClassDetails
          }}
        >
<div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Form.Item>


<Button htmlType='submit'   style={{
    border: '1px solid #0E60B2',  // Red border
    color: '#0E60B2',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px",marginRight:"10px"
  }} onClick={handleClassChangeSingleQuestion}>
                Save
              </Button>

              <Button htmlType='submit' type='primary' style={{ marginRight: '8px', width:"110px" }} onClick={handleClassChangeMultipleQuestion}>
                Save & New
              </Button>
            
              <Button
  htmlType="submit"
  onClick={props.handleCancel}
  style={{
    border: '1px solid red',  // Red border
    color: 'red',              // Red text color
    backgroundColor: 'transparent',  // No background color
    width: "110px"
  }}
>
  Cancel
</Button>

            </Form.Item>
 </div>
        </Form>) :
        (<div></div>)}




{addBadgesVisible ? 
  <ModalComponent title={"Add Badges"}
 
  content={<AddBadgesModal 
   handleCancel={handleDeleteCancel}
   addBadgesDetailsPayload = {addBadgesDetailsPayload}
   formBadges={formBadges}
onFinishBadges={onFinishBadges} 
handleClassBadges={handleClassBadges}
   />}
open={addBadgesVisible} 


handleCancel={handleDeleteCancel}

style={{ minHeight: `500px` }}
 ></ModalComponent>

:""}

    
    </div>
  );
};

// const styles = {
//   gridContainer: {
//     display: "grid",
//     gridTemplateColumns: "repeat(5, 1fr)", // 4 columns
//     gap: "10px", // space between items
//     padding: "15px"
//   },
//   gridItem: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     justifyContent: "center",
//     border: "1px solid #ddd",
//     padding: "5px",
//     borderRadius: "8px",
//     backgroundColor: "#f9f9f9"
//   },
//   image: {
//     marginTop:"10px",
//     width: "20px",
//     height: "auto",
//     maxHeight: "20px",
//     objectFit: "cover",
//     borderRadius: "5px"
//   },
//   name: {
//     marginTop: "10px",
//     fontSize: "10px",
//     textAlign: "center",
//     color: "#333"
//   }
// };

const styles = {
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gap: "10px",
    padding: "15px"
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "0.3s" // Smooth transition for style change
  },
  image: {
    marginTop: "10px",
    width: "20px",
    height: "20px",
    objectFit: "cover",
    borderRadius: "5px"
  },
  name: {
    marginTop: "10px",
    fontSize: "10px",
    textAlign: "center",
    color: "#333"
  },
  deleteButton: {
    padding: "5px 10px",
    fontSize: "10px",
    color: "#fff",
    backgroundColor: "#ff4d4f",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer"
  }
};

export default AddCourseContentModal;
